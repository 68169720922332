import React, { useEffect, useState, createContext } from "react";
import PersonalInformation from "./PersonalInformation";
import NewTenant from "./NewTenant";
import UploadDocument from "./UploadDocument";
import DocumentUploads from "./DocumentUploads";

export const TenantContext = createContext(undefined);

const AddTenant = (props) => {
  const [nextPage, setNextPage] = useState("PersonalInformation")
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    contact: '',
    job: '',
    age: '',
    family_members: '',
    email: '',
    password: '',
    previousAddress: '',
    previousCountry: '',
    previousState: '',
    previousCity: '',
    previousZipCode: '',
    permanentAddress: '',
    permanentCountry: '',
    permanentState: '',
    permanentCity: '',
    permanentZipCode: '',
    property_id:'',
    unit_id: '',
    lease_start_date: '',
    lease_end_date: '',
    lease_renewal: '',
    general_rent: '',
    security_deposit: '',
    late_fee: '',
    incident_receipt: ''
  })
  
  const handleNextPage = (val) => {
    setNextPage(val)
  }

  const updateFormData = (data) => {
    setFormData({...formData,...data})
  }

  const { pageStatus, unit, property } = props;

  return (
    <>
      <TenantContext.Provider value={{
        handleNextPage:handleNextPage,
        updateFormData:updateFormData,
        formData:formData,
        changePage:pageStatus,
        unitList:unit,
        propertyList:property
      }}>
          {nextPage === "PersonalInformation" && <PersonalInformation  /> }
          {nextPage === "NewTenant" && <NewTenant /> }
          {nextPage === "UploadDocument" && <UploadDocument /> }
      </TenantContext.Provider>
    </>
  );
};

export default AddTenant;

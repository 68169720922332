import React, { useContext } from 'react';
import { Box, FormControl, FormControlLabel, RadioGroup, Radio, Grid, TextField, FormLabel, Select, MenuItem } from '@mui/material';
import { Formik, Form } from 'formik';
import { NewPropertyValidation } from './PropertyValidationSchema';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { PropertyContext } from './Main';
import { AddPropertyContext } from './AddProperty';
import { useAppDispatch } from '../../Store/hooks';
import { showLoader } from '../../Store/loaderSlice';
import { showToaster } from '../../Store/toastSlice';
import { PostApiMethod, PutApiMethod } from '../../Utils/ApiService';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const NewProperty = () => {
    const handleChangePT = (e,setFieldValue) => {
        setFieldValue("lease_amount",'');
        setFieldValue("lease_start_date",'');
        setFieldValue("lease_end_date",'');
    }

    const property = useContext(PropertyContext);
    const addpropertyform = useContext(AddPropertyContext);
    const dispatch = useAppDispatch();

    const updateProperty = async(values) => {
        dispatch(showLoader({showLoader:true}))
        try{
            let res = await PutApiMethod(`property/properties/${addpropertyform.data.property_id}`,values);
            if(res["data"]["error"] === false){
                dispatch(showToaster({dialogBgColor: "bg-success",
                    dialogMsg: res["data"]["message"],
                    showDialog: true,
                    timer: "3000"
                }));
                property.getPropertyList(property.propertyPage)
                addpropertyform.formSubmit("Location",values);
            }
        }catch(error){
            dispatch(showToaster({dialogBgColor: "bg-danger",
                dialogMsg: "Property details not updated",
                showDialog: true,
                timer: "3000"
            }));
        }
        dispatch(showLoader({showLoader:false}))
    }

    const addProperty = async(values) => {
        dispatch(showLoader({showLoader:true}));
        try{
            let res = await PostApiMethod("property/properties/add",values);
            if(res["data"]["error"] === false){
                dispatch(showToaster({dialogBgColor: "bg-success",
                    dialogMsg: res["data"]["message"],
                    showDialog: true,
                    timer: "3000"
                }));
                property.getPropertyList(property.propertyPage);
                let data = res["data"]["results"]["data"]
                addpropertyform.formSubmit("Location",{...values,property_id:data["id"],user_id:data["owner_user_id"]});
            }
        }catch(error){
            console.log(error)
            dispatch(showToaster({dialogBgColor: "bg-danger",
                dialogMsg: "Property not created",
                showDialog: true,
                timer: "3000"
            }));
        }
        dispatch(showLoader({showLoader:false}))
    }
    
    return (
        <>
            <Box className="mt-4 d-flex justify-content-between">
                <Box>
                    <h5 className='fw-bold'>{ property.isEdit ? <><ArrowBackIcon sx={{cursor:'pointer'}} onClick={property.onBackPropertyList}/>Edit</> : "Add New" } Property</h5>
                    <p sx={{color:'gray'}}>Property Information</p>
                </Box>
            </Box>
            <Formik
                initialValues={{
                    property_type:addpropertyform.data.property_type,
                    property_name:addpropertyform.data.property_name,
                    number_of_unit:addpropertyform.data.number_of_unit,
                    description:addpropertyform.data.description,
                    lease_amount:addpropertyform.data.lease_amount,
                    lease_start_date:addpropertyform.data.lease_start_date,
                    lease_end_date:addpropertyform.data.lease_end_date,
                    maintainer_name:addpropertyform.data.maintainer_name,
                    number_of_parking:addpropertyform.data.number_of_parking,
                    property_category:addpropertyform.data.property_category
                }}
                validationSchema={NewPropertyValidation}
                onSubmit={async(values) => {
                    if(property.isEdit || addpropertyform.data.property_id){
                        updateProperty(values);
                    }else{
                        addProperty(values);
                    }
                }}
            >
                {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                        <FormControl component="fieldset" margin="normal">
                            <h6>Select Property Type</h6>
                            <RadioGroup row defaultValue={values.property_type} onChange={(e) => handleChangePT(e,setFieldValue)}>
                                <FormControlLabel value="own" control={<Radio name="property_type" onChange={handleChange} />} label="Own Property" />
                                <FormControlLabel value="lease" control={<Radio name="property_type" onChange={handleChange}/>} label="Lease Property" />
                            </RadioGroup>
                        </FormControl>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <FormLabel className="fw-bold">Property Name</FormLabel>
                                <TextField 
                                    name="property_name"
                                    type="text"
                                    className='form-control'
                                    value={values.property_name}
                                    variant='standard'
                                    placeholder='Type here...'
                                    onChange={handleChange}
                                />
                                {touched.property_name && errors.property_name && (<div className='text-danger'>{errors.property_name}</div>)}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <FormLabel className="fw-bold">Number of Units</FormLabel>
                                <TextField 
                                    name="number_of_unit"
                                    type="number"
                                    className='form-control'
                                    value={values.number_of_unit}
                                    variant='standard'
                                    placeholder='Type here...'
                                    onChange={handleChange}
                                />
                                {touched.number_of_unit && errors.number_of_unit && (<div className='text-danger'>{errors.number_of_unit}</div>)}
                            </Grid>
                            {values.property_type === 'lease' && <>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <FormLabel className='fw-bold'>Lease Amount</FormLabel>
                                    <TextField 
                                        name="lease_amount"
                                        type="number"
                                        className='form-control'
                                        value={values.lease_amount}
                                        variant='standard'
                                        placeholder='Type here...'
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <FormLabel className='fw-bold'>Lease Start Date</FormLabel>
                                    <TextField 
                                        name="lease_start_date"
                                        type="date"
                                        className='form-control'
                                        value={values.lease_start_date}
                                        variant='standard'
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <FormLabel className='fw-bold'>Lease End Date</FormLabel>
                                    <TextField 
                                        name="lease_end_date"
                                        type="date"
                                        className='form-control'
                                        value={values.lease_end_date}
                                        variant='standard'
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </>}
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <FormLabel className="fw-bold">Description</FormLabel>
                                <TextField 
                                    name="description"
                                    type="text"
                                    className='form-control'
                                    value={values.description}
                                    variant='standard'
                                    placeholder='Type here...'
                                    onChange={handleChange}
                                />
                                {touched.description && errors.description && (<div className='text-danger'>{errors.description}</div>)}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <FormLabel className="fw-bold">Maintainer Name</FormLabel>
                                <TextField 
                                    name="maintainer_name"
                                    type="text"
                                    className="form-control"
                                    value={values.maintainer_name}
                                    variant='standard'
                                    placeholder='Type here...'
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <FormLabel className='fw-bold'>Number of Parking</FormLabel>
                                <TextField 
                                    name="number_of_parking"
                                    type="text"
                                    className="form-control"
                                    value={values.number_of_parking}
                                    variant='standard'
                                    placeholder='Type here...'
                                    onChange={handleChange}
                                />
                            </Grid> 
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <FormLabel className='fw-bold'>Property Category</FormLabel>
                                <Select displayEmpty value={values.property_category} className='w-100' variant="standard" name="property_category">
                                    <MenuItem disabled value=""><em>Choose</em></MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                        <Box className="mt-4">
                            <button type="button" className='btn btn-white' onClick={property.onBackPropertyList}><ArrowBackIosIcon style={{fontSize:'18px'}}/>Back</button>
                            <button type="submit" className='btn btn-danger ms-2'>Save & Proceed Next</button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default NewProperty
import React, { useEffect, useState } from "react";
import CustomTextfield from "../../components/textfield1";
import styles from "./propertyInformation.module.css";
import RadioButton1 from "../../components/radioButton";
import { radioButton1 } from "../../types/interface";
import { FormButton1 } from "../../components/formButton";
import { PostApiMethod, PutApiMethod } from "../../../../Utils/ApiService";
import { useAppDispatch } from "../../../../Store/hooks";
import { showLoader } from "../../../../Store/loaderSlice";
import { showToaster } from "../../../../Store/toastSlice";
import { isApiConnected } from "../../controls";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import BasicDatePicker from "../../components/datePicker";
import { PostApiMethod1 } from "../../api_service";
import { Box } from "@mui/system";
import { Card, Typography } from "@mui/material";
import propertyImg from "../../../../Assets/images/icons/property.png";

interface PropertyInformationFormType {
  backBtn: Function;
  submitBtn: Function;
  forUpdatePropertyValues?: any;
  isForUpdate?: boolean;
}
interface ApiBody {
  property_type: string;
  property_name: string;
  number_of_unit: string;
  description: string;
  lease_amount: string;
  lease_start_date: string;
  lease_end_date: string;
  id: string;
}
const PropertyInformationForm: React.FunctionComponent<
  PropertyInformationFormType
> = ({ backBtn, submitBtn, forUpdatePropertyValues, isForUpdate = false }) => {
  const [Own_or_lease, setOwn_or_lease] = useState("own");
  const [FormContents, setFormContents] = useState(ownPropertyContents); //leasePropertyContents
  const [ApiBodyFinal, setApiBodyFinal] = useState<ApiBody>({
    property_type: Own_or_lease,
    property_name: "",
    number_of_unit: "",
    description: "",
    lease_amount: "",
    lease_start_date:"",
    lease_end_date:"",
    id: "",
  });
  const [LeaseStartDate, setLeaseStartDate] = useState();
  const dispatch = useAppDispatch();

  function setInputValue(
    InputValue: any,
    inputName: string,
    currentIndex: number
  ) {
    setApiBodyFinal({ ...ApiBodyFinal, [inputName]: InputValue });
  }

  function datePicker(key: any, value: any) {
    setApiBodyFinal({ ...ApiBodyFinal, [key]: value });
    setLeaseStartDate(value);
  }

  useEffect(() => {
    if (forUpdatePropertyValues) setApiBodyFinal(forUpdatePropertyValues);
  }, [forUpdatePropertyValues]);
  function changePropertyType(keyValueName: any, selectedProperty: any) {
    setInputValue(keyValueName, selectedProperty, 0);
    console.log("selectedProperty: " + selectedProperty, keyValueName);
    console.log("ApiBodyFinal: " + JSON.stringify(ApiBodyFinal));
    const emptyOBj = {};
    setOwn_or_lease(selectedProperty);
    // setApiBodyFinal(emptyOBj);
    setApiBodyFinal({ ...ApiBodyFinal, [keyValueName]: selectedProperty });
    setFormContents(
      selectedProperty === "own"
        ? ownPropertyContents
        : selectedProperty === "lease"
        ? leasePropertyContents
        : ownPropertyContents
    );
  }

  function backBtnFunc() {
    backBtn("Home");
  }

  async function submmitForm(event: any) {
    event.preventDefault();

    if (isApiConnected) {
    } else {
      submitBtn("Property Information", 1);
      return;
    }
    dispatch(showLoader({ showLoader: true }));
    // sampleRealDataForDevelopment
    // ApiBodyFinal
    if (isForUpdate) {
      PutApiMethod("property/properties/" + ApiBodyFinal.id, ApiBodyFinal)
        .then((res) => {
          const PropertyDetails: any = ApiBodyFinal;
          dispatch(
            showToaster({
              dialogBgColor: "bg-success",
              dialogMsg: "Updated successfully",
              showDialog: true,
              timer: "3000",
            })
          );
          submitBtn(
            "Property Information",
            ApiBodyFinal.id,
            PropertyDetails.owner_user_id,
            PropertyDetails.country_id,
            PropertyDetails.number_of_unit,
            ApiBodyFinal
          );
          dispatch(showLoader({ showLoader: false }));
        })
        .catch((e) => {
          dispatch(
            showToaster({
              dialogBgColor: "bg-danger",
              // dialogMsg: e.response.data.message,
              dialogMsg: "Error, Try again",
              showDialognull: true,
              timer: "5000",
            })
          );
          dispatch(showLoader({ showLoader: false }));
        });
    } else {
      try {
        const res = await PostApiMethod1(
          "property/properties/add",
          ApiBodyFinal
        );

        if (res["data"]["code"] === 201) {
          const PropertyDetails = res["data"]["results"]["data"];
          dispatch(
            showToaster({
              dialogBgColor: "bg-success",
              dialogMsg: "Property Created",
              showDialog: true,
              timer: "3000",
            })
          );
          submitBtn(
            "Property Information",
            PropertyDetails.id,
            PropertyDetails.owner_user_id,
            PropertyDetails.country_id,
            PropertyDetails.number_of_unit,
            ApiBodyFinal
          );
          dispatch(showLoader({ showLoader: false }));
        }
      } catch (e: any) {
        dispatch(
          showToaster({
            dialogBgColor: "bg-danger",
            // dialogMsg: e.response.data.message,
            dialogMsg: "Error, Try again",
            showDialog: true,
            timer: "5000",
          })
        );
        dispatch(showLoader({ showLoader: false }));
      }
    }
  }

  return (
    <>
      <Card elevation={0}>
        <Box className="h-48 d-flex justify-content-between align-items-center">
          <Box className="d-flex align-items-center">
            <img src={propertyImg} alt="Property" width={20} />
            <Typography className="psemiboldfont page-header-text">
              Property
            </Typography>
          </Box>
        </Box>
        <Typography className="pb-10 mb-2">Select Property Type</Typography>
        <form onSubmit={submmitForm} className={styles.form}>
          <Box className={`${styles.radioBox} pb-30 mb-3`}>
            <RadioButton1
              name={"property_type"}
              valueChange={setInputValue}
              label={"Own Property"}
              value={"own"}
              isChecked={
                ApiBodyFinal.property_type.toLocaleLowerCase() === "own"
              }
              forUpdateRadioValue={forUpdatePropertyValues}
              indexOfTextField={0}
            />
            <RadioButton1
              name={"property_type"}
              valueChange={setInputValue}
              label={"Lease Property"}
              value={"lease"}
              isChecked={
                ApiBodyFinal.property_type.toLocaleLowerCase() === "lease"
              }
              forUpdateRadioValue={forUpdatePropertyValues}
              indexOfTextField={0}
            />
          </Box>
          {ApiBodyFinal.property_type.toLocaleLowerCase() === "own" ? (
            <div className={styles.inputTotalBox}>
              <CustomTextfield
                label="Property Name"
                name="property_name"
                type="text"
                valueChange={setInputValue}
                value={ApiBodyFinal.property_name}
              />
              <CustomTextfield
                label="Number of Units"
                name="number_of_unit"
                type="number"
                valueChange={setInputValue}
                value={ApiBodyFinal.number_of_unit}
                disable={isForUpdate}
              />
              <CustomTextfield
                label="Description"
                name="description"
                type="text"
                valueChange={setInputValue}
                value={ApiBodyFinal.description}
              />
            </div>
          ) : null}
          {ApiBodyFinal.property_type.toLocaleLowerCase() === "lease" ? (
            <div className={styles.inputTotalBox}>
              <CustomTextfield
                label="Property Name"
                name="property_name"
                type="text"
                valueChange={setInputValue}
                value={ApiBodyFinal.property_name}
              />
              <CustomTextfield
                label="Number of Units"
                name="number_of_unit"
                type="number"
                valueChange={setInputValue}
                value={ApiBodyFinal.number_of_unit}
                disable={isForUpdate}
              />
              <CustomTextfield
                label="Lease Amount"
                name="lease_amount"
                type="number"
                valueChange={setInputValue}
                value={ApiBodyFinal.lease_amount}
              />
              <BasicDatePicker
                label={"Lease Start Date"}
                name={"lease_start_date"}
                val={ApiBodyFinal.lease_start_date}
                valueChange={setInputValue}
                // defaultValue={ApiBodyFinal.lease_start_date}
              />
              <BasicDatePicker
                label={"Lease End Date"}
                name={"lease_end_date"}
                val={ApiBodyFinal.lease_end_date}
                valueChange={setInputValue}
                // defaultValue={ApiBodyFinal.lease_end_date}
              />
              <CustomTextfield
                label="Description"
                name="description"
                type="text"
                valueChange={setInputValue}
                value={ApiBodyFinal.description}
              />
            </div>
          ) : null}

          <div className={styles.btn}>
            <FormButton1 backBtnClick={backBtnFunc} label={"Save & Proceed Next"}/>
          </div>

          {/*                 
          {FormContents.map((val, index) => (
            <div key={index} className={styles.inputTotalBox}>
              {val.type === "text" ? (
                <CustomTextfield
                  label={val.lable}
                  name={val.name}
                  type={val.type}
                  valueChange={setInputValue}
                />
              ) : val.type === "number" ? (
                <CustomTextfield
                  label={val.lable}
                  name={val.name}
                  type={val.type}
                  valueChange={setInputValue}
                />
              ) : val.type === "radio" ? (
                <p>radio</p>
              ) : val.type === "dropdowm" ? (
                <p>dropdowm</p>
              ) : val.type === "date" ? (
                <p>date</p>
              ) : null}
            </div>
          ))}
          {Own_or_lease === "own" ? (
            ""
          ) : (
            <div className={styles.inputTotalBox}>
              <BasicDatePicker
                label={"Lease Start Date"}
                name={"lease_start_date"}
                valueChange={setInputValue}
              />
            </div>
          )}

          {Own_or_lease === "own" ? (
            ""
          ) : (
            <div className={styles.inputTotalBox}>
              <BasicDatePicker
                label={"Lease End Date"}
                name={"lease_end_date"}
                valueChange={setInputValue}
              />
            </div>
          )}
          <div className={styles.inputTotalBox}>
            <CustomTextfield
              label="Description"
              name="description"
              type="text"
              valueChange={setInputValue}
              currentIndex={2}
            />
          </div> */}
        </form>
        {/* <input type="text" placeholder="place" name="lllll" /> */}
      </Card>
    </>
  );
};
export default PropertyInformationForm;

interface PropertyContent {
  type: "text" | "number";
  lable: string;
  name: string;
}
const ownPropertyContents: PropertyContent[] = [
  {
    type: "text",
    lable: "Property Name",
    name: "property_name",
  },
  {
    type: "number",
    lable: "Number of Units",
    name: "number_of_unit",
  },
  {
    type: "text",
    lable: "Description",
    name: "description",
  },
];

const leasePropertyContents: PropertyContent[] = [
  {
    type: "text",
    lable: "Property Name",
    name: "property_name",
  },
  {
    type: "number",
    lable: "Number of Units",
    name: "number_of_unit",
  },
  {
    type: "number",
    lable: "Lease Amount",
    name: "lease_amount",
  },
  // {
  //   type: "text",
  //   lable: "Lease Start Date",
  //   name: "lease_start_date",
  // },
  // {
  //   type: "number",
  //   lable: "Lease End Date",
  //   name: "lease_end_date",
  // },
  // {
  //   type: "text",
  //   lable: "Description",
  //   name: "description",
  // },
];

const sampleRealDataForDevelopment = {
  property_type: "own",
  property_name: "qqqq",
  number_of_unit: "2",
  description: "descr",
};

import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { AuthorizedLayout } from '../Layouts/AuthorizedLayout';
import { Typography } from '@mui/material';
import EngineeringIcon from '@mui/icons-material/Engineering';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import CustomTabPanel from '../../Components/Tab/CustomTabPanel';
import { SelectChangeEvent } from '@mui/material/Select';
import AddMaintainers from './AddMaintainers';
import MaintenanceRequest from './MaintenanceRequest';
import { AntTabs, AntTab } from '../../Components/Tab/AntTab';

const Maintainers = () => {
    const [tabValue, setTabValue] = useState(0);
    const [property, setProperty] = useState('');
    const [maintenanceButton, setMaintenanceButton] = useState(true);
    const [addNew, setAddNew] = useState({addMaintainers:false,addRequest:false});
    const [data, setData] = useState([]);
    const [showAddMaintainer, setShowAddMaintainer] = useState(true);

    const handleChange = (e:any,val:any) => {
        setTabValue(val)
        setAddNew({addMaintainers:false,addRequest:false})
        setMaintenanceButton(val === 1 ? false : true)

    }


    const handleProperty = (e:SelectChangeEvent) => {
        setProperty(e.target.value)
    }

    const handleMaintainers = () => {
        setAddNew({...addNew,addMaintainers:!addNew['addMaintainers']})
        setShowAddMaintainer(!showAddMaintainer)
    }
    return (
        <>
            <AuthorizedLayout>
                <Card variant="outlined" sx={{borderRadius:'8px'}} className="border-0" style={{height: '90vh'}}>
                    <CardContent>
                        <Box className="d-flex justify-content-between align-items-center">
                            <Box className="d-flex align-items-center">
                                <EngineeringIcon className='fs-20' style={{color:'#FF7D73'}}/>
                                <Typography className="psemiboldfont" style={{color:'#2C2C2C', marginLeft: 10}}>Supplier</Typography>
                                <AntTabs value={tabValue} onChange={handleChange}>
                                    <AntTab label="All Suppliers" className='tab-menu' />
                                    {/* <AntTab label="Maintenance Request" className='tab-menu '/> */}
                                </AntTabs>
                            </Box>
                            <Box>
                                {maintenanceButton ? showAddMaintainer && <button  className='btn-danger' onClick={() => setAddNew({...addNew,addMaintainers:true})}><AddIcon  className='fs-23 '/>Add New Supplier</button>
                                :<button  className='btn-danger'  onClick={() => setAddNew({...addNew,addRequest:!addNew['addRequest']})}><AddIcon className='fs-23'/>Add Maintenance Request</button>}
                            </Box>
                        </Box>
                        {/* {!Object.values(addNew).includes(true) && <Box sx={{mt:3}}>
                           <Grid container spacing={2}>
                            <Grid item lg={6} md={6} xs={12} sm={12}>
                                <FormControl variant="standard" className="w-100">
                                    <Typography className="poppins-family fs-16" >Select Property</Typography>
                                    <Select className="w-100" value={property} displayEmpty onChange={handleProperty}>
                                        <MenuItem value="" disabled>Choose</MenuItem>
                                        <MenuItem value={10}>Unity Enclove</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                           </Grid>
                        </Box>} */}
                        <Box>
                            <CustomTabPanel value={tabValue} index={0} >
                                <AddMaintainers addMaintainers={addNew["addMaintainers"]} handleMaintainers={handleMaintainers}/>
                            </CustomTabPanel>
                            {/* <CustomTabPanel value={tabValue} index={1}>
                                <MaintenanceRequest addRequest={addNew["addRequest"]}/>
                            </CustomTabPanel> */}
                        </Box>
                    </CardContent>
                </Card>
            </AuthorizedLayout>
        </>
    )
}

export default Maintainers
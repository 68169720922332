import React, { memo, useContext } from 'react';
import { Typography, Box, Grid, MenuItem, TextField } from '@mui/material';
import { Formik, Form } from 'formik';
import { bookingSchema } from './AmenitiesSchema';
import Select from '@mui/material/Select';
import { AmenitiesContext } from './Amenities';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import { useAppDispatch } from '../../Store/hooks';
import { showLoader } from '../../Store/loaderSlice';
import { showToaster } from "../../Store/toastSlice";
import { PostApiMethod } from '../../Utils/ApiService';

const AddBooking = () => {
    const dispath = useAppDispatch();
    const amenities = useContext(AmenitiesContext);
    const handleTimeChanged = (name, value, setFieldValue) => {
        setFieldValue(name, value);
    };

    const createBooking = async(formData, resetForm) => {
        dispath(showLoader({showLoader:true}));
        try {
            let response = await PostApiMethod("property/amenities-booking/add",formData);
            if(response['data']['error'] === false){
                resetForm({values:''});
                amenities.getBookingList(amenities.page);
                amenities.onBack();
                dispath(showToaster({
                    dialogBgColor: "bg-success",
                    dialogMsg: response["data"]["message"],
                    showDialog: true,
                    timer: "3000"
                }));
            }
        }catch(error){
            dispath(showToaster({
                dialogBgColor: "bg-danger",
                dialogMsg: error.response ? error.response.data.message : "Failed to create booking",
                showDialog: true,
                timer: "3000"
            }));
        }finally{
            dispath(showLoader({showLoader:false}));
        }
    }

    return (
        <>
            <Box className="mt-4">
                <Typography className='fw-bold fs-3'>Add New Booking</Typography>
            </Box>
            <Box>
                <Formik
                    initialValues={{
                        propertyId:'',
                        unitId:'',
                        userId:'',
                        amenitiesId:'',
                        bookingdate:'',
                        startTime:'',
                        endTime:'',
                        no_of_people:''
                    }}
                    validationSchema={bookingSchema}
                    onSubmit={async(values, {resetForm}) => {
                        const formData = new FormData();
                        Object.keys(values).map((val,key) => {
                            if(val === 'startTime'){
                                formData.append(val,dayjs(values['startTime']).format('hh:mm A'))
                            }else if(val === 'endTime'){
                                formData.append(val,dayjs(values['endTime']).format('hh:mm A'))
                            }else{
                                formData.append(val,values[val])
                            }
                        })
                        createBooking(formData,resetForm)
                    }}
                >
                    {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }) => (
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={3} className="mt-3"> 
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Property</Typography>
                                    <Select displayEmpty className="w-100 mt-2" name="propertyId" variant='standard'value={values.propertyId} onChange={handleChange}>
                                        <MenuItem disabled value="">
                                            <em>Choose</em>
                                        </MenuItem>
                                        {amenities.propertiesList.length !== 0 && amenities.propertiesList.map((val,key) => (
                                            <MenuItem value={val.id}>{val.property_name}</MenuItem>
                                        ))}
                                    </Select>
                                    {touched.propertyId && errors.propertyId && (<div className='text-danger'>{errors.propertyId}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Unit Name</Typography>
                                    <Select displayEmpty className="w-100 mt-2" name="unitId" variant='standard'value={values.unitId} onChange={handleChange}>
                                        <MenuItem disabled value="">
                                            <em>Choose</em>
                                        </MenuItem>
                                        {amenities.unitList.length !== 0 && amenities.unitList.map((val,key) => (
                                            <MenuItem value={val.id}>{val.unit_name}</MenuItem>
                                        ))}
                                    </Select>
                                    {touched.unitId && errors.unitId && (<div className='text-danger'>{errors.unitId}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">User Name</Typography>
                                    <Select displayEmpty className="w-100 mt-2" name="userId" variant='standard'value={values.userId} onChange={handleChange}>
                                        <MenuItem disabled value="">
                                            <em>Choose</em>
                                        </MenuItem>
                                        {/* <MenuItem value="2">Chenthur Pandi</MenuItem> */}
                                        {amenities.userList.length !== 0 && amenities.userList.map((val,key) => (
                                            <MenuItem value={val?.id}>{val?.firstName+' '+val?.lastName}</MenuItem>
                                        ))}
                                    </Select>
                                    {/* <TextField 
                                        variant="standard"
                                        className="w-100"
                                        placeholder="Type here"
                                        name="name"
                                        onChange={handleChange}
                                    /> */}
                                    {touched.userId && errors.userId && (<div className='text-danger'>{errors.userId}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Amenities</Typography>
                                    <Select displayEmpty className="w-100 mt-2" name="amenitiesId" variant='standard' value={values.amenitiesId} onChange={handleChange}>
                                        <MenuItem disabled value="">
                                            <em>Choose</em>
                                        </MenuItem>
                                        {amenities.amenityList.length !== 0 && amenities.amenityList.map((val,key) => (
                                            <MenuItem value={val.id}>{val.name}</MenuItem>
                                        ))}
                                    </Select>
                                    {touched.amenitiesId && errors.amenitiesId && (<div className='text-danger'>{errors.amenitiesId}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Date</Typography>
                                    <TextField
                                        type="date"
                                        className='w-100'
                                        variant="standard"
                                        value={values.bookingdata}
                                        name="bookingdate"
                                        onChange={handleChange}
                                    />
                                    {touched.bookingdate && errors.bookingdate && (<div className='text-danger'>{errors.bookingdate}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className='fw-bold'>Start Time</Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            variant='standard'
                                            className='w-100 mt-2'
                                            name="startTime"
                                            inputFormat="hh:mm a"
                                            value={values.from}
                                            onChange={(e) => handleTimeChanged('startTime',e,setFieldValue)}
                                            sx={{
                                                '.MuiInputBase-root': {
                                                    borderBottom: '2px solid #adb5bd',
                                                    borderRadius: 0,
                                                    marginTop:'-9px'
                                                },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    border: 'none'
                                                }
                                            }}
                                            slotProps={{ textField: { placeholder: 'HH:MM AM/PM' } }}
                                        />
                                    </LocalizationProvider>
                                    {touched.startTime && errors.startTime && (<div className='text-danger'>{errors.startTime}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className='fw-bold'>End Time</Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            variant='standard'
                                            className='w-100 mt-2'
                                            name="endTime"
                                            inputFormat="hh:mm a"
                                            value={values.from}
                                            onChange={(e) => handleTimeChanged('endTime',e,setFieldValue)}
                                            sx={{
                                                '.MuiInputBase-root': {
                                                    borderBottom: '2px solid #adb5bd',
                                                    borderRadius: 0,
                                                    marginTop:'-9px'
                                                },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    border: 'none'
                                                }
                                            }}
                                            slotProps={{ textField: { placeholder: 'HH:MM AM/PM' } }}
                                        />
                                    </LocalizationProvider>
                                    {touched.endTime && errors.endTime && (<div className='text-danger'>{errors.endTime}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">No. of People</Typography>
                                    <TextField
                                        type="text"
                                        className='w-100'
                                        variant="standard"
                                        value={values.no_of_people}
                                        name="no_of_people"
                                        placeholder="Type here"
                                        onChange={handleChange}
                                    />
                                    {touched.no_of_people && errors.no_of_people && (<div className='text-danger'>{errors.no_of_people}</div>)}
                                </Grid>
                            </Grid>
                            <Box className="mt-5">
                                <button type="button" className='btn btn-white' onClick={amenities.onBack}><ArrowBackIosIcon style={{fontSize:'18px'}}/>Back</button>
                                <button type="submit" className='btn btn-danger ms-2'>Submit</button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    )
}

export default memo(AddBooking);
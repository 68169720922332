import React from 'react';
import { Box } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const ButtonGroup = (props) => {
    return (
        <>
            <Box className="my-3">
                <button type="button" className='btn btn-white' onClick={props?.onBack}><ArrowBackIosIcon style={{fontSize:'18px'}}/>Back</button>
                <button type="submit" className='btn btn-danger'>{props?.submitText}</button>
            </Box>
        </>
    )
}

export default React.memo(ButtonGroup);
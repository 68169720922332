import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { AuthorizedLayout } from "../Layouts/AuthorizedLayout";
import { Pagination, Rating, TextField, Typography } from "@mui/material";
import EngineeringIcon from "@mui/icons-material/Engineering";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import CustomTabPanel from "../../Components/Tab/CustomTabPanel";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import ShowHelpDesk from "./ShowHelpDesk";
import AssignVendorsList from "../Vendors/AssignVendorsList";
import ListHelpDesk from "./ListHelpDesk";
import EditHelpDesk from "./EditHelpDesk";
import { ButtonPrimary } from "../../Components/Button/ButtonPrimary";
import AddIcon from "../../Assets/images/add_plus_icon.svg";
import SimpleCard from "../Dashboard/SimpleCard";
import ColumnChart from "../../Components/Chart/ColumnChart";
import DoughnutChart from "../../Components/Chart/DoughnutChart";
import TopFive from "../Dashboard/TopFive";
import OffChart from "../../Components/Chart/OffChart";
import TopFiveTable from "../Dashboard/TopFiveTable";
import TicketImg from "../../Assets/images/ticket-image.png";
import TicketCard from "./TicketCard";
import { LineChart } from "@mui/x-charts";
import LineChartComponent from "../../Components/Chart/LineChart";
import TotalEmployeeDoughnut from "../../Components/Chart/TotalEmployee";
import TicketImage from "../../Assets/images/menu-icons/receipt_FILL_selected.png";
import PDListHelpDesk from "./PDListHelpDesk";

const AntTabs = styled(Tabs)({
  marginLeft: "15px",
  "& .MuiTabs-indicator": {
    backgroundColor: "#FF7D73",
  },
});

const AntTab = styled(Tab)({
  fontSize: "13px",
  fontFamily: "Poppins",
  "&.Mui-selected": {
    color: "#FF7d73",
  },
});
interface HelpDeskOptions {
  listHelpDesk: boolean;
  addHelpDesk: boolean;
  addRequest: boolean;
  showHelpDesk: boolean;
  assignVendorsList: boolean;
  editHelpDesk: boolean;
  [key: string]: boolean; // Index signature
}

const HelpDesk = () => {
  const [tabValue, setTabValue] = useState(0);
  const [helpDeskData, setHelpDeskData] = useState("");
  const [addNew, setAddNew] = useState({
    listHelpDesk: true,
    addHelpDesk: false,
    addRequest: false,
    showHelpDesk: false,
    assignVendorsList: false,
    editHelpDesk: false,
  });
  const [loading, setLoading] = useState(false);
  const [assignVendors, setAssignVendors] = useState(false);
  const [addHelpDesk, setAddHelpDesk] = useState(false);
  const [listHelpDesk, setListHelpDesk] = useState(true);
  const [showHelpDesk, setShowHelpDesk] = useState(false);
  const [helpDeskList, setHelpDeskList] = useState([]);
  const [helpDeskCount, setHelpDeskCount] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);

  // const handleChange = (e: any, val: any) => {
  //     setTabValue(val)
  //     setAddNew({ listHelpDesk: true, addHelpDesk: false, addRequest: false, showHelpDesk: false })

  // }
  // useEffect(() => {
  //     if (assignVendors) {
  //         setAddNew({ listHelpDesk: false, addHelpDesk: false, addRequest: false, showHelpDesk: false })
  //     }

  // }, [assignVendors])

  // const handleVendor = (e: SelectChangeEvent) => {
  //     setVendor(e.target.value)
  // }
  const pagination = (e: any) => {
    setPage(e + 1);
    // setLimit();
  };
  const rowsPerPage = (e: any) => {
    console.log("e", e);
    setLimit(e);
    // setLimit();
  };
  // useEffect(() => {
  //     fetchHelpDeskSearchList();
  // }, [page])
  // const fetchHelpDeskSearchList = async (search: any = "") => {
  //     setLoading(true)
  //     let formdata = {
  //         page: page,
  //         limit: limit,
  //         searchString: search
  //     }
  //     let response = await PostApiMethod('property/help-desk/list', formdata)
  //     if (response["data"]["error"] === false) {
  //         setHelpDeskList(response.data.results.data)
  //         setHelpDeskCount(response.data.results.totalCount)
  //         setLoading(false)
  //     }
  //     else {
  //         console.log("response", response)
  //         setLoading(false)
  //     }
  // }
  const handleSearchChange = (e: any) => {
    setSearch(e);
  };
  const handleHelpDesk = (e: any, id = null) => {
    if (e === "Add") {
      setAddHelpDesk(true);
      setListHelpDesk(false);
    } else if (e === "Back") {
      setSearch("");
      setListHelpDesk(true);
      setAddHelpDesk(false);
      setShowHelpDesk(false);
      // setAssignVendors(false)
    }
  };
  const handlePageDataChange = (data: any) => {
    setHelpDeskData(data.data);
  };
  const pageStatus = (data: any) => {
    let tmpData: HelpDeskOptions = { ...addNew };
    for (let prop in tmpData) {
      if (prop !== data) {
        tmpData[prop] = false; // Set all properties to false except the one specified
      } else {
        tmpData[prop] = true;
      }
    }

    setIsUpdate(data === "editHelpDesk" ? true : false);
    setAddNew(tmpData);
  };

  const DueTime = [
    { label: "Overdue", count: 20, color: "#24BC94" },
    { label: "Today", count: 20, color: "#FFA95D" },
    { label: "Yesterday", count: 20, color: "#FB695E" },
    { label: "This week", count: 20, color: "#60A3F9" },
    { label: "This month", count: 20, color: "#CF6EE6" },
    { label: "This year", count: 20, color: "#797CD9" },
  ];

  const Ratings = [
    { label: "Plumbing", starRating: 5 },
    { label: "Electricals", starRating: 4 },
    { label: "Cleaning", starRating: 3.5 },
  ];

  const Priority = [
    { label: "Urgent", Number: 10, color: "#FF7D73" },
    { label: "High Priority", Number: 20, color: "#60A3F9" },
    { label: "Medium", Number: 25, color: "#24BC94" },
    { label: "Low", Number: 13, color: "#FFA95D" },
  ];
  return (
    <>
      <AuthorizedLayout>
        <Grid container spacing={3}>
          <Grid container item lg={8} spacing={3}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TicketCard
                title="My Tickets"
                color="#24BC94"
                amount="20"
                image={TicketImg}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TicketCard
                title="Pending Tickets"
                color="#FFA95D"
                amount="15"
                image={TicketImg}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TicketCard
                title="Closed Tickets"
                color="#FB695E"
                amount="20"
                image={TicketImg}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TicketCard
                title="Success Rate"
                color="#60A3F9"
                amount="99%"
                image={TicketImg}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={7.5} lg={7.5}>
              <Box className="h-100 custom-card dashboard-card">
                <Box className="d-flex justify-content-between">
                  <Typography variant="body1">Weekly Overview</Typography>
                  <button
                    style={{
                      background: "white",
                      border: "1px solid #FF7D73",
                      color: "#FF7d73",
                      padding: "5px 15px 5px 15px",
                      borderRadius: "25px",
                      fontSize: "11px",
                    }}
                  >
                    Download Report
                  </button>
                </Box>
                <LineChartComponent />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4.5} lg={4.5}>
              <Box className="p-4 bg-white mb-3 radius-12">
                <Card className="dashboard-card" elevation={0}>
                  <Typography variant="body1" className="mb-3">
                    Ticket Due Time
                  </Typography>
                </Card>
                <ul className="due-list">
                  {DueTime.map((item, index) => (
                    <li key={index}>
                      <Box className="d-flex justify-content-between">
                        <Typography>{item.label}</Typography>
                        <Typography
                          sx={{ color: item.color, fontWeight: "bold" }}
                        >
                          {item.count}
                        </Typography>
                      </Box>
                    </li>
                  ))}
                </ul>
              </Box>
              <Box className="p-4 bg-white mb-3 radius-12">
                <Card className="dashboard-card" elevation={0}>
                  <Typography variant="body1" className="mb-3">
                    Top 3 Ratings
                  </Typography>
                </Card>
                <ul className="rating-list">
                  {Ratings.map((item, index) => (
                    <li key={index}>
                      <Box className="d-flex justify-content-between">
                        <Typography>{item.label}</Typography>
                        <Rating
                          name={`rating-${index}`}
                          value={item.starRating}
                          precision={0.5}
                          readOnly
                        />
                        <Typography variant="body1" sx={{ color: "#808080" }}>
                          {item.starRating}/5
                        </Typography>
                      </Box>
                    </li>
                  ))}
                </ul>
              </Box>
            </Grid>
          </Grid>
          <Grid item lg={4}>
            <Box className="p-4 bg-white mb-3 radius-12">
              <TotalEmployeeDoughnut />
            </Box>

            <Grid>
              <Box className="custom-card dashboard-card pb-0">
                <Box className="d-flex justify-content-between">
                  <Typography variant="body1" className="mb-4">
                    Priority
                  </Typography>
                </Box>
                <ul className="priority-list">
                  {Priority.map((item, index) => (
                    <li key={index}>
                      <Box className="d-flex">
                        <Box
                          className="priority-count"
                          sx={{ background: item.color }}
                        >
                          {item.Number}
                        </Box>
                        <Typography variant="body1">{item.label}</Typography>
                      </Box>
                    </li>
                  ))}
                </ul>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <PDListHelpDesk />
      </AuthorizedLayout>
    </>
  );
};

export default HelpDesk;

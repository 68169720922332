import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../Store/hooks";
import { clearAuthSlice } from "../../Store/authSlice";

const axiosApiInstance = axios.create();

export const AuthInterceptor = () => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();

    // Request interceptor
    axios.interceptors.request.use(
        async function (config: any) {
            // Updating the request header.
            if (!config.headers["Content-Type"]) {
                config.headers["Content-Type"] = "application/json";
                config.headers.Accept = "application/json";
            }
            config.headers["Access-Control-Allow-Origin"] = "*";
            config.headers["Access-Control-Allow-Methods"] =
                "POST, GET, PUT, OPTIONS, DELETE";
            let token = localStorage.getItem("accessToken");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        function (error: any) {
            return Promise.reject(error);
        }
    );

    // Response interceptor
    axios.interceptors.response.use(
        function (response: any) {
            return response;
        },
        async function (error: any) {
            const originalRequest = error.config;
            const promise = new Promise((resolve,reject) => {
                if (
                    error.response &&
                    (error.response.status === 403 ||
                        error.response.status === 401) &&
                    !originalRequest._retry
                ) {
                    originalRequest._retry = true;
                    localStorage.clear();
                    dispatch(clearAuthSlice());
                    navigate("/sign-in");
                    resolve(axiosApiInstance(originalRequest));
                } else {
                    reject(error);
                }
            });
            return promise;
        }
    );
};


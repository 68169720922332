import React, { createContext, useEffect, useState } from 'react'
import { AuthorizedLayout } from '../Layouts/AuthorizedLayout';
import { Card } from '@mui/material';
import BoxHeader from '../../Components/Box/BoxHeader';
import IconAssets from "../../Assets/images/icon_assets_red.svg";
import { AntTab, AntTabs } from '../../Components/Tab/AntTab';
import CustomTabPanel from '../../Components/Tab/CustomTabPanel';
import AllAssets from './AllAssets';
import AddAssets from './AddAssets';
import { useAppDispatch } from '../../Store/hooks';
import { getAssets, getCategory, getEquipment } from './Masters/MastersAPI';
import { ListMenu } from '../../Components/DropDown/ListMenu';
import CategoryTable from './Masters/CategoryTable';
import LocationTable from './Masters/LocationTable';
import EquipmentTable from './Masters/EquipmentTable';
import ComponentTable from './Masters/ComponentTable';
import { PermissionCheck } from '../../App/PermissionCheck';

export const AssetContext = createContext(null);

const AssetManagement = () => {
    const [tabValue, setTabValue] = useState(0);
    const dispatch = useAppDispatch();
    const [masterData, setMasterData] = useState({
        category:[],
        equipment:[]
    });
    const [mastersSearch, setMastersSearch] = useState({
        category:'',
        location:'',
        equipment:'',
        component:''
    })
    const [category, setCategory] = useState({
        modal:false,
        name:'',
        page:{page:1,limit:5,count:0},
        data:[],
        edit:false,
    });
    const [location, setLocation] = useState({
        modal:false,
        name:'',
        page:{page:1,limit:5,count:0},
        data:[],
        edit:false,
    });
    const [equipment, setEquipment] = useState({
        modal:false,
        name:'',
        page:{page:1,limit:5,count:0},
        data:[],
        edit:false,
    });
    const [component, setComponent] = useState({
        modal:false,
        name:'',
        page:{page:1,limit:5,count:0},
        data:[],
        edit:false,
    });
    const [assetsData, setAssetsData] = useState({
        data:[],
        page:{page:1,limit:5},
        open:false,
        edit:false
    });
    const [assetsSearch, setAssetsSearch] = useState("");
    const [selectedMasterTab, setSelectedMasterTab] = useState(0);

    useEffect(() => {
        getCategory(dispatch,masterData,setMasterData);
        getEquipment(dispatch,masterData,setMasterData);
        getAssets(dispatch,assetsData,setAssetsData,assetsSearch);
    },[]);

    /**Masters Tab */
    let assetMasters = [
        {
            name:"Category",
            func:() => setCategory({...category,modal:true,edit:false,name:''}),
            component:<CategoryTable />
        },
        {
            name:"Location",
            func:() => setLocation({...location,modal:true,edit:false,name:'',categoryId:''}),
            component:<LocationTable />
        },
        {
            name:"Equipment",
            func:() => setEquipment({...equipment,modal:true,edit:false,name:'',categoryId:''}),
            component:<EquipmentTable />
        },
        {
            name:"Component",
            func:() => setComponent({...component,modal:true,edit:false,name:'',equipmentId:''}),
            component:<ComponentTable />
        }
    ];

    const addPermission = PermissionCheck("pb_assets_add");
    
    return (
        <>
            <AuthorizedLayout>
                <AssetContext.Provider value={{
                    masterData,
                    category,
                    setCategory,
                    location,
                    setLocation,
                    equipment,
                    setEquipment,
                    component,
                    setComponent,
                    assetsData,
                    setAssetsData,
                    assetsSearch,
                    setAssetsSearch,
                    mastersSearch,
                    setMastersSearch
                }}>
                    <Card elevation={0} className='p-4'>
                        <BoxHeader
                            dashboard_name="Assets"
                            image={IconAssets}
                            button_name={
                                tabValue === 0 ? addPermission && "Add New Asset" 
                                : "Add New " +assetMasters[selectedMasterTab]?.name
                            }
                            button_show={(!assetsData?.open && addPermission)&& true}
                            onAdd={() => 
                                tabValue === 0 ? setAssetsData({
                                    data:[...assetsData.data],
                                    page:{...assetsData.page},
                                    open:true,
                                    edit:false
                                })
                                : assetMasters[selectedMasterTab]?.func()
                            }
                        >
                            <AntTabs value={tabValue} onChange={(e, val) => setTabValue(val)}>
                                <AntTab label="All Assets" className='tab-menu' />
                                <AntTab label={
                                    <ListMenu 
                                        selectedIndex={selectedMasterTab}
                                        setSelectedIndex={setSelectedMasterTab} 
                                        data={assetMasters} 
                                    />
                                } className='tab-menu' />
                            </AntTabs>
                        </BoxHeader>
                        <CustomTabPanel value={tabValue} index={0}>
                            {!assetsData?.open ? <AllAssets /> : <AddAssets />}
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={1}>
                            {assetMasters[selectedMasterTab]?.component}
                        </CustomTabPanel>
                    </Card>
                </AssetContext.Provider>
            </AuthorizedLayout>
        </>
    )
}

export default AssetManagement
import React, { useContext } from 'react';
import { Box, Typography, TextField, Grid } from '@mui/material';
import { TenantContext } from './AddTenant';
import { Formik, Form } from 'formik';
import { PersonalInfoSchema } from './ValidationSchema';
import { PostApiMethod } from '../../Utils/ApiService';
import { useAppDispatch } from '../../Store/hooks';
import { showLoader } from '../../Store/loaderSlice';
import { showToaster } from "../../Store/toastSlice";
import ImageGallery from "../../Assets/images/image-gallery.png";

const PersonalInformation = () => {
    const tenant = useContext(TenantContext);
    const dispatch = useAppDispatch();

    const savePersonalInformation = async(values) => {
        dispatch(showLoader({showLoader:true}))
        try{
            let body = {
                email:values.email,
                firstName:values.firstName,
                lastName:values.lastName,
                phoneNumber:values.phoneNumber
            }
            let res = await PostApiMethod("auth/create-user",body);
            if(res["data"]["error"] === false){
                let addressBody = {
                    userId:res["data"]["results"]["data"]["id"],
                    permanentAddress:values.permanentAddress,
                    permanentCity:values.permanentCity,
                    permanentCountry:values.permanentCountry,
                    permanentState:values.permanentState,
                    permanentZipCode:values.permanentZipCode,
                    previousAddress:values.previousAddress,
                    previousCity:values.previousCity,
                    previousCountry:values.previousCountry,
                    previousState:values.previousState,
                    previousZipCode:values.previousZipCode
                }
                let response = await PostApiMethod("property/tenant/save/address",addressBody);
                if(response["data"]["error"] === false){
                    dispatch(showToaster({
                        dialogBgColor:'bg-success',
                        dialogMsg:'Tenant created successfully',
                        showDialog:true,
                        timer:'3000'
                    }))
                    tenant.handleNextPage("NewTenant")
                    tenant.updateFormData({...values,...{userId:res["data"]["results"]["data"]["id"]}})
                }
            }
        }catch(error){
            dispatch(showToaster({
                dialogBgColor:'bg-danger',
                dialogMsg:'Failed to create tenant',
                showDialog:true,
                timer:'3000'
            }))
        }
        dispatch(showLoader({showLoader:false}))
    }

    const updatePersonalInformation = (values) => {
        dispatch(showLoader({showLoader:true}))
        try{
            tenant.handleNextPage("NewTenant");
            dispatch(showToaster({
                dialogBgColor:'bg-success',
                dialogMsg:'Tenant info updated successfully',
                showDialog:true,
                timer:'3000'
            }))
        }catch(error){
            dispatch(showToaster({
                dialogBgColor:'bg-danger',
                dialogMsg:'Failed to update tenant',
                showDialog:true,
                timer:'3000'
            }))
        }
        dispatch(showLoader({showLoader:false}))
    }

    return (
        <>
            <Box className="mt-5 d-flex justify-content-between">
                <Box>
                    <h5 className="fw-bold">Add New Tenant</h5>
                    <Typography sx={{ color: 'gray' }}>Tenant Information</Typography>
                </Box>
            </Box>
            <Formik
                initialValues={{
                    firstName: tenant.formData.firstName ,
                    lastName: tenant.formData.lastName,
                    phoneNumber: tenant.formData.phoneNumber,
                    job: tenant.formData.job,
                    age: tenant.formData.age,
                    family_members: tenant.formData.family_members,
                    email: tenant.formData.email,
                    password: tenant.formData.password,
                    previousAddress: tenant.formData.previousAddress,
                    previousCountry: tenant.formData.previousCountry,
                    previousState: tenant.formData.previousState,
                    previousCity: tenant.formData.previousCity,
                    previousState: tenant.formData.previousState,
                    previousZipCode: tenant.formData.previousZipCode,
                    permanentAddress: tenant.formData.permanentAddress,
                    permanentCountry: tenant.formData.permanentCountry,
                    permanentState: tenant.formData.permanentState,
                    permanentCity: tenant.formData.permanentCity,
                    permanentZipCode: tenant.formData.permanentZipCode
                }}
                // validationSchema={ PersonalInfoSchema }
                onSubmit={async(values) => {
                    tenant.handleNextPage("NewTenant")
                    // if(tenant.formData.userId){
                    //     updatePersonalInformation(values);
                    // }else{
                    //     savePersonalInformation(values);
                    // }
                }}
            >
                {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                        <Box className="mt-4">
                            <Typography className="fw-bold">Personal Information</Typography>
                            <Box className="d-flex align-items-center">
                                <Box sx={{width:'100px',height:'100px',borderRadius:'100%',background:'#E8E8E8',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <img src={ImageGallery} />
                                </Box>
                                <Box className="ms-3">
                                    <Typography className='fw-bold'>Profile Image</Typography>
                                    <input type="file" />
                                </Box>
                            </Box>
                        </Box>
                        <Box className="mt-3">
                            <Grid container spacing={4}>
                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    <Typography className="fw-bold">First Name</Typography>
                                    <TextField
                                        type="text"
                                        className="form-control"
                                        name="firstName"
                                        placeholder="Type here"
                                        variant="standard"
                                        value={values.firstName}
                                        onChange={handleChange}
                                    />
                                    {touched.firstName && errors.firstName && (<div className='text-danger'>{errors.firstName}</div>)}
                                </Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    <Typography className="fw-bold">Last Name</Typography>
                                    <TextField
                                        type="text"
                                        className="form-control"
                                        name="lastName"
                                        placeholder="Type here"
                                        variant="standard"
                                        value={values.lastName}
                                        onChange={handleChange}
                                    />
                                    {touched.lastName && errors.lastName && (<div className='text-danger'>{errors.lastName}</div>)}
                                </Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    <Typography className="fw-bold">Contact Number</Typography>
                                    <TextField
                                        type="number"
                                        className="form-control"
                                        name="phoneNumber"
                                        placeholder="Type here"
                                        variant="standard"
                                        value={values.phoneNumber}
                                        onChange={handleChange}
                                    />
                                    {touched.phoneNumber && errors.phoneNumber && (<div className='text-danger'>{errors.phoneNumber}</div>)}
                                </Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    <Typography className="fw-bold">Job</Typography>
                                    <TextField
                                        type="text"
                                        className="form-control"
                                        name="job"
                                        placeholder="Type here"
                                        variant="standard"
                                        value={values.job}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    <Typography className="fw-bold">Age</Typography>
                                    <TextField
                                        type="number"
                                        className="form-control"
                                        name="age"
                                        placeholder="Type here"
                                        variant="standard"
                                        value={values.age}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    <Typography className="fw-bold">Family Members</Typography>
                                    <TextField
                                        type="number"
                                        className="form-control"
                                        name="family_members"
                                        placeholder="Type here"
                                        variant="standard"
                                        value={values.family_members}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    <Typography className="fw-bold">Email</Typography>
                                    <TextField
                                        type="email"
                                        className="form-control"
                                        name="email"
                                        placeholder="Type here"
                                        variant="standard"
                                        value={values.email}
                                        onChange={handleChange}
                                    />
                                    {touched.email && errors.email && (<div className='text-danger'>{errors.email}</div>)}
                                </Grid>
                                {/* <Grid item lg={4} md={4} sm={12} xs={12}>
                                    <Typography className="fw-bold">Password</Typography>
                                    <TextField
                                        type="password"
                                        className="form-control"
                                        name="password"
                                        placeholder="Type here"
                                        variant="standard"
                                        value={values.password}
                                        onChange={handleChange}
                                    />
                                </Grid> */}
                            </Grid>
                            <Box className="mt-3">
                                <Typography className="fw-bold">Previous Address</Typography>
                            </Box>
                            <Grid container spacing={4} className="mt-1">
                                <Grid item lg={4} md={4} xs={12} sm={12}>
                                    <Typography className="fw-bold">Address</Typography>
                                    <TextField
                                        name="previousAddress"
                                        className="form-control"
                                        placeholder="Type here"
                                        variant="standard"
                                        type="text"
                                        value={values.previousAddress}
                                        onChange={handleChange}
                                    />
                                    {touched.previousAddress && errors.previousAddress && (<div className='text-danger'>{errors.previousAddress}</div>)}
                                </Grid>
                                <Grid item lg={4} md={4} xs={12} sm={12}>
                                    <Typography className="fw-bold">Country</Typography>
                                    <TextField
                                        name="previousCountry"
                                        className="form-control"
                                        placeholder="Type here"
                                        variant="standard"
                                        type="text"
                                        value={values.previousCountry}
                                        onChange={handleChange}
                                    />
                                    {touched.previousCountry && errors.previousCountry && (<div className='text-danger'>{errors.previousCountry}</div>)}
                                </Grid>
                                <Grid item lg={4} md={4} xs={12} sm={12}>
                                    <Typography className="fw-bold">State</Typography>
                                    <TextField
                                        name="previousState"
                                        className="form-control"
                                        placeholder="Type here"
                                        variant="standard"
                                        type="text"
                                        value={values.previousState}
                                        onChange={handleChange}
                                    />
                                    {touched.previousState && errors.previousState && (<div className='text-danger'>{errors.previousState}</div>)}
                                </Grid>
                                <Grid item lg={4} md={4} xs={12} sm={12}>
                                    <Typography className="fw-bold">City</Typography>
                                    <TextField
                                        name="previousCity"
                                        className="form-control"
                                        placeholder="Type here"
                                        variant="standard"
                                        type="text"
                                        value={values.previousCity}
                                        onChange={handleChange}
                                    />
                                    {touched.previousCity && errors.previousCity && (<div className='text-danger'>{errors.previousCity}</div>)}
                                </Grid>
                                <Grid item lg={4} md={4} xs={12} sm={12}>
                                    <Typography className="fw-bold">Zip Code</Typography>
                                    <TextField
                                        name="previousZipCode"
                                        className="form-control"
                                        placeholder="Type here"
                                        variant="standard"
                                        type="text"
                                        value={values.previousZipCode}
                                        onChange={handleChange}
                                    />
                                    {touched.previousZipCode && errors.previousZipCode && (<div className='text-danger'>{errors.previousZipCode}</div>)}
                                </Grid>
                            </Grid>
                            <Box className="mt-3">
                                <Typography className="fw-bold">Permanent Address</Typography>
                            </Box>
                            <Grid container spacing={4} className="mt-1">
                                <Grid item lg={4} md={4} xs={12} sm={12}>
                                    <Typography className="fw-bold">Address</Typography>
                                    <TextField
                                        name="permanentAddress"
                                        className="form-control"
                                        placeholder="Type here"
                                        variant="standard"
                                        type="text"
                                        value={values.permanentAddress}
                                        onChange={handleChange}
                                    />
                                    {touched.permanentAddress && errors.permanentAddress && (<div className='text-danger'>{errors.permanentAddress}</div>)}
                                </Grid>
                                <Grid item lg={4} md={4} xs={12} sm={12}>
                                    <Typography className="fw-bold">Country</Typography>
                                    <TextField
                                        name="permanentCountry"
                                        className="form-control"
                                        placeholder="Type here"
                                        variant="standard"
                                        type="text"
                                        value={values.permanentCountry}
                                        onChange={handleChange}
                                    />
                                    {touched.permanentCountry && errors.permanentCountry && (<div className='text-danger'>{errors.permanentCountry}</div>)}
                                </Grid>
                                <Grid item lg={4} md={4} xs={12} sm={12}>
                                    <Typography className="fw-bold">State</Typography>
                                    <TextField
                                        name="permanentState"
                                        className="form-control"
                                        placeholder="Type here"
                                        variant="standard"
                                        type="text"
                                        value={values.permanentState}
                                        onChange={handleChange}
                                    />
                                    {touched.permanentState && errors.permanentState && (<div className='text-danger'>{errors.permanentState}</div>)}
                                </Grid>
                                <Grid item lg={4} md={4} xs={12} sm={12}>
                                    <Typography className="fw-bold">City</Typography>
                                    <TextField
                                        name="permanentCity"
                                        className="form-control"
                                        placeholder="Type here"
                                        variant="standard"
                                        type="text"
                                        value={values.permanentCity}
                                        onChange={handleChange}
                                    />
                                    {touched.permanentCity && errors.permanentCity && (<div className='text-danger'>{errors.permanentCity}</div>)}
                                </Grid>
                                <Grid item lg={4} md={4} xs={12} sm={12}>
                                    <Typography className="fw-bold">Zip Code</Typography>
                                    <TextField
                                        name="permanentZipCode"
                                        className="form-control"
                                        placeholder="Type here"
                                        variant="standard"
                                        type="text"
                                        value={values.permanentZipCode}
                                        onChange={handleChange}
                                    />
                                    {touched.permanentZipCode && errors.permanentZipCode && (<div className='text-danger'>{errors.permanentZipCode}</div>)}
                                </Grid>
                            </Grid>
                        </Box>
                        <Box className="d-flex align-items-center mt-4">
                            <button className="btn btn-white" type="button" onClick={() => tenant.changePage("listTenant")}>Back</button>
                            <button className="btn btn-danger ms-2" type="submit">Save & Proceed Next</button>
                        </Box>
                    </Form>
                )}
            </Formik>

        </>
    )
}

export default PersonalInformation
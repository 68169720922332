import React from "react";
import arrow from "../../../Assets/images/icons/arrow.png";
import styles from "./formButton.module.css";
import { ButtonPrimary } from "../../../Components/Button/ButtonPrimary";
import { margin } from "@mui/system";
import { Box } from "@mui/material";

interface formBtn1 {
  backBtnClick: Function;
  label: string;
}

export const FormButton1: React.FunctionComponent<formBtn1> = ({
  backBtnClick,
  label="Save & Proceed Next"
}) => {
  return (
    <>
      <Box className={styles.box}>
        <Box
          className={`${styles.backBtn} mr-30`}
          sx={{ marginRight: "30px" }}
          onClick={() => backBtnClick()}
        >
          <img src={arrow} alt="arrow" width={8} className={styles.backImg} />
          <span style={{ color: "#808080" }}>Back</span>
        </Box>

        <ButtonPrimary text={label} className="text-16" />
      </Box>
    </>
  );
};

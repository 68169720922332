import * as Yup from "yup";

export const assetSchema = Yup.object().shape({
    asset_id: Yup.string().required("Asset ID is Required"),
    name: Yup.string().required("Asset name is Required"),
    description: Yup.string().required("Description is Required"),
    category: Yup.string().required("Category field is required"),
    location: Yup.string().required("Location field is Required"),
    equipment: Yup.string().required("Equipment field is Requirement"),
    component: Yup.string().required("Component field is Requirement"),
    make: Yup.string().required("Make field is Requirement"),
    model: Yup.string().required("Model field is Requirement"),
    image: Yup.mixed().required("Upload .png, .jpg, .jpeg file"),
    dateOfPurchase: Yup.string().required("Purchase date is Required")
});
import React, { useCallback } from "react";
import { InputAdornment, TextField } from "@mui/material";
import IconSearch from "../../Assets/images/search.svg";
import Calendar from "../../Assets/images/calendar.svg";
import Noti from "../../Assets/images/bell.png";
import LoginOut from "../../Assets/images/logout.png";
import Profile from "../../Assets/images/profile.png";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import {
  clearAuthSlice,
  fetchingUserDetails,
  setIsAuthenticated,
} from "../../Store/authSlice";
import { ButtonPrimary } from "../Button/ButtonPrimary";

interface HeaderProps {
  openMenu: boolean;
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Header({ openMenu, setOpenMenu }: HeaderProps) {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth);

  const logout = useCallback(async () => {
    dispatch(setIsAuthenticated(false));
    dispatch(fetchingUserDetails(false));
    dispatch(clearAuthSlice());
    try {
      localStorage.clear();
    } catch {
      localStorage.clear();
    }
  }, [dispatch]);

  return (
    <nav
      className="navbar navbar-expand-lg"
      style={{ backgroundColor: "#ffffff" }}
    >
      <div className="container-fluid p-0">
        {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button> */}
        <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
          <div className="space-between align-items-center">
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none", // Remove the border
                  },
                },
                "& .MuiInputBase-root": {
                  background: "#F6F6F6",
                  borderRadius: "12px",
                },
              }}
              style={{ width: "45%" }}
              size="small"
              variant="outlined"
              placeholder="Search what you are looking for"
              //   onChange={handleChange}
              InputProps={{
                className: "search-placeHolder",
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={IconSearch} width="16px" height="16px" />
                  </InputAdornment>
                ),
              }}
            />
            <Box className="d-flex align-items-center">
              <img src={Calendar} alt="FB" width={21} height={21} />
              <img
                src={Noti}
                alt="FB"
                width={23}
                height={23}
                style={{ marginLeft: "40px" }}
              />
              <Box
                className="d-flex align-items-center"
                style={{ marginLeft: "40px", marginRight: "40px" }}
              >
                <img src={Profile} alt="Circular" width={46} height={46} />
                <Box className="ms-2">
                  <Typography
                    sx={{ fontSize: "10px", color: "#808080" }}
                    className="poppins-light-font"
                  >
                    Admin
                  </Typography>
                  <Typography
                    sx={{ fontSize: "11px", color: "#2C2C2C" }}
                    className="psemiboldfont"
                  >
                    {user.adminData.firstName + user.adminData.lastName}
                  </Typography>
                </Box>
              </Box>
              {/* <button className="ms-3 header-logout" onClick={logout}><img src={LoginOut} width={18} style={{ marginRight: '10px' }} />&nbsp;Logout</button> */}
              <ButtonPrimary
                text="Logout"
                className="h-46 radius-12 min-w-120"
                onClick={logout}
                imgSrc={LoginOut}
                imgAlt="Firebridge icon"
              />
            </Box>
          </div>
        </div>
      </div>
    </nav>
  );
}

import React, { useState , useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { useFormik } from "formik";
import { AddNoticeBoardSchema } from "../../Components/Validation";
import { showLoader } from "../../Store/loaderSlice";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { showToaster } from "../../Store/toastSlice";
import { PostApiMethod } from "../../Utils/ApiService";
import { errorHandling } from "../../Utils/HelperService";
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
interface NewDataType {
  title: any;
  details: any;
  property_id: any;
  unit_id: any;
  property_all: string | boolean;
  unit_all: string | boolean;
  start_date?: any;
  end_date?: any;  
}
export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const AddNewNoticeBoard = (props: any) => {

  let dispatch = useDispatch();


  const [unitList, setUnitList] = useState([]);
  const [propertyList, setPropertyList] = useState([]);
  const [images, setImages] = useState<File[]>([]);
  const [imgArray, setImgArray] = useState<File[]>([]);

  const getInitialState = {
    title: "",
    details: "",
    start_date: "",
    end_date: "",
    property_id: "",
    unit_id: "",
    property_all:"Yes",
    unit_all:"Yes"
  };
  

  const { values, handleChange, handleSubmit, errors, touched, resetForm } =
  useFormik({
    initialValues: getInitialState,
    validationSchema: AddNoticeBoardSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      handlFormSubmit(values);
    },
  });



  const fetchProperty = async () => {
    let response = await PostApiMethod("property/properties/list", {});
    if (response["data"]["error"] === false) {
        setPropertyList(response.data.results.data);
    } else {
      console.log("response", response);
    }
  };
  const fetchUnit = async () => {
    let response = await PostApiMethod("property/unit/list", {});
    if (response["data"]["error"] === false) {
      setUnitList(response.data.results.data);
    } else {
      console.log("response", response);
    }
  };

  const handlFormSubmit = async (values: any) => {
    try {
      dispatch(showLoader({ showLoader: true }));

      let newData: NewDataType = {
        title: values.title,
        details: values.details,
        property_id: values.property_id,
        unit_id: values.unit_id,
        property_all: values.property_all === "Yes" ? '1' : false,
        unit_all: values.unit_all === "Yes" ? '1' : false,
      };
      if (values.start_date) {
        newData = { ...newData, start_date: values.start_date };
      }
      if (values.end_date) {
        newData = { ...newData, end_date: values.end_date };
      }

      let res :any;
      if(images.length > 0){
          const form = new FormData()
          form.append("title",values.title)
          form.append("details",values.details)
          form.append("property_id",values.property_id)
          form.append("unit_id",values.unit_id)
          if (values.start_date) {
            form.append("start_date",values.start_date)
          }
          if (values.end_date) {
            form.append("end_date",values.end_date)
          }
        for(let image of images){
          form.append("file_name",image)
        }
        res = await PostApiMethod('property/notice-boards/add', form , {
         headers: {
              'Content-Type': 'multipart/form-data',
            },
        });

      }
      else{   
       res = await PostApiMethod('property/notice-boards/add', newData);
      }

  
      dispatch(showLoader({ showLoader: false }));
  
      dispatch(
        showToaster({
          dialogBgColor: 'success',
          dialogMsg: 'Notice Board Added successfully',
          showDialog: true,
          timer: '5000',
        })
      );
      props.pageStatus("listNotice");
  } catch (err: any) {
    console.log(err)
      const error = errorHandling(err);
      dispatch(
        showToaster({
          dialogBgColor: 'bg-danger',
          dialogMsg: error,
          showDialog: true,
          timer: '5000',
        })
      );
      dispatch(showLoader({ showLoader: false }));
    }
  };

  const handleImageChange = (e:any) => {
    e.preventDefault();
    setImages(e.target.files);
    const files = Array.from(e.target.files || []) as File[];
    setImgArray(files);
  };

  const handleDeleteImage=(index:any)=>{
    try{
    const newImages = imgArray.filter((_, i) => i !== index);
      setImgArray(newImages);
      setImages(newImages);
    }
    catch(e){
      console.log(e)
    }
  }

  useEffect(() => {
    fetchProperty();
    fetchUnit();
  }, []);
  return (
    <>
      <Box className="pt-3">
        <Box className="mt-3">
          <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
          <FormLabel className="poppins-family text-black">
             Property For All
          </FormLabel>
          <RadioGroup row aria-labelledby="responsibility" name="property_all" value={values.property_all} onChange={handleChange}>
            <FormControlLabel
              className="poppins-family"
              value="Yes"
              control={<Radio />}
              label="Yes"
            />
            <FormControlLabel
              className="poppins-family"
              value="No"
              control={<Radio />}
              label="No"
            />
          </RadioGroup>
          </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
          <FormLabel className="poppins-family text-black">
             Unit For All
          </FormLabel>
          <RadioGroup row aria-labelledby="responsibility" name="unit_all" value={values.unit_all} onChange={handleChange}>
            <FormControlLabel
              className="poppins-family"
              value="Yes"
              control={<Radio />}
              label="Yes"
            />
            <FormControlLabel
              className="poppins-family"
              value="No"
              control={<Radio />}
              label="No"
            />
          </RadioGroup>
          </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <FormLabel className="poppins-family text-black">Title</FormLabel>
              <TextField
                type="text"
                variant="standard"
                placeholder="Type here..."
                className="w-100"
                name="title"
                onChange={handleChange}
                value={values.title}
              />
                  {errors.title && touched.title && (
                  <Typography className="text-danger">{errors.title}</Typography>
                )}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <FormLabel className="poppins-family text-black">
                Property
              </FormLabel>
              <FormControl variant="standard" className="w-100">
                 <Select
                    name="property_id"
                    value={values.property_id}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="">
                    None
                  </MenuItem>
                  {propertyList.map((data:any) => (
                    <MenuItem key={data.id} value={data.id}>
                      {data.property_name}
                    </MenuItem>
                  ))}
                </Select>
             {errors.property_id && touched.property_id && (
                  <Typography className="text-danger">{errors.property_id}</Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <FormLabel className="poppins-family text-black">
                Unit Name
              </FormLabel>
              <FormControl variant="standard" className="w-100">
                <Select
                    name="unit_id"
                    value={values.unit_id}
                    onChange={handleChange}
                    displayEmpty
                  >
                <MenuItem value="">
                    None
                  </MenuItem>
                {unitList.map((data:any) => (
                    <MenuItem key={data.id} value={data.id}>
                      {data.unit_name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.unit_id && touched.unit_id && (
                  <Typography className="text-danger">{errors.unit_id}</Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <FormLabel className="poppins-family text-black">
                Details
              </FormLabel>
              <TextField
                type="text"
                variant="standard"
                className="w-100"
                placeholder="Type here..."
                name="details"
                onChange={handleChange}
                value={values.details}
              />
                 {errors.details && touched.details && (
                  <Typography className="text-danger">
                    {errors.details}
                  </Typography>
                )}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
            <FormLabel className="poppins-family text-black">
            Start Date
            </FormLabel>
            <TextField
                className="w-100"
                variant="standard"
                name="start_date"
                placeholder="dd/mm/yyyy"
                type="date"
                value={values.start_date}
                onChange={handleChange}
                />
            {errors.start_date && touched.start_date && (
                <Typography className="text-danger">
                {errors.start_date}
                </Typography>
            )}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
            <FormLabel className="poppins-family text-black">
            End Date
            </FormLabel>
            <TextField
                className="w-100"
                variant="standard"
                name="end_date"
                placeholder="dd/mm/yyyy"
                type="date"
                value={values.end_date}
                onChange={handleChange}
                />
            {errors.end_date && touched.end_date && (
                <Typography className="text-danger">
                {errors.end_date}
                </Typography>
            )}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
           <Typography className="poppins-family">Attachement</Typography>          
                <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                onChange={(e:any) =>handleImageChange(e)}
              >
                Upload file
                <VisuallyHiddenInput type="file" multiple />
              </Button>
                {imgArray.map((file, index) => (
                  <div className="border border-danger p-1">
                {file.name}
        <CancelTwoToneIcon className="float-end" onClick={()=>{handleDeleteImage(index)}}></CancelTwoToneIcon>
                </div>
              ))}
            </Grid>
          </Grid>
          <Box className="mt-5">
            <Button className="btn-white border-0" onClick={() => {
                  props.pageStatus("listNotice");
                }}>
              <ChevronLeftIcon sx={{ fontSize: "23px", marginRight: "-6px" }} />
              Back
            </Button>
            <button type="submit" className="btn-danger ms-2">Submit</button>
          </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default AddNewNoticeBoard;

import React, { useCallback, useEffect, useState } from 'react';
import { CustomModal, ModalStyle } from '../../../Components/ModalStyle';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { SelectDropdown, TextInput } from '../../../Components/Text/TextInput';
import { Box, Grid, MenuItem } from '@mui/material';
import { PostApiMethod, PutApiMethod } from '../../../Utils/ApiService';
import { useAppDispatch } from '../../../Store/hooks';
import { showToaster } from '../../../Store/toastSlice';
import { showLoader } from '../../../Store/loaderSlice';
import { createPetrolCategory, updatePetrolCategory, createPetrolLocation, updatePetrolLocation, createPetrolEquipment, updtePetrolEquipment, createPetrolComponent, updatePetrolComponent } from './MastersAPI';
import ApiSearch from '../../../Components/AutoCompleteTextView/ApiSearch';

const categorySchema = Yup.object().shape({
    name:Yup.string().required("Category is required")
});

const locationSchema = Yup.object().shape({
    categoryId:Yup.string().required("Category is required"),
    name:Yup.string().required("Location is required")
});

const equipmentSchema = Yup.object().shape({
    categoryId:Yup.string().required("Category is required"),
    name:Yup.string().required("Equipment is required")
});

const componentSchema = Yup.object().shape({
    equipmentId:Yup.string().required("Equipment is required"),
    name:Yup.string().required("Component is required")
});

export const Category = (props) => {
    const dispatch = useAppDispatch();
    const { onClose, getCategory, categoryId } = props;

    return (
        <>
            <CustomModal
                open={props?.open}
                onClose={props?.onClose}
                header={props?.header}
                style={ModalStyle({width:800,height:'auto'})}
            >
                <Formik
                    initialValues={{
                        name: props?.name || ''
                    }}
                    validationSchema={categorySchema}
                    onSubmit={async(values) => {
                        if(props?.edit){
                            updatePetrolCategory({
                                values,
                                dispatch,
                                onClose,
                                getCategory,
                                categoryId
                            })
                        }else{
                            createPetrolCategory({
                                values,
                                dispatch,
                                onClose,
                                getCategory
                            })
                        }
                    }}
                >
                    {({handleChange, handleSubmit, values, errors, touched}) => (
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={2} className='p-3'>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextInput
                                        label={"Category"}
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                    />
                                    {touched?.name && errors?.name && (<div className='text-danger'>{errors?.name}</div>)}
                                </Grid>
                            </Grid>
                            <Box className="d-flex justify-content-end mt-3 p-3">
                                <button className='btn btn-danger' type="submit">Submit</button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </CustomModal>
        </>
    )
}

export const Location = (props) => {
    const dispatch = useAppDispatch();
    const { category, onClose, getLocation, locationId } = props;
    const [searchLoaders, setSearchLoaders] = useState({category:false})
    const [categoryList, setCategoryList] = useState([]);

    const searchCategory = useCallback((value) => {
        setSearchLoaders((prev) => ({...prev,category: true}));
        PostApiMethod("petrol-bunk/category/getHpCategory", {
            searchString: value,
            page: "1",
            limit: "50",
          })
            .then((res) => {
                setSearchLoaders((prev) => ({...prev,category: false}));
                setCategoryList(res.data.results.data);
            })
            .catch((e) => {
              setSearchLoaders((prev) => ({...prev,category: false}));
              setCategoryList([]);
            });
    },[]);
    return (
        <>
            <CustomModal
                open={props?.open}
                onClose={props?.onClose}
                header={props?.header}
                style={ModalStyle({width:800,height:'auto'})}
            >
                <Formik
                    initialValues={{
                        name: props?.name || '',
                        categoryId: props?.categoryId || '',
                        categoryDetails: {
                            name: props?.categoryName || "",
                            id: props?.categoryId ||"",
                        },
                    }}
                    validationSchema={locationSchema}
                    onSubmit={async(values) => {
                        if(props?.edit){
                            updatePetrolLocation({
                                values,
                                onClose,
                                dispatch,
                                getLocation,
                                locationId
                            })
                        }else{
                            createPetrolLocation({
                                values,
                                onClose,
                                dispatch,
                                getLocation
                            })
                        }
                    }}
                >
                    {({handleChange, handleSubmit, values, errors, touched, setFieldValue}) => (
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={3} className="p-3">
                                <Grid item xs={12} sm={12} md={12}>
                                <ApiSearch
                                value={values.categoryDetails}
                                onChange={(newValue) => {
                                   setFieldValue("categoryDetails", {id:newValue.id,name: newValue.name});
                                   setFieldValue("categoryId",newValue.id);
                                }}
                                loading={searchLoaders.category}
                                options={categoryList}
                                searchApi={searchCategory}
                                isOptionEqualToValue={(option, value) => option?.name === value.name}
                                getOptionLabel={(option) => 
                                  option?.name
                                }
                                showTitle={true}
                                label={"Category"}
                                />
                                    {touched?.categoryId && errors?.categoryId && (<div className='text-danger'>{errors?.categoryId}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextInput
                                        label={"Location"}
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                    />
                                    {touched?.name && errors?.name && (<div className='text-danger'>{errors?.name}</div>)}
                                </Grid>
                            </Grid>
                            <Box className="d-flex justify-content-end mt-3 p-3">
                                <button className='btn btn-danger' type="submit">Submit</button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </CustomModal>
        </>
    )
}

export const Equipment = (props) => {
    const dispatch = useAppDispatch();
    const { category, onClose, equipmentId, getEquipment } = props;
    const [searchLoaders, setSearchLoaders] = useState({category:false})
    const [categoryList, setCategoryList] = useState([]);

    const searchCategory = useCallback((value) => {
        setSearchLoaders((prev) => ({...prev,category: true}));
        PostApiMethod("petrol-bunk/category/getHpCategory", {
            searchString: value,
            page: "1",
            limit: "50",
          })
            .then((res) => {
                setSearchLoaders((prev) => ({...prev,category: false}));
                setCategoryList(res.data.results.data);
            })
            .catch((e) => {
              setSearchLoaders((prev) => ({...prev,category: false}));
              setCategoryList([]);
            });
    },[]);

    return (
        <>
            <CustomModal
                open={props?.open}
                onClose={onClose}
                header={props?.header}
                style={ModalStyle({width:800,height:'auto'})}
            >
                <Formik
                    initialValues={{
                        categoryId: props?.categoryId || '',
                        name: props?.name || '',
                        categoryDetails: {
                            name: props?.categoryName || "",
                            id: props?.categoryId || "",
                        },
                    }}
                    validationSchema={equipmentSchema}
                    onSubmit={async(values) => {
                        if(props?.edit){
                            updtePetrolEquipment({
                                values,
                                dispatch,
                                equipmentId,
                                onClose,
                                getEquipment
                            })
                        }else{
                            createPetrolEquipment({
                                values,
                                dispatch,
                                onClose,
                                getEquipment
                            })
                        }
                    }}
                >
                    {({handleChange, handleSubmit, values, errors, touched, setFieldValue}) => (
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={2} className="p-3">
                                <Grid item xs={12} sm={12} md={12}>
                                <ApiSearch
                                value={values.categoryDetails}
                                onChange={(newValue) => {
                                   setFieldValue("categoryDetails", {id:newValue.id,name: newValue.name});
                                   setFieldValue("categoryId",newValue.id);
                                }}
                                loading={searchLoaders.category}
                                options={categoryList}
                                searchApi={searchCategory}
                                isOptionEqualToValue={(option, value) => option?.name === value.name}
                                getOptionLabel={(option) => 
                                  option?.name
                                }
                                showTitle={true}
                                label={"Category"}
                                />
                                    {touched?.categoryId && errors?.categoryId && (<div className='text-danger'>{errors?.categoryId}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextInput
                                        label={"Equipment"}
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                    />
                                    {touched?.name && errors?.name && (<div className='text-danger'>{errors?.name}</div>)}
                                </Grid>
                            </Grid>
                            <Box className="d-flex justify-content-end mt-3 p-3">
                                <button className='btn btn-danger' type="submit">Submit</button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </CustomModal>
        </>
    )
}

export const Component = (props) => {
    const dispatch = useAppDispatch();
    const { equipment, onClose, getComponent, componentId } = props;
    const [searchLoaders, setSearchLoaders] = useState({equipment: false})
    const [equipmentList, setEquipmentList] = useState([]);

    const searchEquipment = useCallback((value,categoryId) => {
        setSearchLoaders((prev) => ({...prev,equipment: true}));
        PostApiMethod("petrol-bunk/equipment/getEquipment", {
            searchString: value,
            page: "1",
            limit: "50",
            category: categoryId
          })
            .then((res) => {
                setSearchLoaders((prev) => ({...prev,equipment: false}));
                setEquipmentList(res.data.results.data);
            })
            .catch((e) => {
              setSearchLoaders((prev) => ({...prev,equipment: false}));
              setEquipmentList([]);
            });
    },[]);
    return (
        <>
            <CustomModal
                open={props?.open}
                onClose={props?.onClose}
                header={props?.header}
                style={ModalStyle({width:800,height:'auto'})}
            >
                <Formik
                    initialValues={{
                        equipmentId: props?.equipmentId || '',
                        name: props?.name || '',
                        equipmentDetails: {
                            name:  props?.equipmentName || "",
                            id: props?.equipmentId || "",
                        },
                    }}
                    validationSchema={componentSchema}
                    onSubmit={async(values) => {
                        if(props?.edit){
                            updatePetrolComponent({
                                values,
                                onClose,
                                dispatch,
                                getComponent,
                                componentId
                            })
                        }else{
                            createPetrolComponent({
                                values,
                                onClose,
                                dispatch,
                                getComponent,
                            })
                        }
                    }}
                >
                    {({handleChange, handleSubmit, values, errors, touched, setFieldValue}) => (
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={2} className="p-3">
                                <Grid item xs={12} sm={12} md={12}>
                                <ApiSearch
                                value={values.equipmentDetails}
                                onChange={(newValue) => {
                                   setFieldValue("equipmentDetails", {id:newValue.id,name: newValue.name})
                                   setFieldValue("equipmentId",newValue.id)
                                  
                                }}
                                loading={searchLoaders.equipment}
                                options={equipmentList}
                                searchApi={(search)=>{
                                    searchEquipment(search,values.category)}}
                                isOptionEqualToValue={(option, value) => option?.name === value.name}
                                getOptionLabel={(option) => 
                                  option?.name
                                }
                                showTitle={true}
                                label={"Equipment"}
                              
                                />
                                    {touched?.equipmentId && errors?.equipmentId && (<div className='text-danger'>{errors?.equipmentId}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextInput
                                        label={"Component"}
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                    />
                                    {touched?.name && errors?.name && (<div className='text-danger'>{errors?.name}</div>)}
                                </Grid>
                            </Grid>
                            <Box className="d-flex justify-content-end mt-3 p-3">
                                <button className='btn btn-danger' type="submit">Submit</button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </CustomModal>
        </>
    )
}
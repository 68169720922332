import React from "react";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import { Typography } from "@mui/material";
import { LineChart as MuiLineChart } from "@mui/x-charts";

const data = [
  { label: "Group A", value: 400, color: "#FFA95D" },
  { label: "Group B", value: 300, color: "#60A3F9" },
];

const LineChartComponent = () => {
  return (
    <Stack direction="row">
      <MuiLineChart
        xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
        series={[
          {
            data: [2, 5.5, 2, 8.5, 1.5, 5],
            area: true,
          },
        ]}
        width={500}
        height={300}
      />
    </Stack>
  );
};

export default LineChartComponent;

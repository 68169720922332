import React, { useState , useCallback , useEffect} from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import Pagination from '../../Components/Pagination/Pagination';
import { useAppDispatch } from '../../Store/hooks';
import {showLoader} from "../../Store/loaderSlice"
import { PostApiMethod , DeleteApiMethod} from '../../Utils/ApiService';
import { DisplayErrorMessages , isNotEmpty } from '../../Utils/HelperService';
import { showToaster } from "../../Store/toastSlice";
import { formatDate } from '../../Components/Utilities';
import Button from "@mui/material/Button";
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";

const AllNoticeBoard = (props:any) => {
const ITEM_HEIGHT = 48;

    const [NoticeBoardList, setNoticeBoardList] = useState<any[]>([]);
  const [page, setPage] = useState({ page: 1, limit: 5, count: 0 });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [cOpen, setcOpen] = useState<Number>(0);
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useAppDispatch();
  const options = ["Edit", "Delete"];

  
    const getUsers = async (page: any, limit: any) => {
        dispatch(showLoader({ showLoader: true }));
        try {
          let res = await PostApiMethod("property/notice-boards/list", {
            page: page,
            limit: limit,
            searchString: props.propertysearch,
          });
          if (res["data"]["code"] === 200) {
            setNoticeBoardList(res["data"]["results"]["data"]);
            setPage((prevPage) => ({
              ...prevPage,
              count: res["data"]["results"]["totalCount"],
            }));
            setTotalCount(res.data.results.totalCount);
          }
          dispatch(showLoader({ showLoader: false }));
        } catch (e) {
          const error = DisplayErrorMessages(e);
          dispatch(showLoader({ showLoader: false }));
        }
      };
      const rowsPerPage = useCallback(
        (val: any) => {
          setPage({ ...page, page: val.page, limit: val.limit });
          getUsers(val.page, val.limit);
        },
        [page]
      );

      const handleClick = (id: Number, event: React.MouseEvent<HTMLElement>) => {
        setcOpen(cOpen === id ? 0 : id);
        setAnchorEl(event.currentTarget);
      };
      const handleClose = (e: any, data: any) => {
        setcOpen(0);
        setAnchorEl(null);
        if (e === "View") {
          props.pageStatus("showNotice");
          let tmpdata = {
            page: e,
            data: data,
          };
          props.pageData(tmpdata);
        }
        if (e === "Edit") {
          props.pageStatus("editNotice");
          let tmpdata = {
            page: e,
            data: data,
          };
          props.pageData(tmpdata);
        }
        if (e === "Delete") {
          deleteNotice(data.id);
        }
      };
      const deleteNotice = async (id: any) => {
        DeleteApiMethod("property/notice-boards/" + id)
          .then((res) => {
            dispatch(
              showToaster({
                dialogBgColor: "success",
                dialogMsg: "Deleted successfully",
                showDialog: true,
                timer: "5000",
              })
            );
            getUsers(page.page, page.limit);
          })
          .catch((e) => {
            const error = DisplayErrorMessages(e);
            dispatch(
              showToaster({
                dialogBgColor: "bg-danger",
                dialogMsg: error,
                showDialog: true,
                timer: "5000",
              })
            );
          });
      };
      useEffect(()=>{
        getUsers(page.page, page.limit);
      },[])
    return (
        <>
            <Box className="mt-3">
      {totalCount > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Ttile</TableCell>
                <TableCell>Details</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Property</TableCell>
                <TableCell>Unit</TableCell>
                <TableCell>Property All</TableCell>
                <TableCell>Unit All</TableCell>
                <TableCell>View Details</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {NoticeBoardList.map((val, key) => (
                <TableRow key={key}>
                  <TableCell>{val.title}</TableCell>
                  <TableCell>{val.details}</TableCell>
                  <TableCell>{val.start_date ? formatDate(val.start_date) : "-"}</TableCell>
                  <TableCell>{val.end_date ? formatDate(val.end_date) : "-"}</TableCell>
                  <TableCell>{val.property_id}</TableCell>
                  <TableCell>{val.unit_id}</TableCell>
                  <TableCell>{val.property_all ? "Yes" : "No"}</TableCell>
                  <TableCell>{val.unit_all ? "Yes" : "No"}</TableCell>
                  <TableCell>
                  {" "}
                        <Button
                          variant="contained"
                          onClick={() => handleClose("View", val)}
                          style={{ borderRadius: 25 }}
                        >
                          view
                        </Button>
                  </TableCell>
                <TableCell>
                        <IconButton
                          aria-label="more"
                          id={"long-button_" + val.id}
                          aria-controls={
                            cOpen === val.id
                              ? "long-menu_" + val.id
                              : undefined
                          }
                          aria-expanded={
                            cOpen === val.id ? "true" : undefined
                          }
                          aria-haspopup="true"
                          onClick={(e) => {
                            handleClick(val.id, e);
                          }}
                        >
                          <Typography
                            className="poppins-family"
                            color="primary"
                          >
                            Action
                          </Typography>
                          <KeyboardArrowDownTwoToneIcon color="primary" />
                        </IconButton>
                        <Menu
                          id={"long-menu_" + val.id}
                          MenuListProps={{
                            "aria-labelledby": "long-button_" + val.id,
                          }}
                          anchorEl={anchorEl}
                          open={cOpen === val.id}
                          onClose={handleClose}
                          PaperProps={{
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5,
                              width: "20ch",
                            },
                          }}
                        >
                          {options.map((option: any) => (
                            <MenuItem
                              key={option}
                              onClick={() => {
                                handleClose(option, val);
                              }}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Menu>
                      </TableCell>
                </TableRow>
              ))}
            </TableBody>
            {NoticeBoardList.length !== 0 && (
                <TableFooter>
                  <TableRow>
                    <Pagination
                      pagination={page}
                      colSpan={6}
                      rowsPerPageChange={rowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              )}
          </Table>
        </TableContainer>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Typography>No data</Typography>
        </div>
      )}
    </Box>
        </>
    )
}

export default AllNoticeBoard
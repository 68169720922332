import React, { useContext } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import { Box, Typography } from '@mui/material';
import { TenantContext } from './AddTenant';
import { Formik, Form } from 'formik';
import { useAppDispatch } from '../../Store/hooks';
import { showLoader } from '../../Store/loaderSlice';
import { showToaster } from "../../Store/toastSlice";
import { PostApiMethod } from '../../Utils/ApiService';

const UploadDocument = () => {
    const tenant = useContext(TenantContext);
    const dispatch = useAppDispatch();

    const handleFileChange = (files, setFieldValue) => {
        setFieldValue('document',files);
    }

    const documentUpload = async(values) => {
        dispatch(showLoader({showLoader:true}))
        try{
            let form = new FormData();
            form.append("document[]",values.document[0]);
            form.append("user_id",186);
            let res = await PostApiMethod("property/document/addTenantDocument",form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                  },
            });
            if(res["data"]["error"] === false){
                dispatch(showToaster({
                    dialogBgColor:'bg-success',
                    dialogMsg:res["data"]["message"],
                    showDialog:true,
                    timer:'3000'
                }))
                tenant.changePage("listTenant")
            }
        }catch(error){
            dispatch(showToaster({
                dialogBgColor:"bg-danger",
                dialogMsg:error["response"] ? error["response"]["data"]["message"] : "Failed to upload document",
                showDialog:true,
                timer:'3000'
            }))
        }
        dispatch(showLoader({showLoader:false}))
    }

    return (
        <>
            <Box className="d-flex justify-content-between mt-4">
                <Box>
                    <h5 className='fw-bold'>Add New Tenant</h5>
                    <Typography style={{color:'gray'}}>Documents</Typography>
                </Box>
            </Box>
            <Formik
                initialValues={{
                    document:[],
                }}
                onSubmit={async(values) => {
                    documentUpload(values)
                }}
            >
                {({ handleSubmit, setFieldValue, values, errors, touched }) => (
                    <Form onSubmit={handleSubmit}>
                        <Box className="p-1 mt-2">
                            <DropzoneArea name="document" value={values.document} onChange={(files) => handleFileChange(files,setFieldValue)}/>
                        </Box>
                        <Box className="mt-3">
                            <button className="btn btn-white" type="button" onClick={() => tenant.handleNextPage("NewTenant")}>Back</button>
                            <button className="btn btn-danger ms-3" type="submit">Save</button>
                        </Box>
                    </Form>
                )}
            </Formik>
            
        </>
    )
}

export default UploadDocument
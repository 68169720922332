import { showLoader } from "../../Store/loaderSlice"
import { showToaster } from "../../Store/toastSlice";
import { DeleteApiMethod, PostApiMethod } from "../../Utils/ApiService";

export const getModules = async(dispatch,state,page,setPage) => {
    dispatch(showLoader({showLoader:true}));
    try {
        let res = await PostApiMethod("module/getModule",{...page});
        if(!res?.data?.error){
            state(res?.data?.results?.data);
            setPage({...page,count: res?.data?.results?.totalCount})
        }
    } catch (error) {
        dispatch(showToaster({dialogBgColor: "bg-danger",
            dialogMsg: error.response ? error.response.data.message : "Failed to get modules",
            showDialog: true,
            timer: "3000"
        }))
    }
    dispatch(showLoader({showLoader:false}));
}

export const savePermission = async(dispatch,data,permissionData,setPermissionData) => {
    dispatch(showLoader({showLoader:true}))
    try{
        let res = await PostApiMethod("permission/add",{...data});
        if(!res?.data?.error){
            dispatch(showToaster({
                dialogBgColor:"bg-success",
                dialogMsg: res?.data?.message,
                showDialog: true,
                timer: "3000"
            }))
        }
        let newPermissionData = [...permissionData,res?.data?.results?.data];
        setPermissionData(newPermissionData);
    }catch(error){
        dispatch(showToaster({dialogBgColor: "bg-danger",
            dialogMsg: error.response ? error.response.data.message : "Failed to save permission",
            showDialog: true,
            timer: "3000"
        }))
    }
    dispatch(showLoader({showLoader:false}))
}

export const removePermission = async(dispatch,data,permissionData,setPermissionData) => {
    dispatch(showLoader({showLoader:true}))
    try{
        await DeleteApiMethod(`permission/${data?.id}`);
        dispatch(showToaster({
            dialogBgColor:"bg-success",
            dialogMsg: "Permission has been removed",
            showDialog: true,
            timer: "3000"
        }));
        let newPermissionData = [...permissionData];
        setPermissionData(newPermissionData?.filter((val) => val.id !== data?.id))
    }catch(error){
        console.log(error)
        dispatch(showToaster({dialogBgColor: "bg-danger",
            dialogMsg: error.response ? error.response.data.message : "Failed to remove permission",
            showDialog: true,
            timer: "3000"
        }))
    }
    dispatch(showLoader({showLoader:false}))
}

export const getPermission = async(dispatch,setData,params) => {
    dispatch(showLoader({showLoader:true}))
    try{
        let res = await PostApiMethod(`permission/getPermission`,{
            roleId:params?.roleId
        });
        if(!res?.data?.error){
            setData(res?.data?.results?.data);
        }
    }catch(error){
        dispatch(showToaster({dialogBgColor: "bg-danger",
            dialogMsg: error.response ? error.response.data.message : "Failed to get permission",
            showDialog: true,
            timer: "3000"
        }))
    }
    dispatch(showLoader({showLoader:false}))
}
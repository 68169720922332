import React, { useContext } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, FormLabel, Grid, TextField } from '@mui/material';
import { UserContext } from './UserManagement';
import { TextInput } from '../../Components/Text/TextInput';
import { Search } from '@mui/icons-material';
import MenuGroup from '../../Components/DropDown/MenuGroup';
import NoData from '../../Components/Pagination/NoData';
import Pagination from '../../Components/Pagination/Pagination';
import { PermissionCheck } from '../../App/PermissionCheck';

const UserTable = () => {
    const user = useContext(UserContext);
    const { data, setData, getUsers, searchUser, setSearchUser } = user;
    const editPermission = PermissionCheck("pb_user_edit");
    const viewPermission = PermissionCheck("pb_user_view");

    return (
        <>
            <Grid container spacing={3} className='mt-1'>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextInput 
                        label="Search"
                        name="search"
                        onChange={(e) => {
                            setSearchUser(e.target.value);
                            getUsers({page:1,limit:5,searchString:e.target.value})
                        }}
                        value={searchUser}
                        startadornment={true}
                        starticon={<Search />}
                    />
                </Grid>
            </Grid>
            {viewPermission && <TableContainer className="mt-2">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Mobile</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {user?.userData.map((val,key) => (
                            <TableRow key={key}>
                                <TableCell>{val.firstName}</TableCell>
                                <TableCell>{val.email}</TableCell>
                                <TableCell>{val.phoneNumber}</TableCell>
                                <TableCell>{val.roleDetails?  val.roleDetails.name : val.role }</TableCell>
                                <TableCell align="right">
                                    <MenuGroup 
                                        edit={editPermission}
                                        clickEdit={() => setData({...data,open:true,edit:true,...val})}
                                    />
                                
                                </TableCell>
                            </TableRow>
                        ))}
                        {user?.userData.length === 0 && <NoData colSpan={5} />}
                        <Pagination 
                            pagination={data?.page}
                            rowsPerPageChange={(val) => getUsers({...val,searchString:searchUser})}
                        />
                    </TableBody>
                </Table>
            </TableContainer>}
        </>
    );
};
export default React.memo(UserTable);

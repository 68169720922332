import React,{ useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { store } from "../Store/store";
import { showToaster } from "../Store/toastSlice";



export const restrictInitialSpace = (e: any) => {
    if (e.keyCode === 32 && !e.target.value.length) {
        e.preventDefault();
    }
}
export const CheckPropertyExistOrNot = (data: any,key: string,loop?: string) => {
    if (loop) {
        return ((loop in data && data[loop] && key in data[loop]) ? data[loop][key] : "-")
    }
    return (key in data ? data[key] : "-");
}

export const textValidator = (payload: Array<{ value: string,maxLength: number | null,minLength: number | null,key: string }>) => {

    let error: Array<{ "key": string,"type": string }> = [];
    payload.forEach(textField => {
        if (textField && textField.value && textField.maxLength && textField.value.trim().length > textField.maxLength)
            error.push({ "key": textField.key,"type": "maxLength" });
        if (textField && textField.value && textField.minLength && textField.value.trim().length < textField.minLength)
            error.push({ "key": textField.key,"type": "minLength" });
    });
    return { "validation": (error && error.length > 0),"error": error };
}


export const DisplayToaster = (bg_color: string,message: string,timer?: string) => {

    store.dispatch(showToaster(
        {
            dialogBgColor: bg_color,
            dialogMsg: message,
            showDialog: true,
            timer: timer ?? '5000'
        }));
}

export const DisplayErrorMessages = (err: any) => {
    let err_msg = "";
    let cnt = 0;
    if (err && err.response && err.response.data && err.response.data.errors !== undefined) {
        err.response.data.errors.forEach((err: any,index: any) => {
            cnt += 1;
            err_msg += cnt + ". " + err.msg + "\n";
        });
    }
    if (err && err.response && err.response.data && err.response.data.error !== undefined) {
        err_msg = err.response.data.error ? err.response.data.error : "Something went wrong!";
    }
    return err_msg;
}

const SharedService = () => {}

export default SharedService;

export const errorHandling = (error: any) => {
  
  if (error && error.error) {
    if (error.error.message && error.error.message.message) {
      return error.error.message.message;
    } else if (error.error.message && !error.error.message.message) {
      return error.error.message;
    }
    if (error.error.response && error.error.response.error_message) {
      return error.error.response.error_message;
    } else if (error.error.response && !error.error.response.error_message) {
      return error.error.response;
    }
  }
  if (error.response && error.response.data && error.response.data.message) {
    if (error.response.data.message.message) {
      return error.response.data.message.message;
    } else if(error.response.data.errors) {
      const resError: Array<string> = [];
      error.response.data.errors.forEach((element:any) => {
        resError.push(element.msg)
      });
      return resError.join(", ");
    } else {
      return error.response.data.message;
    }
  }
  if (error.response && error.response.data && 'response' in error.response.data && typeof error.response.data.response === 'string') {
    return error.response.data.response;
  }
  return '';
}
export const isNotEmpty = (obj: {} | null | undefined) => obj !== undefined && obj !== null && Object.keys(obj).length ? true : false;


export const Login = () => {
    //const navigate = useNavigate();
    //FetchUserDetails();
    //navigate("/customer");
}
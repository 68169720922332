import React, { useContext } from 'react';
import { Box, Grid, TextField, Typography, Select, MenuItem } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { TenantContext } from './AddTenant';
import { Formik, Form } from 'formik';
import { useAppDispatch } from '../../Store/hooks';
import { showLoader } from '../../Store/loaderSlice';
import { showToaster } from "../../Store/toastSlice";

const NewTenant = () => {
    const tenant = useContext(TenantContext);
    const dispatch = useAppDispatch();

    const addNewTenant = (values) => {
        dispatch(showLoader({showLoader:true}))
        try{
            tenant.handleNextPage("UploadDocument");
            tenant.updateFormData(values);
        }catch(error){
            dispatch(showToaster({dialogBgColor: "bg-danger",
                dialogMsg: "Failed to update tenant details",
                showDialog: true,
                timer: "3000"}))
        }
        dispatch(showLoader({showLoader:false}))
    }
   
    return (
        <>
            <Box className="mt-5 d-flex justify-content-between">
                <Box>
                    <h4 className='fw-bold'>Add New Tenant</h4>
                    <Typography sx={{ color: 'gray' }}>Home Details</Typography>
                </Box>
            </Box>
            <Formik
                initialValues={{
                    property_id:tenant.formData.property_id,
                    unit_id: tenant.formData.unit_id,
                    lease_start_date: tenant.formData.lease_start_date,
                    lease_end_date: tenant.formData.lease_end_date,
                    lease_renewal: tenant.formData.lease_renewal,
                    general_rent: tenant.formData.general_rent,
                    security_deposit: tenant.formData.security_deposit,
                    late_fee: tenant.formData.late_fee,
                    incident_receipt: tenant.formData.incident_receipt
                }}
                onSubmit={(values) => {
                    addNewTenant(values);
                }}
            >
                {({ handleChange, handleSubmit, values, errors, touched }) => (
                    <Form onSubmit={handleSubmit}>
                        <Box className="mt-3">
                            <Grid container spacing={4}>
                                <Grid item md={4} lg={4} sm={12} xs={12}>
                                    <Typography className="fw-bold">Property</Typography>
                                    <Select displayEmpty variant="standard" name="property_id" className='w-100' value={values.property_id} onChange={handleChange}>
                                        <MenuItem disabled value=""><em>Choose</em></MenuItem>
                                        {tenant.propertyList.length !== 0 && tenant.propertyList.map((val,key) => (
                                            <MenuItem value={val.id} key={key}>{val.property_name}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item md={4} lg={4} sm={12} xs={12}>
                                    <Typography className="fw-bold">Unit Name</Typography>
                                    <Select displayEmpty variant="standard" name="unit_id" className='w-100' value={values.unit_id} onChange={handleChange}>
                                        <MenuItem disabled value=""><em>Choose</em></MenuItem>
                                        {tenant.unitList.length !== 0 && tenant.unitList.map((val,key) => (
                                            <MenuItem value={val.id} key={key}>{val.unit_name}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item md={4} lg={4} sm={12} xs={12}>
                                    <Typography className="fw-bold">Lease Start Date</Typography>
                                    <TextField
                                        type="date"
                                        name="lease_start_date"
                                        className='form-control'
                                        variant='standard'
                                        value={values.lease_start_date}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item md={4} lg={4} sm={12} xs={12}>
                                    <Typography className="fw-bold">Lease End Date</Typography>
                                    <TextField
                                        type="date"
                                        name="lease_end_date"
                                        className='form-control'
                                        variant='standard'
                                        value={values.lease_end_date}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item md={4} lg={4} sm={12} xs={12}>
                                    <Typography className="fw-bold">Lease Renewal Reminder</Typography>
                                    <TextField
                                        type="number"
                                        name="lease_renewal"
                                        className='form-control'
                                        variant='standard'
                                        placeholder="Type here"
                                        value={values.lease_renewal}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                            <Box className="mt-3">
                                <Typography className="fw-bold">Rental Information</Typography>
                            </Box>
                            <Grid container spacing={4} className="mt-1">
                                <Grid item md={4} lg={4} sm={12} xs={12}>
                                    <Typography className="fw-bold">General Rent</Typography>
                                    <TextField
                                        type="number"
                                        name="general_rent"
                                        className='form-control'
                                        placeholder='Type here'
                                        variant='standard'
                                        value={values.general_rent}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item md={4} lg={4} sm={12} xs={12}>
                                    <Typography className="fw-bold">Security Deposit</Typography>
                                    <TextField
                                        type="number"
                                        name="security_deposit"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    Fixed
                                                </InputAdornment>
                                            ),
                                        }}
                                        className='form-control'
                                        placeholder='Type here'
                                        variant='standard'
                                        value={values.security_deposit}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item md={4} lg={4} sm={12} xs={12}>
                                    <Typography className="fw-bold">Late Fee</Typography>
                                    <TextField
                                        type="number"
                                        name="late_fee"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    Fixed
                                                </InputAdornment>
                                            ),
                                        }}
                                        className='form-control'
                                        placeholder='Type here'
                                        variant='standard'
                                        value={values.late_fee}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item md={4} lg={4} sm={12} xs={12}>
                                    <Typography className="fw-bold">Incident Receipt</Typography>
                                    <TextField
                                        type="number"
                                        name="incident_receipt"
                                        className='form-control'
                                        placeholder='Type here'
                                        variant='standard'
                                        value={values.incident_receipt}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box className="mt-4">
                            <button className="btn btn-white" onClick={() => tenant.handleNextPage("PersonalInformation")}>Back</button>
                            <button className="btn btn-danger ms-2">Save & Proceed Next</button>
                        </Box>
                    </Form>
                )}
            </Formik>

        </>
    )
}

export default NewTenant;
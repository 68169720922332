import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { AuthorizedLayout } from "../Layouts/AuthorizedLayout";
import { Pagination, TextField, Typography } from "@mui/material";
import EngineeringIcon from "@mui/icons-material/Engineering";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import CustomTabPanel from "../../Components/Tab/CustomTabPanel";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import AddTenant from "./AddTenant";
import { GetApiMethod, PostApiMethod } from "../../Utils/ApiService";
import { isNotEmpty } from "../../Utils/HelperService";
import { Button } from "@mui/base";
import ShowTenant from "./ShowTenant";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import AssignVendorsList from "../Vendors/AssignVendorsList";
import ListTenant from "./ListTenant";
import ListTenantHistory from "./ListTenantHistory";
import TenantSelected from "../../Assets/images/menu-icons/location_away_selected.png";
import EditTenant from "./EditTenant";
import { ButtonPrimary } from "../../Components/Button/ButtonPrimary";
import AddIcon from "../../Assets/images/add_plus_icon.svg";
import { useAppDispatch } from "../../Store/hooks";
import { showLoader } from "../../Store/loaderSlice";
import BoxHeader from "../../Components/Box/BoxHeader";

const AntTabs = styled(Tabs)({
  marginLeft: "15px",
  "& .MuiTabs-indicator": {
    backgroundColor: "#FF7D73",
  },
});

const AntTab = styled(Tab)({
  fontSize: "13px",
  fontFamily: "Poppins",
  "&.Mui-selected": {
    color: "#FF7d73",
  },
});
interface TenantOptions {
  listTenant: boolean;
  addTenant: boolean;
  addRequest: boolean;
  showTenant: boolean;
  assignVendors: boolean;
  editTenant: boolean;
  [key: string]: boolean; // Index signature
}
const Tenant = () => {
  const [tabValue, setTabValue] = useState(0);
  const [tenantData, setTenantData] = useState("");
  const [addNew, setAddNew] = useState({
    listTenant: true,
    addTenant: false,
    addRequest: false,
    showTenant: false,
    assignVendors: false,
    editTenant: false,
  });
  const [assignVendors, setAssignVendors] = useState(false);
  const [addTenant, setAddTenant] = useState(false);
  const [listTenant, setListTenant] = useState(true);
  const [showTenant, setShowTenant] = useState(false);
  const [tenantList, setTenantList] = useState([]);
  const [tenantCount, setTenantCount] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [unitList, setUnitList] = useState([]);
  const [propertyList, setPropertyList] = useState([]);
  const dispatch = useAppDispatch();
 
  useEffect(() => {
    getUnit();
    getProperty();
  },[])

  const getUnit = async() => {
    dispatch(showLoader({showLoader:true}))
    try{
      let response = await PostApiMethod("property/unit/list", {});
      if (response["data"]["error"] === false) {
        setUnitList(response.data.results.data)
      }
    }catch(error){
      console.log("error",error)
    }
  }

  const getProperty = async() => {
    try{
      let response = await PostApiMethod("property/properties/list", {});
      if (response["data"]["error"] === false) {
        setPropertyList(response.data.results.data);
      }
    }catch(error){
      console.log("error",error)
    }
    dispatch(showLoader({showLoader:false}))
  }


  const handleChange = (e: any, val: any) => {
    setTabValue(val);
  };

  const pagination = (e: any) => {
    setPage(e + 1);
    // setLimit();
  };

  const rowsPerPage = (e: any) => {
    console.log("e", e);
    setLimit(e);
    // setLimit();
  };

  const handleSearchChange = (e: any) => {
    setSearch(e);
  };

  const handleTenant = (e: any, id = null) => {
    if (e === "Add") {
      setAddTenant(true);
      setListTenant(false);
    } else if (e === "Back") {
      setSearch("");
      setListTenant(true);
      setAddTenant(false);
      setShowTenant(false);
      // setAssignVendors(false)
    }
  };

  const handlePageDataChange = (data: any) => {
    setTenantData(data.data);
  };

  const pageStatus = (data: any) => {
    let tmpData: TenantOptions = { ...addNew };
    for (let prop in tmpData) {
      if (prop !== data) {
        tmpData[prop] = false; // Set all properties to false except the one specified
      } else {
        tmpData[prop] = true;
      }
    }
    setAddNew(tmpData);
  };

  const handleBackSubmit = () => {
    let tmpData: TenantOptions = { ...addNew };
    for (let prop in tmpData) {
      if (prop === "listTenant") {
        tmpData[prop] = true; // Set all properties to false except the one specified
      } else {
        tmpData[prop] = false;
      }
    }
    setAddNew(tmpData);
  };

  const historyTab = () => {
    setTabValue(0);
  }
  const clearTenantData = () => {
    setTenantData("")
  }

  return (
    <>
      <AuthorizedLayout>
        <Card
          variant="outlined"
          sx={{ borderRadius: "8px" }}
          className="border-0 default-page-height"
        >
          <BoxHeader
            dashboard_name="Tenant"
            image={TenantSelected}
            button_name="Add New Tenant"
            button_show={addNew["listTenant"]}
            onAdd={() => pageStatus("addTenant")}
          >
            <AntTabs value={tabValue} onChange={handleChange}>
              <AntTab
                className="tab-menu"
                label={
                  (addNew.addTenant 
                    ? "Add"
                    : addNew.editTenant && tenantData
                    ? "Edit"
                    : addNew.showTenant
                    ? "Show"
                    : "All") + " Tenant"
                }
              />
              {addNew.showTenant && <AntTab className="tab-menu" label="Tenant History" />}
            </AntTabs>
          </BoxHeader>
          {!Object.values(addNew).includes(true) && (
            <Box sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                {listTenant ? (
                  <Grid item lg={2} md={6} xs={12} sm={12}>
                    <FormControl variant="standard" className="w-100">
                      <TextField
                        type="text"
                        variant="standard"
                        placeholder="Search here..."
                        className="form-control"
                        name="search"
                        onChange={(e) => {
                          handleSearchChange(e.target.value);
                        }}
                      />
                    </FormControl>
                  </Grid>
                ) : (
                  ""
                )}
                {/* <Grid item lg={2} md={6} xs={12} sm={12}>
                                    <Button className='btn-danger' type="button" onClick={() => { handleSearchClick() }}>Search</Button>
                                </Grid> */}
              </Grid>
            </Box>
          )}
          <Box>
            <CustomTabPanel value={tabValue} index={0}>
              <Box>
                {addNew["listTenant"] ? (
                  <ListTenant
                    pageStatus={pageStatus}
                    pageData={(e: any) => {
                      handlePageDataChange(e);
                    }}
                    unitList={unitList}
                    propertyList={propertyList}
                  />
                ) : addNew["addTenant"] ? (
                  <AddTenant
                    pageStatus={pageStatus}
                    clearTenantData={clearTenantData}
                    pageData={(e: any) => {
                      handlePageDataChange(e);
                    }}
                    unit={unitList}
                    property={propertyList}
                    tenantdata={tenantData}
                    // showTenantChange={(e: any) => { handleTenant("Show", e) }}
                    tenantsearch={search}
                  />
                ) : addNew["showTenant"] ? (
                  <ShowTenant
                    pageStatus={pageStatus}
                    pageData={(e: any) => {
                      handlePageDataChange(e);
                    }}
                    tenantdata={tenantData}
                  />
                ) : (
                  addNew["editTenant"] && (
                    <EditTenant
                      pageStatus={pageStatus}
                      clearTenantData={clearTenantData}
                      pageData={(e: any) => {
                        handlePageDataChange(e);
                      }}
                      tenantdata={tenantData}
                    />
                  )
                )}
              </Box>
            </CustomTabPanel>
            {addNew.showTenant && <CustomTabPanel value={tabValue} index={1}>
              <Box>
                <ListTenantHistory
                  pageStatus={pageStatus}
                  pageData={(e: any) => {
                    handlePageDataChange(e);
                  }}
                  historyTab={historyTab}
                />
              </Box>
            </CustomTabPanel>}
          </Box>
        </Card>
      </AuthorizedLayout>
    </>
  );
};

export default Tenant;

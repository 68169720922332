import React from 'react';
import { Box } from '@mui/material';
import BigCoins from '../../Assets/images/big-coins.png';

export const MoneyCard = () => {
    return (
        <Box sx={{width:'300px',height:'128px',background:'#F9F9F9',borderRadius:'10px',padding:'18px'}}>
            <Box className="d-flex align-items-center justify-content-between">
                <Box className="d-flex align-items-center">
                    <img src={BigCoins} alt="coins" width={25} />
                    <Box sx={{color:'#24BC94',fontSize:'16px'}} className="ms-2">Total Budget</Box>
                </Box>
                <Box sx={{color:'#808080',fontSize:'14px'}}>Feb 2024</Box>
            </Box>
            <Box className="d-flex align-items-center justify-content-between">
                <Box sx={{fontSize:'40px',fontWeight:'bold'}}>R15,000</Box>
            </Box>
        </Box>
    )
}
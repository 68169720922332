import React, { useContext } from 'react';
import { Box, Grid, FormLabel, TextField, Select, MenuItem } from '@mui/material';
import { Formik, Form } from 'formik';
import ButtonGroup from '../../Components/Button/ButtonGroup';
import { TrainingContext } from './TrainingManagement';
import { AddNewTargetSchema } from './TrainingValidationSchema';

const AddNewTarget = () => {
    const training = useContext(TrainingContext);

    return (
        <>
            <Box className="fw-bold fs-5 my-3">Add New Training Budget</Box>
            <Formik
                initialValues={{
                    from_date:'',
                    to_date:'',
                    training_certificate:'',
                    budget:''
                }}
                validationSchema={AddNewTargetSchema}
                onSubmit={async(values) => {
                    console.log(values)
                }}
            >
                {({handleSubmit, handleChange, values, errors, touched}) => (
                    <Form onSubmit={handleSubmit}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <FormLabel className='fw-bold'>From Date</FormLabel>
                                <TextField 
                                    variant='standard'
                                    type="date"
                                    className='form-control'
                                    name="from_date"
                                    value={values.from_date}
                                    onChange={handleChange}
                                />
                                {touched.from_date && errors.from_date && (<div className='text-danger'>{errors.from_date}</div>)}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <FormLabel className='fw-bold'>To Date</FormLabel>
                                <TextField 
                                    variant='standard'
                                    type="date"
                                    className='form-control'
                                    name="to_date"
                                    value={values.to_date}
                                    onChange={handleChange}
                                />
                                {touched.to_date && errors.to_date && (<div className='text-danger'>{errors.to_date}</div>)}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <FormLabel className='fw-bold'>Training/Certificate Name</FormLabel>
                                <Select displayEmpty className='w-100 mt-2' variant='standard' value={values.training_certificate}>
                                    <MenuItem value="">Choose</MenuItem>
                                    <MenuItem value="Excavator Operator">Excavator Operator</MenuItem>
                                    <MenuItem value="Grader Operator">Grader Operator</MenuItem>
                                    <MenuItem value="Tipper Truck Operator">Tipper Truck Operator</MenuItem>
                                    <MenuItem value="Water Bower Operator">Water Bower Operator</MenuItem>
                                </Select>
                                {touched.training_certificate && errors.training_certificate && (<div className='text-danger'>{errors.training_certificate}</div>)}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <FormLabel className='fw-bold'>Budget</FormLabel>
                                <TextField 
                                    variant='standard'
                                    type="number"
                                    className='form-control'
                                    name="budget"
                                    value={values.budget}
                                    onChange={handleChange}
                                    placeholder='Type here...'
                                />
                                {touched.budget && errors.budget && (<div className='text-danger'>{errors.budget}</div>)}
                            </Grid>
                        </Grid>
                        <ButtonGroup onBack={training.closeAddNewTarget} submitText="Save"/>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default AddNewTarget
import React from "react";
import Stack from "@mui/material/Stack";
import { PieChart } from "@mui/x-charts/PieChart";
import Card from "@mui/material/Card";
import { Box, Typography } from "@mui/material";

const data = [
  { label: "Plumber", value: 25, color: "#23BB93" },
  { label: "Cleaner", value: 30, color: "#60A3F9" },
  { label: "Electrician", value: 40, color: "#FF7D73" },
  { label: "Office Staff", value: 10, color: "#FFA95D" },
];

const TotalEmployeeDoughnut = () => {
  return (
    <Card elevation={0} className="dashboard-card">
      <Box className="d-flex justify-content-between">
        <Typography variant="body1">Total Employee</Typography>
        <button
          style={{
            background: "white",
            border: "1px solid #FF7D73",
            color: "#FF7D73",
            padding: "5px 15px",
            borderRadius: "25px",
            fontSize: "11px",
          }}
        >
          View All
        </button>
      </Box>

      <Stack direction="row" justifyContent="center" alignItems="center">
        <div style={{ position: "relative", width: 250, height: 180 }}>
          {/* <PieChart
            series={[
              {
                paddingAngle: 5,
                innerRadius: 60,
                outerRadius: 80,
                data,
              },
            ]}
            width={250}
            height={250}
          /> */}

          <div
            style={{
              position: "absolute",
              top: "50%", // Center vertically
              right: 20, // Distance from the right edge
              transform: "translateY(-50%)", // Center vertically
              textAlign: "left",
            }}
          >
            {data.map((item) => (
              <div key={item.label} style={{ marginBottom: 5 }}>
                <span
                  style={{
                    display: "inline-block",
                    width: 10,
                    height: 10,
                    borderRadius: 100,
                    backgroundColor: item.color,
                    marginRight: 5,
                  }}
                />
                <span>{item.label}</span>
              </div>
            ))}
          </div>
        </div>
      </Stack>
    </Card>
  );
};

export default TotalEmployeeDoughnut;

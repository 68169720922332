import { Input, InputAdornment, Select, TextField, Typography } from '@mui/material';
import React from 'react';

export const TextInput = (props) => {
    const {label, value, name, onChange, endadornment, endicon, startadornment, starticon} = props;
    const InputProps = {};

    if (endadornment) {
    InputProps.endAdornment = <InputAdornment position="end">{endicon}</InputAdornment>;
    }

    if (startadornment) {
    InputProps.startAdornment = <InputAdornment position="start">{starticon}</InputAdornment>;
    }
    return (
        <>
           <Typography variant="div" className='fw-bold'>{label}</Typography>
            <TextField
                type="text"
                className='form-control'
                variant="standard"
                placeholder='Type here...'
                value={value}
                name={name}
                onChange={onChange}
                InputProps={InputProps}
            />
        </>
    )
}

export const DateInput = ({label, name, onChange, value}) => {
    return (
        <>
           <Typography variant="div" className='fw-bold'>{label}</Typography>
            <TextField
                type="date"
                className='form-control'
                variant="standard"
                value={value}
                placeholder='Type here...'
                name={name}
                onChange={onChange}
            />
        </>
    )
}

export const ImageInput = ({label, name, onChange}) => {
    return (
        <>
           <Typography variant="div" className='fw-bold'>{label}</Typography>
            <TextField
                type="file"
                className='form-control'
                variant="standard"
                placeholder='Type here...'
                name={name}
                onChange={onChange}
            />
        </>
    )
}

export const SelectDropdown = (props) => {
    const { label, name, onChange, children, value } = props;
    return (
        <>
            <Typography variant="div" className='fw-bold'>{label}</Typography>
            <Select
                className="w-100 mt-1"
                name={name}
                onChange={onChange}
                displayEmpty
                value={value}
                variant='standard'
            >
                {children}
            </Select>
        </>
    )
}
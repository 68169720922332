import { Close } from "@mui/icons-material";
import { Box, Modal, Typography } from "@mui/material";

export const ModalStyle = (props) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: props.width,
        height:props.height,
        bgcolor: 'white',//'background.paper',
        border: '2px solid #ffff',
        boxShadow: 24,
        outline:'none'
    };
    return style;
}

export const CustomModal = (props) => {
    return (
        <Modal
            open={props?.open}
            onClose={props?.onClose}
        >   
            <Box sx={props?.style}>
                <Box className="border-bottom p-3 d-flex justify-content-between align-items-center">
                    <Typography variant="h6">{props?.header}</Typography>
                    <Close sx={{cursor:'pointer'}} onClick={props?.onClose}/>
                </Box>  
                {props?.children}
            </Box>
        </Modal>
    )
}

import { AuthorizedLayout } from "../Layouts/AuthorizedLayout";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Box, Typography } from "@mui/material";
import settingImage from "../../Assets/images/menu-icons/settings.png";
import CustomTabPanel from "../../Components/Tab/CustomTabPanel";
import { useCallback, useState } from "react";
import { AntTabs, AntTab } from "../../Components/Tab/AntTab";
import HelpDeskTypeList from "../HelpDesk/HelpDeskType/HelpDeskTypeList";
import InvoiceType from "../InvoiceType/InvoiceType";
import { ButtonPrimary } from "../../Components/Button/ButtonPrimary";
import AddIcon from "../../Assets/images/add_plus_icon.svg";

const Settings = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = useCallback((e: any, val: any) => {
    setTabValue(val);
  }, []);

  return (
    <>
      <AuthorizedLayout>
        <Card elevation={0}>
          <Box className="d-flex justify-content-between align-items-center">
            <Box className="d-flex align-items-center">
              <img src={settingImage} alt={"FB"} width="20px" height="20px" />
              <Typography className="psemiboldfont page-header-text">
                Settings
              </Typography>
              <AntTabs value={tabValue} onChange={handleChange}>
                <AntTab label="Ticket Type" className="tab-menu" />
                <AntTab label="Invoice Type" className="tab-menu" />
              </AntTabs>
            </Box>
            {/* <ButtonPrimary
              text="Add New Type"
              className=""
              onClick={() => {
                // setOpenForm(true);
                // setFormValue({
                //   value: { name: "", status: true, image: null, id: "" },
                //   isUpdate: false,
                // });
              }}
              imgSrc={AddIcon}
            /> */}
          </Box>
          <Box>
            <CustomTabPanel value={tabValue} index={0}>
              <HelpDeskTypeList />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <InvoiceType />
            </CustomTabPanel>
          </Box>
        </Card>
      </AuthorizedLayout>
    </>
  );
};

export default Settings;

import React,{ useContext, useState, useEffect } from 'react';
import { useAppDispatch } from '../../Store/hooks';
import { DeleteApiMethod, PostApiMethod, PutApiMethod } from '../../Utils/ApiService';
import { showLoader } from '../../Store/loaderSlice';
import { showToaster } from '../../Store/toastSlice';
import { Modal, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, FormLabel, Grid, TextField, TableFooter, Box, Typography, FormGroup, FormControlLabel, Checkbox, Chip, Select, MenuItem } from '@mui/material';
import { UserContext } from './UserManagement';
import Pagination from '../../Components/Pagination/Pagination';
import MenuGroup from '../../Components/DropDown/MenuGroup';
import { CustomModal, ModalStyle } from '../../Components/ModalStyle';
import { modulePagesSchema } from './AddUserSchema';
import { Form, Formik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import { TextInput } from '../../Components/Text/TextInput';
import { PermissionCheck } from '../../App/PermissionCheck';

const ModulePage = () => {
    const modulePages = useContext(UserContext);
    const { masterData, modulePage, setModulePage } = modulePages;
    const dispatch = useAppDispatch();

    useEffect(() => {
        getModulesPages(modulePage.page);
    },[]);

    const getModulesPages = async(params) => {
        var parameters = params?.limit === -1 ? {} : {page:params?.page,limit:params?.limit};
        setModulePage({...modulePage,searchString:params?.searchString});
        dispatch(showLoader({showLoader:true}));
        try {
            let res = await PostApiMethod('sub-module/getSubModule',{...parameters,searchString:params?.searchString})
            if(!res?.data?.error){
                setModulePage({
                    ...modulePage,
                    modal:false,
                    data:res?.data?.results?.data,
                    page:{page:params?.page, limit:params?.limit, count:res['data']['results']['totalCount']}
                })
            }
        } catch (error) {
            setModulePage({
                ...modulePage,
                data:[]
            });
            dispatch(showToaster({dialogBgColor: "bg-danger",
                dialogMsg: error.response ? error.response.data.message : "Failed to get modules&pages",
                showDialog: true,
                timer: "3000"
            }))
        }
        dispatch(showLoader({showLoader:false}))
    }

    const handleNextPage = (val) => {
        getModulesPages(val)
    }

    const createModulePage = async(val) => {
        dispatch(showLoader({showLoader:true}))
        try {
            let res = await PostApiMethod('sub-module/add',val);
            if(!res?.data?.error){
                dispatch(showToaster({
                    dialogBgColor:'bg-success',
                    dialogMsg: res?.data?.message,
                    showDialog: true,
                    timer: "3000"
                }));
                getModulesPages(modulePage.page)
            }
        } catch (error) {
            dispatch(showToaster({
                dialogBgColor:'bg-danger',
                dialogMsg: error.response ? error.response.data.message : `Failed to create modules&pages`,
                showDialog: true,
                timer: "3000"
            }))
        }
        dispatch(showLoader({showLoader:false}))
    }

    const updateModulePage = async(val) => {
        dispatch(showLoader({showLoader:true}))
        try {
            let res = await PutApiMethod(`sub-module/${modulePage.formData.id}`,val);
            if(!res?.data?.error){
                dispatch(showToaster({
                    dialogBgColor:'bg-success',
                    dialogMsg: res?.data?.message,
                    showDialog: true,
                    timer: "3000"
                }));
                getModulesPages(modulePage.page)
            }
        } catch (error) {
            dispatch(showToaster({
                dialogBgColor:'bg-danger',
                dialogMsg: error.response ? error.response.data.message : `Failed to update Sub Module`,
                showDialog: true,
                timer: "3000"
            }))
        }
        dispatch(showLoader({showLoader:false}))
    }

    const deleteModulePage = async(val) => {
        dispatch(showLoader({showLoader:true}))
        try {
            await DeleteApiMethod(`sub-module/${val.id}`);
            dispatch(showToaster({
                dialogBgColor:'bg-success',
                dialogMsg: `${val.name} has been deleted`,
                showDialog: true,
                timer: "3000"
            }))
            getModulesPages({page:1,limit:5,count:0})
        } catch (error) {
            dispatch(showToaster({
                dialogBgColor:'bg-danger',
                dialogMsg: error.response ? error.response.data.message : `Failed to delete Sub Module`,
                showDialog: true,
                timer: "3000"
            }))
        }
        dispatch(showLoader({showLoader:false}))
    }

    const editPermission = PermissionCheck("pb_submodule_edit");
    const deletePermission = PermissionCheck("pb_submodule_delete");
    const viewPermission = PermissionCheck("pb_submodule_view");

    return (
        <>
            <Grid container spacing={4} className='mt-1'>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormLabel className='fw-bold fs-6'>Search</FormLabel>
                    <TextField 
                        variant='standard'
                        placeholder='Type here...'
                        className='form-control'
                        onChange={(e) => getModulesPages({...modulePage?.page,searchString:e.target.value})}
                    />
                </Grid>
            </Grid>

            {viewPermission && <TableContainer className="mt-2">
                <Table>
                    <TableHead>
                        <TableRow>
                            {/* <TableCell>Module Name</TableCell> */}
                            <TableCell>Sub Module</TableCell>
                            <TableCell align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {modulePage.data.length !== 0 && modulePage.data.map((val,key) => (
                            <TableRow key={key}>
                                {/* <TableCell>{val.module.module_name}</TableCell> */}
                                <TableCell>{val.name}</TableCell>
                                <TableCell align='right'>
                                    <MenuGroup 
                                        id={val.id}
                                        view={false}
                                        edit={editPermission}
                                        delete={deletePermission}
                                        clickEdit={() => {
                                            setModulePage({...modulePage,edit:true,modal:true,formData:{id:val.id,moduleId:val.moduleId,name:val.name}})}
                                        }
                                        clickDelete={() => deleteModulePage(val)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                        {modulePage.data.length === 0 && <TableRow>
                            <TableCell colSpan={8} align='center'>No Data</TableCell>
                        </TableRow>}
                    </TableBody>
                    {modulePage.data.length !== 0 &&<TableFooter>
                        <Pagination colSpan={8} pagination={modulePage.page} rowsPerPage={10} rowsPerPageChange={handleNextPage} />
                    </TableFooter>}
                </Table>
            </TableContainer>}

            {/**Module Page */}
            <CustomModal
                open={modulePage?.modal}
                onClose = {() =>  setModulePage({...modulePage,...{modal:false}})}
                header={`${modulePage.edit ? "Edit " : "Create "}Sub Module`}
                style={ModalStyle({width:800,height:'auto'})}
            >
                <Formik
                    initialValues={{
                        moduleId: modulePage.edit ? modulePage.formData.moduleId : "",
                        name: modulePage.edit ? modulePage.formData.name : ""
                    }}
                    validationSchema={modulePagesSchema}
                    onSubmit={async (values, { resetForm }) => {
                        if (modulePage?.edit) {
                            updateModulePage(values);
                        } else {
                            createModulePage(values);
                        }
                    }}
                >
                    {({ handleSubmit, handleChange, values, errors, touched }) => (
                        <>
                            <Box className="modal-body">
                                <Form onSubmit={handleSubmit}>
                                    <Grid container spacing={2} className='p-3'>
                                        <Grid item md={12}>
                                            <FormLabel className='fw-bold mb-2'>Module Name:<sup className='text-danger'>*</sup></FormLabel>
                                            <Select
                                                className='w-100'
                                                displayEmpty
                                                variant='standard'
                                                value={values.moduleId}
                                                name='moduleId'
                                                onChange={handleChange}
                                            >
                                                <MenuItem disabled value="">Choose</MenuItem>
                                                {masterData.modules.length !== 0 && masterData.modules.map((val, key) => (
                                                    <MenuItem value={val.id} key={key}>{val.name}</MenuItem>
                                                ))}
                                            </Select>
                                            {touched.moduleId && errors.moduleId && <div className='text-danger'>{errors.moduleId}</div>}
                                        </Grid>
                                        <Grid item md={12}>
                                            <TextInput
                                                label={"Sub Module"}
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}
                                            />
                                            {touched.name && errors.name && <div className='text-danger'>{errors.name}</div>}
                                        </Grid>
                                    </Grid>
                                    <Box className="d-flex justify-content-end mt-3 p-3">
                                        <button className='btn btn-danger' type="submit">Submit</button>
                                    </Box>
                                </Form>
                            </Box>
                        </>
                    )}
                </Formik>
            </CustomModal>
            
        </>
    )
}

export default ModulePage
import React, { useState, useContext, useCallback } from 'react';
import { Box, TableContainer, Table, TableHead, TableBody, TableCell, TableRow, Modal, Grid, TextField, FormLabel } from '@mui/material';
import { MaintenanceMasterContext } from './MaintenanceMasters';
import MenuGroup from '../../Components/DropDown/MenuGroup';
import { ModalStyle } from '../../Components/ModalStyle';
import * as Yup from 'yup';
import { DeleteApiMethod, PostApiMethod, PutApiMethod } from '../../Utils/ApiService';
import { useAppDispatch } from '../../Store/hooks';
import { showLoader } from '../../Store/loaderSlice';
import { showToaster } from '../../Store/toastSlice';
import { Formik, Form } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import { MaintenanceContext } from './Maintenance';
import AddIcon from '@mui/icons-material/Add';
import Pagination from '../../Components/Pagination/Pagination';

const AreaSchema = Yup.object().shape({
    area_name:Yup.string().required("Area name is required")
})

const Area = () => {
    const master = useContext(MaintenanceMasterContext);
    const maintenance = useContext(MaintenanceContext);
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editData, setEditData] = useState({});
    const dispatch = useAppDispatch();

    const onClickEdit = useCallback((val) => {
        setIsEdit(true);
        setEditData(val);
        setModalOpen(true);
    },[editData])

    const onClickDelete = async(val) => {
        try{
            await DeleteApiMethod(`maintenance/area-type/${val.id}`)
            dispatch(showToaster({
                dialogBgColor: "bg-success",
                dialogMsg: "Area details has been deleted",
                showDialog: true,
                timer: "3000"
            }));
            master.getAreas(master.areaPage);
            maintenance.getAllArea();
        }catch(error){
            dispatch(showToaster({
                dialogBgColor: "bg-danger",
                dialogMsg: error.response ? error.response.data.message : "Failed to delete job",
                showDialog: true,
                timer: "3000"
            }));
        }
    }

    const createArea = async(val,resetForm) => {
        dispatch(showLoader({showLoader:true}))
        setModalOpen(false);
        try{
            let res = await PostApiMethod("maintenance/area-type/add",val);
            if(res["data"]["error"] === false){
                resetForm({values:''});
                dispatch(showToaster({
                    dialogBgColor: "bg-success",
                    dialogMsg: res["data"]["message"],
                    showDialog: true,
                    timer: "3000"
                }));
                master.getAreas(master.areaPage);
                maintenance.getAllArea();
            }
        }catch(error){
            dispatch(showToaster({
                dialogBgColor: "bg-danger",
                dialogMsg: error.response ? error.response.data.message : "Failed to create job",
                showDialog: true,
                timer: "3000"
            }))
        }
        dispatch(showLoader({showLoader:false}))
    }

    const updateArea = async(val) => {
        dispatch(showLoader({showLoader:true}));
       setModalOpen(false);
       setIsEdit(false);
       try{
        let res = await PutApiMethod(`maintenance/area-type/update/${editData.id}`,val);
        if(res["data"]["error"] === false){
            dispatch(showToaster({
                dialogBgColor: "bg-success",
                dialogMsg: res["data"]["message"],
                showDialog: true,
                timer: "3000"
            }));
            master.getAreas(master.areaPage);
            maintenance.getAllArea();
        }
       }catch(error){
        dispatch(showToaster({
            dialogBgColor: "bg-danger",
            dialogMsg: error.response ? error.response.data.message : "Failed to update job",
            showDialog: true,
            timer: "3000"
        }))
       }
       dispatch(showLoader({showLoader:false}));
    }

    const onModalOpen = () => {
        setModalOpen(true);
        setIsEdit(false);
    }

    return (
        <>
            <Box className="d-flex justify-content-between my-3">
                <h5>Areas</h5>
                <button className="btn btn-danger" onClick={onModalOpen}><AddIcon />Add New Area</button>
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align='right'>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {master.areaList.length !== 0 && master.areaList.map((val,key) => (
                            <TableRow key={key}>
                                <TableCell>{val.area_name}</TableCell>
                                <TableCell align='right'>
                                    <MenuGroup 
                                        view={false}
                                        edit={true}
                                        delete={true}
                                        clickEdit={() => onClickEdit(val)}
                                        clickDelete={() => onClickDelete(val)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <Pagination pagination={master.areaPage} rowsPerPage={10} rowsPerPageChange={master.areaNextPage}/>
                </Table>
            </TableContainer>

            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
            >
                <Box sx={ModalStyle({width:600,height:280})}>
                    <Box className="border-bottom p-3 d-flex justify-content-between align-items-center">
                        <Box className='fw-bold fs-5'>{!isEdit ? "Create" : "Edit"} Area</Box>
                        <CloseIcon sx={{cursor:'pointer'}} onClick={() => setModalOpen(false)}/>
                    </Box>
                    <Box>
                        <Formik
                            initialValues={{
                                area_name: isEdit ? editData.area_name : ''
                            }}
                            validationSchema={AreaSchema}
                            onSubmit={async(values, {resetForm}) => {
                                if(isEdit){
                                    updateArea(values)
                                }else{
                                    createArea(values,resetForm)
                                }
                            }}
                        >
                            {({ handleSubmit, handleChange, values, errors, touched }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Grid container spacing={2} className='p-4 '>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <FormLabel className='fw-bold'>Name</FormLabel>
                                            <TextField
                                                type="text"
                                                className='w-100'
                                                placeholder='Type here...'
                                                value={values.area_name}
                                                name='area_name'
                                                variant="standard"
                                                onChange={handleChange}
                                            />
                                            {touched.area_name && errors.area_name && <div className="text-danger">{errors.area_name}</div>}
                                        </Grid>
                                    </Grid>
                                    <Box className="border-top p-4 d-flex justify-content-end mt-3">
                                        <button className='btn btn-primary' type="submit">Submit</button>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                    
                </Box>
            </Modal>
        </>
    )
}

export default Area;
import * as yup from 'yup';

export const userSchema = yup.object().shape({
    name:yup.string().required("Name field is required"),
    email:yup.string().required("Email field is required"),
    mobile:yup.string().required("Mobile number is required"),
    role:yup.string().required("Role field is required")
});

export const roleSchema = yup.object().shape({
    name:yup.string().required("Name field is required")
});

export const moduleSchema = yup.object().shape({
    name:yup.string().required("Name field is required")
});

export const pageSchema = yup.object().shape({
    page_name:yup.string().required('Name field is required'),
    page_url:yup.string().required("URL field is required"),
    page_icon:yup.string().required("ICON field is required")
});

export const modulePagesSchema = yup.object().shape({
    moduleId:yup.string().required("Module name field is required"),
    name:yup.string().required("Required")
});

export const moduleRolesSchema = yup.object().shape({
    role_id:yup.string().required("Role name field is required"),
    module_id:yup.array().min(1, 'Module must be selected').required("Rrequired")
});

export const pagePermissionSchema = yup.object().shape({
    permission: yup.array().of(
        yup.object({
            module_id: yup.string().required("Module is required"),
            role_id: yup.string().required("Role is required"),
            page_id: yup.string().required("Page is required")
        })
    )
})


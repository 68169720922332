import React, { useContext } from 'react';
import { Formik, Form } from 'formik';
import { DropzoneArea } from 'material-ui-dropzone';
import { PropertyContext } from './Main';
import { AddPropertyContext } from './AddProperty';
import { Box, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useAppDispatch } from '../../Store/hooks';
import { showLoader } from '../../Store/loaderSlice';
import { showToaster } from '../../Store/toastSlice';
import { PostApiMethod } from '../../Utils/ApiService';
import { ImageValidation } from './PropertyValidationSchema';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Images = () => {
    const addpropertyform = useContext(AddPropertyContext);
    const property = useContext(PropertyContext);
    const dispatch = useAppDispatch();

    const handleFileChange = (files, setFieldValue) => {
        setFieldValue('image',files);
    }

    const updateImage = async(values) => {
        dispatch(showLoader({showLoader:true}))
        try{
            let form = new FormData();
            values.image.forEach((val) => {
                form.append("image[]",val);
            })
            let res = await PostApiMethod("property/properties/updateImage/"+addpropertyform.data.property_id,form,{
                headers:{
                    "Content-Type":"multipart/form-data"
                }
            });
            if(res["data"]["error"] === false){
                dispatch(showToaster({dialogBgColor: "bg-success",
                    dialogMsg: res["data"]["message"],
                    showDialog: true,
                    timer: "3000"
                }));
                property.onBackPropertyList();
            }
        }catch(error){
            dispatch(showToaster({dialogBgColor: "bg-danger",
                dialogMsg: "Property Image not updated",
                showDialog: true,
                timer: "3000"
            }))
        }
        dispatch(showLoader({showLoader:false}))
    }
    return (
        <>
            <Box className="d-flex justify-content-between mt-4">
                <Box>
                    <h5 className='fw-bold'>{property.isEdit ? <><ArrowBackIcon sx={{cursor:'pointer'}} onClick={property.onBackPropertyList}/>Edit</> : "Add New"} Property</h5>
                    <Typography style={{color:'gray'}}>Images</Typography>
                </Box>
            </Box>
            <Formik
                initialValues={{
                    image:[],
                }}
                // validationSchema={ImageValidation}
                onSubmit={async(values) => {
                    updateImage(values)
                }}
            >
                {({ handleSubmit, setFieldValue, values, errors, touched }) => (
                    <Form onSubmit={handleSubmit}>
                        <Box className="p-1 mt-2">
                            <DropzoneArea name="image[]" acceptedFiles={['image/jpeg', 'image/png']} value={values.image} onChange={(files) => handleFileChange(files,setFieldValue)}/>
                            {touched.image && errors.image && (<div className='text-danger'>{errors.image}</div>)}
                        </Box>
                        {/* <Box className="mt-3">
                            <button className="btn btn-white" type="button" onClick={() => addpropertyform.onBackForm("Rent Form")}>Back</button>
                            <button className="btn btn-danger ms-3" type="submit">Save</button>
                        </Box> */}
                        <Box className="mt-4">
                            <button type="button" className='btn btn-white' onClick={() => addpropertyform.onBackForm("Rent Form")}><ArrowBackIosIcon style={{fontSize:'18px'}}/>Back</button>
                            <button type="submit" className='btn btn-danger ms-2'>Save</button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default Images
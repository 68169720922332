import React, { useState, useContext, useEffect } from 'react';
import { Typography, Box, FormGroup, Checkbox } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Formik, Form } from 'formik';
import { visitorSchema } from './VisitorSchema';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
// import SignatureCanvas from 'react-signature-canvas';
// import fileUpload from '../../Assets/images/icons/fileUpload.png';
// import Modal from '@mui/material/Modal';
import { VisitorContext } from './Visitor';
import { PostApiMethod, PutApiMethod } from "../../Utils/ApiService";
import { useAppDispatch } from '../../Store/hooks';
import { showLoader } from '../../Store/loaderSlice';
import { showToaster } from "../../Store/toastSlice";
import { setgid } from 'process';

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 750,
//     height: 500,
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//   };

const external = ["vehicle_no","make","model","color"];

const AddVisitor = (props) => {
    const visitor = useContext(VisitorContext);
    const [pov, setPOV] = useState(true); // Purpose of visit
    // const [modalOpen, setModalOpen] = useState(false);
    // const [sign, setSign] = useState();
    // const [signUrl, setSignUrl] = useState(" ");
    const dispatch = useAppDispatch();

    const apiResponse = (color,msg) => {
        dispatch(showToaster({dialogBgColor: color,
        dialogMsg: msg,
        showDialog: true,
        timer: "3000",}))
    }

    useEffect(() => {
        if(visitor.editData.purpose_of_visit === 'Official'){
            setPOV(false)
        }
    },[visitor])

    // POV => Purpose of visit
    const handleChangePOV = (e,setFieldValue) => {
        if (e.target.value === 'Official') {
        setPOV(false);
        } else {
        setPOV(true);
        }
        setFieldValue("visitor_type","");
        setFieldValue("unit_id","");
        setFieldValue("tenant_id","");
        setFieldValue("data",[]);
        setFieldValue("location","");
        setFieldValue("phone","");
    };

    const createVisitor = async(formData, resetForm) => {
        dispatch(showLoader({showLoader:true}))
        try{
            let response = await PostApiMethod("property/visitor/add",formData)
            if(response["data"]["error"] === false){
                resetForm({values:''})
                visitor.getRequestList(visitor.page)
                apiResponse("bg-success","Visitor created")
                props.onBack()
            }
        }catch(error){
            apiResponse("bg-danger","Failed to visitor created")
        }
        dispatch(showLoader({showLoader:false}))
    }

    const updateVisitor = async(formData) => {
        dispatch(showLoader({showLoader:true}))
        try{
            let response = await PutApiMethod("property/visitor/update/"+visitor.editData.id,formData);
            if(response["data"]["error"] === false){
                apiResponse("bg-success","Visitor data updated successfully")
                visitor.getRequestList(visitor.page)
                props.onBack();
            }
        }catch(error){
            apiResponse("bg-danger","Failed to update visitor data")
        }
        dispatch(showLoader({showLoader:false}))
    }

//   const addSign = () => {
//     setSignUrl(sign.getTrimmedCanvas().toDataURL("image/png"))
//     setModalOpen(false)
//   }

//   const clearSign = () => {
//     setSign();
//     setSignUrl(" ");
//   }

    const handleTimeChanged = (name, value, setFieldValue) => {
        setFieldValue(name, value);
    };

    const handleParking = (e, setFieldValue) => {
        setFieldValue('parking_requirement',e.target.value);
        setFieldValue('parking_id','');
        setFieldValue('make','');
        setFieldValue('model','');
        setFieldValue('color','');
        setFieldValue('vehicle_no','');
    }
    
    return (
        <>
            <Typography className="fw-bold fs-4 mt-4">{Object.entries(visitor.editData).length !== 0 ? "Edit" : "Add New"} Visitor</Typography>
            <Formik
                initialValues={{
                    purpose_of_visit:visitor.editData.purpose_of_visit || 'Personal',
                    visitor_name: visitor.editData.visitor_name || '',
                    email:visitor.editData.email || '',
                    phone:visitor.editData.phone || '',
                    unit_id: visitor.editData.unit_id || '',
                    parking_requirement: visitor.editData.parking_requirement || '',
                    parking_id:visitor.editData.parking_id || '',
                    description: visitor.editData.description || '',
                    tenant_id: visitor.editData.tenant_id || '',
                    visitor_type: visitor.editData.visitor_type || '',
                    location: visitor.editData.location || '',
                    room_id: visitor.editData.room_id || '',
                    date: visitor.editData.date ? visitor.editData.date.split("T")[0] : '',
                    vehicle_no: visitor.editData.vehicle_no || '',
                    make: visitor.editData.make || '',
                    model: visitor.editData.model || '',
                    color: visitor.editData.color || '',
                    from: visitor.editData.timing ?dayjs(visitor.editData.timing.split("-")[0], 'hh:mm A') : null,
                    to: visitor.editData.timing ? dayjs(visitor.editData.timing.split("-")[1],"hh:mm A"):null,
                    data: [],
                }}
                validationSchema={ visitorSchema }
                onSubmit={async(values, {resetForm}) => {
                    const formData = new FormData();
                    let from, to, fromto = '';
                    Object.keys(values).map((val,key) => {
                        if(values[val] !== ""){
                            if(val === "data"){
                                values[val].forEach((av,ak) => {
                                    formData.append(`data[${ak}]`,av)
                                })
                            }else if(val === 'from' || val === 'to'){
                                formData.delete('timing')
                                if(val === 'from'){
                                    from = values['from'] && dayjs(values['from']).format('hh:mm:A');
                                    fromto += from
                                }else if(val === 'to'){
                                    to = values["to"] && dayjs(values['to']).format('hh:mm:A');
                                    fromto +=  "-" + to
                                }
                                formData.append('timing',fromto)
                            }else{
                                formData.append(val,values[val])
                            }
                        }
                        return true
                    })
                    if(visitor.editData.purpose_of_visit){
                        updateVisitor(formData)
                    }else{
                        createVisitor(formData,resetForm)
                    }
                }}
            >
                {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                        <FormControl component="fieldset" margin="normal">
                            <h6>Purpose of Visit</h6>
                            <RadioGroup row defaultValue={values.purpose_of_visit} onChange={(e) => handleChangePOV(e,setFieldValue)}>
                                <FormControlLabel value="Personal" control={<Radio name="purpose_of_visit" onChange={handleChange} />} label="Personal" />
                                <FormControlLabel value="Official" control={<Radio name="purpose_of_visit" onChange={handleChange}/>} label="Official" />
                            </RadioGroup>
                        </FormControl>
                        <Box className="mt-3">
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={12} lg={4} md={4}>
                                    <Typography className='fw-bold'>Visitor Name</Typography>
                                    <TextField 
                                        variant='standard'
                                        className='w-100'
                                        name='visitor_name'
                                        placeholder="Type here"
                                        onChange={handleChange}
                                        value={values.visitor_name}
                                    />
                                    {touched.visitor_name && errors.visitor_name && (<div className="text-danger">{errors.visitor_name}</div>)}
                                </Grid>
                                {values.purpose_of_visit === 'Official' && <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Visitor Type</Typography>
                                    <Select displayEmpty className="w-100 mt-2" variant='standard' name="visitor_type" value={values.visitor_type} onChange={handleChange}>
                                        <MenuItem disabled value=""><em>Choose</em></MenuItem>
                                        <MenuItem value="Internal">Internal</MenuItem>
                                        <MenuItem value="External">External</MenuItem>
                                    </Select>
                                </Grid>}
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Email</Typography>
                                    <TextField 
                                        variant='standard'
                                        placeholder='Type here'
                                        className="w-100"
                                        name="email"
                                        onChange={handleChange}
                                        value={values.email}
                                    />
                                    {touched.email && errors.email && <div className="text-danger">{errors.email}</div>}
                                </Grid>
                                {values.purpose_of_visit === 'Official' && <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Phone Number</Typography>
                                    <TextField 
                                        variant='standard'
                                        placeholder='Type here'
                                        className="w-100"
                                        name="phone"
                                        value={values.phone}
                                        onChange={handleChange}
                                    />
                                    {touched.phone && errors.phone && <div className='text-danger'>{errors.phone}</div>}
                                </Grid>}
                                {values.purpose_of_visit === 'Personal' && <Grid item xs={12} sm={12} lg={4} md={4}>
                                    <Typography className='fw-bold'>Unit</Typography>
                                    <Select displayEmpty className="w-100 mt-2" name="unit_id" variant='standard'value={values.unit_id} onChange={handleChange}>
                                        <MenuItem disabled value="">
                                            <em>Choose</em>
                                        </MenuItem>
                                        {visitor.unit.length !== 0 && visitor.unit.map((val,key) => {
                                            return (
                                                <MenuItem value={val.id} key={key}>{val.unit_name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </Grid>}
                                <Grid item xs={12} sm={12} lg={4} md={4}>
                                    <Typography className='fw-bold'>Parking Requirement</Typography>
                                    <Select displayEmpty className="w-100 mt-2"  name="parking_requirement" variant='standard' value={values.parking_requirement} onChange={(e) => handleParking(e,setFieldValue)}>
                                        <MenuItem disabled value="">
                                            <em>Choose</em>
                                        </MenuItem>
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </Select>
                                </Grid>
                                {values.parking_requirement === 'Yes' && <Grid item xs={12} sm={12} lg={4} md={4}>
                                    <Typography className='fw-bold'>Parking No</Typography>
                                    <Select displayEmpty className="w-100 mt-2"  name="parking_id" variant='standard' value={values.parking_id} onChange={handleChange}>
                                        <MenuItem disabled value="">
                                            <em>Choose</em>
                                        </MenuItem>
                                        {visitor.parking.length !== 0 && visitor.parking.map((val,key) => {
                                            return (
                                                <MenuItem value={val.id} key={key}>{val.parking_no}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </Grid>}
                                {values.purpose_of_visit === 'Personal' && <Grid item xs={12} sm={12} lg={4} md={4}>
                                    <Typography className='fw-bold'>Tenant Name</Typography>
                                    <Select displayEmpty className="w-100 mt-2"  name="tenant_id" variant='standard' value={values.tenant_id} onChange={handleChange}>
                                        <MenuItem disabled value="">
                                            <em>Choose</em>
                                        </MenuItem>
                                        {visitor.tenant.length !== 0 && visitor.tenant.map((val,key) => {
                                            return (
                                                <MenuItem value={val.id} key={key}>{val.firstName + ' '+ val.lastName}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </Grid>}
                                {values.parking_requirement === 'Yes' && <><Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className='fw-bold'>Vehicle No</Typography>
                                    <TextField 
                                        variant='standard'
                                        placeholder='Type here'
                                        className="w-100"
                                        name="vehicle_no"
                                        value={values.vehicle_no}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className='fw-bold'>Make</Typography>
                                    <TextField 
                                        variant='standard'
                                        placeholder='Type here'
                                        className="w-100"
                                        name="make"
                                        value={values.make}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className='fw-bold'>Model</Typography>
                                    <TextField 
                                        variant='standard'
                                        placeholder='Type here'
                                        className="w-100"
                                        name="model"
                                        value={values.model}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className='fw-bold'>Color</Typography>
                                    <TextField 
                                        variant='standard'
                                        placeholder='Type here'
                                        className="w-100"
                                        name="color"
                                        value={values.color}
                                        onChange={handleChange}
                                    />
                                </Grid></>}
                                {values.purpose_of_visit === 'Official' && <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Location</Typography>
                                    <TextField 
                                        variant='standard'
                                        placeholder='Type here'
                                        className="w-100"
                                        name="location"
                                        value={values.location}
                                        onChange={handleChange}
                                    />
                                    {touched.location && errors.location && <div className='text-danger'>{errors.location}</div>}
                                </Grid>}
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Room</Typography>
                                    <Select displayEmpty className="w-100 mt-2" variant='standard' name="room_id" value={values.room_id} onChange={handleChange}>
                                        <MenuItem disabled value=""><em>Choose</em></MenuItem>
                                        {visitor.amenities.length !== 0 && visitor.amenities.map((val,key) => {
                                            if(val.status){
                                                return (
                                                    <MenuItem value={val.id} key={key}>{val.name}</MenuItem>
                                                )

                                            }
                                        })}
                                    </Select>
                                    {touched.room_id && errors.room_id && <div className='text-danger'>{errors.room_id}</div>}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Date</Typography>
                                    <TextField 
                                        variant='standard'
                                        placeholder='Type here'
                                        className="w-100"
                                        type="date"
                                        name="date"
                                        value={values.date}
                                        onChange={handleChange}
                                    />
                                    {touched.date && errors.date && <div className='text-danger'>{errors.date}</div>}
                                </Grid>
                                {/* <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Timings</Typography>
                                    <TextField 
                                        variant='standard'
                                        placeholder='Type here'
                                        className="w-100"
                                        type="time"
                                        name="timing"
                                        value={values.timing}
                                        onChange={handleChange}
                                    />
                                </Grid> */}
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className='fw-bold'>From</Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        {/* <DemoContainer components={['TimePicker', 'TimePicker']} sx={{border:'none'}}> */}
                                        <TimePicker
                                            variant='standard'
                                            className='w-100'
                                            name="from"
                                            inputFormat="hh:mm a"
                                            value={values.from}
                                            onChange={(e) => handleTimeChanged('from',e,setFieldValue)}
                                            sx={{
                                                '.MuiInputBase-root': {
                                                    borderBottom: '2px solid #adb5bd',
                                                    borderRadius: 0,
                                                    marginTop:'-9px'
                                                },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    border: 'none'
                                                }
                                            }}
                                            slotProps={{ textField: { placeholder: 'HH:MM AM/PM' } }}
                                        />
                                       {/* </DemoContainer> */}
                                    </LocalizationProvider>
                                    {touched.from && errors.from && <div className='text-danger'>{errors.from}</div>}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className='fw-bold'>To</Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        {/* <DemoContainer components={['TimePicker', 'TimePicker']} sx={{border:'none'}}> */}
                                        <TimePicker
                                            variant='standard'
                                            className='w-100'
                                            name="to"
                                            sx={{
                                                '.MuiInputBase-root': {
                                                    borderBottom: '2px solid #adb5bd',
                                                    borderRadius: 0,
                                                    marginTop:'-9px'
                                                },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    border: 'none'
                                                }
                                            }}
                                            value={values.to}
                                            onChange={(e) => handleTimeChanged("to",e,setFieldValue)}
                                            slotProps={{ textField: { placeholder: 'HH:MM AM/PM' } }}
                                        />
                                        {/* </DemoContainer> */}
                                    </LocalizationProvider>
                                    {touched.to && errors.to && <div className='text-danger'>{errors.to}</div>}
                                </Grid>
                                {values.purpose_of_visit === 'Official' && <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Host Name</Typography>
                                    <Select displayEmpty className="w-100 mt-2"  name="tenant_id" variant='standard' value={values.tenant_id} onChange={handleChange}>
                                        <MenuItem disabled value="">
                                            <em>Choose</em>
                                        </MenuItem>
                                        {visitor.tenant.length !== 0 && visitor.tenant.map((val,key) => {
                                            return (
                                                <MenuItem value={val.id} key={key}>{val.firstName + ' '+ val.lastName}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </Grid>}
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Description</Typography>
                                    <TextField 
                                        variant='standard'
                                        placeholder='Type here'
                                        className="w-100"
                                        name="description"
                                        value={values.description}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                {values.purpose_of_visit === 'Official' && <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Additional Requirement</Typography>
                                    <FormGroup>
                                        {visitor.additionalReq.length !== 0 && visitor.additionalReq.map((val,key) => {
                                            if(val.status){
                                                return (
                                                    <FormControlLabel key={key} control={<Checkbox value={val.id} name={`data`} onChange={handleChange} defaultChecked={visitor.editData.visitorAdditionalRequirement ? visitor.editData.visitorAdditionalRequirement.some(requirement => requirement.additionalRequirement.id === val.id) : false}/>} label={val.name}/>
                                                )
                                            }
                                        })}
                                    </FormGroup>
                                </Grid>}
                                {/* <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <img style={{height:'100px',width:'230px'}} className='mb-2' src={signUrl === " "?fileUpload:signUrl} alt="Signature not found" />
                                    <Box>
                                        <button className='btn btn-secondary' onClick={clearSign}>Clear</button>
                                        <button className="btn btn-primary ms-2" onClick={() => setModalOpen(true)}>Add Signature</button>
                                    </Box>
                                </Grid> */}
                            </Grid>
                        </Box>
                        <Box className="mt-4">
                            <button type="button" className='btn btn-white' onClick={props.onBack}><ArrowBackIosIcon style={{fontSize:'18px'}}/>Back</button>
                            <button type="submit" className='btn btn-danger ms-2'>Submit</button>
                        </Box>
                    </Form>
                )}
            </Formik>
        {/* Signature Pad */}
        {/* <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box className="header border-bottom p-3">
                    <h4>Add Signature</h4>
                </Box>
                <SignatureCanvas 
                    canvasProps={{width: 750, height:350, className:'sigCanvas'}}
                    ref={data=>setSign(data)}
                />
                <Box className="footer border-top">
                    <Box className="d-flex justify-content-end align-items-center mt-3">
                        <button className='btn btn-secondary' onClick={() => sign.clear()}>Clear</button>
                        <button className='btn btn-primary ms-2 me-2' onClick={addSign}>Save</button>
                    </Box>
                </Box>
            </Box>
        </Modal> */}
        </>
    );
};

export default AddVisitor;

import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

const TicketCard = (props: any) => {
  return (
    <>
      <Box className="ticket-card ps-4 py-4 bg-white">
        <Box className="d-flex">
          <img src={props.image} />
          <Box className="d-flex flex-column">
            <Typography>{props.title}</Typography>
            <Typography variant="h3" sx={{ color: props.color }}>
              {props.amount}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TicketCard;

import React, { useCallback, useState, createContext } from 'react';
import { AuthorizedLayout } from '../Layouts/AuthorizedLayout';
import { AntTab, AntTabs } from "../../Components/Tab/AntTab";
import { Box } from '@mui/material';
import CustomTabPanel from '../../Components/Tab/CustomTabPanel';
import TrainingCertificates from './TrainingCertificates';
import AddNewTraining from './AddNewTraining';
import AddNewTarget from './AddNewTarget';
import TrainingDashboard from './TrainingDashboard';

export const TrainingContext = createContext();

const TrainingManagement = () => {
    const [tabValue, setTabValue] = useState(0);
    const [trainingData, setTrainingData] = useState([
        {
            id:1,
            training_certificate:'Tipper Truck Operator',
            employee:'Benetic',
            cost:'0.00',
            conducted:'01-01-2024',
            expiring:'01-01-2025',
            expires:'active'
        }
    ]);
    const [add, setAdd] = useState({addNewTraining:false,addNewTarget:false})

    const handleTabChange = useCallback((e,val) => {
        setTabValue(val);
        setAdd({addNewTraining:false,addNewTarget:false})
    },[tabValue]);

    const openAddNewTraining = useCallback(() => {
        setAdd({...add,...{addNewTraining:true}})
    },[add]);
    
    const closeAddNewTraining = useCallback(() => {
        setAdd({...add,...{addNewTraining:false}})
    },[add]);

    const openAddNewTarget = useCallback(() => {
        setAdd({...add,...{addNewTarget:true}})
    },[add]);

    const closeAddNewTarget = useCallback(() => {
        setAdd({...add,...{addNewTarget:false}})
    },[add])

    return (
        <>
            <AuthorizedLayout>
                <TrainingContext.Provider
                    value={{
                        trainingData:trainingData,
                        closeAddNewTraining:closeAddNewTraining,
                        closeAddNewTarget:closeAddNewTarget
                    }}
                >
                    <Box className="d-flex justify-content-between align-items-center flex-wrap">
                        <Box className="d-flex align-items-center flex-wrap">
                            <span className='fw-bold'>Training Management</span>
                            <AntTabs value={tabValue} onChange={handleTabChange}>
                                <AntTab label="Dashboard" className='tab-menu' />
                                <AntTab label="Training/Certificated" className='tab-menu' />
                            </AntTabs>
                        </Box>
                        {!add.addNewTarget && !add.addNewTraining && tabValue === 1 && <Box className="d-flex" sx={{gap:'10px'}}>
                            <button className='btn btn-outline-danger' onClick={openAddNewTraining}>Add New Training</button>
                            <button className='btn btn-danger' onClick={openAddNewTarget}>Add New Target</button>
                        </Box>}
                    </Box>
                    <CustomTabPanel value={tabValue} index={0}>
                        <TrainingDashboard />
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={1}>
                        { add.addNewTraining ? <AddNewTraining /> : add.addNewTarget ? <AddNewTarget /> : <TrainingCertificates /> }
                    </CustomTabPanel>
                </TrainingContext.Provider>
            </AuthorizedLayout>
        </>
    )
}

export default TrainingManagement
import React, { useState, createContext, useCallback, useEffect } from 'react';
import { AuthorizedLayout } from '../Layouts/AuthorizedLayout';
import { Card, Box, Typography } from '@mui/material';
import { AntTab, AntTabs } from "../../Components/Tab/AntTab";
import CustomTabPanel from '../../Components/Tab/CustomTabPanel';
import MaintenanceCalendar from './MaintenanceCalendar';
import AddMaintenanceRequest from './AddMaintenanceRequest';
import MaintenanceRequestDetails from './MaintenanceRequestDetails';
import TenantSelected from "../../Assets/images/menu-icons/location_away_selected.png";
import AddIcon from "../../Assets/images/add_plus_icon.svg";
import { PostApiMethod } from '../../Utils/ApiService';
import { useAppDispatch } from '../../Store/hooks';
import { showLoader } from '../../Store/loaderSlice';
import { showToaster } from '../../Store/toastSlice';
import { convertTo24HourFormat, currentDate } from '../../Components/CommonFormik';
import MaintenanceRequestList from './MaintenanceRequestList';
import MaintenanceMasters from './MaintenanceMasters';
import BoxHeader from '../../Components/Box/BoxHeader';

export const MaintenanceContext = createContext();

const Maintenance = () => {
  const [tabValue, setTabValue] = useState(0);
  const [isAdd, setIsAdd] = useState(false);
  const [isView, setIsView] = useState(true);
  const [list, setList] = useState({
    maintenanceList:[],
    jobList:[],
    areaList:[],
    weekList:[],
  });
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const dispatch = useAppDispatch();

  useEffect(() => {
    getAllMaintenance();
    getAllJob();
    getAllArea();
  },[])

  const handleTabChange = useCallback((e, val) => {
    setTabValue(val);
    setIsView(false);
    setIsAdd(false);
  },[tabValue])

  const onView = useCallback(() => {
    setIsAdd(true);
    setIsView(true);
    setTabValue(1);
  },[isView])

  const onCloseView = useCallback(() => {
    setIsAdd(false);
    setIsView(false);
    setTabValue(0);
  },[isView]);

  const onAddMaintenance = useCallback(() => {
    setIsAdd(true);
    setIsView(false);
    setTabValue(0);
    setIsEdit(false);
  },[isAdd]);

  const onCloseMaintenance = useCallback(() => {
    setIsAdd(false);
    setIsView(true);
  },[isAdd]);

  const getAllMaintenance = useCallback(async() => {
    dispatch(showLoader({showLoader:true}))
    try{
      let res = await PostApiMethod("maintenance/list",{});
      if(res["data"]["error"] === false){
        getWeekList(currentDate());
        let data = res["data"]["results"]["data"];
        setList((pre) => ({...pre,maintenanceList:data}));
      }
    }catch(error){
      dispatch(showToaster({
        dialogBgColor: "bg-danger",
        dialogMsg: error.response ? error.response.data.message : "Failed to get maintenance details",
        showDialog: true,
        timer: "3000"
      }))
    }
    dispatch(showLoader({showLoader:false}))
  },[list]);

  const getWeekList = useCallback(async(date) => {
    dispatch(showLoader({showLoader:true}));
    try{
      let res = await PostApiMethod("maintenance/weekList",{date:date});
      if(res["data"]["error"] === false){
        let data = res["data"]["results"]["data"];
        let result = data.map((val,key) => {
          let timings = val.timings.split("-");
          return {
            id: key+1,
            text:val.areaCount > 1 ? val.areas.split(",")[0] + " +"+(val.areaCount - 1)+" more..." : val.areas,
            start:val.date.split("T")[0]+"T"+convertTo24HourFormat(timings[0])+":00",
            end:val.date.split("T")[0]+"T"+convertTo24HourFormat(timings[1])+":00",
            areas:val.areas
          }
        });
        setList((prev) => ({...prev,weekList:result}));
      }
    }catch(error){
      dispatch(showToaster({
        dialogBgColor: "bg-danger",
        dialogMsg: error.response ? error.response.data.message : "Failed to get week list details",
        showDialog: true,
        timer: "3000"
      }))
    }
    dispatch(showLoader({showLoader:false}));
  },[])

  const getAllJob = useCallback(async() => {
    dispatch(showLoader({showLoader:true}))
    try{
      let res = await PostApiMethod("maintenance/job-type/list",{});
      if(res["data"]["error"] === false){
        setList((pre) => ({...pre,jobList:res["data"]["results"]["data"]}))
      }
    }catch(error){
      dispatch(showToaster({
        dialogBgColor: "bg-danger",
        dialogMsg: error.response ? error.response.data.message : "Failed to get job details",
        showDialog: true,
        timer: "3000"
      }))
    }
    dispatch(showLoader({showLoader:false}))
  },[list]);

  const getAllArea = useCallback(async() => {
    dispatch(showLoader({showLoader:true}))
    try{
      let res = await PostApiMethod("maintenance/area-type/list",{});
      if(res["data"]["error"] === false){
        setList((pre) => ({...pre,areaList:res["data"]["results"]["data"]}))
      }
    }catch(error){
      dispatch(showToaster({
        dialogBgColor: "bg-danger",
        dialogMsg: error.response ? error.response.data.message : "Failed to get job details",
        showDialog: true,
        timer: "3000"
      }))
    }
    dispatch(showLoader({showLoader:false}))
  },[list]);

  const handleEdit = useCallback((val) => {
    setTabValue(0);
    setIsEdit(true);
    setEditData(val);
    setIsAdd(true);
  },[isEdit])

  return (
    <>
      <AuthorizedLayout>
        <MaintenanceContext.Provider value={{
          onView:onView,
          onCloseView:onCloseView,
          onCloseMaintenance:onCloseMaintenance,
          jobList:list.jobList,
          areaList:list.areaList,
          maintenanceList:list.maintenanceList,
          weekList:list.weekList,
          getAllMaintenance:getAllMaintenance,
          getAllJob:getAllJob,
          getAllArea:getAllArea,
          getWeekList:getWeekList,
          isEdit:isEdit,
          editData:editData,
          handleEdit:handleEdit
        }}>
          <Card elevation={0}>
            {/* <Box className='d-flex justify-content-between align-items-center flex-wrap'>
              <Box className="d-flex align-items-center flex-wrap">
                <img src={TenantSelected} alt="Firebridge" className="page-header-icon " />
                <Typography className='fw-bold fs-5 ms-1'>Maintenance</Typography>
                <AntTabs value={tabValue} onChange={handleTabChange}>
                  <AntTab label="Maintenance Calendar" className='tab-menu' />
                  <AntTab label="Maintenance Request" className='tab-menu' />
                  <AntTab label="Masters" className='tab-menu' />
                </AntTabs>
              </Box>
              {tabValue !== 2 && !isAdd && <button className='btn btn-danger' onClick={onAddMaintenance}>
                <img src={AddIcon} width="21" height="21" />
                Add Maintenance Request
              </button>}
            </Box> */}
            <BoxHeader
              dashboard_name="Maintenance"
              image={TenantSelected}
              button_name="Add Maintenance Request"
              button_show={tabValue !== 2 && !isAdd && true}
              onAdd={tabValue !== 2 && !isAdd && onAddMaintenance}
            >
              <AntTabs value={tabValue} onChange={handleTabChange}>
                <AntTab label="Maintenance Calendar" className='tab-menu' />
                <AntTab label="Maintenance Request" className='tab-menu' />
                <AntTab label="Jobs & Areas" className='tab-menu' />
              </AntTabs>
            </BoxHeader>
            <CustomTabPanel value={tabValue} index={0}>
              { isAdd ? <AddMaintenanceRequest /> : <MaintenanceCalendar /> }
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              { !isView ? <MaintenanceRequestList /> : <MaintenanceRequestDetails /> }
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={2}>
              <MaintenanceMasters />
            </CustomTabPanel>
          </Card>
        </MaintenanceContext.Provider>
      </AuthorizedLayout>
    </>
  )
}

export default Maintenance
import React from "react";
import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL;
// const baseUrl = "https://assetmanagementapi.firebridge.co.za/";
// headers: { [index: string]: { [index: string]: string } } = {
//     headers: { "Content-Type": "multipart/form-data" },
//   }

export const GetApiMethod1 = (
  routeName: string,
  headers: { [index: string]: { [index: string]: string } } = { headers: {} }
) => {
  return axios.get(`${baseUrl}/${routeName}`, headers);
};

export const PostApiMethod1 = (
  routeName: string,
  body: object,

  headers: { [index: string]: { [index: string]: string } } = {
    headers: { "Content-Type": "multipart/form-data" },
  }
) => {
  return axios.post(`${baseUrl}/${routeName}`, body, headers);
};

export const PutApiMethod1 = (
  routeName: string,
  body: object,
  headers: { [index: string]: { [index: string]: string } } = {
    headers: { "Content-Type": "multipart/form-data" },
  }
) => {
  console.log("put: url:" + routeName + "body: " + JSON.stringify(body));
  return axios.put(`${baseUrl}/${routeName}`, body, headers);
};

export const DeleteApiMethod1 = (
  routeName: string,
  body: object,
  headers: { [index: string]: { [index: string]: string } } = {
    headers: { "Content-Type": "multipart/form-data" },
  }
) => {
  return axios.delete(`${baseUrl}/${routeName}`, body);
};

import React, { useState, useContext } from 'react';
import { Box, Typography, Grid, TextField } from '@mui/material';
import CustomTabPanel from '../../Components/Tab/CustomTabPanel';
import { Tabs, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Table, TableBody, TableRow, TableCell, TableContainer } from '@mui/material';
import Paper from '@mui/material/Paper';
import Profile from "../../Assets/images/profile.png";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MaintenanceContext } from './Maintenance';

const AntTabs = styled(Tabs)({
    '& .MuiTabs-flexContainer':{
        marginLeft:'0px !important'
    },
    "& .MuiTabs-indicator": {
        backgroundColor: "#FF7D73",
    },
});

const RequestDetails = () => {
    return (
        <>
            <Typography className='my-2 fw-bold'>Request Details</Typography>
            <TableContainer component={Paper}>
                <Table className='table table-striped'>
                    <TableBody>
                        <TableRow>
                            <TableCell className='fw-bold p-3'>Maintenance Type</TableCell>
                            <TableCell className='p-3'>Corrective</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='fw-bold p-3'>Job Type</TableCell>
                            <TableCell className='p-3'>Cleaning</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='fw-bold p-3'>Area</TableCell>
                            <TableCell className='p-3'>Meeting Room</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='fw-bold p-3'>Issue</TableCell>
                            <TableCell className='p-3'>Meeting Room to be cleaned</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='fw-bold p-3'>Date</TableCell>
                            <TableCell className='p-3'>01-01-2024</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='fw-bold p-3'>Timings</TableCell>
                            <TableCell className='p-3'>10:00 AM</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='fw-bold p-3'>Status</TableCell>
                            <TableCell className='p-3 text-success'>New Request</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='fw-bold p-3'>Priority</TableCell>
                            <TableCell className='p-3'>High</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='fw-bold p-3'>No of People</TableCell>
                            <TableCell className='p-3'>1</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='fw-bold p-3'>Assigned To</TableCell>
                            <TableCell className='p-3'>Richard Nickson</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

const RequestHistory = () => {
    return(
        <>
            <Box sx={{background:'#F9F9F9',borderRadius:'10px'}} className="p-3 mt-4">
                <Typography className='fw-bold'>Message</Typography>
                <Grid container spacing={4}>
                    <Grid item sm={12} xs={12} md={12} lg={12}>
                        <TextField
                            variant='standard'
                            placeholder='Type here...'
                            className='w-100'
                        />
                    </Grid>
                    <Grid item md={12} >
                        <Box >{/**sx={{borderBottom:'1px solid #b7b2b2'}} */}
                            <Box sx={{display:'grid',gridTemplateColumns:'50px auto'}}>
                                <Box sx={{gridRow:'span 2'}}>
                                    <img src={Profile} alt="profile" width={40} height={40} />
                                </Box>
                                <Box className="d-flex">
                                    <Typography>Richard David</Typography>
                                    <Typography sx={{color:'gray'}} className='ms-1'>Admin - 01 Jan 2024</Typography>
                                </Box>
                                <Box className="mb-2 d-flex">
                                    <Typography className='text-primary'>Richard Nickson</Typography> 
                                    <Typography>has started working on the request</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

const MaintenanceRequestDetails = () => {
    const [tabValue, setTabValue] = useState(0);
    const maintenance = useContext(MaintenanceContext);

    const handleTabChange = (e,val) => {
        setTabValue(val)
    }

    return (
        <>
            <Typography className='fw-bold fs-5'>
                <ArrowBackIcon sx={{cursor:'pointer'}} onClick={maintenance.onCloseView}/>
                Maintenance Request Details
            </Typography>
            <AntTabs value={tabValue} onChange={handleTabChange}>
                <Tab label="Request Details" className='tab-menu'/>
                <Tab label="Request History" className='tab-menu'/>
            </AntTabs>
            <Box>
                <CustomTabPanel value={tabValue} index={0}>
                    <RequestDetails />
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}>
                    <RequestHistory />
                </CustomTabPanel>
            </Box>
        </>
    )
}

export default MaintenanceRequestDetails
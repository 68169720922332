import React from "react";
import Card from "@mui/material/Card";
import { Box, Typography } from "@mui/material";

const TopFive = () => {
  return (
    <>
      <Card className="h-100 custom-card pb-3">
        <Box className="dashboard-card flex_row_between">
          <Typography variant="body1">Payments</Typography>
          <button
            style={{
              background: "white",
              border: "1px solid #FF7D73",
              color: "#FF7d73",
              padding: "5px 15px 5px 15px",
              borderRadius: "25px",
              fontSize: "11px",
              fontWeight: "bold",
            }}
          >
            View all
          </button>
        </Box>
        <Box className="dashboard-card">
          <Typography variant="subtitle1">Recent Transactions</Typography>
        </Box>
        <Box className="card-list">
          <Box className="flex_row_between">
            <Typography variant="caption" className="category">
              Maintenance | B-123
            </Typography>
            <Typography variant="caption" className="card-date">
              29,Oct 2024
            </Typography>
          </Box>
          <Box className="flex_row_between">
            <Typography variant="body1">Jessica Wilson</Typography>
            <Typography variant="body1" className="color-green">
              +R150
            </Typography>
          </Box>
        </Box>

        <Box className="card-list">
          <Box className="flex_row_between">
            <Typography variant="caption" className="category">
              Maintenance | B-123
            </Typography>
            <Typography variant="caption" className="card-date">
              29,Oct 2024
            </Typography>
          </Box>
          <Box className="flex_row_between">
            <Typography variant="body1">Samuel Jackson</Typography>
            <Typography variant="body1" className="color-green">
              +R150
            </Typography>
          </Box>
        </Box>

        <Box className="card-list">
          <Box className="flex_row_between">
            <Typography variant="caption" className="category">
              Auditing | Consultant
            </Typography>
            <Typography variant="caption" className="card-date">
              29,Oct 2024
            </Typography>
          </Box>
          <Box className="flex_row_between">
            <Typography variant="body1">Angela Robert</Typography>
            <Typography variant="body1" className="color-green">
              -R100
            </Typography>
          </Box>
        </Box>

        <Box className="card-list">
          <Box className="flex_row_between">
            <Typography variant="caption" className="category">
              Maintenance | B-123
            </Typography>
            <Typography variant="caption" className="card-date">
              29,Oct 2024
            </Typography>
          </Box>
          <Box className="flex_row_between">
            <Typography variant="body1">John Richard</Typography>
            <Typography variant="body1" className="color-green">
              +R150
            </Typography>
          </Box>
        </Box>

        <Box className="card-list">
          <Box className="flex_row_between">
            <Typography variant="caption" className="category">
              Electricals | Office Room
            </Typography>
            <Typography variant="caption" className="card-date">
              29,Oct 2024
            </Typography>
          </Box>
          <Box className="flex_row_between">
            <Typography variant="body1">Samuel Jackson</Typography>
            <Typography variant="body1" className="color-green">
              -R70
            </Typography>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default TopFive;

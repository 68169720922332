import React, { useState, useCallback, createContext, useEffect } from 'react';
import { AuthorizedLayout } from "../Layouts/AuthorizedLayout";
import { Box, Card, Typography } from '@mui/material';
import Car from "../../Assets/images/car.png";
import { AntTab, AntTabs } from "../../Components/Tab/AntTab";
import CustomTabPanel from "../../Components/Tab/CustomTabPanel";
import ParkingList from './ParkingList';
import AddIcon from '@mui/icons-material/Add';
import AddParking from './AddParking';
import { useAppDispatch } from '../../Store/hooks';
import { showLoader } from '../../Store/loaderSlice';
import { showToaster } from "../../Store/toastSlice";
import { DeleteApiMethod, GetApiMethod, PostApiMethod } from '../../Utils/ApiService';
import ViewParking from './ViewParking';
import Department from '../Masters/Department';
import BoxHeader from '../../Components/Box/BoxHeader';

export const ParkingContext = createContext();

const Parking = () => {
    const [ tabValue, setTabValue ] = useState(0);
    const [ page, setPage ] = useState({page:1,limit:5,count:5});
    const [ parkingList, setParkingList ] = useState([]);
    const [ addParking, setAddParking ] = useState(false);
    const [ unit, setUnit ] = useState([]);
    const [ tenant, setTenant ] = useState([]);
    const [ editData, setEditData ] = useState({}); 
    const [ viewParking, setViewParking ] = useState(false);
    const [ viewParkingData, setViewParkingData ] = useState({});
    const [ search, setSearch ] = useState("");
    const [ parkingType, setParkingType] = useState("");
    const [ department, setDepartment ] = useState([]);
    const dispatch = useAppDispatch();

    useEffect(() => {
        getUnit();
        getTenant();
        getDepartment();
        getParking(page);
    },[])

    const getParking = async(params) => {
        var parameters = params.limit === -1 ? {} : {page:params.page,limit:params.limit};
        try{
            let response = await PostApiMethod("property/parking/list",parameters);
            if(response["data"]["error"] === false){
                setParkingList(response["data"]["results"]["data"])
                setPage({page:params.page,limit:params.limit,count: response["data"]["results"]["totalCount"]})
            }
        }catch(error){
            dispatch(showToaster({dialogBgColor: "bg-danger",
            dialogMsg: "Parking Details fetching failed",
            showDialog: true,
            timer: "3000",}))
        }
        dispatch(showLoader({ showLoader: false }))
    }

    const getUnit = async() => {
        dispatch(showLoader({ showLoader: true }))
        try{
            let response = await PostApiMethod("property/unit/list",{});
            if(response["data"]["error"] === false){
                setUnit(response["data"]["results"]["data"])
            }
        }catch(error){
            dispatch(showToaster({dialogBgColor: "bg-danger",
            dialogMsg: "Unit Details fetching failed",
            showDialog: true,
            timer: "3000",}))
        }
    }

    const handleDelete = async(id) => {
        dispatch(showLoader({showLoader:true}))
        try{
            let res = await DeleteApiMethod("property/parking/"+id)
            dispatch(showToaster({dialogBgColor: "bg-success",
            dialogMsg: "Parking details deleted",
            showDialog: true,
            timer: "3000",}))
            getParking({page:1,limit:5,count:5})
        }catch(error){
            dispatch(showToaster({dialogBgColor: "bg-danger",
            dialogMsg: "Failed parking details deleted",
            showDialog: true,
            timer: "3000",}))
        }
        dispatch(showLoader({showLoader:false}))
    }


    const handleChange = useCallback((e,newValue) => {
        setTabValue(newValue)
        setAddParking(false);
        setViewParking(false);
    },[tabValue])

    const openParking = useCallback(() => {
        setAddParking(true);
        setTabValue(1);
        setEditData({});
    },[addParking])

    const onBack = useCallback(() => {
        setAddParking(false);
        setTabValue(1);
        setParkingType('');
    },[addParking])

    const handleNextPage = useCallback((val) => {
        dispatch(showLoader({ showLoader: true }));
        setPage({...page,page:val.page,limit:val.limit})
        getParking(val)
    },[page])

    const getEditData = useCallback(async(val) => {
        setEditData(val);
        setAddParking(true);
        // try{
        //     let response = await GetApiMethod("property/parking/"+id,{});
        //     if(response["data"]["error"] === false){
        //         setEditData(response["data"]["results"]["data"])
        //         setAddParking(true)
        //     }
        // }catch(error){
        //     dispatch(showToaster({dialogBgColor: "bg-danger",
        //     dialogMsg: "Get parking details failed",
        //     showDialog: true,
        //     timer: "3000",}))
        // }
    },[editData])

    const searchString = async(val) => {
        dispatch(showLoader({showLoader:true}))
        setSearch(val.string);
        setParkingType(val.type);
        try{
            let res = await PostApiMethod("property/parking/list",{...page,searchString:val.string,parkingType:val.type})
            if(res["data"]["error"] === false){
                setParkingList(res["data"]["results"]["data"])
                setPage((prevPage) => ({...prevPage,count: res["data"]["results"]["totalCount"],}))
            }
        }catch(error){
            dispatch(showToaster({dialogBgColor: "bg-danger",
            dialogMsg: "Get parking details failed",
            showDialog: true,
            timer: "3000",}))
        }
        dispatch(showLoader({showLoader:false}))
    }

    const enableViewParking = useCallback(async(id)=> {
        dispatch(showLoader({showLoader:true}))
        try{
            let res = await GetApiMethod("property/parking/"+id,{})
            if(res["data"]["error"] === false){
                setViewParkingData(res['data']["results"]["data"])
                setViewParking(true)
            }
        }catch(error){
            dispatch(showToaster({dialogBgColor: "bg-danger",
            dialogMsg: "Get parking details failed",
            showDialog: true,
            timer: "3000",}))
        }
        dispatch(showLoader({showLoader:false}))
        
    },[viewParking])

    const onBackList = useCallback(() => {
        setViewParking(false)
    },[viewParking])

    const getTenant = useCallback(async() => {
        let response = await PostApiMethod("user/getUser",{"role":"PMU"});
        if(response["data"]["error"] === false){
            setTenant(response["data"]["results"]["data"])
        }
    },[setTenant])

    const getDepartment = async() => {
        try{
            let res = await PostApiMethod("property/department/list",{});
            if(res["data"]["error"] === false){
                setDepartment(res["data"]["results"]["data"])
            }
        }catch(error){
            dispatch(showToaster({dialogBgColor: "bg-danger",
                dialogMsg: "Fetch department details failed",
                showDialog: true,
                timer: "3000",}))
        }
    }

    return (
        <>
            <AuthorizedLayout>
                <ParkingContext.Provider value={{
                    page:page,
                    parkingList:parkingList,
                    onBack:onBack,
                    unit:unit,
                    handleNextPage:handleNextPage,
                    getParking:getParking,
                    getEditData:getEditData,
                    editData:editData,
                    handleDelete:handleDelete,
                    searchString:searchString,
                    enableViewParking:enableViewParking,
                    viewParkingData:viewParkingData,
                    onBackList:onBackList,
                    tenant:tenant,
                    search:search,
                    parkingType:parkingType,
                    department:department,
                    getDepartment:getDepartment
                }}>
                    <Card elevation={0}>
                        <BoxHeader
                            dashboard_name="Parking"
                            image={Car}
                            button_name={![0,2].includes(tabValue) && !addParking && "Add Parking"}
                            button_show={![0,2].includes(tabValue) && !addParking && true}
                            onAdd={![0,2].includes(tabValue) && !addParking && openParking}
                        >
                            <AntTabs value={tabValue} onChange={handleChange}>
                                <AntTab label="Parking Space" className="tab-menu"/>
                                <AntTab label="Parking List" className="tab-menu"/>
                                <AntTab label="Department" className='tab-menu' />
                            </AntTabs>
                        </BoxHeader>
                        <CustomTabPanel value={tabValue} index={0}>
                            <Typography>Parking Space</Typography>
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={1}>
                            {viewParking ? <ViewParking /> :
                            !addParking ? <ParkingList/> : <AddParking/>}
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={2}>
                            <Department />
                        </CustomTabPanel>
                    </Card>
                </ParkingContext.Provider>
            </AuthorizedLayout>

        </>
    )
}

export default Parking
import React, { useContext } from 'react';
import { Box, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Grid } from '@mui/material';
import { Select, MenuItem, TextField } from '@mui/material';
import { Formik, Form } from 'formik';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { MaintenanceContext } from './Maintenance';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { MaintenanceSchema } from './MaintenanceSchema';
import { PostApiMethod, PutApiMethod } from '../../Utils/ApiService';
import { useAppDispatch } from '../../Store/hooks';
import { showLoader } from '../../Store/loaderSlice';
import { showToaster } from '../../Store/toastSlice';
import dayjs from 'dayjs';

const AddMaintenanceRequest = () => {
    const maintenance = useContext(MaintenanceContext);
    const dispatch = useAppDispatch();
    
    const addMaintenance = async(values) => {
        dispatch(showLoader({showLoader:true}))
        try{
            let res = await PostApiMethod("maintenance/add",values);
            if(res["data"]["error"] === false){
                dispatch(showToaster({
                    dialogBgColor: "bg-success",
                    dialogMsg: res["data"]["message"],
                    showDialog: true,
                    timer: "3000"
                }));
                maintenance.getAllMaintenance();
                maintenance.onCloseMaintenance();
            }
        }catch(error){
            dispatch(showToaster({
                dialogBgColor: "bg-danger",
                dialogMsg: error.response ? error.response.data.message : "Failed to create maintenance",
                showDialog: true,
                timer: "3000"
            }))
        }
        dispatch(showLoader({showLoader:false}))
    }

    const handleChangeType = (e,setFieldValue) => {
        setFieldValue('recurring_type','');
        setFieldValue('maintenance_reminder','');
    }

    const handleTimeChanged = (name, value, setFieldValue) => {
        setFieldValue(name, value);
    };

    const updateMaintenance = async(val) => {
        dispatch(showLoader({showLoader:true}))
        try{
            let res = await PutApiMethod(`maintenance/update/${maintenance.editData.id}`,val);
            if(res["data"]["error"] === false){
                dispatch(showToaster({
                    dialogBgColor: "bg-success",
                    dialogMsg: res["data"]["message"],
                    showDialog: true,
                    timer: "3000"
                }));
                maintenance.getAllMaintenance();
                maintenance.onCloseMaintenance();
            }
        }catch(error){
            dispatch(showToaster({
                dialogBgColor: "bg-danger",
                dialogMsg: error.response ? error.response.data.message : "Failed to update maintenance",
                showDialog: true,
                timer: "3000"
            }));
        }
        dispatch(showLoader({showLoader:false}))
    }

    return (
        <>
            <Box className="my-4">
                <Typography className='fw-bold fs-5'>{maintenance.isEdit ? "Edit" : "Add New"} Maintenance Request</Typography>
            </Box>
            <Box>
                <Formik
                    initialValues={{
                        type:maintenance.isEdit ? maintenance.editData.type : 'Corrective',
                        job_type_id:maintenance.isEdit ? maintenance.editData.job_type_id : '',
                        area_type_id:maintenance.isEdit ? maintenance.editData.area_type_id : '',
                        issue:maintenance.isEdit ? maintenance.editData.issue : '',
                        date:maintenance.isEdit ? maintenance.editData.date.split("T")[0] : '',
                        from:maintenance.isEdit ? dayjs(maintenance.editData.timings.split("-")[0], 'hh:mm A') : null,
                        to:maintenance.isEdit ? dayjs(maintenance.editData.timings.split("-")[1], 'hh:mm A') : null,
                        status:maintenance.isEdit ? maintenance.editData.status : '',
                        priority:maintenance.isEdit ? maintenance.editData.priority : '',
                        no_of_people:maintenance.isEdit ? maintenance.editData.no_of_people : '',
                        assign_to:maintenance.isEdit ? maintenance.editData.assign_to : '',
                        recurring_type:maintenance.isEdit ? maintenance.editData.recurring_type : '',
                        maintenance_reminder:maintenance.isEdit ? maintenance.editData.maintenance_reminder : ''
                    }}
                    validationSchema={MaintenanceSchema}
                    onSubmit={async(values) => {
                        let res = {}
                        Object.keys(values).forEach((val,key) => {
                            if(values[val] !== ""){
                                if(val === "from" || val === "to"){
                                    if(val === "from"){
                                        res['timings'] = dayjs(values["from"]).format('hh:mm:A');
                                    }else{
                                        res['timings'] = res['timings']+'-'+dayjs(values["to"]).format('hh:mm:A');
                                    }
                                }else{
                                    res[val] = values[val]
                                }
                            }
                        });
                        if(maintenance.isEdit){
                            updateMaintenance(res)
                        }else{
                            addMaintenance(res)
                        }
                    }}
                >
                    {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }) => (
                        <Form onSubmit={handleSubmit}>
                            <FormControl>
                                <FormLabel className='fw-bold'>Maintenance Type</FormLabel>
                                <RadioGroup row defaultValue={values.type} onChange={(e) => handleChangeType(e,setFieldValue)}>
                                    <FormControlLabel value="Corrective" control={<Radio name="type" onChange={handleChange}/>} label="Corrective" />
                                    <FormControlLabel value="Preventive" control={<Radio name="type" onChange={handleChange}/>} label="Preventive" />
                                </RadioGroup>
                            </FormControl>
                            <Grid container spacing={4} className='my-1'>
                                <Grid item sm={12} xs={12} md={4} lg={4}>
                                    <FormLabel className='fw-bold'>Job Type</FormLabel>
                                    <Select displayEmpty className="w-100 mt-2" variant='standard' name="job_type_id" value={values.job_type_id} onChange={handleChange}>
                                        <MenuItem disabled value=""><em>Choose</em></MenuItem>
                                        {maintenance.jobList.length !== 0 && maintenance.jobList.map((val,key) => (
                                            <MenuItem key={key} value={val.id}>{val.job_type_name}</MenuItem>
                                        ))}
                                    </Select>
                                    {touched.job_type_id && errors.job_type_id && (<div className='text-danger'>{errors.job_type_id}</div>)}
                                </Grid>
                                <Grid item sm={12} xs={12} md={4} lg={4}>
                                    <FormLabel className='fw-bold'>Area</FormLabel>
                                    <Select displayEmpty className="w-100 mt-2" variant='standard' name="area_type_id" value={values.area_type_id} onChange={handleChange}>
                                        <MenuItem disabled value=""><em>Choose</em></MenuItem>
                                        {maintenance.areaList.length !== 0 && maintenance.areaList.map((val,key) => (
                                            <MenuItem key={key} value={val.id}>{val.area_name}</MenuItem>
                                        ))}
                                    </Select>
                                    {touched.area_type_id && errors.area_type_id && (<div className='text-danger'>{errors.area_type_id}</div>)}
                                </Grid>
                                <Grid item sm={12} xs={12} md={4} lg={4}>
                                    <FormLabel className='fw-bold'>Issue</FormLabel>
                                    <TextField 
                                        className='form-control'
                                        name='issue'
                                        value={values.issue}
                                        variant='standard'
                                        onChange={handleChange}
                                        placeholder='Type here...'
                                    />
                                    {touched.issue && errors.issue && (<div className='text-danger'>{errors.issue}</div>)}
                                </Grid>
                                {values.type === 'Preventive' && <Grid item sm={12} xs={12} md={4} lg={4}>
                                    <FormLabel className='fw-bold'>Recurring Type</FormLabel>
                                    <Select displayEmpty className="w-100 mt-2" variant='standard' name="recurring_type" value={values.recurring_type} onChange={handleChange}>
                                        <MenuItem disabled value=""><em>Choose</em></MenuItem>
                                    </Select>
                                    {touched.recurring_type && errors.recurring_type && (<div className='text-danger'>{errors.recurring_type}</div>)}
                                </Grid>}
                                <Grid item sm={12} xs={12} md={4} lg={4}>
                                    <FormLabel className='fw-bold'>Date</FormLabel>
                                    <TextField 
                                        type="date"
                                        className='form-control'
                                        name='date'
                                        value={values.date}
                                        variant='standard'
                                        onChange={handleChange}
                                    />
                                    {touched.date && errors.date && (<div className='text-danger'>{errors.date}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <FormLabel className='fw-bold'>From</FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            variant='standard'
                                            className='w-100 mt-2'
                                            name="from"
                                            inputFormat="hh:mm a"
                                            value={values.from}
                                            onChange={(e) => handleTimeChanged('from',e,setFieldValue)}
                                            sx={{
                                                '.MuiInputBase-root': {
                                                    borderBottom: '2px solid #adb5bd',
                                                    borderRadius: 0,
                                                    marginTop:'-9px'
                                                },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    border: 'none'
                                                }
                                            }}
                                            slotProps={{ textField: { placeholder: 'HH:MM AM/PM' } }}
                                        />
                                    </LocalizationProvider>
                                    {touched.from && errors.from && (<div className='text-danger'>{errors.from}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <FormLabel className='fw-bold'>To</FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            variant='standard'
                                            className='w-100 mt-2'
                                            name="to"
                                            inputFormat="hh:mm a"
                                            value={values.to}
                                            onChange={(e) => handleTimeChanged('to',e,setFieldValue)}
                                            sx={{
                                                '.MuiInputBase-root': {
                                                    borderBottom: '2px solid #adb5bd',
                                                    borderRadius: 0,
                                                    marginTop:'-9px'
                                                },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    border: 'none'
                                                }
                                            }}
                                            slotProps={{ textField: { placeholder: 'HH:MM AM/PM' } }}
                                        />
                                    </LocalizationProvider>
                                    {touched.to && errors.to && (<div className='text-danger'>{errors.to}</div>)}
                                </Grid>
                                <Grid item sm={12} xs={12} md={4} lg={4}>
                                    <FormLabel className='fw-bold'>Status</FormLabel>
                                    <Select displayEmpty className="w-100 mt-2" variant='standard' name="status" value={values.status} onChange={handleChange}>
                                        <MenuItem disabled value=""><em>Choose</em></MenuItem>
                                        <MenuItem value="New Request">New Request</MenuItem>
                                        <MenuItem value="In Progress">In Progress</MenuItem>
                                        <MenuItem value="Completed">Completed</MenuItem>
                                        <MenuItem value="Cancelled">Cancelled</MenuItem>
                                    </Select>
                                    {touched.status && errors.status && (<div className='text-danger'>{errors.status}</div>)}
                                </Grid>
                                <Grid item sm={12} xs={12} md={4} lg={4}>
                                    <FormLabel className='fw-bold'>Priority</FormLabel>
                                    <Select displayEmpty className="w-100 mt-2" variant='standard' name="priority" value={values.priority} onChange={handleChange}>
                                        <MenuItem disabled value=""><em>Choose</em></MenuItem>
                                        <MenuItem value="High">High</MenuItem>
                                        <MenuItem value="Medium">Medium</MenuItem>
                                        <MenuItem value="Low">Low</MenuItem>
                                    </Select>
                                    {touched.priority && errors.priority && (<div className='text-danger'>{errors.priority}</div>)}
                                </Grid>
                                <Grid item sm={12} xs={12} md={4} lg={4}>
                                    <FormLabel className='fw-bold'>No of People</FormLabel>
                                    <TextField 
                                        className='form-control'
                                        name='no_of_people'
                                        value={values.no_of_people}
                                        variant='standard'
                                        onChange={handleChange}
                                        placeholder='Type here...'
                                    />
                                    {touched.no_of_people && errors.no_of_people && (<div className='text-danger'>{errors.no_of_people}</div>)}
                                </Grid>
                                <Grid item sm={12} xs={12} md={4} lg={4}>
                                    <FormLabel className='fw-bold'>Assign To</FormLabel>
                                    <Select displayEmpty className="w-100 mt-2" variant='standard' name="assign_to" value={values.assign_to} onChange={handleChange}>
                                        <MenuItem disabled value=""><em>Choose</em></MenuItem>
                                        <MenuItem value="1">Super Admin</MenuItem>
                                    </Select>
                                    {touched.assign_to && errors.assign_to && (<div className='text-danger'>{errors.assign_to}</div>)}
                                </Grid>
                                {values.type === 'Preventive' && <Grid item sm={12} xs={12} md={4} lg={4}>
                                    <FormLabel className='fw-bold'>Maintenance Reminder(No of days before)</FormLabel>
                                    <TextField 
                                        type="number"
                                        className='form-control'
                                        name='maintenance_reminder'
                                        value={values.maintenance_reminder}
                                        variant='standard'
                                        onChange={handleChange}
                                        placeholder='Type here...'
                                    />
                                    {touched.maintenance_reminder && errors.maintenance_reminder && (<div className='text-danger'>{errors.maintenance_reminder}</div>)}
                                </Grid>}
                            </Grid>
                            <Box className="mt-4">
                                <button type="button" className='btn btn-white' onClick={maintenance.onCloseMaintenance}><ArrowBackIosIcon style={{fontSize:'18px'}}/>Back</button>
                                <button type="submit" className='btn btn-danger ms-2'>Submit</button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    )
}

export default AddMaintenanceRequest
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Box, Checkbox, FormControl, FormLabel, Grid, FormGroup, FormControlLabel, TextField, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Select, MenuItem } from '@mui/material';
import ButtonGroup from '../../Components/Button/ButtonGroup';
import { UserContext } from './UserManagement';
import { PostApiMethod } from '../../Utils/ApiService';
import { useAppDispatch } from '../../Store/hooks';
import { showToaster } from '../../Store/toastSlice';
import { showLoader } from '../../Store/loaderSlice';
import { getModules, getPermission, removePermission, savePermission } from './UserApi';
import { AuthorizedLayout } from '../Layouts/AuthorizedLayout';
import { useParams } from 'react-router-dom';
import Pagination from '../../Components/Pagination/Pagination';

const PermissionForm = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState({page:1,limit:5,count:0})
  const [permissionData, setPermissionData] = useState([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getPermissionData();
  },[]);

  let { id } = useParams();
  const getPermissionData = async() => {
    await getModules(dispatch,setData,page,setPage);
    await getPermission(dispatch,setPermissionData,{roleId:id});
  } 

  const handleChange = async(e) => {
    const { checked, value } = e.target;
    let data = JSON.parse(value);
    let { propertyId, ...others } = data;
    console.log(propertyId)
    if(checked){
      await savePermission(dispatch,others,permissionData,setPermissionData)
    }else{
      await removePermission(dispatch,{id:propertyId},permissionData,setPermissionData);
    }
  }

  return (
    <>
      <AuthorizedLayout>     
        <Box className="fs-5 fw-bold mt-3">Assign Permission</Box>
        {data?.map((val,key) => (
          <Grid container spacing={3} key={key} sx={{borderBottom:'1px solid gray',display:'flex',alignItems:'center',padding:'10px 0px',whiteSpace:'nowrap'}}>
            <Grid item sm={12} xs={12} md={2} lg={2}>
              {val?.name}
            </Grid>
            <Grid item sm={12} xs={12} md={10} lg={10}>
              <Grid container spacing={3}>
              {val?.SubModules?.map((v,k) => (
                <Grid item sm={12} xs={12} md={3} lg={3} key={k}>
                  <FormGroup>
                    <FormControlLabel 
                      control={
                        <Checkbox 
                          value={JSON.stringify({
                            submoduleId:v?.id,
                            moduleId:val.id,
                            roleId:id,
                            propertyId:permissionData?.filter((obj) => obj?.submoduleId === v?.id)?.[0]?.["id"]
                          })} 
                          onChange={handleChange}
                          checked={permissionData.some((obj) => obj?.submoduleId === v?.id)}
                        />
                      } 
                      label={v.name} 
                    />
                  </FormGroup>
                </Grid>
              ))}
              </Grid>
            </Grid>
          </Grid>
        ))}
        <Pagination colSpan={2} pagination={page} rowsPerPage={10} rowsPerPageChange={(val) => console.log(val) }/>
      </AuthorizedLayout>
    </>
  )
}

export default PermissionForm
import React,{ createContext,useState,ReactNode,useMemo } from 'react';

import { useAppDispatch,useAppSelector } from "../Store/hooks";
import { clearAuthSlice,fetchingUserDetails,setIsAuthenticated,setPermission,setUserDetails } from "../Store/authSlice";
import { isLogin } from "./authService";

import { showToaster } from "../Store/toastSlice";
import { GetApiMethod } from "../Utils/ApiService";

import { LoadingComponent } from '../Components/Loading';
import { DisplayErrorMessages } from '../Utils/HelperService';

interface AuthContextProps {
    isAuthenticated: boolean;
}

const CheckLoginUser = () => {
    const dispatch = useAppDispatch();
    let fetchingUserData = React.useRef<boolean>(false);

    const fetchUserDetails = React.useCallback(() => {
        dispatch(fetchingUserDetails(true));
        GetApiMethod('user/user')
            .then((res:any) => {
                //dispatch(fetchingUserDetails(false));
                //dispatch(setIsAuthenticated(true));
                //if (res && res.data && res.data.user) {
                //    dispatch(setUserDetails(res && res.data && res.data.user));
                //}
                dispatch(setIsAuthenticated(true));
                dispatch(setUserDetails(res.data.results.data.user));
                dispatch(setPermission(res?.data?.results?.data?.permission));
            })
            .catch((err: { response: { data: { message: any; }; }; }) => {
                const error = DisplayErrorMessages(err);
                dispatch(showToaster(
                    {
                        dialogBgColor: 'bg-danger',
                        dialogMsg: error ? error : 'Unauthorized',
                        showDialog: true,
                        timer: '5000'
                    }
                ));
            });
        setTimeout(() => {
            dispatch(fetchingUserDetails(false));
        },2000);

    },[dispatch]);

    React.useEffect(() => {
        if (isLogin()) {
            if (!fetchingUserData.current) {
                fetchingUserData.current = true;
                fetchUserDetails();
            }
        } else {
            dispatch(fetchingUserDetails(false));
            localStorage.clear();
            dispatch(clearAuthSlice());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    let authState = useAppSelector((state) => state.auth);
    const [isAuthenticated,setIsAuthenticated] = useState<boolean>(true);
    return (
        <AuthContext.Provider value={{ isAuthenticated }}>
            {authState.loading ? <LoadingComponent /> : children}
        </AuthContext.Provider>
    );
};



export default CheckLoginUser;

import * as Yup from 'yup';

export const MaintenanceSchema = Yup.object().shape({
    type:Yup.string().required("Maintenance type field is required"),
    job_type_id:Yup.string().required("Job type field is required"),
    area_type_id:Yup.string().required("Area field is required"),
    issue:Yup.string().required("Issue field is required"),
    date:Yup.string().required("Date field is required"),
    from:Yup.string().required("From field is required"),
    to:Yup.string().required("To field is required"),
    status:Yup.string().required("Status field is required"),
    priority:Yup.string().required("Priority field is required"),
    no_of_people:Yup.string().required("Number of people field is required"),
    assign_to:Yup.string().required("Assign to field is required"),
    recurring_type:Yup.string().when('type',{
        is:'Preventive',
        then:(schema) =>
            schema.required("Recurring type field is required"),
        otherwise:(schema) => 
            schema.notRequired(),
    }),
    maintenance_reminder:Yup.string().when('type',{
        is:'Preventive', 
        then:(schema) =>
            schema.required("Maintenance reminder field is required"),
        otherwise:(schema) => 
            schema.notRequired(),
    })
})
import React, { useContext, useState } from 'react';
import { Table, TableBody, TableHead, TableRow, TableCell, TableContainer } from '@mui/material';
import Paper from '@mui/material/Paper';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import { IconButton, Typography } from "@mui/material";
import Pagination from '../../Components/Pagination/Pagination';
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";
import { VisitorContext } from './Visitor';

const DataTable = (props) => {
  const visitor = useContext(VisitorContext);
  const [anchorEl, setAnchorEl] = useState(null)
  const [cOpen, setcOpen] = useState(0);
  const { data } = props;
  const handleClose = () => {
    setcOpen(0)
    setAnchorEl(null)
    
  }

  const handleClick = (id,event) => {
    setcOpen(cOpen === id ? 0 : id);
    setAnchorEl(event.currentTarget);
  }

  const textColor = {'Expired':'text-danger','Upcoming':'text-warning','Closed':'text-success','Cancelled':'text-danger'}
  const roundColor = {'Expired':'red','Upcoming':'#ffc107','Closed':'green','Cancelled':'red'}

  return (
    <>
      <TableContainer >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Visitor&nbsp;Name</TableCell>
              <TableCell>Purpose&nbsp;of&nbsp;visit</TableCell>
              <TableCell>Unit/Department</TableCell>
              <TableCell>Vehicle&nbsp;Details</TableCell>
              <TableCell>Tenant/Host</TableCell>
              <TableCell>Parking&nbsp;No</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              {data.length !==0 && data.map((val,key) => {
                return (<TableRow key={key}>
                <TableCell>{val.visitor_name}</TableCell>
                <TableCell>{val.purpose_of_visit}</TableCell>
                <TableCell>{val.unit !==null && val.unit.unit_name}</TableCell>
                <TableCell>{val.vehicle_no}</TableCell>
                <TableCell>{val.tenantDetail !== null && val.tenantDetail.firstName}</TableCell>
                <TableCell>{val.status === 'Upcoming' && val.parkingDetail !== null && <Chip label={val.parkingDetail.parking_no} color="primary" />}</TableCell>
                <TableCell className={`${textColor[val.status]} fw-bold`  }>
                  <div className='d-flex align-items-center'><div style={{width:'8px',height:'8px',background:roundColor[val.status],borderRadius:'100px',marginRight:'5px'}}></div>{val.status}</div>
                </TableCell>
                <TableCell>
                <IconButton
                  aria-label="more"
                  id={"long-button_" + val.id}
                  aria-controls={
                    cOpen === val.id
                      ? "long-menu_" + val.id
                      : undefined
                  }
                  aria-expanded={
                    cOpen === val.id ? "true" : undefined
                  }
                  aria-haspopup="true"
                  onClick={(e) => {
                    handleClick(val.id, e);
                  }}
                >
                  <Typography
                    className="poppins-family"
                    color="primary"
                  >
                    Action
                  </Typography>
                  <KeyboardArrowDownTwoToneIcon color="primary" />
                </IconButton>
                <Menu
                  id={"long-menu_" + val.id}
                  MenuListProps={{
                    "aria-labelledby": "long-button_" + val.id,
                  }}
                  anchorEl={anchorEl}
                  open={cOpen === val.id}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => props.view(val)} className="text-success" >View</MenuItem>
                  {val.status === 'Upcoming' && <MenuItem onClick={() => props.handleEdit(val)} className='text-primary'>Edit</MenuItem>}
                  <MenuItem onClick={() => visitor.deleteRequestList(val.id)} className='text-danger'>Delete</MenuItem>
                  <MenuItem onClick={() => props.statusChanged(val)}>Change Status</MenuItem>
                </Menu>
                </TableCell>
              </TableRow>)
              })}
            {data.length === 0 && <TableRow>
              <TableCell colSpan={8}>
                No Data
              </TableCell>
            </TableRow>}
          </TableBody>
          <Pagination pagination={visitor.page} rowsPerPage={10} rowsPerPageChange={visitor.handleNextPage}/>
        </Table>
      </TableContainer>
    </>
  )
}

export default DataTable
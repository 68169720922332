import { Route,Routes,Navigate,useLocation,useRoutes } from "react-router-dom";
import { DtoRoutesDefinition } from "../Dto/auth";
import { isAuthenticated } from "../Store/authSlice";
import { useAppSelector } from "../Store/hooks";
import { RoutePermissionCheck } from "./RoutePermission";
import { ProtectedRoutes,AuthRoutes } from "./Route"
import NotFoundPage from "../Pages/ErrorPages/PageNotFound";

const UnAuthRouteMiddleware = () => {
    const location = useLocation();
    return (
        <Navigate to="/sign-in" state={{ locationFrom: location.pathname }} />
    );
};

const AuthRouteMiddleware = () => {
    const redirectUrl = "/dashboard";
    const location = useLocation();

    return (
        <Navigate
            to={redirectUrl}
            state={{ locationFrom: location.pathname }}
        />
    );
};

const ProtectedRouteMiddleware = (routeDefinition: {
    routeInfo: DtoRoutesDefinition;
}) => {
    const location = useLocation();
    if (RoutePermissionCheck(routeDefinition.routeInfo)) {
        if (routeDefinition.routeInfo.redirectTo) {
            return (
                <Navigate
                    to={routeDefinition.routeInfo.redirectTo}
                    state={{ locationFrom: location.pathname }}
                />
            );
        }
        return routeDefinition.routeInfo.element;
    } else {
        return (
            <Navigate
                to={"/error"}
                state={{ locationFrom: location.pathname }}
            />
        );
    }
};

const RoutesComponent = () => {
    const authenticated = useAppSelector(isAuthenticated);
    const permission = useAppSelector((state) => state?.auth?.permission)
    // const ProtectedRoutesMap = ProtectedRoutes.map((routeInfo) => ({
    //     key: routeInfo.path,
    //     path: routeInfo.path,
    //     element: (authenticated) ? (<ProtectedRouteMiddleware routeInfo={routeInfo} />) : (<UnAuthRouteMiddleware />)
    // }));
    const ProtectedRoutesMap:any = []
    ProtectedRoutes.forEach((routeInfo) => {
        if(permission.some((obj:any) => obj?.Module?.name === routeInfo?.modules )){
            ProtectedRoutesMap.push({
                key: routeInfo.path,
                path: routeInfo.path,
                element: (authenticated) ? (<ProtectedRouteMiddleware routeInfo={routeInfo} />) : (<UnAuthRouteMiddleware />)
            })
        }
    });

    const AuthRoutesMap = AuthRoutes.map((routeInfo) => ({
        key: routeInfo.path,
        path: routeInfo.path,
        element: (!authenticated) ? routeInfo.element : (<AuthRouteMiddleware />)
    }));
    const token = localStorage.getItem('accessToken');
    const commonRouteMap = [
        { path:"/",element:(<Navigate replace to={"/sign-in"}/>) },
        { path: "*",element: (< Navigate replace to={"/error"} />) }
    ];
    const errorRouteMap = [{ path: "/error",element: <NotFoundPage />,key: "/error" }];
    const mappedRoutes = [...ProtectedRoutesMap,...AuthRoutesMap,...commonRouteMap,...errorRouteMap];
    return useRoutes(mappedRoutes);
};


export const AppRoutes = () => { 
    return (
        <RoutesComponent />
    );
};
import React, { useContext } from 'react';
import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import BoxComponent from '../../Components/Box/BoxComponent';
import { MaintenanceContext } from './Maintenance';
import { DeleteApiMethod } from '../../Utils/ApiService';
import { useAppDispatch } from '../../Store/hooks';
import { showLoader } from '../../Store/loaderSlice';
import { showToaster } from '../../Store/toastSlice';

const MaintenanceRequestList = () => {
    const maintenance = useContext(MaintenanceContext);
    const dispatch = useAppDispatch();

    const onDelete = async(val) => {
        dispatch(showLoader({showLoader:true}))
        try{
            let res = await DeleteApiMethod(`maintenance/${val.id}`);
            dispatch(showToaster({
                dialogBgColor: "bg-success",
                dialogMsg: res["data"]["message"],
                showDialog: true,
                timer: "3000"
            }));
            maintenance.getAllMaintenance()
        }catch(error){
            dispatch(showToaster({
                dialogBgColor: "bg-danger",
                dialogMsg: error.response ? error.response.data.message : "Failed to create maintenance",
                showDialog: true,
                timer: "3000"
            }))
        }
        dispatch(showLoader({showLoader:false}))
    }

    return (
        <>
            <Box className="my-3">
                <Box className='fw-bold fs-4'>Request</Box>
            </Box>
            <hr/>
            <Box sx={{display:'grid',gridTemplateColumns:'1fr 1fr 1fr 1fr',gap:'50px'}}>
                <Box>
                    <Box className="d-flex justify-content-between mb-3">
                        <Box className="fs-5">New Request</Box>
                        <AddIcon sx={{cursor:'pointer'}} />
                    </Box>
                    {maintenance.maintenanceList.length !== 0 && maintenance.maintenanceList.map((val,key) => (
                        val.status === 'New Request' ? <BoxComponent key={key} {...val} onDelete={() => onDelete(val)} onEdit={() => maintenance.handleEdit(val)}/> : null
                    ))}
                </Box>
                <Box>
                    <Box className="d-flex justify-content-between mb-3">
                        <Box className="fs-5">In Progress</Box>
                        <AddIcon sx={{cursor:'pointer'}} />
                    </Box>
                    {maintenance.maintenanceList.length !== 0 && maintenance.maintenanceList.map((val,key) => (
                        val.status === 'In Progress' ?  <BoxComponent key={key} {...val} onDelete={() => onDelete(val)} onEdit={() => maintenance.handleEdit(val)}/> : null
                    ))}
                </Box>
                <Box>
                    <Box className="d-flex justify-content-between mb-3">
                        <Box className="fs-5">Repaired</Box>
                        <AddIcon sx={{cursor:'pointer'}} />
                    </Box>
                    {maintenance.maintenanceList.length !== 0 && maintenance.maintenanceList.map((val,key) => (
                        val.status === 'Repaired' ? <BoxComponent key={key} {...val} onDelete={() => onDelete(val)} onEdit={() => maintenance.handleEdit(val)}/> : null
                    ))}
                </Box>
                <Box>
                    <Box className="d-flex justify-content-between mb-3">
                        <Box className="fs-5">Cancelled</Box>
                        <AddIcon sx={{cursor:'pointer'}} />
                    </Box>
                    {maintenance.maintenanceList.length !== 0 && maintenance.maintenanceList.map((val,key) => (
                        val.status === 'Cancelled' ? <BoxComponent key={key} {...val} onDelete={() => onDelete(val)} onEdit={() => maintenance.handleEdit(val)}/> : null
                    ))}
                </Box>
            </Box>
        </>
    )
}

export default MaintenanceRequestList
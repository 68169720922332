import * as React from 'react';

export function NotFound() {

	return (
		<div className="container-fluid">
			404 Page Not Found.
		</div>

	);
}

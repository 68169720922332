import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableFooter,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Actions from "../../Components/Actions/Actions";
import Pagination from "../../Components/Pagination/Pagination";
import { AddRecurringInvoice } from "./AddRecurringInvoice";
import { useAppDispatch } from "../../Store/hooks";
import { DeleteApiMethod, PostApiMethod } from "../../Utils/ApiService";
import { showLoader } from "../../Store/loaderSlice";
import { showToaster } from "../../Store/toastSlice";
import { errorHandling } from "../../Utils/HelperService";
import { formatDate } from "../../Components/Utilities";
import { InvoiceList } from "./InvoiceList";

interface RecurringSettingProps {
  screenType: boolean;
  handleOthers: (type: any) => void;
  searchString: string;
  propertyId: string;
}

const RecurringSetting = (props: RecurringSettingProps) => {
  const [allInvoices, setAllInvoices] = useState([]);
  const [page, setPage] = useState({ page: 1, limit: 10, count: 0 });
  const dispatch = useAppDispatch();
  const { screenType, handleOthers } = props;
  const [isUpdate, setUpdate] = useState(false);
  const [updatPropertyId, setUpdatePropertyId] = useState("");

  useEffect(() => {
    getAllInvoices(1, 10);
  }, [props.searchString, props.propertyId]);
  const getAllInvoices = useCallback(
    (page: number, limit: number) => {
      let params: any = {
        page: page,
        limit: limit,
        searchString: props.searchString,
        propertyId: props.propertyId,
        type: "Recurring",
      };
      PostApiMethod("property/invoice/list", params)
        .then((response) => {
          setAllInvoices(response.data.results.data);
          dispatch(showLoader({ showLoader: false }));
          setPage((prevPage) => ({
            ...prevPage,
            count: response["data"]["results"]["totalCount"],
          }));
        })
        .catch((e) => {
          dispatch(showLoader({ showLoader: false }));
        });
    },
    [props]
  );

  const deleteInvoice = useCallback((deleteId: String) => {
    dispatch(showLoader({ showLoader: true }));
    DeleteApiMethod("property/invoice/" + deleteId)
      .then((res) => {
        getAllInvoices(page.page, page.limit);
        dispatch(showLoader({ showLoader: false }));
        dispatch(
          showToaster({
            dialogBgColor: "success",
            dialogMsg: "Deleted successfully",
            showDialog: true,
            timer: "5000",
          })
        );
      })
      .catch((e: any) => {
        const error = errorHandling(e);
        dispatch(
          showToaster({
            dialogBgColor: "bg-danger",
            dialogMsg: error,
            showDialog: true,
            timer: "5000",
          })
        );
        dispatch(showLoader({ showLoader: false }));
      });
  }, []);

  const rowsPerPage = useCallback(
    (val: any) => {
      setPage({ ...page, page: val.page, limit: val.limit });
      getAllInvoices(val.page, val.limit);
    },
    [page]
  );

  const edit = useCallback((value: any) => {
    props.handleOthers({ recurringInvoice: true });
    setUpdatePropertyId(value.id);
    setUpdate(true);
  }, []);

  return (
    <>
      <Box className="mt-3">
        {!screenType ? (
          <InvoiceList
            listValues={allInvoices}
            rowsPerPage={rowsPerPage}
            page={page}
            deleteInvoice={deleteInvoice}
            editInvoice={edit}
          />
        ) : (
          <AddRecurringInvoice
            handleOthers={handleOthers}
            refreshPage={() => {
              getAllInvoices(page.page, page.limit);
            }}
            isUpdate={isUpdate}
            updatPropertyId={updatPropertyId}
            setUpdate={setUpdate}
          />
        )}
      </Box>
    </>
  );
};

export default RecurringSetting;

import { Grid, TableContainer, Table, TableBody, TableRow, TableCell, TableHead } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { TextInput } from '../../../Components/Text/TextInput';
import { Search } from '@mui/icons-material';
import { AssetContext } from '../AssetManagement';
import { useAppDispatch } from '../../../Store/hooks';
import { deletePetrolCategory, getPetrolCategory } from './MastersAPI';
import MenuGroup from '../../../Components/DropDown/MenuGroup';
import { Category } from './AssetMasters';
import NoData from '../../../Components/Pagination/NoData';
import Pagination from '../../../Components/Pagination/Pagination';

const CategoryTable = () => {
    const asset = useContext(AssetContext);
    const dispatch = useAppDispatch();

    const { category, setCategory, mastersSearch, setMastersSearch } = asset;

    useEffect(() => {
        getPetrolCategory(dispatch,setCategory,category);
    },[])

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextInput 
                        label={"Search"}
                        name="search"
                        value={mastersSearch?.category}
                        onChange={(e) => {
                            setMastersSearch(e.target.value)
                            getPetrolCategory(dispatch,setCategory,{...category,page:{page:1,limit:5},searchString:e.target.value})}
                        }
                        startadornment={true}
                        starticon={<Search />}
                    />
                </Grid>
            </Grid>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {category?.data?.map((val,key) => (
                            <TableRow key={key}>
                                <TableCell>{val?.name}</TableCell>
                                <TableCell align='right'>
                                    <MenuGroup 
                                        id={val?.id}
                                        edit={true}
                                        delete={true}
                                        clickEdit={() => setCategory({...category,edit:true,modal:true,...val})}
                                        clickDelete={async() => {
                                            await deletePetrolCategory({
                                                dispatch,
                                                categoryId:val?.id,
                                                name:val?.name
                                            });
                                            await getPetrolCategory(dispatch,setCategory,{...category,page:{page:1,limit:5,count:0}})
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                        {category?.data?.length === 0 && <NoData colSpan={2} />}
                    </TableBody>
                    <Pagination 
                        pagination={category?.page} 
                        rowsPerPage={5} 
                        rowsPerPageChange={(val) => {
                            getPetrolCategory(dispatch,setCategory,{...category,...{page:val},searchString:mastersSearch?.category})}
                        }
                    />
                </Table>
            </TableContainer>
            <Category 
                open={category?.modal}
                onClose={() => setCategory({...category,...{edit:false,modal:false}})}
                header={`${category?.edit ? "Edit " : "Create"} Category`}
                name={category?.name}
                edit={category?.edit}
                categoryId={category?.id}
                getCategory={() => getPetrolCategory(dispatch,setCategory,{...category,page:{page:1,limit:5,count:0}})}
            />
        </>
    )
}

export default CategoryTable
import React from 'react';
import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const BoxHeader = (props) => {
    const { onAdd } = props;
    return (
        <>
            <Box className="d-flex justify-content-between align-items-center flex-wrap">
                <Box className="d-flex align-items-center flex-wrap">
                    <img src={props?.image}  alt="header" width="20" height="20" />
                    <Box className="fw-bold fs-5 ms-2">{props?.dashboard_name}</Box>
                    {props?.children}
                </Box>
                {props?.button_show && 
                    <button className='btn btn-danger' onClick={onAdd}>
                        <AddIcon />{props?.button_name}
                    </button>}
            </Box>
        </>
    )
}

export default BoxHeader
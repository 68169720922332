import React, { useContext, useCallback } from 'react';
import { Box, Typography, Grid, TextField } from '@mui/material';
import { Formik, Form } from 'formik';
import { LocationValidation } from './PropertyValidationSchema';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { AddPropertyContext } from './AddProperty';
import { PropertyContext } from './Main';
import { PutApiMethod } from '../../Utils/ApiService';
import { useAppDispatch } from '../../Store/hooks';
import { showLoader } from '../../Store/loaderSlice';
import { showToaster } from '../../Store/toastSlice';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { PostApiMethod } from '../../Utils/ApiService';

const Location = () => {
    const addpropertyform = useContext(AddPropertyContext);
    const property = useContext(PropertyContext);
    const dispatch = useAppDispatch();

    const updateProperty = async(values) => {
        dispatch(showLoader({showLoader:true}))
        try{
            let res = await PutApiMethod(`property/properties/${addpropertyform.data.property_id}`,values);
            if(res["data"]["error"] === false){
                dispatch(showToaster({dialogBgColor: "bg-success",
                    dialogMsg: res["data"]["message"],
                    showDialog: true,
                    timer: "3000"
                }));
                property.getPropertyList(property.propertyPage);
                // if(property.isEdit){
                    // property.onBackPropertyList();
                // }else{
                    addpropertyform.formSubmit("Unit Form",values);
                // }
            }
        }catch(error){
            dispatch(showToaster({dialogBgColor: "bg-danger",
                dialogMsg: "Property details not updated",
                showDialog: true,
                timer: "3000"
            }))
        }
        dispatch(showLoader({showLoader:false}))
    }
    return (
        <>
            <Box className="mt-4 d-flex justify-content-between">
                <Box>
                    <h5 className='fw-bold'>{ property.isEdit ? <><ArrowBackIcon sx={{cursor:'pointer'}} onClick={property.onBackPropertyList}/>Edit</> : "Add New" } Property</h5>
                    <p sx={{color:'gray'}}>Location</p>
                </Box>
            </Box>
            <Formik
                initialValues={{
                    country_id:addpropertyform.data.country_id,
                    state_id:addpropertyform.data.state_id,
                    city_id:addpropertyform.data.city_id,
                    zip_code:addpropertyform.data.zip_code,
                    address:addpropertyform.data.address,
                    map_link:addpropertyform.data.map_link
                }}
                validationSchema={LocationValidation}
                onSubmit={async(values) => {
                    updateProperty(values)
                }}
            >
                {({ handleSubmit, handleChange, values, errors, touched }) => (
                    <Form onSubmit={handleSubmit}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Typography className='fw-bold'>Country</Typography>
                                <TextField
                                    type="text"
                                    className='form-control'
                                    name="country_id"
                                    value={values.country_id}
                                    onChange={handleChange}
                                    placeholder='Type here...'
                                    variant="standard"
                                />
                                {touched.country_id && errors.country_id && (<div className='text-danger'>{errors.country_id}</div>)}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Typography className='fw-bold'>State</Typography>
                                <TextField
                                    type="text"
                                    className='form-control'
                                    name="state_id"
                                    value={values.state_id}
                                    onChange={handleChange}
                                    placeholder='Type here...'
                                    variant="standard"
                                />
                                {touched.state_id && errors.state_id && (<div className='text-danger'>{errors.state_id}</div>)}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Typography className='fw-bold'>City</Typography>
                                <TextField
                                    type="text"
                                    className='form-control'
                                    name="city_id"
                                    value={values.city_id}
                                    onChange={handleChange}
                                    placeholder='Type here...'
                                    variant="standard"
                                />
                                {touched.city_id && errors.city_id && (<div className='text-danger'>{errors.city_id}</div>)}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Typography className='fw-bold'>Zip Code</Typography>
                                <TextField
                                    type="text"
                                    className='form-control'
                                    name="zip_code"
                                    value={values.zip_code}
                                    onChange={handleChange}
                                    placeholder='Type here...'
                                    variant="standard"
                                />
                                {touched.zip_code && errors.zip_code && (<div className='text-danger'>{errors.zip_code}</div>)}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Typography className='fw-bold'>Address</Typography>
                                <TextField
                                    type="text"
                                    className='form-control'
                                    name="address"
                                    value={values.address}
                                    onChange={handleChange}
                                    placeholder='Type here...'
                                    variant="standard"
                                />
                                {touched.address && errors.address && (<div className='text-danger'>{errors.address}</div>)}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Typography className='fw-bold'>Map Link</Typography>
                                <TextField
                                    type="text"
                                    className='form-control'
                                    name="map_link"
                                    value={values.map_link}
                                    onChange={handleChange}
                                    placeholder='Type here...'
                                    variant="standard"
                                />
                            </Grid>
                        </Grid>
                        <Box className="mt-4">
                            <button type="button" className='btn btn-white' onClick={() => addpropertyform.onBackForm("New Property")}><ArrowBackIosIcon style={{fontSize:'18px'}}/>Back</button>
                            <button type="submit" className='btn btn-danger ms-2'>{"Save & Proceed Next"}</button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default Location;
import React, { useCallback, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import FormTitle from "../../Components/FormTitle";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Grid, MenuItem } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useFormik } from "formik";
import { InvoiceTypeAddSchema } from '../../Components/Validation';
import { ErrorTextField } from '../../Components/Error/ErrorTextField';
import { DropDown } from '../../Components/DropDown/DropDown';
import { TaxTypes } from '../../Components/Utilities';
import { showLoader } from '../../Store/loaderSlice';
import { PostApiMethod, PutApiMethod } from '../../Utils/ApiService';
import { showToaster } from '../../Store/toastSlice';
import { errorHandling } from '../../Utils/HelperService';


interface AddEditTypeProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    value: { name: string, tax: string, taxType: string, owner_user_id: string, id: string }
    isUpdate: boolean;
    refreshPage: () => void;
}
interface FormValues {
    name: string;
    tax: string;
    taxType: string;
    owner_user_id: string,
    id: string
}

const AddEditType = (props: AddEditTypeProps) => {
    const loader = useAppSelector(state => state.loader);
    const dispatch = useAppDispatch();
    const [initialValue, setInitialValue] = useState<FormValues>({ id:"",name: "", tax: "", taxType: "Percentage", owner_user_id: "1" });
    
    const addInvoiceType = useCallback((values:any) => {
        dispatch(showLoader({showLoader:true}));
        const form = new FormData();
        form.append("name", values.name);
        form.append("tax", values.tax);
        form.append("taxType", values.taxType);
        form.append("owner_user_id","1");


        PostApiMethod("property/invoice-type/add", form, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((res) => {
            dispatch(
                showToaster({
                    dialogBgColor: "success",
                    dialogMsg: "Type added successfully",
                    showDialog: true,
                    timer: "5000",
                })
            );
            resetForm();
            props.setIsOpen(false);
            props.refreshPage();
            dispatch(showLoader({ showLoader: false }));

        }).catch((e) => {
            const error = errorHandling(e);
            dispatch(
                showToaster({
                    dialogBgColor: "bg-danger",
                    dialogMsg: error,
                    showDialog: true,
                    timer: "5000",
                })
            );
            dispatch(showLoader({ showLoader: false }));

        })
    },[])

    const updateInvoiceType = useCallback((values: { name: string, tax: string, taxType: string, owner_user_id: string, id: string }) => {
        dispatch(showLoader({ showLoader: true }));
        const form = new FormData();
        form.append("name", values.name);
        form.append("tax", values.tax);
        form.append("taxType", values.taxType);
        form.append("owner_user_id","1");


        PutApiMethod("property/invoice-type/update/" + values.id, form, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((res) => {
            dispatch(
                showToaster({
                    dialogBgColor: "success",
                    dialogMsg: "Invoice Type updated successfully",
                    showDialog: true,
                    timer: "5000",
                })
            );
            resetForm();
            props.setIsOpen(false);
            props.refreshPage();
            dispatch(showLoader({ showLoader: false }));

        }).catch((e) => {
            const error = errorHandling(e);
            dispatch(
                showToaster({
                    dialogBgColor: "bg-danger",
                    dialogMsg: error,
                    showDialog: true,
                    timer: "5000",
                })
            );
            dispatch(showLoader({ showLoader: false }));

        })
    }, []);
 

    const { values, handleChange, handleSubmit, errors, touched, resetForm, setFieldValue } = useFormik({
        initialValues: initialValue,
        validationSchema: InvoiceTypeAddSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            if (props.isUpdate) {
                 updateInvoiceType(values);
            } else {
                addInvoiceType(values);
            }
        },
    });

    useEffect(() => {
        if (props.value)
            setInitialValue(props.value)
    }, [props.value])

    return (
        <Dialog open={props.isOpen} >
            <DialogTitle>
                {` ${props.isUpdate ? "Update" : "Add"} Invoice Type`}
            </DialogTitle>
            <DialogContent dividers>
                <Box sx={{ width: '550px' }}>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <FormTitle text="Name" />
                                <TextField
                                    type="text"
                                    variant="standard"
                                    placeholder="Type here..."
                                    className="form-control w-100"
                                    name="name"
                                    onChange={handleChange}
                                    value={values.name}
                                />
                                <ErrorTextField error={errors.name} touched={touched.name!} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <FormTitle text="Tax" />
                                <TextField
                                    type="number"
                                    variant="standard"
                                    placeholder="Type here..."
                                    className="form-control"
                                    name="tax"
                                    onChange={handleChange}
                                    value={values.tax}
                                />
                                <ErrorTextField error={errors.tax} touched={touched.tax!} />

                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <FormTitle text="Tax Type" />
                                <DropDown
                                    value={values.taxType}
                                    handleChange={handleChange}
                                    name="taxType"
                                >
                                    {TaxTypes.map((option: any, index: number) => {
                                        return (
                                            <MenuItem key={index} value={option}>
                                                {option}
                                            </MenuItem>
                                        );
                                    })}
                                </DropDown>

                            </Grid>
                        </Grid>
                        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }} className="d-flex">
                            <Button
                                className="btn-white"
                                onClick={() => {
                                    props.setIsOpen(false);
                                    resetForm();

                                }}
                            >
                                <ChevronLeftIcon className="fs-20" />
                                Back
                            </Button>
                            <Button
                                className="btn-danger ms-2"
                                type="submit"
                                disabled={loader.showLoader}
                            >
                                {props.isUpdate ? "Update" : "Add"}
                            </Button>
                        </Box>
                    </form>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default AddEditType
import React, { useState } from "react";
import { UnAuthorizedLayout } from "../Layouts/UnAuthorizedLayout";
import { useAppDispatch } from "../../Store/hooks";
import { setIsAuthenticated, setPermission, setUserDetails } from "../../Store/authSlice";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { loginSchema } from "../../Components/Validation";
import { useFormik } from "formik";
import { PostApiMethod } from "../../Utils/ApiService";
import { showToaster } from "../../Store/toastSlice";
import { DisplayErrorMessages } from "../../Utils/HelperService";
import { showLoader } from "../../Store/loaderSlice";
import Logo from "../../Assets/images/logo.png"
import { Box, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const initialValues = {
  username: "",
  password: "",
};
const LoginPage = () => {
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      dispatch(showLoader({ showLoader: true }));
      try {
        const res = await PostApiMethod("auth/login", {
          email: values["username"],
          password: values["password"],
        });

        if (res["data"]["code"] === 200) {
          const data = res["data"]["results"]["data"];
          window.localStorage.setItem("accessToken", data["accessToken"]);
          window.localStorage.setItem("login", "true");
          dispatch(setIsAuthenticated(true));
          dispatch(setUserDetails(data["user"]));
          dispatch(showLoader({ showLoader: false }));
          dispatch(setPermission(data["permission"]));
        }
      } catch (error: any) {
        dispatch(
          showToaster({
            dialogBgColor: "bg-danger",
            dialogMsg: error.response.data.message,
            showDialog: true,
            timer: "5000",
          })
        );
        dispatch(showLoader({ showLoader: false }));
      }
    },
  });

  return (
    <UnAuthorizedLayout>
      <Box className="login">
        <img alt="logo" src={Logo} className='logo' />
        <Typography sx={{ color: '#6F7275' }}>Property Management System</Typography>

        <Box className="mt-4">
          <Typography variant='h3'>Login</Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12} className="mt-3">
              <Typography className="poppins-family" >Email:</Typography>
              <TextField
                variant="standard"
                type="text"
                // placeholder="Enter your username"
                onChange={handleChange}
                name="username"
                className="w-100 mt-1"
                value={values.username}
              />
              {(errors.username && touched.username) && (
                <Typography className="text-danger">{errors.username}</Typography>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className="mt-2">
              <Typography className="poppins-family">Password:</Typography>
              <TextField
                variant="standard"
                type={showPassword ? "text" : "password"}
                // placeholder="Enter your password"
                name="password"
                onChange={handleChange}
                className="w-100 mt-1"
                value={values.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                fullWidth
              />
              {(errors.password && touched.password) && (
                <Typography className="text-danger">{errors.password}</Typography>
              )}
            </Grid>
          </Grid>
          <Box className="d-grid mt-5">
            <Button className="btn-danger" type="submit" sx={{ height: '40px' }} >
              Login
            </Button>
          </Box>
        </form>
      </Box>
    </UnAuthorizedLayout>
  );
};

export default LoginPage;

import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Grid,
  FormLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Formik, Form } from "formik";
import { userSchema } from "./AddUserSchema";
import ButtonGroup from "../../Components/Button/ButtonGroup";
import { UserContext } from "./UserManagement";
import { DropDown } from "../../Components/DropDown/DropDown";
import { useAppDispatch } from "../../Store/hooks";
import { showLoader } from "../../Store/loaderSlice";
import { PostApiMethod, PutApiMethod } from "../../Utils/ApiService";
import { errorHandling } from "../../Utils/HelperService";
import { showToaster } from "../../Store/toastSlice";

const AddUser = () => {
  const user = useContext(UserContext);
  const { data, setData, getUsers } = user;
  const dispatch = useAppDispatch();
  const [initialValue, setInitialValue] = useState({
    name: data?.firstName || "",
    email: data?.email || "",
    mobile: data?.phoneNumber || "",
    role: data?.role || "",
    id: data?.id || "",
  }) 
  const addUsers = useCallback((values) => {
    dispatch(showLoader({ showLoader: true }));
    const param = {
      email: values.email,
      firstName: values.name,
      phoneNumber: values.mobile,
      role: values.role,
      parentId: "1"
    };
    if(data?.edit) {
      PutApiMethod("user/"+values.id, param)
      .then((res) => {
        dispatch(showLoader({ showLoader: false }));
        dispatch(
          showToaster({
            dialogBgColor: "success",
            dialogMsg: "User has been updated successfully",
            showDialog: true,
            timer: "5000",
          })
        );
         getUsers({page:1,limit:5})
      })
      .catch((e) => {
        console.log(e);
        dispatch(showLoader({ showLoader: false }));
        const error = errorHandling(e);
        dispatch(
          showToaster({
            dialogBgColor: "bg-danger",
            dialogMsg: error,
            showDialog: true,
            timer: "5000",
          })
        );
      });
    }else {
    PostApiMethod("auth/create-user", param)
      .then((res) => {
        dispatch(showLoader({ showLoader: false }));
        dispatch(
          showToaster({
            dialogBgColor: "success",
            dialogMsg: "User has been added successfully",
            showDialog: true,
            timer: "5000",
          })
        );
         getUsers({page:1,limit:5})
      })
      .catch((e) => {
        console.log(e);
        dispatch(showLoader({ showLoader: false }));
        const error = errorHandling(e);
        dispatch(
          showToaster({
            dialogBgColor: "bg-danger",
            dialogMsg: error,
            showDialog: true,
            timer: "5000",
          })
        );
      });
    }
  }, [data?.edit ]);
  useEffect(() => {
    if(data) {
      setInitialValue((prev)=>({...prev,
        name: data?.firstName,
        email: data?.email,
        mobile: data?.phoneNumber,
        role: data?.role,
      }))
    }
  },[data])
  return (
    <>
      <Box className="fs-5 fw-bold mt-3">{data?.edit ? "Edit" : "Add"} User</Box>
      <Box sx={{ color: "gray" }}>User Information</Box>
      <Formik
        initialValues={initialValue}
        validationSchema={userSchema}
        onSubmit={(values) => {
          addUsers(values)
        }}
        enableReinitialize={true}
      >
        {({ handleChange, handleSubmit, errors, values, touched }) => (
          <Form onSubmit={handleSubmit}> 
            <Grid container spacing={4} className="mt-2">
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormLabel className="fw-bold">Name:</FormLabel>
                <TextField
                  variant="standard"
                  name="name"
                  className="form-control"
                  placeholder="Type here..."
                  value={values.name}
                  onChange={handleChange}
                />
                {touched?.name && errors?.name && (
                  <div className="text-danger">{errors?.name}</div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormLabel className="fw-bold">Email:</FormLabel>
                <TextField
                  variant="standard"
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Type here..."
                  value={values.email}
                  onChange={handleChange}
                />
                {touched?.email && errors?.email && (
                  <div className="text-danger">{errors?.email}</div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormLabel className="fw-bold">Mobile:</FormLabel>
                <TextField
                  variant="standard"
                  type="number"
                  name="mobile"
                  className="form-control"
                  placeholder="Type here..."
                  value={values.mobile}
                  onChange={handleChange}
                />
                {touched?.mobile && errors?.mobile && (
                  <div className="text-danger">{errors?.mobile}</div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormLabel className="fw-bold">Role</FormLabel>
                <DropDown
                  value={values.role}
                  handleChange={handleChange}
                  name="role"
                >
                  {user?.masterData.roles.map((option, index) => {
                    return (
                      <MenuItem key={index} value={option.id}>
                        {option.name}
                      </MenuItem>
                    );
                  })}
                </DropDown>
                {touched?.role && errors?.role && (
                  <div className="text-danger">{errors?.role}</div>
                )}
              </Grid>
            </Grid>
            <ButtonGroup submitText="Submit" onBack={() => setData({...data,open:false})} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddUser;

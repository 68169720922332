import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

const SimpleCard = (props: any) => {
  return (
    <>
      <Box
        sx={{ height: "138px", borderRadius: "12px" }}
        className="p-4 bg-white"
      >
        <Box className="d-flex justify-content-between align-items-center">
          <Box className="d-flex align-items-center">
            <img src={props.prefix} width="25" height="25" />
            <Typography
              className="poppins-family ms-1"
              sx={{ fontSize: "16px", color: props.color }}
            >
              {props.title}
            </Typography>
          </Box>
          <Typography
            className="poppins-family text-gray"
            sx={{ fontSize: "14px" }}
          >
            {props.date}
          </Typography>
        </Box>
        <Box className="d-flex justify-content-between align-items-center mt-2">
          <Typography className="poppins-family fs-1 fw-bold">
            {props.amount}
          </Typography>
          <img src={props.image} style={{ width: "50px", height: "50px" }} />
        </Box>
      </Box>
    </>
  );
};

export default SimpleCard;

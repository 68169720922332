import React, { useState , useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { useFormik } from "formik";
import { AddExpenseSchema } from "../../Components/Validation";
import { showLoader } from "../../Store/loaderSlice";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { showToaster } from "../../Store/toastSlice";
import { PostApiMethod } from "../../Utils/ApiService";
import { errorHandling } from "../../Utils/HelperService";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const AddNewExpense = (props: any) => {

  let dispatch = useDispatch();


  const [openExpenseType, setOpenExpenseType] = useState(false);
  const [openUnitType, setOpenUnitType] = useState(false);
  const [propertyList, setPropertyList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [expenseTypeList, setExpenseTypeList] = useState([]);
  const [TagList, setTagList] = useState([]);
  const [expenseTypeName, setExpenseTypeName] = useState('');
  const [TagTypeName, setTagTypeName] = useState('');
  const [error, setError] = useState('');
  const [error2, setError2] = useState('');
  const [images, setImages] = useState<File[]>([]);
  const [imgArray, setImgArray] = useState<File[]>([]);

  const getInitialState = {
    name: "",
    propertyId: "",
    unitId: "",
    expenseTypeId: "",
    description: "",
    responsibility: "Tenant",
    amount: "",
    tagId: "",
    attachment:""
  };
  
  const handleImageChange = (e:any) => {
    e.preventDefault();
    setImages(e.target.files);    
    const files = Array.from(e.target.files || []) as File[];
    setImgArray(files);
  };
  const handleDeleteImage=(index:any)=>{
    try{
    const newImages = imgArray.filter((_, i) => i !== index);
      setImgArray(newImages);
      setImages(newImages);
    }
    catch(e){
      console.log(e)
    }
  }
  const { values, handleChange, handleSubmit, errors, touched, resetForm } =
  useFormik({
    initialValues: getInitialState,
    validationSchema: AddExpenseSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      handlFormSubmit(values);
    },
  });

  const handleExpenseTypeOpen = () => {
    setOpenExpenseType(!openExpenseType);
  };
  const handleUnitTypeOpen = () => {
    setOpenUnitType(!openUnitType);
  };


  const fetchProperty = async () => {
    let response = await PostApiMethod("property/properties/list", {});
    if (response["data"]["error"] === false) {
      setPropertyList(response.data.results.data);
    } else {
      console.log("response", response);
    }
  };
  const fetchUnit = async () => {
    let response = await PostApiMethod("property/unit/list", {});
    if (response["data"]["error"] === false) {
      setUnitList(response.data.results.data);
    } else {
      console.log("response", response);
    }
  };
  const fetchExpenseType = async () => {
    let response = await PostApiMethod("property/expenseType/list", {});
    if (response["data"]["error"] === false) {
      setExpenseTypeList(response.data.results.data);
    } else {
      console.log("response", response);
    }
  };
  const fetchTag = async () => {
    let response = await PostApiMethod("property/tag/list", {});
    if (response["data"]["error"] === false) {
      setTagList(response.data.results.data);
    } else {
      console.log("response", response);
    }
  };
  const handlFormSubmit = async (values: any) => {
    try {
      dispatch(showLoader({ showLoader: true }));
      if (images.length > 0) {
        const form = new FormData();
        form.append("name",values.name)
        form.append("propertyId",values.propertyId)
        form.append("unitId",values.unitId)
        form.append("expenseTypeId",values.expenseTypeId)
        form.append("description",values.description)
        form.append("responsibility",values.responsibility)
        form.append("amount",values.amount)
        form.append("tagId",values.tagId)
        for(let image of images){
          form.append("attachment",image)
        }
         await PostApiMethod('property/expense/add', form, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        
      } else {
           await PostApiMethod('property/expense/add', values);
        
      }
  
      dispatch(showLoader({ showLoader: false }));
  
      dispatch(
        showToaster({
          dialogBgColor: 'success',
          dialogMsg: values.id ? 'Expense updated successfully' : 'Expense added successfully',
          showDialog: true,
          timer: '5000',
        })
      );
      props.pageStatus("listExpense");
  } catch (err: any) {
      const error = errorHandling(err);
      dispatch(
        showToaster({
          dialogBgColor: 'bg-danger',
          dialogMsg: error,
          showDialog: true,
          timer: '5000',
        })
      );
      dispatch(showLoader({ showLoader: false }));
    }
  };


  const handleAddExpenseType = async (e:any) => {
    e.preventDefault();
    try{
      if (expenseTypeName.trim() === '') {
        setError('Expense Type Name is required');
        return;
      }
      setError('');
      let res:any = await PostApiMethod("property/expenseType/add", {name:expenseTypeName});
      setOpenExpenseType(!openExpenseType);
      setExpenseTypeName('');
      fetchExpenseType();
      dispatch(
        showToaster({
          dialogBgColor: "success",
          dialogMsg:"Expense Type  added successfully",
          showDialog: true,
          timer: "5000",
        })
      );
    }
    catch(err:any){
      const error = errorHandling(err);
      dispatch(
        showToaster({
          dialogBgColor: "bg-danger",
          dialogMsg: error,
          showDialog: true,
          timer: "5000",
        })
      );
      dispatch(showLoader({ showLoader: false }));
    }
   
  };
  const handleAddTagType = async (e:any) => {
    e.preventDefault();
    try{
      if (TagTypeName.trim() === '') {
        setError2('Tag Name is required');
        return;
      }
      setError2('');
      let res:any = await PostApiMethod("property/tag/add", {name:TagTypeName});
      setOpenUnitType(!openUnitType);
      setTagTypeName('');
      fetchTag();
      dispatch(
        showToaster({
          dialogBgColor: "success",
          dialogMsg:"Tag  added successfully",
          showDialog: true,
          timer: "5000",
        })
      );
    }
    catch(err:any){
      const error = errorHandling(err);
      dispatch(
        showToaster({
          dialogBgColor: "bg-danger",
          dialogMsg: error,
          showDialog: true,
          timer: "5000",
        })
      );
      dispatch(showLoader({ showLoader: false }));
    }
   
  };

  useEffect(() => {
    fetchProperty();
    fetchUnit();
    fetchExpenseType();
    fetchTag();
  }, []);

  return (
    <>
      <Box className="pt-3">
        <FormControl className="mt-3">
          <Box className="card-title mb-3">
            <Typography variant="h5">
              {props.editExpense ? "Update" : "Add New"} Expense
            </Typography>
          </Box>
          <FormLabel
            className="poppins-family text-black"
            id="responsibilty-type"
          >
            Responsibility Type
          </FormLabel>
          <RadioGroup row aria-labelledby="responsibility" name="responsibility" value={values.responsibility} onChange={handleChange}>
            <FormControlLabel
              className="poppins-family"
              value="Tenant"
              control={<Radio />}
              label="Tenant"
            />
            <FormControlLabel
              className="poppins-family"
              value="Property Owner"
              control={<Radio />}
              label="Property Owner"
            />
          </RadioGroup>
        </FormControl>
        <Box className="mt-3">
          <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <FormLabel className="poppins-family text-black">Name</FormLabel>
              <TextField
                type="text"
                variant="standard"
                placeholder="Type here..."
                className="w-100"
                name="name"
                onChange={handleChange}
                value={values.name}
              />
                  {errors.name && touched.name && (
                  <Typography className="text-danger">{errors.name}</Typography>
                )}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <FormLabel className="poppins-family text-black">
                Property
              </FormLabel>
              <FormControl variant="standard" className="w-100">
                 <Select
                    name="propertyId"
                    value={values.propertyId}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="">
                    None
                  </MenuItem>
                  {propertyList.map((data:any) => (
                    <MenuItem key={data.id} value={data.id}>
                      {data.property_name}
                    </MenuItem>
                  ))}
                </Select>
             {errors.propertyId && touched.propertyId && (
                  <Typography className="text-danger">{errors.propertyId}</Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <FormLabel className="poppins-family text-black">
                Unit Name
              </FormLabel>
              <FormControl variant="standard" className="w-100">
                <Select
                    name="unitId"
                    value={values.unitId}
                    onChange={handleChange}
                    displayEmpty
                  >
                <MenuItem value="">
                    None
                  </MenuItem>
                {unitList.map((data:any) => (
                    <MenuItem key={data.id} value={data.id}>
                      {data.unit_name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.unitId && touched.unitId && (
                  <Typography className="text-danger">{errors.unitId}</Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} className="mt-2">
              <Box className="d-flex justify-content-between">
                <FormLabel className="poppins-family text-black">
                  Expense Type
                </FormLabel>
                <Typography
                  onClick={handleExpenseTypeOpen}
                  className="poppins-family text-primary d-flex align-items-center"
                  sx={{ cursor: "pointer", fontSize: "15px" }}
                >
                  <AddIcon sx={{ fontSize: "15px" }} />
                  Add New Type
                </Typography>
              </Box>
              <FormControl variant="standard" className="w-100">
                <Select
                    name="expenseTypeId"
                    value={values.expenseTypeId}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="">
                    None
                  </MenuItem>
                {expenseTypeList.map((data:any) => (
                    <MenuItem key={data.id} value={data.id}>
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.expenseTypeId && touched.expenseTypeId && (
                  <Typography className="text-danger">{errors.expenseTypeId}</Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <FormLabel className="poppins-family text-black">
                Description
              </FormLabel>
              <TextField
                type="text"
                variant="standard"
                className="w-100"
                placeholder="Type here..."
                name="description"
                onChange={handleChange}
                value={values.description}
              />
                 {errors.description && touched.description && (
                  <Typography className="text-danger">
                    {errors.description}
                  </Typography>
                )}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <FormLabel className="poppins-family text-black">
                Amount
              </FormLabel>
              <TextField
                variant="standard"
                className="w-100"
                placeholder="Type here..."
                name="amount"
                onChange={handleChange}
                value={values.amount}
              />
                 {errors.amount && touched.amount && (
                  <Typography className="text-danger">
                    {errors.amount}
                  </Typography>
                )}
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box className="d-flex justify-content-between">
                <FormLabel className="poppins-family text-black">
                Tag
                </FormLabel>
                <Typography
                  onClick={handleUnitTypeOpen}
                  className="poppins-family text-primary d-flex align-items-center"
                  sx={{ cursor: "pointer", fontSize: "15px" }}
                >
                  <AddIcon sx={{ fontSize: "15px" }} />
                  Add New Type
                </Typography>
              </Box>
              <FormControl variant="standard" className="w-100">
                <Select
                    name="tagId"
                    value={values.tagId}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="">
                    None
                  </MenuItem>
                {TagList.map((data:any) => (
                    <MenuItem key={data.id} value={data.id}>
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.tagId && touched.tagId && (
                  <Typography className="text-danger">{errors.tagId}</Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography className="poppins-family">Attachement</Typography>          
                <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                onChange={(e:any) =>handleImageChange(e)}
              >
                Upload file
                <VisuallyHiddenInput type="file" multiple />
              </Button>
        {imgArray.map((file, index) => (
          <div className="border border-danger p-1">
        {file.name}
<CancelTwoToneIcon className="float-end" onClick={()=>{handleDeleteImage(index)}}></CancelTwoToneIcon>
        </div>
      ))}
            </Grid>
          </Grid>
          <Box className="mt-5">
            <Button className="btn-white border-0" onClick={() => {
                  props.pageStatus("listExpense");
                }}>
              <ChevronLeftIcon sx={{ fontSize: "23px", marginRight: "-6px" }} />
              Back
            </Button>
            <button type="submit" className="btn-danger ms-2">Submit</button>
          </Box>
          </form>
        </Box>
      </Box>
      {/**Add New Expense Type Start*/}
      <Modal open={openExpenseType}>
        <Box className="d-flex justify-content-center align-items-center vh-100 border-0">
          <Box className="bg-white p-4 rounded" sx={{ width: "30%" }}>
            <form onSubmit={handleAddExpenseType}>
            <Typography className="poppins-family fw-bold fs-6">
              Add New Expense Type
            </Typography>
            <Box className="mt-3">
              <Typography className="poppins-family">
                Expense Type Name
              </Typography>
              <TextField
                variant="standard"
                placeholder="Type here..."
                className="w-100"
                value={expenseTypeName}
                onChange={(e) => setExpenseTypeName(e.target.value)}        
              />
              {error && (
                  <Typography className="text-danger">
                    {error}
                  </Typography>
                )}
            </Box>
            <Box className="mt-3">
              <button
                className="btn btn-white border-0"
                onClick={handleExpenseTypeOpen}
              >
                <ChevronLeftIcon
                  sx={{ fontSize: "23px", marginRight: "-6px" }}
                />
                Back
              </button>
              <button type="submit" className="btn btn-danger ms-2">Save</button>
            </Box>
            </form>
          </Box>
        </Box>
      </Modal>
      {/**Add New Expense Type End*/}
      
      {/**Add New Tag Type Start*/}
      <Modal open={openUnitType}>
        <Box className="d-flex justify-content-center align-items-center vh-100 border-0">
          <Box className="bg-white p-4 rounded" sx={{ width: "30%" }}>
            <form onSubmit={handleAddTagType}>
            <Typography className="poppins-family fw-bold fs-6">
              Add New Tag Type
            </Typography>
            <Box className="mt-3">
              <Typography className="poppins-family">
                Tag Type Name
              </Typography>
              <TextField
                variant="standard"
                placeholder="Type here..."
                className="w-100"
                value={TagTypeName}
                onChange={(e) => setTagTypeName(e.target.value)}        
              />
              {error2 && (
                  <Typography className="text-danger">
                    {error2}
                  </Typography>
                )}
            </Box>
            <Box className="mt-3">
              <button
                className="btn btn-white border-0"
                onClick={handleUnitTypeOpen}
              >
                <ChevronLeftIcon
                  sx={{ fontSize: "23px", marginRight: "-6px" }}
                />
                Back
              </button>
              <button type="submit" className="btn btn-danger ms-2">Save</button>
            </Box>
            </form>
          </Box>
        </Box>
      </Modal>
      {/**Add New Tag Type End*/}
    </>
  );
};

export default AddNewExpense;

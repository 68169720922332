import React from 'react';
import Box from '@mui/material/Box';
import Typography from 'antd/es/typography/Typography';

const ColorText = (props:any) => {
  return (
    <>
        <Box className="d-flex align-items-center">
            <Box sx={{width:'5px',height:'5px',borderRadius:'100%',background:props.color}} className="me-1"></Box>
            <Typography style={{color:props.color}} className='poppins-family'>{props.children}</Typography> 
        </Box>
    </>
  )
}

export default ColorText
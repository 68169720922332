import { Box, Grid, TextField, Button, Typography } from "@mui/material";
import React, {useEffect, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import { AddNoticeBoardSchema } from "../../Components/Validation";
import { useFormik } from "formik";
import { PutApiMethod , PostApiMethod , DeleteApiMethod} from "../../Utils/ApiService";
import { showLoader } from "../../Store/loaderSlice";
import { showToaster } from "../../Store/toastSlice"; 
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { YearDateFormat } from "../../Components/Utilities";
import Swal from "sweetalert2";

interface NewDataType {
  title: any;
  details: any;
  property_id: any;
  unit_id: any;
  property_all: string | boolean;
  unit_all: string | boolean;
  start_date?: any;
  end_date?: any;  
}
const EditNoticeBoard = (props: any) => {  
  const loader = useAppSelector((state) => state.loader);
  const [propertyList, setPropertyList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [imgArray , setImgArray] = useState([]);
  const [images, setImages] = useState<File[]>([]);

  const [initialValue, setInitialValue] = useState({
    title: "",
    details: "",
    start_date: "",
    end_date: "",
    property_id: "",
    unit_id: "",
    id:"",
    property_all:"",
    unit_all:"",
    FileManagers:""
  });
  const dispatch = useAppDispatch();

  const submit = async (value: any) => { 
    dispatch(showLoader({ showLoader: true }));

    let newData: NewDataType = {
      title: value.title,
      details: value.details,
      property_id: value.property_id,
      unit_id: value.unit_id,
      property_all: value.property_all === "Yes" ? '1' : '0',
      unit_all: value.unit_all === "Yes" ? '1' : '0',
    };

    if (value.start_date) {
      newData.start_date = value.start_date;
    }
    if (value.end_date) {
      newData.end_date = value.end_date;
    }

    try {
      if (images.length > 0) {
        const form = new FormData();
        form.append("title", value.title);
        form.append("details", value.details);
        form.append("property_id", value.property_id);
        form.append("unit_id", value.unit_id);
        if (value.start_date) {
          form.append("start_date", value.start_date);
        }
        if (value.end_date) {
          form.append("end_date", value.end_date);
        }
        for (let image of images) {
          form.append("file_name", image);
        }
        await PutApiMethod(`property/notice-boards/${initialValue.id}`, form, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        await PutApiMethod(`property/notice-boards/${initialValue.id}`, newData);
      }

      dispatch(showLoader({ showLoader: false }));
      dispatch(
        showToaster({
          dialogBgColor: "success",
          dialogMsg: "Updated successfully",
          showDialog: true,
          timer: "5000",
        })
      );
      props.pageStatus("listNotice");
    } catch (error) {
      dispatch(showLoader({ showLoader: false }));
      dispatch(
        showToaster({
          dialogBgColor: "error",
          dialogMsg: "Failed to update",
          showDialog: true,
          timer: "5000",
        })
      );
      console.error("Update failed", error);
    }
  };
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: AddNoticeBoardSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      submit(values);
    },
  });

  const handleImageDelete = async (Imgid: any) => {
    dispatch(showLoader({ showLoader: true }));
    try {
      const res = await DeleteApiMethod(`property/file-manager/${Imgid}`);
      dispatch(showLoader({ showLoader: false }));
      dispatch(
        showToaster({
          dialogBgColor: "success",
          dialogMsg: "Image Deleted successfully",
          showDialog: true,
          timer: "5000",
        })
      );
      // Remove the image from imgArray
      setImgArray((prevImgArray: any) => prevImgArray.filter((file: any) => file.id !== Imgid));
    } catch (error) {
      dispatch(showLoader({ showLoader: false }));
      dispatch(
        showToaster({
          dialogBgColor: "error",
          dialogMsg: "Failed to delete image",
          showDialog: true,
          timer: "5000",
        })
      );
    }
  };
  const handleImageChange = (e:any) => {
    e.preventDefault();
    setImages(e.target.files);
  };
  const fetchProperty = async () => {
    let response = await PostApiMethod("property/properties/list", {});
    if (response["data"]["error"] === false) {
      setPropertyList(response.data.results.data);
    } else {
      console.log("response", response);
    }
  };
  const fetchUnit = async () => {
    let response = await PostApiMethod("property/unit/list", {});
    if (response["data"]["error"] === false) {
      setUnitList(response.data.results.data);
    } else {
      console.log("response", response);
    }
  };

  useEffect(() => {
    fetchProperty();
    fetchUnit();
  }, []);

  useEffect(() => {
    if (props.NoticeBoardData) {
      const newData = {
        ...props.NoticeBoardData,
        start_date: props.NoticeBoardData.start_date ? YearDateFormat(props.NoticeBoardData.start_date) : "",
        end_date: props.NoticeBoardData.end_date ? YearDateFormat(props.NoticeBoardData.end_date) : "",    
        property_all:props.NoticeBoardData.property_all == true ? "Yes" : "No",   
        unit_all:props.NoticeBoardData.unit_all == true ? "Yes" : "No",   
      };
      setInitialValue(newData);
      if(props.NoticeBoardData.FileManagers.length> 0 ){
        setImgArray(props.NoticeBoardData.FileManagers)
      }
    }
  }, [props.NoticeBoardData]);
  console.log(props.NoticeBoardData)


  return (
    <>
      <Box sx={{ mt: 3 }}>
        <form onSubmit={handleSubmit}>
        <FormControl className="mt-3">
          <Box className="card-title mb-3">
            <Typography variant="h5">
              Update Notice
            </Typography>
          </Box>
         
        </FormControl>
          <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
          <FormLabel className="poppins-family text-black">
             Property For All
          </FormLabel>
          <RadioGroup row aria-labelledby="responsibility" name="property_all" value={values.property_all} onChange={handleChange}>
            <FormControlLabel
              className="poppins-family"
              value="Yes"
              control={<Radio />}
              label="Yes"
            />
            <FormControlLabel
              className="poppins-family"
              value="No"
              control={<Radio />}
              label="No"
            />
          </RadioGroup>
          </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
          <FormLabel className="poppins-family text-black">
             Unit For All
          </FormLabel>
          <RadioGroup row aria-labelledby="responsibility" name="unit_all" value={values.unit_all} onChange={handleChange}>
            <FormControlLabel
              className="poppins-family"
              value="Yes"
              control={<Radio />}
              label="Yes"
            />
            <FormControlLabel
              className="poppins-family"
              value="No"
              control={<Radio />}
              label="No"
            />
          </RadioGroup>
          </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
            <FormLabel className="poppins-family text-black">Title</FormLabel>
              <TextField
                type="text"
                variant="standard"
                placeholder="Type here..."
                className="w-100"
                name="title"
                onChange={handleChange}
                value={values.title}
              />
                  {errors.title && touched.title && (
                  <Typography className="text-danger">{errors.title}</Typography>
                )}
                </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
            <FormLabel className="poppins-family text-black">
                Property
              </FormLabel>
              <FormControl variant="standard" className="w-100">
                 <Select
                    name="property_id"
                    value={values.property_id}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="">
                    None
                  </MenuItem>
                  {propertyList.map((data:any) => (
                    <MenuItem key={data.id} value={data.id}>
                      {data.property_name}
                    </MenuItem>
                  ))}
                </Select>
             {errors.property_id && touched.property_id && (
                  <Typography className="text-danger">{errors.property_id}</Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
            <FormLabel className="poppins-family text-black">
                Unit Name
              </FormLabel>
              <FormControl variant="standard" className="w-100">
                <Select
                    name="unit_id"
                    value={values.unit_id}
                    onChange={handleChange}
                    displayEmpty
                  >
                <MenuItem value="">
                    None
                  </MenuItem>
                {unitList.map((data:any) => (
                    <MenuItem key={data.id} value={data.id}>
                      {data.unit_name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.unit_id && touched.unit_id && (
                  <Typography className="text-danger">{errors.unit_id}</Typography>
                )}
              </FormControl>
            </Grid>
            
            <Grid item lg={4} md={4} sm={12} xs={12}>
            <FormLabel className="poppins-family text-black">
            Details
              </FormLabel>
              <TextField
                type="text"
                variant="standard"
                className="w-100"
                placeholder="Type here..."
                name="details"
                onChange={handleChange}
                value={values.details}
              />
                 {errors.details && touched.details && (
                  <Typography className="text-danger">
                    {errors.details}
                  </Typography>
                )}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
            <FormLabel className="poppins-family text-black">
            Start Date
            </FormLabel>
            <TextField
                className="w-100"
                variant="standard"
                name="start_date"
                placeholder="dd/mm/yyyy"
                type="date"
                value={values.start_date}
                onChange={handleChange}
                />
            {errors.start_date && touched.start_date && (
                <Typography className="text-danger">
                {errors.start_date}
                </Typography>
            )}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
            <FormLabel className="poppins-family text-black">
            End Date
            </FormLabel>
            <TextField
                className="w-100"
                variant="standard"
                name="end_date"
                placeholder="dd/mm/yyyy"
                type="date"
                value={values.end_date}
                onChange={handleChange}
                />
            {errors.end_date && touched.end_date && (
                <Typography className="text-danger">
                {errors.end_date}
                </Typography>
            )}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
            <input
              name="file_name"
              type="file" 
              onChange={(e:any) =>handleImageChange(e)}
              placeholder="Upload image"
              accept="image/*"
              multiple
              // required
            />
            </Grid>
            <Grid item lg={12} md={4} sm={12} xs={12}>
              {imgArray.length > 0 && (
                imgArray.map((file: any) => (
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                  <img 
                  height={"80%"}
                width={"50%"}
                    src={`${process.env.REACT_APP_API_URL}/${file.folderPath}`} 
                    alt="" 
                    key={file.id} 
                  />
                  <Button   onClick={() => {
                Swal.fire({
                  title: "Are you sure?",
                  text: "You are about to Delete this Image!",
                  icon: "error",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                }).then((result:any) => {
                  if (result.isConfirmed) {
                    handleImageDelete(file.id);
                  }
                });
              }}
             className="btn-danger">delete</Button>
                  </Grid>
                ))
              )}
            </Grid>
          </Grid> 
          <Box sx={{ mt: 4 }} className="d-flex">
            <Button
              className="btn-white"
              onClick={() => {
                props.pageStatus("listNotice");
              }}
            >
              <ChevronLeftIcon className="fs-20" />
              Back
            </Button>
            <Button
              className="btn-danger ms-2"
              type="submit"
              disabled={loader.showLoader}
            >
              Save
            </Button>
          </Box>
        </form>
      </Box>
       
    </>
  );
};

export default EditNoticeBoard;

import React, { useContext } from 'react';
import { Box, Typography, Grid, TextField, Radio, FormControlLabel, RadioGroup } from '@mui/material';
import { Formik, Form } from 'formik';
import { RentFormValidation } from './PropertyValidationSchema';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { PropertyContext } from './Main';
import { AddPropertyContext } from './AddProperty';
import { useAppDispatch } from '../../Store/hooks';
import { showLoader } from '../../Store/loaderSlice';
import { showToaster } from '../../Store/toastSlice';
import { PutApiMethod } from '../../Utils/ApiService';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const RentForm = () => {
    const addpropertyform = useContext(AddPropertyContext);
    const property = useContext(PropertyContext);
    const dispatch = useAppDispatch();

    const updateRent = async(formData,values) => {
        console.log("update")
        dispatch(showLoader({showLoader:true}))
        try{
            let res = await PutApiMethod("property/unit/updateRate",formData);
            if(res["data"]["error"] === false){
                dispatch(showToaster({dialogBgColor: "bg-success",
                    dialogMsg: res["data"]["message"],
                    showDialog: true,
                    timer: "3000"
                }));
                addpropertyform.formSubmit("Images",values);
                property.getUnitList(property.unitPage)
            }
        }catch(error){
            dispatch(showToaster({dialogBgColor: "bg-danger",
                dialogMsg: "Unit details not updated",
                showDialog: true,
                timer: "3000"
            }))
        }
        dispatch(showLoader({showLoader:false}))
    }

    const handleChangeRentType = (key,setFieldValue) => {
        setFieldValue(`rents[${key}].monthly_due_date`,'')
        setFieldValue(`rents[${key}].yearly_due_date`,'')
        setFieldValue(`rents[${key}].lease_start_date`,'')
        setFieldValue(`rents[${key}].lease_end_date`,'')
        setFieldValue(`rents[${key}].lease_payment_due_date`,'')
    }

    return (
        <>
            <Box className="mt-4 d-flex justify-content-between">
                <Box>
                    <h5 className='fw-bold'>{ property.isEdit ? <><ArrowBackIcon sx={{cursor:'pointer'}} onClick={property.onBackPropertyList}/>Edit</> : "Add New" } Property</h5>
                    <p sx={{color:'gray'}}>Rent & Charges</p>
                </Box>
            </Box>
            <Formik
                initialValues={{
                    rents: addpropertyform.data.rents || Array.from({length: addpropertyform.data.number_of_unit}, () => ({
                        unit_name:"",
                        general_rent:"",
                        security_deposit:"",
                        late_fee:"",
                        incident_receipt:"",
                        rent_type:"Monthly",
                        monthly_due_date:"",
                        yearly_due_date:"",
                        lease_start_date:"",
                        lease_end_date:"",
                        lease_payment_due_date:""
                    }))
                }}
                validationSchema={RentFormValidation}
                onSubmit={async(values) => {
                    console.log("rent",values)
                    let formData = new FormData();
                    values.rents.forEach((val,key) => {
                        formData.append(`data[${key}][unit_name]`,val["unit_name"])
                        formData.append(`data[${key}][general_rent]`,val["general_rent"])
                        formData.append(`data[${key}][security_deposit]`,val["security_deposit"])
                        formData.append(`data[${key}][late_fee]`,val["late_fee"])
                        formData.append(`data[${key}][incident_receipt]`,val["incident_receipt"])
                        formData.append(`data[${key}][rent_type]`,val["rent_type"])
                        if(val["rent_type"] === 'Monthly'){
                            formData.append(`data[${key}][monthly_due_date]`,val["monthly_due_date"])
                        }else if(val["rent_type"]=== 'Yearly'){
                            formData.append(`data[${key}][yearly_due_date]`,val["yearly_due_date"])
                        }else{
                            formData.append(`data[${key}][lease_start_date]`,val["lease_start_date"])
                            formData.append(`data[${key}][lease_end_date]`,val["lease_end_date"])
                            formData.append(`data[${key}][lease_payment_due_date]`,val["lease_payment_due_date"])
                        }
                    });

                    addpropertyform.data.unit_id.forEach((val,key) => {
                        formData.append(`data[${key}][id]`,val)
                    });
                    
                    updateRent(formData,values)
                }}
            >
                {({handleSubmit, handleChange, errors, values, touched, setFieldValue}) => (
                    <Form onSubmit={handleSubmit}>
                        {values.rents.map((val,key) =>  {
                            return(
                                <>
                                    { key !== 0 && <hr/> }
                                    <Grid container spacing={4} key={key} >
                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                            <Typography className="fw-bold">Unit Name</Typography>
                                            <TextField 
                                                className='form-control'
                                                name={`rents[${key}].unit_name`}
                                                type="text"
                                                value={val.unit_name}
                                                disabled
                                                variant="standard"
                                            />
                                            {touched.rents && touched.rents[key] && touched.rents[key].unit_name && errors.rents && errors.rents[key] && errors.rents[key].unit_name && (
                                                <div className='text-danger'>{errors.rents[key].unit_name}</div>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                            <Typography className="fw-bold">General Rent</Typography>
                                            <TextField 
                                                className='form-control'
                                                name={`rents[${key}].general_rent`}
                                                type="number"
                                                value={val.general_rent}
                                                variant='standard'
                                                placeholder="Type here..."
                                                onChange={handleChange}
                                            />
                                            {touched.rents && touched.rents[key] && touched.rents[key].general_rent && errors.rents && errors.rents[key] && errors.rents[key].general_rent && (
                                                <div className='text-danger'>{errors.rents[key].general_rent}</div>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                            <Typography className="fw-bold">Security Deposit</Typography>
                                            <TextField 
                                                className='form-control'
                                                name={`rents[${key}].security_deposit`}
                                                type="number"
                                                value={val.security_deposit}
                                                variant='standard'
                                                placeholder="Type here..."
                                                onChange={handleChange}
                                            />
                                            {touched.rents && touched.rents[key] && touched.rents[key].security_deposit && errors.rents && errors.rents[key] && errors.rents[key].security_deposit && (
                                                <div className='text-danger'>{errors.rents[key].security_deposit}</div>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                            <Typography className="fw-bold">Late Fee</Typography>
                                            <TextField 
                                                className='form-control'
                                                name={`rents[${key}].late_fee`}
                                                type="number"
                                                value={val.late_fee}
                                                variant='standard'
                                                placeholder="Type here..."
                                                onChange={handleChange}
                                            />
                                            {touched.rents && touched.rents[key] && touched.rents[key].late_fee && errors.rents && errors.rents[key] && errors.rents[key].late_fee && (
                                                <div className='text-danger'>{errors.rents[key].late_fee}</div>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                            <Typography className="fw-bold">Incident Receipt</Typography>
                                            <TextField 
                                                className='form-control'
                                                name={`rents[${key}].incident_receipt`}
                                                value={val.incident_receipt}
                                                variant='standard'
                                                placeholder="Type here..."
                                                onChange={handleChange}
                                            />
                                            {touched.rents && touched.rents[key] && touched.rents[key].incident_receipt && errors.rents && errors.rents[key] && errors.rents[key].incident_receipt && (
                                                <div className='text-danger'>{errors.rents[key].incident_receipt}</div>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <Typography className='fw-bold'>Rent Type</Typography>
                                            <RadioGroup defaultValue={val.rent_type} row  onChange={() => handleChangeRentType(key,setFieldValue)}>
                                                <FormControlLabel value="Monthly" control={<Radio name={`rents[${key}].rent_type`} onChange={handleChange} />} label="Monthly" />
                                                <FormControlLabel value="Yearly" control={<Radio name={`rents[${key}].rent_type`} onChange={handleChange}/>} label="Yearly" />
                                                <FormControlLabel value="Custom" control={<Radio name={`rents[${key}].rent_type`} onChange={handleChange}/>} label="Custom" />
                                            </RadioGroup>
                                            {touched.rents && touched.rents[key] && touched.rents[key].rent_type && errors.rents && errors.rents[key] && errors.rents[key].rent_type && (
                                                <div className='text-danger'>{errors.rents[key].rent_type}</div>
                                            )}
                                        </Grid>
                                        {val.rent_type === 'Monthly' && <Grid item xs={12} sm={12} md={4} lg={4}>
                                            <Typography className='fw-bold'>Due Date</Typography>
                                            <TextField 
                                                type="number"
                                                className='form-control'
                                                name={`rents[${key}].monthly_due_date`}
                                                value={val.monthly_due_date}
                                                variant='standard'
                                                onChange={handleChange}
                                                placeholder='Type date of month: 1 to 30'
                                            />
                                            {touched.rents && touched.rents[key] && touched.rents[key].monthly_due_date && errors.rents && errors.rents[key] && errors.rents[key].monthly_due_date && (
                                                <div className='text-danger'>{errors.rents[key].monthly_due_date}</div>
                                            )}
                                        </Grid>}
                                        {val.rent_type === 'Yearly' && <Grid item xs={12} sm={12} md={4} lg={4}>
                                            <Typography className='fw-bold'>Due Month</Typography>
                                            <TextField 
                                                type="number"
                                                className='form-control'
                                                name={`rents[${key}].yearly_due_date`}
                                                value={val.yearly_due_date}
                                                variant='standard'
                                                onChange={handleChange}
                                                placeholder='Type month of year: 1 to 12'
                                            />
                                            {touched.rents && touched.rents[key] && touched.rents[key].yearly_due_date && errors.rents && errors.rents[key] && errors.rents[key].yearly_due_date && (
                                                <div className='text-danger'>{errors.rents[key].yearly_due_date}</div>
                                            )}
                                        </Grid>}
                                        {val.rent_type === 'Custom' && <>
                                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                                <Typography className='fw-bold'>Lease start date</Typography>
                                                <TextField 
                                                    type="date"
                                                    className="form-control"
                                                    name={`rents[${key}].lease_start_date`}
                                                    value={val.lease_start_date}
                                                    onChange={handleChange}
                                                    variant='standard'
                                                />
                                                {touched.rents && touched.rents[key] && touched.rents[key].lease_start_date && errors.rents && errors.rents[key] && errors.rents[key].lease_start_date && (
                                                    <div className='text-danger'>{errors.rents[key].lease_start_date}</div>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                                <Typography className='fw-bold'>Lease end date</Typography>
                                                <TextField 
                                                    type="date"
                                                    className="form-control"
                                                    name={`rents[${key}].lease_end_date`}
                                                    value={val.lease_end_date}
                                                    onChange={handleChange}
                                                    variant='standard'
                                                />
                                                {touched.rents && touched.rents[key] && touched.rents[key].lease_end_date && errors.rents && errors.rents[key] && errors.rents[key].lease_end_date && (
                                                    <div className='text-danger'>{errors.rents[key].lease_end_date}</div>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                                <Typography className='fw-bold'>Payment due on date</Typography>
                                                <TextField 
                                                    type="date"
                                                    className="form-control"
                                                    name={`rents[${key}].lease_payment_due_date`}
                                                    value={val.lease_payment_due_date}
                                                    onChange={handleChange}
                                                    variant='standard'
                                                />
                                                {touched.rents && touched.rents[key] && touched.rents[key].lease_payment_due_date && errors.rents && errors.rents[key] && errors.rents[key].lease_payment_due_date && (
                                                    <div className='text-danger'>{errors.rents[key].lease_payment_due_date}</div>
                                                )}
                                            </Grid>
                                        </>}
                                    </Grid>
                                    
                                </>
                            )
                        })}
                        <Box className="mt-4">
                            <button type="button" className='btn btn-white' onClick={() => addpropertyform.onBackForm("Unit Form")}><ArrowBackIosIcon style={{fontSize:'18px'}}/>Back</button>
                            <button type="submit" className='btn btn-danger ms-2'>Save & Proceed Next</button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default RentForm;
import React, { useCallback, useEffect, useState } from "react";
import styles from "./updateForms.module.css";
import { AuthorizedLayout } from "../Layouts/AuthorizedLayout";
import { Box, CardContent, Typography } from "@mui/material";
import propertyImg from "../../Assets/images/icons/property.png";
import { useNavigate, useParams } from "react-router-dom";
import PropertyInformationForm from "./addNewProperty/forms/propertyInformation";
import { useAppDispatch } from "../../Store/hooks";
import { showLoader } from "../../Store/loaderSlice";
import { GetApiMethod } from "../../Utils/ApiService";
import { currentPageList } from "./addNewProperty/newProperty1";
import UnitForm from "./addNewProperty/forms/unit";
import LocationForm from "./addNewProperty/forms/location";
import RentAndChargesForm from "./addNewProperty/forms/rendAndCharges";
import ImageForm from "./addNewProperty/forms/image";
import Card from "@mui/material/Card";

export default function UpdateForms() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [propertDetails, setPropertyDetails] = useState();
  const [CurrentAddPropertySection, setCurrentAddPropertySection] = useState(
    currentPageList[0]
  );
  const [CreatedPropertyIdFromApi, setCreatedPropertyIdFromApi] = useState("");
  const [CreatedUserIdFromApi, setCreatedUserIdFromApi] = useState("");
  const [CreatedCountryIdFromApi, setCreatedCountryIdFromApi] = useState("");
  const [CreatedUnitDetailsFromApi, setCreatedUnitDetailsFromApi] = useState();
  const [CreatedNo_of_unitsFromApi, setCreatedNo_of_unitsFromApi] =
    useState("");
  const navigate = useNavigate();

  const getPropertyDetails = useCallback(() => {
    dispatch(showLoader({ showLoader: true }));
    GetApiMethod("/property/properties/" + id)
      .then((res) => {
        dispatch(showLoader({ showLoader: false }));
        setPropertyDetails(res.data.results.data);
      })
      .catch((e) => {
        dispatch(showLoader({ showLoader: false }));
      });
  }, [dispatch]);

  function backBtnFunc(val:any) {
    if(val === 'Home'){
      navigate("/property");
    }else{
      setCurrentAddPropertySection(val)
    }
  }

  useEffect(() => {
    getPropertyDetails();
  }, []);

  function formChange(currentComponent: any) {
    console.log(currentComponent)
    currentComponent.toString() === "Property Information"
      ? setCurrentAddPropertySection(currentPageList[1])
      : currentComponent.toString() === "Location"
      ? navigate("/property")
      : currentComponent.toString() === "Unit"
      ? navigate("/property")
      : currentComponent.toString() === "Rent & Charges"
      ? setCurrentAddPropertySection(currentPageList[4])
      : setCurrentAddPropertySection("");
  }

  function submitBtnForProperyInformation(
    currentComponent: any,
    propertyId: string,
    propertyUserId: string,
    propertyCountryId: string,
    No_of_unitsFromApi: string
  ) {
    
    setCreatedPropertyIdFromApi(propertyId);
    setCreatedUserIdFromApi(propertyUserId);
    setCreatedCountryIdFromApi(propertyCountryId);
    setCreatedNo_of_unitsFromApi(No_of_unitsFromApi);
    formChange(currentComponent);
  }

  function submitBtnForUnitForm(currentComponent: any, unitId: any) {
    setCreatedUnitDetailsFromApi(unitId);
    formChange(currentComponent);
  }
  function submitBtmForRentAndCharges(currentComponent: string) {
    formChange(currentComponent);
  }

  function submitBtnFunc(currentComponent: any, propertyId: string) {}

  return (
    <>
      <AuthorizedLayout>
        <Card
          variant="outlined"
          sx={{ borderRadius: "8px" }}
          className="border-0"
          style={{ height: "90vh", overflowY: "auto" }}
        >
          {/* <CardContent>
                    <Box className="d-flex justify-content-between align-items-center">
                      <Box className="d-flex align-items-center">
                        <img
                          src={propertyImg}
                          alt="Property"
                          width={20}
                          className={styles.smallImg}
                        />
                        <Typography className="poppins-family fw-bold">
                          Property this
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent> */}
          <div className={styles.formBox}>
            {CurrentAddPropertySection === "Property Information" ? (
              <PropertyInformationForm
                backBtn={backBtnFunc}
                submitBtn={submitBtnForProperyInformation}
                forUpdatePropertyValues={propertDetails}
                isForUpdate={true}
              />
            ) : (
              <div>
                <LocationForm
                  backBtn={backBtnFunc}
                  submitBtn={formChange}
                  CreatedPropertyDetailsFromApi={CreatedPropertyIdFromApi}
                  CreatedCountryIdFromApi={CreatedCountryIdFromApi}
                  forUpdatePropertyValues={propertDetails}
                  isForUpdate={true}
                />
              </div>
            )}
          </div>
        </Card>
      </AuthorizedLayout>
    </>
  );
}

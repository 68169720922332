import { useState, useCallback, useEffect } from "react";
import ApiSearch from "./ApiSearch";
import { UnitInterfaceData } from "../../Dto/unit";
import { errorHandling } from "../../Utils/HelperService";
import { PostApiMethod } from "../../Utils/ApiService";
import { string } from "yup";

interface UnitSearchComponent {
  onChange: (value: UnitInterfaceData) => void;
  disableClearable?: boolean;
  propertyId    : string;
  userId: string;
}

const UnitSearchComponent = (props: UnitSearchComponent) => {
  const [unitList, setUnitList] = useState<UnitInterfaceData[]>([]);
  const [unitValue, setUnitValue] = useState(null);
  const [unitSearchLoader, setUnitSearchLoader] = useState(false);

  const searchUnit = useCallback((value: string) => {
    setUnitSearchLoader(true);  
    PostApiMethod("property/unit/getUnitByPropertyId", {
      searchString: value,
      page: "1",
      limit: "20",
      property_id: props.propertyId,
      user_id: props.userId,
    })
      .then((res) => {
        setUnitSearchLoader(false);
        setUnitList(res.data.results.data.rows);
        setUnitValue(null);
      })
      .catch((e) => {
        const error = errorHandling(e);
        setUnitSearchLoader(false);
        setUnitList([]);
      });
  }, [props]);
   console.log(unitList,"unitList")
  useEffect(() => {
    searchUnit("");
    }, [props.propertyId]);
    console.log(unitValue)

  return (
    <>
      <ApiSearch
        value={unitValue}
        onChange={(newValue: any) => {
          setUnitValue(newValue);
          props.onChange(newValue);
        }}
        disableClearable={
          props.disableClearable ? props.disableClearable : false
        }
        loading={unitSearchLoader}
        options={unitList}
        searchApi={searchUnit}
        isOptionEqualToValue={(option, value) =>
          option.unit_name === value.unit_name
        }
        getOptionLabel={(option: UnitInterfaceData) => option.unit_name}
      />
    </>
  );
};

export default UnitSearchComponent;

import { Grid, TableContainer, Table, TableBody, TableRow, TableCell, TableHead } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { TextInput } from '../../../Components/Text/TextInput';
import { Search } from '@mui/icons-material';
import { AssetContext } from '../AssetManagement';
import { useAppDispatch } from '../../../Store/hooks';
import { getPetrolComponent, deletePetrolComponent } from './MastersAPI';
import MenuGroup from '../../../Components/DropDown/MenuGroup';
import { Component } from './AssetMasters';
import NoData from '../../../Components/Pagination/NoData';
import Pagination from '../../../Components/Pagination/Pagination';

const ComponentTable = () => {
    const asset = useContext(AssetContext);
    const dispatch = useAppDispatch();

    const { masterData, component, setComponent, mastersSearch, setMastersSearch } = asset;

    useEffect(() => {
        getPetrolComponent(dispatch,setComponent,component,component?.page);
    },[])

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextInput 
                        label={"Search"}
                        name="search"
                        value={mastersSearch?.component}
                        onChange={(e) => {
                            setMastersSearch(e.target.value)
                            getPetrolComponent(dispatch,setComponent,{...component,page:{page:1,limit:5},searchString:e.target.value})}
                        }
                        startadornment={true}
                        starticon={<Search />}
                    />
                </Grid>
            </Grid>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {component?.data?.map((val,key) => (
                            <TableRow key={key}>
                                <TableCell>{val?.name}</TableCell>
                                <TableCell align='right'>
                                    <MenuGroup 
                                        id={val?.id}
                                        edit={true}
                                        delete={true}
                                        clickEdit={() => setComponent({...component,edit:true,modal:true,...val})}
                                        clickDelete={async() => {
                                            await deletePetrolComponent({
                                                dispatch,
                                                componentId:val?.id,
                                                name:val?.name
                                            });
                                            await getPetrolComponent(dispatch,setComponent,{...component,page:{page:1,limit:5,count:0}});
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                        {component?.data?.length === 0 && <NoData colSpan={2} />}
                        <Pagination
                            pagination={component?.page} 
                            rowsPerPage={5} 
                            rowsPerPageChange={(val) => {
                                getPetrolComponent(dispatch,setComponent,{...component,...{page:val},searchString:mastersSearch?.location})}
                            }
                        />
                    </TableBody>
                </Table>
            </TableContainer>
            <Component 
                open={component?.modal} 
                onClose={() => setComponent({...component,...{modal:false}})}
                equipment={masterData.equipment}
                header={`${component?.edit ? "Edit" : "Create"} Component`}
                name={component?.name}
                edit={component?.edit}
                equipmentId={component?.equipmentId} 
                componentId={component?.id}
                equipmentName={component?.Equipment?.name}
                getComponent={() => getPetrolComponent(dispatch,setComponent,{...component,page:{page:1,limit:5,count:0}})}
            />
        </>
    )
}

export default ComponentTable
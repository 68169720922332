import React from 'react';
import { MoneyCard } from './TrainingComponents';

const TrainingDashboard = () => {
    return (
        <>
            <MoneyCard />
        </>
    )
}

export default TrainingDashboard
import React, { useEffect } from "react";
import "./Toaster.css";
import { hideToaster } from "../../Store/toastSlice";
import { useAppSelector, useAppDispatch } from "../../Store/hooks";

export default function Toaster() {
    let toastState = useAppSelector((state) => state.toast);
    const dispatch = useAppDispatch();
    const onDismissToast = () => {
        dispatch(hideToaster());
    };

    useEffect(() => {
        if (toastState.timer) {
            setTimeout(() => {
                onDismissToast();
            }, +toastState.timer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toastState]);

    return toastState.showDialog ? (
        <div
            className={
                "toast toaster-class align-items-center text-bg-primary border-0 fade show " +
                toastState.dialogBgColor
            }
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
        >
            <div className="d-flex">
                <div className="toast-body">{toastState.dialogMsg}</div>
                <button
                    type="button"
                    className="btn-close btn-close-white me-2 m-auto"
                    aria-label="Close"
                    onClick={() => {
                        onDismissToast();
                    }}
                ></button>
            </div>
        </div>
    ) : (
        <></>
    );
}
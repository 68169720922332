import { Search } from '@mui/icons-material'
import { Box, Grid, Input, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useCallback, useContext } from 'react'
import { TextInput } from '../../Components/Text/TextInput'
import { AssetContext } from './AssetManagement'
import NoData from '../../Components/Pagination/NoData'
import MenuGroup from '../../Components/DropDown/MenuGroup'
import Pagination from '../../Components/Pagination/Pagination'
import { getAssets } from './Masters/MastersAPI'
import { useAppDispatch } from '../../Store/hooks'
import { PermissionCheck } from '../../App/PermissionCheck'

const AllAssets = () => {
    const assets = useContext(AssetContext);
    const dispatch = useAppDispatch();
    const { assetsData, setAssetsData, assetsSearch, setAssetsSearch } = assets;
    const editPermission = PermissionCheck("pb_assets_edit");
    const viewPermission = PermissionCheck("pb_assets_view");
    return (
        <>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3} lg={3} >
                        <TextInput 
                            label={"Search"}
                            name="search"
                            startadornment={true}
                            starticon={<Search />}
                            onChange={(e) => {
                                setAssetsSearch(e.target.value)
                                getAssets(dispatch,{...assetsData,page:{page:1,limit:5}},setAssetsData,e.target.value)
                            }}
                        />
                    </Grid>
                </Grid>
                {viewPermission && <TableContainer className='mt-1'>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Asset Name</TableCell>
                                <TableCell>Asset Model No</TableCell>
                                <TableCell>Asset ID</TableCell>
                                <TableCell>Checklist</TableCell>
                                <TableCell>Category</TableCell>
                                <TableCell align="right">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {assetsData?.data?.map((val,key) => (
                                <TableRow key={key}>
                                    <TableCell>{val?.name}</TableCell>
                                    <TableCell>{val?.model}</TableCell>
                                    <TableCell>{val?.asset_id}</TableCell>
                                    <TableCell>{val?.checkList}</TableCell>
                                    <TableCell>{val?.HDCategory?.name}</TableCell>
                                    <TableCell align="right">
                                        <MenuGroup 
                                            id={val?.id}
                                            edit={editPermission}
                                            clickEdit={() => setAssetsData({...assetsData,open:true,edit:true,...val})}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                            {assetsData?.data?.length === 0 && <NoData colSpan={6} />}
                            <Pagination 
                                pagination={assetsData?.page}
                                rowsPerPageChange={(val) => {
                                    getAssets(dispatch,{...assetsData,...{page:val}},setAssetsData)}
                                }
                            />
                        </TableBody>
                    </Table>
                </TableContainer>}
            </Box>
        </>
    )
}

export default AllAssets
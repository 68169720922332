import * as Yup from 'yup';

export const AddNewTrainingSchema = Yup.object().shape({
    training_certificate:Yup.string().required("Training certificate is required"),
    cost:Yup.string().required("Cost field is required"),
    employee_name:Yup.string().required("Employee name field is required"),
    employee_surname:Yup.string().required("Employee surname field is required"),
    date_conducted:Yup.string().required("Date conducte field is required"),
    date_expiring:Yup.string().required("Date expiring field is required"),
    note:Yup.string().required("Note field is required"),
    upload_file:Yup.string().required("Upload file field is required")
});

export const AddNewTargetSchema = Yup.object().shape({
    from_date:Yup.string().required("From date field is required"),
    to_date:Yup.string().required("To date field is required"),
    training_certificate:Yup.string().required("Training certificate field is required"),
    budget:Yup.string().required("Budget field is required")
})
import { showLoader } from "../../../Store/loaderSlice";
import { showToaster } from "../../../Store/toastSlice";
import { DeleteApiMethod, PostApiMethod, PutApiMethod } from "../../../Utils/ApiService";

//without pagination
export const getCategory = async(dispatch,previous,state) => {
    dispatch(showLoader({ showLoader: true }))
    try {
        let res = await PostApiMethod("petrol-bunk/category/getHpCategory");
        if (!res?.data?.error) {
            state({...previous,category:res?.data?.results?.data})
        }
    } catch (error) {
        dispatch(showToaster({
            dialogBgColor: 'bg-success',
            dialogMsg: error.response ? error.response.data.message : `Failed to get category`,
            showDialog: true,
            timer: "3000"
        }))
    }
    dispatch(showLoader({ showLoader: false }))
}

//without pagination
export const getEquipment = async(dispatch,previous,state) => {
    dispatch(showLoader({ showLoader: true }))
    try {
        let res = await PostApiMethod("petrol-bunk/equipment/getEquipment");
        if (!res?.data?.error) {
            state({...previous,equipment:res?.data?.results?.data})
        }
    } catch (error) {
        dispatch(showToaster({
            dialogBgColor: 'bg-success',
            dialogMsg: error.response ? error.response.data.message : `Failed to get category`,
            showDialog: true,
            timer: "3000"
        }))
    }
    dispatch(showLoader({ showLoader: false }))
}

//with pagination
export const getPetrolCategory = async(dispatch, setData, data) => {
    dispatch(showLoader({showLoader:true}));

    try {
        let res = await PostApiMethod("petrol-bunk/category/getHpCategory",{
            page:data?.page.page,
            limit:data?.page.limit === -1 ? 0 : data?.page.limit,
            searchString:data?.searchString
        });
        if(!res?.data?.error){
            setData((prev) => ({
                ...prev,
                modal:false,
                edit:false,
                data:res?.data?.results?.data,
                page:{
                    page:data?.page.page,
                    limit:data?.page.limit,
                    count:res?.data?.results?.totalCount
                }
            }));
        }
    } catch (error) {
        dispatch(showToaster({
            dialogBgColor: "bg-danger",
            dialogMsg: error.response ? error.response.data.message : "Failed to fetch category",
            showDialog: true,
            timer: "3000"
        }))
    }
    dispatch(showLoader({showLoader:false}))
}

export const getPetrolLocation = async(dispatch, setData, data) => {
    dispatch(showLoader({showLoader:true}));
    try {
        let res = await PostApiMethod("petrol-bunk/location/getLocation",{
            page:data?.page.page,
            limit:data?.page.limit === -1 ? 0 : data?.page.limit,
            searchString:data?.searchString
        });
        if(!res?.data?.error){
            setData({
                ...data,
                modal:false,
                edit:false,
                data:res?.data?.results?.data,
                page:{
                    page:data?.page.page,
                    limit:data?.page.limit,
                    count:res?.data?.results?.totalCount
                }
            });
        }
    } catch (error) {
        dispatch(showToaster({
            dialogBgColor: "bg-danger",
            dialogMsg: error.response ? error.response.data.message : "Failed to fetch location",
            showDialog: true,
            timer: "3000"
        }))
    }
    dispatch(showLoader({showLoader:false}))
}

export const getPetrolEquipment = async(dispatch, setData, data) => {
    dispatch(showLoader({showLoader:true}))
    try {
        let res = await PostApiMethod("petrol-bunk/equipment/getEquipment",{
            page:data?.page.page,
            limit:data?.page.limit === -1 ? 0 : data?.page.limit,
            searchString:data?.searchString
        });
        if(!res?.data?.error){
            setData({
                ...data,
                modal:false,
                edit:false,
                data:res?.data?.results?.data,
                searchString:data?.searchString,
                page:{
                    page:data?.page.page,
                    limit:data?.page.limit,
                    count:res?.data?.results?.totalCount
                }
            });
        }
    } catch (error) {
        dispatch(showToaster({
            dialogBgColor: "bg-danger",
            dialogMsg: error.response ? error.response.data.message : "Failed to fetch equipment",
            showDialog: true,
            timer: "3000"
        }))
    }
    dispatch(showLoader({showLoader:false}))
}

export const getPetrolComponent = async(dispatch, setData, data) => {
    dispatch(showLoader({showLoader:true}))
    try {
        let res = await PostApiMethod("petrol-bunk/component/getComponent",{
            page:data?.page.page,
            limit:data?.page.limit === -1 ? 0 : data?.page.limit,
            searchString:data?.searchString
        });
        if(!res?.data?.error){
            setData({
                ...data,
                data:res?.data?.results?.data,
                edit:false,
                modal:false,
                searchString:data?.searchString,
                page:{
                    page:data?.page.page,
                    limit:data?.page.limit,
                    count:res?.data?.results?.totalCount
                }
            });
        }
    } catch (error) {
        dispatch(showToaster({
            dialogBgColor: "bg-danger",
            dialogMsg: error.response ? error.response.data.message : "Failed to fetch component",
            showDialog: true,
            timer: "3000"
        }))
    }
    dispatch(showLoader({showLoader:false}))
}

//create update api
export const createPetrolCategory = async(props) => {
    const { dispatch } = props;
    dispatch(showLoader({showLoader:true}));
    try{
        let res = await PostApiMethod('petrol-bunk/category/add',{...props?.values});
        if(!res?.data?.error){
            props?.onClose();
            props?.getCategory && getCategory() ;
            dispatch(showToaster({
                dialogBgColor:'bg-success',
                dialogMsg: res?.data?.message,
                showDialog: true,
                timer: "3000"
            }))
        }
    }catch(error){
        dispatch(showToaster({
            dialogBgColor:'bg-danger',
            dialogMsg: error.response ? error.response.data.message : `Failed to create category`,
            showDialog: true,
            timer: "3000"
        }))
    }
    dispatch(showLoader({showLoader:false}))
}

export const updatePetrolCategory = async(props) => {
    const { dispatch } = props;
    dispatch(showLoader({showLoader:true}));
    try{
        let res = await PutApiMethod(`petrol-bunk/category/${props?.categoryId}`,{...props?.values});
        if(!res?.data?.error){
            props?.onClose();
            props?.getCategory();
            dispatch(showToaster({
                dialogBgColor:'bg-success',
                dialogMsg: res?.data?.message,
                showDialog: true,
                timer: "3000"
            }))
        }
    }catch(error){
        dispatch(showToaster({
            dialogBgColor:'bg-danger',
            dialogMsg: error.response ? error.response.data.message : `Failed to update category`,
            showDialog: true,
            timer: "3000"
        }))
    }
    dispatch(showLoader({showLoader:false}));
}

export const deletePetrolCategory = async(props) => {
    const { dispatch } = props;
    dispatch(showLoader({showLoader:true}))
    try{
        await DeleteApiMethod(`petrol-bunk/category/${props?.categoryId}`);
        dispatch(showToaster({
            dialogBgColor:'bg-success',
            dialogMsg: `${props?.name} has been deleted`,
            showDialog: true,
            timer: "3000"
        }))
    }catch(error){
        dispatch(showToaster({
            dialogBgColor:'bg-danger',
            dialogMsg: error.response ? error.response.data.message : `Failed to delete ${props?.name}`,
            showDialog: true,
            timer: "3000"
        }))
    }
    dispatch(showLoader({showLoader:false}))
} 

export const createPetrolLocation = async(props) => {
    const { dispatch } = props;
    dispatch(showLoader({showLoader:true}))
    try{
        let res = await PostApiMethod("petrol-bunk/location/add",{...props?.values});
        if(!res?.data?.error){
            props?.onClose();
            // props?.getLocation();
            dispatch(showToaster({
                dialogBgColor: 'bg-success',
                dialogMsg: res?.data?.message,
                showDialog: true,
                timer: "3000"
            }))
        }
    }catch(error){
        dispatch(showToaster({
            dialogBgColor: 'bg-danger',
            dialogMsg: error.response ? error.response.data.message : `Failed to create location`,
            showDialog: true,
            timer: "3000"
        }))
    }
    dispatch(showLoader({showLoader:false}))
}

export const updatePetrolLocation = async(props) => {
    const { dispatch } = props;
    dispatch(showLoader({showLoader:true}))
    try{
        let res = await PutApiMethod(`petrol-bunk/location/${props?.locationId}`,{...props?.values});
        if(!res?.data?.error){
            props?.onClose();
            props?.getLocation();
            dispatch(showToaster({
                dialogBgColor: 'bg-success',
                dialogMsg: res?.data?.message,
                showDialog: true,
                timer: "3000"
            }))
        }
    }catch(error){
        dispatch(showToaster({
            dialogBgColor: 'bg-danger',
            dialogMsg: error.response ? error.response.data.message : `Failed to update location`,
            showDialog: true,
            timer: "3000"
        }))
    }
    dispatch(showLoader({showLoader:false}))
}

export const deletePetrolLocation = async(props) => {
    const { dispatch } = props;
    dispatch(showLoader({showLoader:true}))
    try{
        await DeleteApiMethod(`petrol-bunk/location/${props?.locationId}`);
        dispatch(showToaster({
            dialogBgColor:'bg-success',
            dialogMsg: `${props?.name} has been deleted`,
            showDialog: true,
            timer: "3000"
        }))
    }catch(error){
        dispatch(showToaster({
            dialogBgColor:'bg-danger',
            dialogMsg: error.response ? error.response.data.message : `Failed to delete ${props?.name}`,
            showDialog: true,
            timer: "3000"
        }))
    }
    dispatch(showLoader({showLoader:false}))
} 

export const createPetrolEquipment = async(props) => {
    const { dispatch } = props;
    dispatch(showLoader({showLoader:true}))
    try{
        let res = await PostApiMethod("petrol-bunk/equipment/add",{...props.values});
        if(!res?.data?.error){
            props.onClose();
            props.getEquipment();
            dispatch(showToaster({
                dialogBgColor: 'bg-success',
                dialogMsg: res?.data?.message,
                showDialog: true,
                timer: "3000"
            }))
        }
    }catch(error){
        dispatch(showToaster({
            dialogBgColor: 'bg-success',
            dialogMsg: error.response ? error.response.data.message : `Failed to create equipment`,
            showDialog: true,
            timer: "3000"
        }))
    }
    dispatch(showLoader({showLoader:false}))
}

export const updtePetrolEquipment = async(props) => {
    const { dispatch } = props;
    dispatch(showLoader({showLoader:true}))
    try{
        let res = await PutApiMethod(`petrol-bunk/equipment/${props?.equipmentId}`,{...props.values});
        if(!res?.data?.error){
            props.onClose();
            props.getEquipment();
            dispatch(showToaster({
                dialogBgColor: 'bg-success',
                dialogMsg: res?.data?.message,
                showDialog: true,
                timer: "3000"
            }))
        }
    }catch(error){
        dispatch(showToaster({
            dialogBgColor: 'bg-success',
            dialogMsg: error.response ? error.response.data.message : `Failed to update equipment`,
            showDialog: true,
            timer: "3000"
        }))
    }
    dispatch(showLoader({showLoader:false}))
}

export const deletePetrolEquipment = async(props) => {
    const { dispatch } = props;
    dispatch(showLoader({showLoader:true}))
    try{
        await DeleteApiMethod(`petrol-bunk/equipment/${props?.equipmentId}`);
        dispatch(showToaster({
            dialogBgColor:'bg-success',
            dialogMsg: `${props?.name} has been deleted`,
            showDialog: true,
            timer: "3000"
        }))
    }catch(error){
        dispatch(showToaster({
            dialogBgColor:'bg-danger',
            dialogMsg: error.response ? error.response.data.message : `Failed to delete ${props?.name}`,
            showDialog: true,
            timer: "3000"
        }))
    }
    dispatch(showLoader({showLoader:false}))
}

export const createPetrolComponent = async(props) => {
    const { dispatch } = props;
    dispatch(showLoader({showLoader:true}))
    try{
        let res = await PostApiMethod("petrol-bunk/component/add",{...props?.values});
        if(!res?.data?.error){
            props?.onClose();
            props?.getComponent();
            dispatch(showToaster({
                dialogBgColor: 'bg-success',
                dialogMsg: res?.data?.message,
                showDialog: true,
                timer: "3000"
            }))
        }
    }catch(error){
        dispatch(showToaster({
            dialogBgColor: 'bg-success',
            dialogMsg: error.response ? error.response.data.message : `Failed to create component`,
            showDialog: true,
            timer: "3000"
        }))
    }
    dispatch(showLoader({showLoader:false}))
}

export const updatePetrolComponent = async(props) => {
    const { dispatch } = props;
    dispatch(showLoader({showLoader:true}));
    try{
        let res = await PutApiMethod(`petrol-bunk/component/${props?.componentId}`,{...props?.values});
        if(!res?.data?.error){
            props?.onClose();
            props?.getComponent();
            dispatch(showToaster({
                dialogBgColor: 'bg-success',
                dialogMsg: res?.data?.message,
                showDialog: true,
                timer: "3000"
            }))
        }
    }catch(error){
        dispatch(showToaster({
            dialogBgColor: 'bg-success',
            dialogMsg: error.response ? error.response.data.message : `Failed to create component`,
            showDialog: true,
            timer: "3000"
        }))
    }
    dispatch(showLoader({showLoader:false}))
}

export const deletePetrolComponent = async(props) => {
    const { dispatch } = props;
    dispatch(showLoader({showLoader:true}))
    try{
        await DeleteApiMethod(`petrol-bunk/component/${props?.componentId}`);
        dispatch(showToaster({
            dialogBgColor:'bg-success',
            dialogMsg: `${props?.name} has been deleted`,
            showDialog: true,
            timer: "3000"
        }))
    }catch(error){
        dispatch(showToaster({
            dialogBgColor:'bg-danger',
            dialogMsg: error.response ? error.response.data.message : `Failed to delete ${props?.name}`,
            showDialog: true,
            timer: "3000"
        }))
    }
    dispatch(showLoader({showLoader:false}))
}

export const getAssets = async(dispatch,data,setData,search) => {
    dispatch(showLoader({showLoader:true}))
    try{
        let res = await PostApiMethod(`petrol-bunk/pb-asset/getAssetList`,{
            page:data?.page?.page,
            limit:data?.page?.limit,
            searchString:search
        });
        if(!res?.data?.error){
            setData({
                ...data,
                open: false,
                data:res?.data?.results?.data,
                page:{
                    page:data?.page?.page,
                    limit:data?.page?.limit,
                    count:res?.data?.results?.totalCount
                }
            })
        }
        
    }catch(error){
        dispatch(showToaster({
            dialogBgColor:'bg-danger',
            dialogMsg: error.response ? error.response.data.message : `Failed to get assets`,
            showDialog: true,
            timer: "3000"
        }))
    }
    dispatch(showLoader({showLoader:false}))
}
import React, { useCallback, useContext, useEffect } from 'react';
import { useAppDispatch } from '../../Store/hooks';
import { DeleteApiMethod, PostApiMethod, PutApiMethod } from '../../Utils/ApiService';
import { showLoader } from '../../Store/loaderSlice';
import { showToaster } from '../../Store/toastSlice';
import { Modal, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, FormLabel, Grid, TextField, TableFooter, Box, Typography, MenuItem } from '@mui/material';
import { UserContext } from './UserManagement';
import Pagination from '../../Components/Pagination/Pagination';
import MenuGroup from '../../Components/DropDown/MenuGroup';
import { CustomModal, ModalStyle } from '../../Components/ModalStyle';
import { roleSchema } from './AddUserSchema';
import { Form, Formik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { PermissionCheck } from '../../App/PermissionCheck';

const RoleTable = () => {
    const user = useContext(UserContext);
    const { role, setRole } = user;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        getRoles(role?.page);
    },[]);

    const getRoles = useCallback(async(params) => {
        dispatch(showLoader({showLoader:true}));
        var parameters = params?.limit === -1 ? {} : {page:params?.page,limit:params?.limit};
        setRole({...role,searchString:params.searchString})
        try {
            let res = await PostApiMethod('role/getRole',{...parameters,searchString:params?.searchString});
            if(res['data']['error'] === false){
                setRole({
                    ...role,
                    modal:false,
                    data:res['data']['results']['data'],
                    page:{page:params?.page, limit:params?.limit, count:res['data']['results']['totalCount'], }
                })
            }
        } catch (error) {
            setRole({
                ...role,
                data:[]
            })
            dispatch(showToaster({dialogBgColor: "bg-danger",
                dialogMsg: error.response ? error.response.data.message : "Failed to get roles",
                showDialog: true,
                timer: "3000"
            }))
        }
        dispatch(showLoader({showLoader:false}))
    },[]);

    const createRole = useCallback(async(values,resetForm) => {
        try{
            let res = await PostApiMethod("role/add",values);
            if(!res?.data?.error){
                dispatch(showToaster({
                    dialogBgColor: "bg-success",
                    dialogMsg: res?.data?.message,
                    showDialog: true,
                    timer: "3000"
                }));
                getRoles(role?.page);
            }
        }catch(error){
            dispatch(showToaster({dialogBgColor: "bg-danger",
                dialogMsg: error.response ? error.response.data.message : "Failed to create role",
                showDialog: true,
                timer: "3000"
            }))
        }
    },[]);

    const updateRole = useCallback(async(values, roleId) => {
        dispatch(showLoader({showLoader:true}));
        try {
            let res = await PutApiMethod(`role/${roleId.id}`,values);
            if(!res?.data?.error){
                dispatch(showToaster({
                    dialogBgColor:'bg-success',
                    dialogMsg:res?.data?.message,
                    showDialog:true,
                    timer:"3000"
                }));
                getRoles(role?.page)
            }
        } catch (error) {
            dispatch(showToaster({dialogBgColor: "bg-danger",
                dialogMsg: error.response ? error.response.data.message : "Failed to update role",
                showDialog: true,
                timer: "3000"
            }))
        }
        dispatch(showLoader({showLoader:false}));
    },[]);

    const deleteRole = useCallback(async(value) => {
        try {
            await DeleteApiMethod(`role/${value?.id}`);
            dispatch(showToaster({
                dialogBgColor:'bg-success',
                dialogMsg:`${value?.name} has been deleted`,
                showDialog:true,
                timer:'3000'
            }));
            getRoles(role?.page);
        } catch (error) {
            dispatch(showToaster({
                dialogBgColor:'bg-danger',
                dialogMsg: error.response ? error.response.data.message : `${value?.role_name} can't be deleted`,
                showDialog: true,
                timer: "3000"
            }))
        }
    },[])

    const handleNextPage = useCallback((val) => {
        getRoles(val)
    },[]);

    const editPermission = PermissionCheck("pb_role_edit");
    const deletePermission = PermissionCheck("pb_role_delete");
    const assignPermission = PermissionCheck("pb_role_assign");
    const viewPermission = PermissionCheck("pb_role_view");

    return (
        <>
            <Grid container spacing={4} className='mt-1'>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormLabel className='fw-bold fs-6'>Search</FormLabel>
                    <TextField 
                        variant='standard'
                        placeholder='Type here...'
                        className='form-control'
                        onChange={(e) => getRoles({...role?.page,searchString:e.target.value})}
                    />
                </Grid>
            </Grid>
            {viewPermission && <TableContainer className="mt-2">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {user?.role?.data?.map((val,key) => (
                            <TableRow key={key}>
                                <TableCell>{val.name}</TableCell>
                                <TableCell align='right'>
                                    <MenuGroup 
                                        id={val.role_id}
                                        view={false}
                                        edit={editPermission}
                                        delete={deletePermission}
                                        others={assignPermission}
                                        othersMenu={
                                            [<MenuItem
                                                className='text-success'
                                                onClick={() => navigate(`/assign-permission/${val.id}`)}
                                            >
                                                Assign Permission
                                            </MenuItem>]
                                        }
                                        clickEdit={() => user.setRole({...user.role,edit:true,modal:true,...val})}
                                        clickDelete={() => deleteRole(val)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                        {user?.role?.data.length === 0 && <TableRow>
                            <TableCell colSpan={2} align='center'>No Data</TableCell>
                        </TableRow>}
                    </TableBody>
                    {user?.role?.data?.length !== 0 &&<TableFooter>
                        <Pagination colSpan={2} pagination={role?.page} rowsPerPage={10} rowsPerPageChange={handleNextPage} />
                    </TableFooter>}
                </Table>
            </TableContainer>}

            {/*Role*/}
            <CustomModal
                open={role?.modal}
                onClose={() => setRole({...role,...{modal:false}})}
                header={`${role?.edit ? "Edit " : "Create "}Role`}
                style={ModalStyle({width:600,height:'auto'})}
            >
                <Formik
                    initialValues={{
                        name: role?.name
                    }}
                    validationSchema={roleSchema}
                    onSubmit={async (values, { resetForm }) => {
                        if (role?.edit) {
                            updateRole(values, role);
                        } else {
                            createRole(values, resetForm)
                        }
                    }}
                >
                    {({ handleSubmit, handleChange, values, errors, touched }) => (
                        <>
                            <Box className="modal-body">
                                <Form onSubmit={handleSubmit}>
                                    <Grid container spacing={1} className='p-3'>
                                        <Grid item md={12}>
                                            <FormLabel className='fw-bold'>Name:<sup className='text-danger'>*</sup></FormLabel>
                                            <TextField
                                                className='w-100'
                                                placeholder='Type here...'
                                                variant='standard'
                                                value={values.name}
                                                name='name'
                                                onChange={handleChange}
                                            />
                                            {touched.name && errors.name && <div className='text-danger'>{errors.name}</div>}
                                        </Grid>
                                    </Grid>
                                    <Box className="d-flex justify-content-end mt-3 p-3">
                                        <button className='btn btn-danger' type="submit">Submit</button>
                                    </Box>
                                </Form>
                            </Box>
                        </>
                    )}
                </Formik>
            </CustomModal>
        </>
    )
}

export default RoleTable
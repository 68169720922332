import { Card, Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, MenuItem, Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import NoData from '../../Components/Pagination/NoData';
import { useNavigate } from 'react-router-dom';
import { PostApiMethod, PutApiMethod } from '../../Utils/ApiService';
import { useAppDispatch } from '../../Store/hooks';
import { showToaster } from '../../Store/toastSlice';
import MenuGroup from '../../Components/DropDown/MenuGroup';
import { showLoader } from '../../Store/loaderSlice';
import Pagination from '../../Components/Pagination/Pagination';
import { CustomModal, ModalStyle } from '../../Components/ModalStyle';
import { Form, Formik } from 'formik';
import { SelectDropdown, TextInput } from '../../Components/Text/TextInput';
import ApiSearch from '../../Components/AutoCompleteTextView/ApiSearch';
import AddIcon from '@mui/icons-material/Add';
import { PermissionCheck } from '../../App/PermissionCheck';

const PDListHelpDesk = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [historyData, setHistoryData] = useState([]);
    const [historyPage, setHistoryPage] = useState({page:1,limit:5})
    const [permissionModal, setPermissionModal] = useState(false);
    const [userList, setUserList] = useState([]);
    const [userLoader, setUserLoader] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState("");
    useEffect(() => {
        getPDHistory({page:1,limit:5});
    },[]);

    const getPDHistory = async(data) => {
        dispatch(showLoader({showLoader:true}))
        try {
            let res = await PostApiMethod(`petrol-bunk/pb-help-desk/getHelpDesk`,{
                page:data?.page,
                limit:data?.limit
            });
            if(!res?.data?.error){
                setHistoryData(res?.data?.results?.data);
                setHistoryPage({
                    page:data?.page,
                    limit:data?.limit,
                    count:res?.data?.results?.totalCount
                })
            }
        } catch (error) {
            dispatch(showToaster({
                dialogBgColor: "bg-danger",
                dialogMsg: error.response ? error.response.data.message : `Failed to get history data`,
                showDialog: true,
                timer: "5000",
            }))
        }
        dispatch(showLoader({showLoader:false}))
    }

    const changeStatus = async(val,e) => {
        dispatch(showLoader({showLoader:true}))
        try{
            let res = await PutApiMethod(`petrol-bunk/pb-help-desk/change-status/${val.id}`,{
                status:e.target.value
            });
            if(!res?.data?.error){
                getPDHistory({page:1,limit:5});
                dispatch(showToaster({
                    dialogBgColor: "bg-success",
                    dialogMsg: res?.data?.message,
                    showDialog: true,
                    timer: "5000",
                }))
            }
        }catch(error){
            dispatch(showToaster({
                dialogBgColor: "bg-danger",
                dialogMsg: error.response ? error.response.data.message : `Failed to update status`,
                showDialog: true,
                timer: "5000",
            }))
        }
        dispatch(showLoader({showLoader:false}))
    }

    const getUsers = async(value) => {
        setUserLoader(true)
        try{
            let res = await PostApiMethod("user/parentUsers",{
                page:1,
                limit: 10,
                searchString: value,
                parentId: "1"
            });
            if(!res?.data?.error){
                setUserList(res?.data?.results?.data)
                setUserLoader(false)

            }
        }catch(error){
            setUserLoader(false)
            dispatch(showToaster({
                dialogBgColor:'bg-danger',
                dialogMsg: error.response ? error.response.data.message : `Failed to get modules data`,
                showDialog: true,
                timer: "3000"
            }))
        }
    }
    const assigUser = useCallback(async (values) => {
        dispatch(showLoader({showLoader:true}))
        try{
            let res = await PutApiMethod(`petrol-bunk/pb-help-desk/assign-ticket/${selectedTicket}`,{userId: values.userId.toString(),checkList:values.checkList});
            if(!res?.data?.error){
                setPermissionModal(false)
                getPDHistory({page:1,limit:5});
                dispatch(showToaster({
                    dialogBgColor: "bg-success",
                    dialogMsg: res?.data?.message,
                    showDialog: true,
                    timer: "5000",
                }))
            }
        }catch(error){
            dispatch(showToaster({
                dialogBgColor: "bg-danger",
                dialogMsg: error.response ? error.response.data.message : `Failed to update status`,
                showDialog: true,
                timer: "5000",
            }))
        }
        dispatch(showLoader({showLoader:false}))
    },[selectedTicket]);
    const hasPermission = PermissionCheck("pb_tickets_assign");
    const viewPermission = PermissionCheck("pb_tickets_view");
    const statusPermission = PermissionCheck("pb_tickets_status");

    return (
        <>
            <Card sx={{mt:2,p:2}}>
                <Box className="d-flex justify-content-end">
                    {PermissionCheck('pb_tickets_add') &&
                        <button className='btn btn-danger' onClick={() => navigate("/helpDesk/add")}><AddIcon /> Create HelpDesk</button>
                    }   
                </Box>
                {viewPermission && <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Asset Name</TableCell>
                                <TableCell>Category Name</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Assigned User</TableCell>
                                <TableCell colSpan={2}>Status</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {historyData?.map((val,key) => (
                                <TableRow key={key}>
                                    <TableCell>{val?.PbAsset?.name}</TableCell>
                                    <TableCell>{val?.HDCategory?.name}</TableCell>
                                    <TableCell>{val?.type}</TableCell>
                                    <TableCell>{val?.User ? val?.User?.firstName : "Not assigned"}</TableCell>
                                    <TableCell>{val?.status}</TableCell>
                                    <TableCell>
                                        {statusPermission && <SelectDropdown
                                            value={val?.status}
                                            onChange={(e) => changeStatus(val,e)}
                                        >
                                            <MenuItem value={"Inprogress"}>Inprogress</MenuItem>
                                            <MenuItem value={"Assigned"}>Assigned</MenuItem>
                                            <MenuItem value={"Completed"}>Completed</MenuItem>
                                            <MenuItem value={"Cancelled"}>Cancelled</MenuItem>
                                            <MenuItem value={"ReAssigned"}>Re Assigned</MenuItem>
                                        </SelectDropdown>}
                                    </TableCell>
                                    <TableCell align='right'>
                                        <MenuGroup 
                                            edit={false}
                                            clickEdit={() => navigate(`/helpDesk/add/${val.id}`)}
                                            others={hasPermission}
                                            othersMenu={
                                                <>
                                                    {hasPermission && <MenuItem className='text-success' onClick={() => {
                                                    setSelectedTicket(val.id);
                                                    setPermissionModal(true)}}>Assign</MenuItem>}
                                                </>
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        {historyData.length === 0 && <NoData colSpan={5} />}
                        <Pagination
                            pagination={historyPage}
                            rowsPerPageChange={(val) => getPDHistory(val)}
                        />
                    </Table>
                </TableContainer>}
            </Card>

            {/**Assign Permission Modal */}
            <CustomModal
                open={permissionModal}
                onClose={() => setPermissionModal(false)}
                style={ModalStyle({width:600,height:'auto'})}
                header={"Assign"}
            >
                <Formik
                    initialValues={{
                        userId:"",
                        checkList:"",
                        userDetails: {
                            id: "",
                            firstName: ""
                        }
                    }}
                    onSubmit={async(values) => {
                        assigUser(values);
                    }}
                    
                >
                    {({handleChange, handleSubmit, values, errors, touched, setFieldValue}) => (
                        <Form onSubmit={handleSubmit} className='p-3'>
                            <Grid container spacing={4} >
                                <Grid item xs={12} md={12} lg={12}>
                                <ApiSearch
                                value={values.userDetails}
                                onChange={(newValue) => {
                                 setFieldValue("userId", newValue.id)
                                 setFieldValue("userDetails", {id: newValue.id,firstName: newValue.firstName})
                                }}
                                loading={userLoader}
                                options={userList}
                                searchApi={getUsers}
                                isOptionEqualToValue={(option, value) => option?.firstName === value.firstName}
                                getOptionLabel={(option) => 
                                  option?.firstName
                                }
                                showTitle={true}
                                label={"Search user"}
                            
                                />
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <TextInput
                                        label="Checklist"
                                        name="checkList"
                                        value={values?.checkList}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                            <Box className="d-flex justify-content-end">
                                <button type="submit" className='btn btn-danger mt-3'>Submit</button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </CustomModal>
        </>
    )
}

export default PDListHelpDesk
import * as Yup from "yup";

export const PersonalInfoSchema = Yup.object().shape({
    firstName: Yup.string().required("First name field is required"),
    lastName: Yup.string().required("Last name field is required"),
    phoneNumber: Yup.string().required("Phone number field is required"),
    email:Yup.string().required("Email field is required"),
    permanentAddress:Yup.string().required("Permanent address field is required"),
    permanentCountry:Yup.string().required("Permanent country field is required"),
    permanentState:Yup.string().required("Permanent state field is required"),
    permanentCity:Yup.string().required("Permanent city field is required"),
    permanentZipCode:Yup.string().required("Permanent zip code field is required"),
    previousAddress:Yup.string().required("Previous address field is required"),
    previousCountry:Yup.string().required("Previous country field is required"),
    previousState:Yup.string().required("Previous state field is required"),
    previousCity:Yup.string().required("Previous city field is required"),
    previousZipCode:Yup.string().required("Previous zip code field is required"),
})
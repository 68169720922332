import React, { useContext, useRef } from 'react';
import { Box, Typography, Grid, TextField, Select, MenuItem } from '@mui/material';
import { Formik, Form } from 'formik';
import { UnitFormValidation } from './PropertyValidationSchema';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { PropertyContext } from './Main';
import { AddPropertyContext } from './AddProperty';
import { useAppDispatch } from '../../Store/hooks';
import { showLoader } from '../../Store/loaderSlice';
import { showToaster } from '../../Store/toastSlice';
import { PostApiMethod, PutApiMethod } from '../../Utils/ApiService';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const UnitForm = () => {
    const addpropertyform = useContext(AddPropertyContext);
    const property = useContext(PropertyContext);
    const dispatch = useAppDispatch();
    const formikRef = useRef();

    // useEffect(() => {
    //     addpropertyform.data.units.map((val,key) => (
    //         formikRef.current.setFieldValue(`units[${key}].image`,val.image)
    //     ))
    // },[addpropertyform])

    const handleFileChange = (e,index,setFieldValue) => {
        const file = e.currentTarget.files[0]
        setFieldValue(`units[${index}].[image]`,file)
    }

    const genereateForm = (handleChange,errors,values,touched,setFieldValue) => {
        return values.units.map((val,index) => (
            <>
                { index !== 0 && <hr key={index}/>}
                <Grid container spacing={4} >
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography className='fw-bold'>Unit Name</Typography>
                        <TextField 
                            className='form-control'
                            type="text"
                            name={`units[${index}].unit_name`}
                            value={val.unit_name}
                            placeholder='Type here...'
                            onChange={handleChange}
                            variant="standard"
                        />
                        {touched.units && touched.units[index] && touched.units[index].unit_name && errors.units && errors.units[index] && errors.units[index].unit_name && (
                            <div className='text-danger'>{errors.units[index].unit_name}</div>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography className='fw-bold'>Bedroom</Typography>
                        <TextField 
                            className='form-control'
                            type="number"
                            name={`units[${index}].bedroom`}
                            value={val.bedroom}
                            placeholder='Type here...'
                            onChange={handleChange}
                            variant="standard"
                        />
                        {touched.units && touched.units[index] && touched.units[index].bedroom && errors.units && errors.units[index] && errors.units[index].bedroom && (
                            <div className='text-danger'>{errors.units[index].bedroom}</div>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography className='fw-bold'>Bathroom</Typography>
                        <TextField 
                            className='form-control'
                            type="number"
                            name={`units[${index}].bath`}
                            value={val.bath}
                            placeholder='Type here...'
                            onChange={handleChange}
                            variant="standard"
                        />
                        {touched.units && touched.units[index] && touched.units[index].bath && errors.units && errors.units[index] && errors.units[index].bath && (
                            <div className='text-danger'>{errors.units[index].bath}</div>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography className='fw-bold'>Kitchen</Typography>
                        <TextField 
                            className='form-control'
                            type="number"
                            name={`units[${index}].kitchen`}
                            value={val.kitchen}
                            placeholder='Type here...'
                            onChange={handleChange}
                            variant="standard"
                        />
                        {touched.units && touched.units[index] && touched.units[index].kitchen && errors.units && errors.units[index] && errors.units[index].kitchen && (
                            <div className='text-danger'>{errors.units[index].kitchen}</div>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography className='fw-bold'>Square Feet</Typography>
                        <TextField 
                            className='form-control'
                            type="text"
                            name={`units[${index}].square_feet`}
                            value={val.square_feet}
                            placeholder='Type here...'
                            onChange={handleChange}
                            variant="standard"
                        />
                        {touched.units && touched.units[index] && touched.units[index].square_feet && errors.units && errors.units[index] && errors.units[index].square_feet && (
                            <div className='text-danger'>{errors.units[index].square_feet}</div>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography className='fw-bold'>Amenities</Typography>
                        <Select displayEmpty className="w-100 mt-2" variant='standard' name={`units[${index}].amenities`} value={val.amenities} onChange={handleChange}>
                            <MenuItem disabled value=""><em>Choose</em></MenuItem>
                            <MenuItem value="1">Amenities 1</MenuItem>
                            {property.amenitiesList.length !== 0 && property.amenitiesList.map((val,key) => {
                                if(val.status){
                                    return (<MenuItem key={key} value={val.id}>{val.name}</MenuItem>)
                                }
                                return true
                            })}
                        </Select>
                        {/* <TextField 
                            className='form-control'
                            type="text"
                            name="amenities[]"
                            value={values.amenities && values.amenities[i]}
                            placeholder='Type here...'
                            onChange={handleChange}
                            variant="standard"
                        /> */}
                        {touched.units && touched.units[index] && touched.units[index].amenities && errors.units && errors.units[index] && errors.units[index].amenities && (
                            <div className='text-danger'>{errors.units[index].amenities}</div>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography className='fw-bold'>Condition</Typography>
                        <TextField 
                            className='form-control'
                            type="number"
                            name={`units[${index}].condition`}
                            value={val.condition}
                            placeholder='Type here...'
                            onChange={handleChange}
                            variant="standard"
                        />
                        {touched.units && touched.units[index] && touched.units[index].condition && errors.units && errors.units[index] && errors.units[index].condition && (
                            <div className='text-danger'>{errors.units[index].condition}</div>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography className='fw-bold'>Parking</Typography>
                        <TextField 
                            className='form-control'
                            type="text"
                            name={`units[${index}].parking`}
                            value={val.parking}
                            placeholder='Type here...'
                            onChange={handleChange}
                            variant="standard"
                        />
                        {touched.units && touched.units[index] && touched.units[index].parking && errors.units && errors.units[index] && errors.units[index].parking && (
                            <div className='text-danger'>{errors.units[index].parking}</div>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography className='fw-bold'>Description</Typography>
                        <TextField 
                            className='form-control'
                            type="text"
                            name={`units[${index}].description`}
                            value={val.description}
                            placeholder='Type here...'
                            onChange={handleChange}
                            variant="standard"
                        />
                        {touched.units && touched.units[index] && touched.units[index].description && errors.units && errors.units[index] && errors.units[index].description && (
                            <div className='text-danger'>{errors.units[index].description}</div>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography className='fw-bold'>Upload Images</Typography>
                        {/* <input 
                            type="file"
                            name={`units[${index}].image`}
                            value={val.image}
                            onChange={handleChange}
                            accept="image/png, image/jpeg"
                        /> */}
                        <TextField 
                            className='form-control'
                            type="file"
                            name={`units[${index}].image`}
                            placeholder='Type here...'
                            onChange={(e) => handleFileChange(e,index,setFieldValue)}
                            variant="standard"
                            inputProps={{
                                accept: "image/png, image/jpeg"
                              }}
                        />
                        {touched.units && touched.units[index] && touched.units[index].image && errors.units && errors.units[index] && errors.units[index].image && (
                            <div className='text-danger'>{errors.units[index].image}</div>
                        )}
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={4} lg={4}>
                        {(property.isUnitEdit || property.isEdit) && val.image && <img src={val.image} width="100" height="100" alt="Unit" style={{border:'2px solid #e6e3e3',borderRadius:'10px'}} /> }
                    </Grid> */}
                </Grid>
            </>
        ))   
    }

    const addUnit = async(formData,values) => {
        dispatch(showLoader({showLoader:true}))
        try{
            let res = await PostApiMethod("property/unit/create",formData,{
                headers:{
                    "Content-Type":"multipart/form-data"
                }
            });
            if(res["data"]["error"] === false){
                dispatch(showToaster({dialogBgColor: "bg-success",
                    dialogMsg: res["data"]["message"],
                    showDialog: true,
                    timer: "3000"
                }));
                let data = res["data"]["results"]["data"];
                addpropertyform.formSubmit("Rent Form",{...values,
                    unit_id:data.map((val) => val["id"]),
                    ...{rents: values["units"].map((val,key) => {
                        return {
                            unit_name:val["unit_name"],
                            general_rent:addpropertyform.data.rents ? addpropertyform.data.rents[key]["general_rent"] : "",
                            security_deposit:addpropertyform.data.rents ? addpropertyform.data.rents[key]["security_deposit"] : "",
                            late_fee:addpropertyform.data.rents ? addpropertyform.data.rents[key]["late_fee"] : "",
                            incident_receipt:addpropertyform.data.rents ? addpropertyform.data.rents[key]["incident_receipt"] : "",
                            rent_type:addpropertyform.data.rents ? addpropertyform.data.rents[key]["rent_type"] : "",
                            monthly_due_date:addpropertyform.data.rents ? addpropertyform.data.rents[key]["monthly_due_date"] : "",
                            yearly_due_date:addpropertyform.data.rents ? addpropertyform.data.rents[key]["yearly_due_date"] : "",
                            lease_start_date:addpropertyform.data.rents ? addpropertyform.data.rents[key]["lease_start_date"] : "",
                            lease_end_date:addpropertyform.data.rents ? addpropertyform.data.rents[key]["lease_end_date"] : "",
                            lease_payment_due_date:addpropertyform.data.rents ? addpropertyform.data.rents[key]["lease_payment_due_date"] : "",
                        }
                    })}
                });
                property.getUnitList(property.unitPage)
            }
        }catch(error){
            dispatch(showToaster({dialogBgColor: "bg-danger",
                dialogMsg: "Unit details not created",
                showDialog: true,
                timer: "3000"
            }))
        }
        dispatch(showLoader({showLoader:false}))
    }

    const updateUnit = async(formData,values) => {
        dispatch(showLoader({showLoader:true}))
        try{
            let res = await PutApiMethod("property/unit/update",formData,{
                headers:{
                    'Content-Type':'multipart/form-data'
                }
            });
            if(res["data"]["error"] === false){
                dispatch(showToaster({dialogBgColor: "bg-success",
                    dialogMsg: res["data"]["message"],
                    showDialog: true,
                    timer: "3000"
                }));
                addpropertyform.formSubmit("Rent Form",{...values,
                    ...{rents: values["units"].map((val,key) => {
                        return {
                            unit_name:val["unit_name"],
                            general_rent:addpropertyform.data.rents ? addpropertyform.data.rents[key]["general_rent"] : "",
                            security_deposit:addpropertyform.data.rents ? addpropertyform.data.rents[key]["security_deposit"] : "",
                            late_fee:addpropertyform.data.rents ? addpropertyform.data.rents[key]["late_fee"] : "",
                            incident_receipt:addpropertyform.data.rents ? addpropertyform.data.rents[key]["incident_receipt"] : "",
                            rent_type:addpropertyform.data.rents ? addpropertyform.data.rents[key]["rent_type"] : "",
                            monthly_due_date:addpropertyform.data.rents ? addpropertyform.data.rents[key]["monthly_due_date"] : "",
                            yearly_due_date:addpropertyform.data.rents ? addpropertyform.data.rents[key]["yearly_due_date"] : "",
                            lease_start_date:addpropertyform.data.rents ? addpropertyform.data.rents[key]["lease_start_date"]:"",
                            lease_end_date:addpropertyform.data.rents ? addpropertyform.data.rents[key]["lease_end_date"] : "",
                            lease_payment_due_date:addpropertyform.data.rents ? addpropertyform.data.rents[key]["lease_payment_due_date"] : "",
                        }
                    })}
                });
                property.onBackUnitList();
                property.getUnitList(property.unitPage)
            }
        }catch(error){
            dispatch(showToaster({dialogBgColor: "bg-danger",
                dialogMsg: "Unit details not updated",
                showDialog: true,
                timer: "3000"
            }))
        }
        dispatch(showLoader({showLoader:false}))
    }


    return (
        <>
            <Box className="mt-4 d-flex justify-content-between">
                <Box>
                    <h5 className='fw-bold'>{ property.isUnitEdit || property.isEdit ? <><ArrowBackIcon sx={{cursor:'pointer'}} onClick={property.onBackPropertyList}/>Edit</> : "Add New" } Property</h5>
                    <p sx={{color:'gray'}}>Unit</p>
                </Box>
            </Box>
            <Formik
                innerRef={formikRef}
                initialValues={{
                    units:addpropertyform.data.units || Array.from({length: addpropertyform.data.number_of_unit}, () => ({
                        unit_name:"",
                        bedroom:"",
                        bath:"",
                        kitchen:"",
                        square_feet:"",
                        amenities:"",
                        condition:"",
                        parking:"",
                        description:"",
                        image:""
                    }))
                }}
                validationSchema={UnitFormValidation}
                onSubmit={async(values) => {
                    let formData = new FormData();
                    values.units.forEach((val,key) => {
                        formData.append(`data[${key}][unit_name]`,val["unit_name"])
                        formData.append(`data[${key}][bedroom]`,val['bedroom'])
                        formData.append(`data[${key}][bath]`,val['bath'])
                        formData.append(`data[${key}][kitchen]`,val['kitchen'])
                        formData.append(`data[${key}][square_feet]`,val['square_feet'])
                        formData.append(`data[${key}][amenities]`,val['amenities'])
                        formData.append(`data[${key}][condition]`,val['condition'])
                        formData.append(`data[${key}][parking]`,val['parking'])
                        formData.append(`data[${key}][description]`,val['description'])
                        formData.append(`data[${key}][image]`,val['image'])
                    });
                    for(let i = 0; i < addpropertyform.data.number_of_unit; i++){
                        formData.append(`data[${i}][property_id]`,addpropertyform.data.property_id);
                        formData.append(`data[${i}][user_id]`,addpropertyform.data.user_id);
                        (property.isEdit || property.isUnitEdit) && formData.append(`data[${i}][id]`,addpropertyform.data.unit_id[i])
                    }
                    if(property.isUnitEdit || addpropertyform.data.unit_id.length !== 0){
                        updateUnit(formData,values);
                    }else{
                        addUnit(formData,values);
                    }
            
                }}
            >
                {({ handleSubmit, handleChange, errors, values, touched, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                        {genereateForm(handleChange,errors,values,touched,setFieldValue)}
                        <Box className="mt-4">
                            {property.isUnitEdit ? <button type="button" className='btn btn-white' onClick={property.onBackUnitList}><ArrowBackIosIcon style={{fontSize:'18px'}}/>Back</button>
                            :<button type="button" className='btn btn-white' onClick={() => addpropertyform.onBackForm("Location")}><ArrowBackIosIcon style={{fontSize:'18px'}}/>Back</button>}
                            
                            <button type="submit" className='btn btn-danger ms-2'>{ property.isUnitEdit ? "Save" : "Save & Proceed Next" }</button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default UnitForm;
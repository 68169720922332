import React, { memo, useCallback, useContext, useState } from 'react';
import { Box, Grid, MenuItem, TableFooter, TextField, Typography, Chip } from '@mui/material';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { Table, TableBody, TableHead, TableRow, TableCell, TableContainer, IconButton, Menu } from '@mui/material';
import { AmenitiesContext } from './Amenities';
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";
import Pagination from '../../Components/Pagination/Pagination';
import Modal from '@mui/material/Modal';
import { ModalStyle } from '../../Components/ModalStyle';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import { PutApiMethod } from '../../Utils/ApiService';
import { useAppDispatch } from '../../Store/hooks';
import { showLoader } from '../../Store/loaderSlice';
import { showToaster } from '../../Store/toastSlice';
import { convertToAmPm } from '../../Components/CommonFormik';

const AmenityList = () => {
    const amenities = useContext(AmenitiesContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [cOpen, setcOpen] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [status, setStatus] = useState({});
    const dispatch = useAppDispatch();

    const handleClick = (id, event) => {
        setcOpen(cOpen === id ? 0 : id);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e, data) => {
        setcOpen(0);
        setAnchorEl(null);
    }

    const changeStatus = (val) => {
        setStatus({id:val.id,status:val.status})
        setModalOpen(true)
    }

    const updateStatus = useCallback(async() => {
        setModalOpen(false)
        dispatch(showLoader({showLoader:true}))
        try{
            let res = await PutApiMethod("property/amenities/"+status.id,{status:status.status});
            if(res['data']['error'] === false){
                dispatch(showToaster({dialogBgColor: "bg-success",
                    dialogMsg: "Status changed successfully",
                    showDialog: true,
                    timer: "3000",}))
                amenities.getAmenitiesList(amenities.alPage)
            }
        }catch(error){
            dispatch(showToaster({dialogBgColor: "bg-danger",
                dialogMsg: "Failed to update status",
                showDialog: true,
                timer: "3000",}))
        }
        dispatch(showLoader({showLoader:false}))
    },[status])
    return (
        <>
            <Box>
                <Grid container className="mt-4">
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography className="fw-bold">Search</Typography>
                        <TextField 
                            className="w-100"
                            variant="standard"
                            placeholder='Type here'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                    <Search />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) => amenities.getAmenitiesList(amenities.page,e.target.value)}
                       />
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Amenity</TableCell>
                                <TableCell>Property</TableCell>
                                <TableCell>Available Days</TableCell>
                                <TableCell>Open Timings</TableCell>
                                <TableCell>Close Timings</TableCell>
                                <TableCell>No.of.People</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {amenities.amenityList.length !== 0 && amenities.amenityList.map((val,key) => {
                                return (
                                <TableRow key={key}>
                                    <TableCell>{val.name}</TableCell>
                                    <TableCell>{val.property.property_name}</TableCell>
                                    <TableCell>{val.availableNameDays}</TableCell>
                                    <TableCell>{convertToAmPm(val.openingTime)}</TableCell>
                                    <TableCell>{convertToAmPm(val.closingTime)}</TableCell>
                                    <TableCell>{val.no_of_people}</TableCell>
                                    <TableCell>{val.status ? <Chip label="Active" color="success" /> : <Chip label="In Active" color="error" />}</TableCell>
                                    <TableCell>
                                    <IconButton
                                        aria-label="more"
                                        id={"long-button_" + val.id}
                                        aria-controls={
                                            cOpen === val.id
                                            ? "long-menu_" + val.id
                                            : undefined
                                        }
                                        aria-expanded={
                                            cOpen === val.id ? "true" : undefined
                                        }
                                        aria-haspopup="true"
                                        onClick={(e) => {
                                            handleClick(val.id, e);
                                        }}
                                    >
                                        <Typography
                                            className="poppins-family"
                                            color="primary"
                                        >
                                            Action
                                        </Typography>
                                        <KeyboardArrowDownTwoToneIcon color="primary" />
                                    </IconButton>
                                    <Menu
                                        id={"long-menu_" + val.id}
                                        MenuListProps={{
                                            "aria-labelledby": "long-button_" + val.id,
                                        }}
                                        anchorEl={anchorEl}
                                        open={cOpen === val.id}
                                        onClose={handleClose}
                                    >
                                        <MenuItem className="text-primary" onClick={() => amenities.amenitiesEdit(val)}>Edit</MenuItem>
                                        <MenuItem className="text-danger" onClick={() => amenities.deleteAmenities(val.id)}>Delete</MenuItem>
                                        <MenuItem onClick={() => changeStatus({id:val.id,status:val.status})}>Change Status</MenuItem>
                                    </Menu>
                                    </TableCell>
                                </TableRow>
                                )
                            })}
                            {amenities.amenityList.length === 0 && <TableRow>
                                <TableCell colspan={8} align="center">
                                    No Data
                                </TableCell>
                            </TableRow>}
                        </TableBody>
                        {amenities.amenityList.length !== 0 && <TableFooter>
                            <Pagination colSpan={8} pagination={amenities.alPage} rowsPerPage={10} rowsPerPageChange={amenities.handleNextPage}/>
                        </TableFooter>}
                    </Table>
                </TableContainer>
            </Box>

            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
            >
                <Box style={ModalStyle({width:600,height:300})} className="bg-white">
                    <Box className="border-bottom p-3 d-flex justify-content-between align-items-center">
                        <Typography className='fw-bold fs-5'>Status Changed</Typography>
                        <CloseIcon sx={{cursor:'pointer'}} onClick={() => setModalOpen(false)}/>
                    </Box>
                    <Box className="p-4 h-50" >
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography className='fw-bold'>Status</Typography>
                                <Select className='w-100' variant='standard' value={status.status} onChange={(e) => setStatus({...status,status:e.target.value})} >
                                    <MenuItem value={true}>Active</MenuItem>
                                    <MenuItem value={false}>In Active</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className="border-top p-4 d-flex justify-content-end">
                        <button className="btn btn-primary" onClick={updateStatus}>Update</button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default memo(AmenityList)
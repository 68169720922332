import React, { useState } from 'react';
import { Box, Menu, IconButton, MenuItem } from '@mui/material';
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";

const MenuGroup = (props) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [cOpen, setcOpen] = useState(0);

    const handleClose = () => {
        setcOpen(0)
        setAnchorEl(null)
    }
    
    const handleClick = (id,event) => {
        setcOpen(cOpen === id ? 0 : id);
        setAnchorEl(event.currentTarget);
    }

    return (
        <>
            <IconButton
                aria-label="more"
                id={"long-button_" + props.id}
                aria-controls={
                cOpen === props.id
                    ? "long-menu_" + props.id
                    : undefined
                }
                aria-expanded={
                cOpen === props.id ? "true" : undefined
                }
                aria-haspopup="true"
                onClick={(e) => {
                handleClick(props.id, e);
                }}
            >
                <Box className="poppins-family fs-6 text-primary">
                    Action
                </Box>
                <KeyboardArrowDownTwoToneIcon color="primary" />
            </IconButton>
            <Menu
                id={"long-menu_" + props.id}
                MenuListProps={{
                "aria-labelledby": "long-button_" + props.id,
                }}
                anchorEl={anchorEl}
                open={cOpen === props.id}
                onClose={handleClose}
            >
                {props.view && <MenuItem className="text-success" >View</MenuItem>}
                {props.edit && <MenuItem className="text-primary" onClick={props.clickEdit}>Edit</MenuItem>}
                {props.delete && <MenuItem className="text-danger" onClick={props.clickDelete}>Delete</MenuItem>}
                {props?.others && props?.othersMenu}
            </Menu>
        </>
    )
}

export default MenuGroup;
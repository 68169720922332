import React, { memo, useContext, useState } from 'react';
import { Box, Grid, IconButton, TableFooter, TextField, Typography, Select } from '@mui/material';
import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { Table, TableBody, TableHead, TableRow, TableCell, TableContainer } from '@mui/material';
import { ParkingContext } from './Parking';
import Pagination from '../../Components/Pagination/Pagination';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";

const ParkingList = () => {
    const parking = useContext(ParkingContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [cOpen, setcOpen] = useState(0);
    // const [ search, setSearch ] = useState("");
    // const [ parkingType, setParkingType] = useState("");

    // useEffect(() => {
    //     parking.searchString({string:search,type:parkingType})
    // },[search,parkingType])

    

    const handleClick = (id, event) => {
        setcOpen(cOpen === id ? 0 : id);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e, data) => {
        setcOpen(0);
        setAnchorEl(null);
    }
    return (
        <>
            <Box>
                <Grid container spacing={4} className="mt-4">
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography className="fw-bold">Search</Typography>
                        <TextField 
                            className="w-100"
                            variant="standard"
                            placeholder='Type here'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                    <Search />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) => parking.searchString({string:e.target.value,type:parking.parkingType})}
                       />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography className="fw-bold">Parking Type</Typography>
                        <Select displayEmpty className="w-100 mt-2" name="parking_type" value={parking.parkingType} variant='standard' onChange={(e) => parking.searchString({string:parking.search,type:e.target.value})} >
                            <MenuItem disabled value="">
                                <em>Choose</em>
                            </MenuItem>
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="Visitors">Visitors</MenuItem>
                            <MenuItem value="Staffs">Staffs</MenuItem>
                            <MenuItem value="Residents">Residents</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <TableContainer className='mt-3'>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Parking No</TableCell>
                                <TableCell>Parking Type</TableCell>
                                <TableCell>Unit</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Vehicle Details(4 wheeler)</TableCell>
                                <TableCell>Vehicle Details(2 wheeler)</TableCell>
                                <TableCell>Billing Cycle</TableCell>
                                <TableCell>Fees</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {parking.parkingList.length !== 0 && parking.parkingList.map((val,key) => {
                                return (
                                <TableRow key={key}>
                                    <TableCell>{val.parking_no}</TableCell>
                                    <TableCell>{val.parking_type}</TableCell>
                                    <TableCell>{val.unit !== null && val.unit.unit_name}</TableCell>
                                    <TableCell>{val.visitor_name}</TableCell>
                                    <TableCell>{val.vehicle_4_wheeler}</TableCell>
                                    <TableCell>{val.vehicle_2_wheeler}</TableCell>
                                    <TableCell>{val.billing_cycle}</TableCell>
                                    <TableCell>{val.fees}</TableCell>
                                    <TableCell>
                                    <IconButton
                                        aria-label="more"
                                        id={"long-button_" + val.id}
                                        aria-controls={
                                            cOpen === val.id
                                            ? "long-menu_" + val.id
                                            : undefined
                                        }
                                        aria-expanded={
                                            cOpen === val.id ? "true" : undefined
                                        }
                                        aria-haspopup="true"
                                        onClick={(e) => {
                                            handleClick(val.id, e);
                                        }}
                                    >
                                        <Typography
                                            className="poppins-family"
                                            color="primary"
                                        >
                                            Action
                                        </Typography>
                                        <KeyboardArrowDownTwoToneIcon color="primary" />
                                    </IconButton>
                                    <Menu
                                        id={"long-menu_" + val.id}
                                        MenuListProps={{
                                            "aria-labelledby": "long-button_" + val.id,
                                        }}
                                        anchorEl={anchorEl}
                                        open={cOpen === val.id}
                                        onClose={handleClose}
                                    >
                                        <MenuItem className="text-primary" onClick={() => parking.enableViewParking(val.id)}>View</MenuItem>
                                        <MenuItem className="text-success" onClick={() => parking.getEditData(val)}>Edit</MenuItem>
                                        <MenuItem className="text-danger" onClick={() => parking.handleDelete(val.id)}>Delete</MenuItem>
                                    </Menu>
                                    </TableCell>
                                </TableRow>
                                )
                            })}
                            {parking.parkingList.length === 0 && <TableRow>
                                <TableCell colspan={6} align="center">
                                    No Data
                                </TableCell>
                            </TableRow>}
                        </TableBody>
                        {parking.parkingList.length !== 0 && <TableFooter>
                            <Pagination colSpan={8} pagination={parking.page} rowsPerPage={10} rowsPerPageChange={parking.handleNextPage}/>
                        </TableFooter>}
                    </Table>
                </TableContainer>
            </Box>
        </>
    )
}

export default memo(ParkingList)
import { Box, Grid, TextField, Button, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import FormTitle from "../../Components/FormTitle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import { addTenantSchema } from "../../Components/Validation";
import { useFormik } from "formik";
import { PutApiMethod } from "../../Utils/ApiService";
import { showLoader } from "../../Store/loaderSlice";
import { errorHandling } from "../../Utils/HelperService";
import { showToaster } from "../../Store/toastSlice"; 

const EditTenant = (props: any) => {  
  const loader = useAppSelector((state) => state.loader);

  const [initialValue, setInitialValiue] = useState({
    id: "",
    email: "",
    familyMember: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    job: "",
    age: "",
  });
  const dispatch = useAppDispatch();

  const submit = useCallback(
    async (value: any) => {
      dispatch(showLoader({ showLoader: true }));
      const param = {
        email: value.email,
        firstName: value.firstName,
        lastName: value.lastName,
        phoneNumber: value.phoneNumber,
        job: value.job,
        age: value.age,
        familyMember: value.familyMember,
      };
      await PutApiMethod("user/" + initialValue.id, param)
        .then((res) => {
          props.clearTenantData();
          dispatch(showLoader({ showLoader: false }));
          dispatch(
            showToaster({
              dialogBgColor: "success",
              dialogMsg: "Updated successfully",
              showDialog: true,
              timer: "5000",
            })
          );
          props.pageStatus("listTenant");

        })
        .catch((err) => {
          const error = errorHandling(err);
          dispatch(
            showToaster({
              dialogBgColor: "bg-danger",
              dialogMsg: error,
              showDialog: true,
              timer: "5000",
            })
          );
          dispatch(showLoader({ showLoader: false }));
        });
    },
    [initialValue.id]
  );

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: addTenantSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      submit(values);
    },
  });
  useEffect(() => {
    if (props.tenantdata) {
      setInitialValiue(props.tenantdata);
    }
  }, [props.tenantdata]);
  return (
    <>
      <Box sx={{ mt: 3 }}>
        <FormTitle
          text="Update Tenant Information"
          extenalCss="subtitle-color"
        />
      <form onSubmit={handleSubmit}>
            <Box className="mt-4">
                <Typography className="fw-bold">Personal Information</Typography>
                <input type="file" />
            </Box>
            <Box className="mt-3">
                <Grid container spacing={4}>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Typography className="fw-bold">First Name</Typography>
                        <TextField
                            type="text"
                            className="form-control"
                            name="first_name"
                            placeholder="Type here"
                            variant="standard"
                            value={values.firstName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Typography className="fw-bold">Last Name</Typography>
                        <TextField
                            type="text"
                            className="form-control"
                            name="last_name"
                            placeholder="Type here"
                            variant="standard"
                            value={values.lastName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Typography className="fw-bold">Contact Number</Typography>
                        <TextField
                            type="number"
                            className="form-control"
                            name="contact"
                            placeholder="Type here"
                            variant="standard"
                            value={values.phoneNumber}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Typography className="fw-bold">Job</Typography>
                        <TextField
                            type="text"
                            className="form-control"
                            name="job"
                            placeholder="Type here"
                            variant="standard"
                            value={values.job}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Typography className="fw-bold">Age</Typography>
                        <TextField
                            type="number"
                            className="form-control"
                            name="age"
                            placeholder="Type here"
                            variant="standard"
                            value={values.age}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Typography className="fw-bold">Family Members</Typography>
                        <TextField
                            type="number"
                            className="form-control"
                            name="family_members"
                            placeholder="Type here"
                            variant="standard"
                            value={values.familyMember}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Typography className="fw-bold">Email</Typography>
                        <TextField
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="Type here"
                            variant="standard"
                            value={values.email}
                            onChange={handleChange}
                        />
                    </Grid>
                    {/* <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Typography className="fw-bold">Password</Typography>
                        <TextField
                            type="password"
                            className="form-control"
                            name="password"
                            placeholder="Type here"
                            variant="standard"
                            // value={values.password}
                            onChange={handleChange}
                        />
                    </Grid> */}
                </Grid>
                <Box className="mt-3">
                    <Typography className="fw-bold">Previous Address</Typography>
                </Box>
                <Grid container spacing={4} className="mt-1">
                    <Grid item lg={4} md={4} xs={12} sm={12}>
                        <Typography className="fw-bold">Address</Typography>
                        <TextField
                            name="previous_address"
                            className="form-control"
                            placeholder="Type here"
                            variant="standard"
                            type="text"
                            // value={values.previous_address}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} xs={12} sm={12}>
                        <Typography className="fw-bold">Country</Typography>
                        <TextField
                            name="previous_country"
                            className="form-control"
                            placeholder="Type here"
                            variant="standard"
                            type="text"
                            // value={values.previous_country}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} xs={12} sm={12}>
                        <Typography className="fw-bold">State</Typography>
                        <TextField
                            name="previous_state"
                            className="form-control"
                            placeholder="Type here"
                            variant="standard"
                            type="text"
                            // value={values.previous_state}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} xs={12} sm={12}>
                        <Typography className="fw-bold">City</Typography>
                        <TextField
                            name="previous_city"
                            className="form-control"
                            placeholder="Type here"
                            variant="standard"
                            type="text"
                            // value={values.previous_city}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} xs={12} sm={12}>
                        <Typography className="fw-bold">Zip Code</Typography>
                        <TextField
                            name="previous_zip"
                            className="form-control"
                            placeholder="Type here"
                            variant="standard"
                            type="text"
                            // value={values.previous_zip}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Box className="mt-3">
                    <Typography className="fw-bold">Permanent Address</Typography>
                </Box>
                <Grid container spacing={4} className="mt-1">
                    <Grid item lg={4} md={4} xs={12} sm={12}>
                        <Typography className="fw-bold">Address</Typography>
                        <TextField
                            name="permanent_address"
                            className="form-control"
                            placeholder="Type here"
                            variant="standard"
                            type="text"
                            // value={values.permanent_address}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} xs={12} sm={12}>
                        <Typography className="fw-bold">Country</Typography>
                        <TextField
                            name="permanent_country"
                            className="form-control"
                            placeholder="Type here"
                            variant="standard"
                            type="text"
                            // value={values.permanent_country}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} xs={12} sm={12}>
                        <Typography className="fw-bold">State</Typography>
                        <TextField
                            name="permanent_state"
                            className="form-control"
                            placeholder="Type here"
                            variant="standard"
                            type="text"
                            // value={values.permanent_state}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} xs={12} sm={12}>
                        <Typography className="fw-bold">City</Typography>
                        <TextField
                            name="permanent_city"
                            className="form-control"
                            placeholder="Type here"
                            variant="standard"
                            type="text"
                            // value={values.permanent_city}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} xs={12} sm={12}>
                        <Typography className="fw-bold">Zip Code</Typography>
                        <TextField
                            name="permanent_zip"
                            className="form-control"
                            placeholder="Type here"
                            variant="standard"
                            type="text"
                            // value={values.permanent_zip}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box className="d-flex align-items-center mt-4">
                <button className="btn btn-white" type="button" onClick={() => props.pageStatus("listTenant")}>Back</button>
                <button className="btn btn-danger ms-2" type="submit">Update</button>
            </Box>
        </form>
      </Box>
    </>
  );
};

export default EditTenant;

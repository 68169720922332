import { Grid, Typography, Button, Box } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import { documentValidation } from "../../Components/Validation";
import { useCallback, useState } from "react";
import { PostApiMethod } from "../../Utils/ApiService";
import { showLoader } from "../../Store/loaderSlice";
import { errorHandling } from "../../Utils/HelperService";
import { showToaster } from "../../Store/toastSlice";
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface DocumentUploadsProps {
  pageStatus: (e: any) => void;
  tenantId: string;
  unitId: string;
  propertyId: string;
  finalData:any;
  propPageStatus:any;
}

const DocumentUploads = (props: DocumentUploadsProps) => {
  const loaderState = useAppSelector((state) => state.loader);
  const [uploadName, setUploadName] = useState<string | undefined>(undefined);

  const [initialValues, setInitialValue] = useState({
    pdfFile: null,
  });
  const { pageStatus, tenantId, propertyId, unitId ,finalData , propPageStatus} = props;
  const dispatch = useAppDispatch();
  const uploadDocument = useCallback(
    async (values: any) => {
      try {
        dispatch(showLoader({ showLoader: true }));
      let documentFormData = new FormData();
      documentFormData.append("user_id", tenantId);
      documentFormData.append("unit_id", unitId);
      documentFormData.append("property_id", propertyId);
      documentFormData.append("document[]", values.pdfFile);
      documentFormData.append("documentType", "temp");
  
      const apiData = {...finalData , propertyId:finalData.propertyId.id , unitId:finalData.unitId.id}

        await PostApiMethod("auth/create-user", apiData);
        const res = await PostApiMethod("property/document/addTenantDocument", documentFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        dispatch(showLoader({ showLoader: false }));
        dispatch(
          showToaster({
            dialogBgColor: "success",
            dialogMsg: "Document uploaded successfully",
            showDialog: true,
            timer: "5000",
          })
        );
        propPageStatus("listTenant");
      } catch (e) {
        const error = errorHandling(e);
        dispatch(
          showToaster({
            dialogBgColor: "bg-danger",
            dialogMsg: error,
            showDialog: true,
            timer: "5000",
          })
        );
        dispatch(showLoader({ showLoader: false }));
      }
    },
    [tenantId, unitId, propertyId, dispatch]
  );

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: documentValidation,
    enableReinitialize: true,
    onSubmit: async (values) => {
      // handlFormSubmit(values);
        uploadDocument(values);
    },
  });
  const handleDeleteImage=()=>{
    try{
      setFieldValue("pdfFile", null);     
      setUploadName(undefined)
    }
    catch(e){
      console.log(e)
    }
  }
  const customHandleChange = (file:any)=>{
    try{
      setFieldValue("pdfFile", file);     
      setUploadName(file ? file.name : undefined);
    }
      catch(e){
        console.log(e)
    }
  }
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item lg={12} md={4} sm={12} xs={12}>
            <Typography className="poppins-family">Add New Tenant</Typography>
          </Grid>

          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Typography className="poppins-family">Documents</Typography>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              onChange={(e: any) => {
                customHandleChange(e.target.files[0])
              }}
            >
              Upload file
              <VisuallyHiddenInput type="file" multiple={false} />
            </Button>
            {uploadName && (
                  <div className="border border-danger p-1">
                  {uploadName}
          <CancelTwoToneIcon className="float-end" onClick={()=>{handleDeleteImage()}}></CancelTwoToneIcon>
                  </div>
            )}
            {errors.pdfFile && touched.pdfFile && (
              <Typography className="text-danger">{errors.pdfFile}</Typography>
            )}
          </Grid>
        </Grid>

        <Box sx={{ mt: 4 }} className="d-flex">
          <Button
            className="btn-white"
            onClick={() => {
              pageStatus("homeDetails");
            }}
          >
            <ChevronLeftIcon className="fs-20" />
            Back
          </Button>
          <Button
            className="btn-danger ms-2"
            type="submit"
            disabled={loaderState.showLoader}
          >
            Save & Proceed Next
          </Button>
        </Box>
      </form>
    </>
  );
};

export default DocumentUploads;

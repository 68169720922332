import React, { useState, createContext, useCallback, useContext } from 'react';
import NewProperty from './NewProperty';
import Location from './Location';
import UnitForm from './UnitForm';
import RentForm from './RentForm';
import Images from './Images';
import { PropertyContext } from './Main';

export const AddPropertyContext = createContext()

const AddProperty = () => {
    const property = useContext(PropertyContext);
    const [data, setData] = useState({
        property_id:property.isEdit ? property.propertyEditData.id : property.isUnitEdit ? property.isUnitEditData[0].property.id : "",
        user_id:property.isEdit ? property.propertyEditData.owner_user_id : property.isUnitEdit ? property.isUnitEditData[0].user_id : "",
        property_type:property.isEdit ? property.propertyEditData.property_type.toLowerCase() : "own",
        property_name:property.isEdit ? property.propertyEditData.property_name : "",
        number_of_unit:property.isEdit ? property.propertyEditData.number_of_unit :  property.isUnitEdit ? 1 : "",
        maintainer_name:"",
        number_of_parking:"",
        property_category:"",
        description:property.isEdit ? property.propertyEditData.description : "",
        lease_amount:property.isEdit ? property.propertyEditData.lease_amount : "",
        lease_start_date:property.isEdit ? property.propertyEditData.lease_start_date && property.propertyEditData.lease_start_date.split("T")[0] : "",
        lease_end_date:property.isEdit ? property.propertyEditData.lease_end_date && property.propertyEditData.lease_end_date.split("T")[0] : "",
        country_id:property.isEdit ? property.propertyEditData.country_id : "",
        state_id:property.isEdit ? property.propertyEditData.state_id : "",
        city_id:property.isEdit ? property.propertyEditData.city_id : "",
        zip_code:property.isEdit ? property.propertyEditData.zip_code : "",
        address:property.isEdit ? property.propertyEditData.address : "",
        map_link:property.isEdit ? property.propertyEditData.map_link : "",
        units:property.isUnitEdit ? property.isUnitEditData : property.isEdit ? property.isUnitEditData : null,
        rents:property.isEdit ? property.isUnitEditData : null,
        // units:property.isUnitEdit ? property.isUnitEditData : "",
        // unit_name:property.isUnitEdit ? [property.isUnitEditData.unit_name] : "",
        // bedroom:property.isUnitEdit ? [property.isUnitEditData.bedroom] : "",
        // bath:property.isUnitEdit ? [property.isUnitEditData.bath] : "",
        // kitchen:property.isUnitEdit ? [property.isUnitEditData.kitchen] : "",
        // square_feet:property.isUnitEdit ? [property.isUnitEditData.square_feet] : "",
        // amenities:property.isUnitEdit ? [property.isUnitEditData.amenities] : "",
        // condition:property.isUnitEdit ? [property.isUnitEditData.condition] : "",
        // parking:property.isUnitEdit ? [property.isUnitEditData.parking] : "",
        // unit_description:"",
        unit_id:property.isUnitEdit ? [property.isUnitEditData[0].id] : property.isEdit ? property.isUnitEditData.map((val) => val.id) : [],
        // general_rent:property.isUnitEdit ? [property.isUnitEditData.general_rent] : [],
        // security_deposit:property.isUnitEdit ? [property.isUnitEditData.security_deposit] : [],
        // late_fee:property.isUnitEdit ? [property.isUnitEditData.late_fee] : [],
        // incident_receipt:property.isUnitEdit ? [property.isUnitEditData.incident_receipt] : [],
    });

    const [formState, setFormState] = useState(property.isUnitEdit?"Unit Form":"New Property");

    const formSubmit = useCallback((val,formData) => {
        setFormState(val);
        setData({...data,...formData})
    },[formState]);

    const onBackForm = useCallback((val) => {
        setFormState(val)
    },[formState]);

    return (
        <>
            <AddPropertyContext.Provider value={{
                data:data,
                formSubmit:formSubmit,
                onBackForm:onBackForm
            }}>
                {formState === "New Property" && <NewProperty />}
                {formState === "Location" && <Location />}
                {formState === "Unit Form" && <UnitForm />}
                {formState === "Rent Form" && <RentForm />}
                {formState === "Images" && <Images />}
            </AddPropertyContext.Provider>
        </>
    )
}

export default AddProperty
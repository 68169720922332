import React, { useContext } from 'react';
import { Box, Grid, FormLabel, TextField } from '@mui/material';
import { Formik, Form } from 'formik';
import ButtonGroup from '../../Components/Button/ButtonGroup';
import { TrainingContext } from './TrainingManagement';
import { AddNewTrainingSchema } from './TrainingValidationSchema';

const AddNewTraining = () => {
    const training = useContext(TrainingContext);

    return (
        <>
            <Box className="fw-bold fs-5 my-2">
                Add New Training
            </Box>
            <Formik
                initialValues={{
                    training_certificate:'',
                    cost:'',
                    employee_name:'',
                    employee_surname:'',
                    date_conducted:'',
                    date_expiring:'',
                    note:'',
                    upload_file:''
                }}
                validationSchema={AddNewTrainingSchema}
                onSubmit={async(values) => {
                    console.log(values)
                }}
            >
                {({handleChange, handleSubmit, errors, values, touched}) => (
                    <Form onSubmit={handleSubmit}>
                        <Grid container spacing={4} className='mt-2'>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <FormLabel className='fw-bold'>Training/Certificate Name</FormLabel>
                                <TextField 
                                    variant='standard'
                                    className='form-control'
                                    name="training_certificate"
                                    value={values.training_certificate}
                                    placeholder='Type here...'
                                    onChange={handleChange}
                                />
                                {touched.training_certificate && errors.training_certificate && (<div className='text-danger'>{errors.training_certificate}</div>)}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <FormLabel className='fw-bold'>Cost(R)</FormLabel>
                                <TextField 
                                    type="number"
                                    variant='standard'
                                    className='form-control'
                                    name="cost"
                                    value={values.cost}
                                    placeholder='Type here...'
                                    onChange={handleChange}
                                />
                                {touched.cost && errors.cost && (<div className='text-danger'>{errors.cost}</div>)}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <FormLabel className='fw-bold'>Employee Name</FormLabel>
                                <TextField 
                                    variant='standard'
                                    className='form-control'
                                    name="employee_name"
                                    value={values.employee_name}
                                    placeholder='Type here...'
                                    onChange={handleChange}
                                />
                                {touched.employee_name && errors.employee_name && (<div className='text-danger'>{errors.employee_name}</div>)}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <FormLabel className='fw-bold'>Employee Surname</FormLabel>
                                <TextField 
                                    variant='standard'
                                    className='form-control'
                                    name="employee_surname"
                                    value={values.employee_surname}
                                    placeholder='Type here...'
                                    onChange={handleChange}
                                />
                                {touched.employee_surname && errors.employee_surname && (<div className='text-danger'>{errors.employee_surname}</div>)}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <FormLabel className='fw-bold'>Date Conducted</FormLabel>
                                <TextField 
                                    type="date"
                                    variant='standard'
                                    className='form-control'
                                    name="date_conducted"
                                    value={values.date_conducted}
                                    placeholder='Type here...'
                                />
                                {touched.date_conducted && errors.date_conducted && (<div className='text-danger'>{errors.date_conducted}</div>)}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <FormLabel className='fw-bold'>Date Expiring</FormLabel>
                                <TextField 
                                    type="date"
                                    variant='standard'
                                    className='form-control'
                                    name="date_expiring"
                                    value={values.date_expiring}
                                    placeholder='Type here...'
                                />
                                {touched.date_expiring && errors.date_expiring && (<div className='text-danger'>{errors.date_expiring}</div>)}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <FormLabel className='fw-bold'>Note</FormLabel>
                                <TextField 
                                    variant='standard'
                                    className='form-control'
                                    name="note"
                                    value={values.note}
                                    placeholder='Type here...'
                                />
                                {touched.note && errors.note && (<div className='text-danger'>{errors.note}</div>)}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <FormLabel className='fw-bold'>Upload File</FormLabel>
                                <TextField 
                                    type="file"
                                    variant='standard'
                                    className='form-control'
                                    name="upload_file"
                                    value={values.upload_file}
                                    placeholder='Type here...'
                                />
                                {touched.upload_file && errors.upload_file && (<div className='text-danger'>{errors.upload_file}</div>)}
                            </Grid>
                        </Grid>
                        <ButtonGroup onBack={training.closeAddNewTraining} submitText={'Save'} />
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default AddNewTraining
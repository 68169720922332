import React, { useCallback, useState } from "react";
import { AntTabs, AntTab } from "../../Components/Tab/AntTab";
import CustomTabPanel from "../../Components/Tab/CustomTabPanel";
import All from "./All";
import Box from "@mui/material/Box";
import GroupReminder from "./GroupReminder";
import NewInvoice from "./NewInvoice";

const AllInvoices = (props: any) => {
  const { others } = props;
  const [tabValue, setTabValue] = useState(0);
  const [isUpdate, setUpdate] = useState(false);
  const [updatPropertyId, setUpdatePropertyId] = useState("");

  const handleTabChange = (e: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const edit = useCallback((value: any) => {
    props.handleOthers({ addinvoice: true });
    setUpdatePropertyId(value.id);
    setUpdate(true);
  }, []);

  return (
    <>
      <Box className="mt-3 billing-center-inner-table">
        {!Object.values(others).includes(true) && (
          <>
            <AntTabs value={tabValue} onChange={handleTabChange}>
              <AntTab label="All" />
              <AntTab label="Paid" />
              <AntTab label="Pending" />
            </AntTabs>
            <CustomTabPanel value={tabValue} index={0} propertyId={""}>
              <All
                status={"All"}
                searchString={props.searchString}
                propertyId={props.propertyId}
                editInvoice={edit}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <All
                status={"Paid"}
                searchString={props.searchString}
                propertyId={props.propertyId}
                editInvoice={edit}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={2}>
              <All
                status={"Pending"}
                searchString={props.searchString}
                propertyId={props.propertyId}
                editInvoice={edit}
              />
            </CustomTabPanel>
          </>
        )}
        {others.groupreminder && (
          <GroupReminder handleOthers={props.handleOthers} />
        )}
        {others.addinvoice && (
          <NewInvoice
            handleOthers={props.handleOthers}
            isUpdate={isUpdate}
            updatPropertyId={updatPropertyId}
            setUpdate={setUpdate}
          />
        )}
      </Box>
    </>
  );
};

// return (
//   <>
//     <Box className="mt-5 no-margin">
//       {!Object.values(others).includes(true) && (
//         <>
//           <AntTabs
//             value={tabValue}
//             onChange={handleTabChange}
//             className="p-0 m-0"
//           >
//             <AntTab label="All(5)" />
//             <AntTab label="Paid" />
//             <AntTab label="Pending" />
//             <AntTab label="Bank Pending" />
//           </AntTabs>
//           <CustomTabPanel value={tabValue} index={0}>
//             <All />
//           </CustomTabPanel>
//           <CustomTabPanel value={tabValue} index={1}>
//             <Paid />
//           </CustomTabPanel>
//           <CustomTabPanel value={tabValue} index={2}>
//             <Pending />
//           </CustomTabPanel>
//           <CustomTabPanel value={tabValue} index={3}>
//             <BankPending />
//           </CustomTabPanel>
//         </>
//       )}
//       {others.groupreminder && (
//         <GroupReminder handleOthers={props.handleOthers} />
//       )}
//       {others.addinvoice && <NewInvoice handleOthers={props.handleOthers} />}
//     </Box>
//   </>
// );
// };

export default AllInvoices;

import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

export const AntTabs = styled(Tabs)({
  marginLeft: "15px",
  "& .MuiTabs-indicator": {
    backgroundColor: "#FF7D73",
  },
});

export const AntTab = styled(Tab)({
  fontSize: "13px",
  fontFamily: "Poppins",
  "&.Mui-selected": {
    color: "#FF7d73",
  },
});

export const ChildTabs = styled(Tabs)({
  '& .MuiTabs-flexContainer':{
    marginLeft:'0px !important'
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#FF7D73",
  },
})

import { AuthorizedLayout } from "../Layouts/AuthorizedLayout";
import SimpleCard from "./SimpleCard";
import Grid from "@mui/material/Grid";
import Layer1 from "./layer1.svg";
import Layer2 from "./layer2.svg";
import Layer3 from "./layer3.svg";
import Coins from "./coins.svg";
import InvoiceBill from "./invoice-bill.svg";
import ColumnChart from "../../Components/Chart/ColumnChart";
import DoughnutChart from "../../Components/Chart/DoughnutChart";
import TopFive from "./TopFive";
import TopFiveTable from "./TopFiveTable";
import OffChart from "../../Components/Chart/OffChart";

const DashBoard = () => {
  return (
    <>
      <AuthorizedLayout>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <SimpleCard
              title="Revenue"
              color="#24BC94"
              date="Feb 2024"
              amount="R15,000"
              image={Layer1}
              prefix={Coins}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <SimpleCard
              title="Expense"
              color="#FF7D73"
              date="Feb 2024"
              amount="R15,000"
              image={Layer2}
              prefix={Coins}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <SimpleCard
              title="Paid Invoice"
              date="Last 30 Days"
              amount="R11,265"
              image={Layer3}
              prefix={InvoiceBill}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <SimpleCard
              title="Open Invoice"
              date="Last 30 Days"
              amount="R21,052"
              image={Layer3}
              prefix={InvoiceBill}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ColumnChart />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <DoughnutChart />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <TopFive />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <OffChart />
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9}>
            <TopFiveTable />
          </Grid>
        </Grid>
      </AuthorizedLayout>
    </>
  );
};

export default DashBoard;

import { Add, NavigateNext } from '@mui/icons-material';
import { Box, Breadcrumbs, Grid, Input, TextField, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState, useContext, useCallback } from 'react';
import { DateInput, ImageInput, TextInput } from '../../Components/Text/TextInput';
import { assetSchema } from "./AssetValidationSchema";
import { AssetContext } from './AssetManagement';
import Barcode from 'react-barcode';
import QRCode from "react-qr-code";
import { Category, Component, Equipment, Location } from './Masters/AssetMasters';
import ApiSearch from '../../Components/AutoCompleteTextView/ApiSearch';
import { PostApiMethod, PutApiMethod } from '../../Utils/ApiService';
import { useAppDispatch } from '../../Store/hooks';
import { showLoader } from '../../Store/loaderSlice';
import { showToaster } from '../../Store/toastSlice';
import { errorHandling } from '../../Utils/HelperService';
import { getAssets } from './Masters/MastersAPI';
import { generateAssetId } from '../../Components/Utilities';

const AddAssets = () => {
    const asset = useContext(AssetContext);
    const { assetsData, setAssetsData, masterData } = asset;
    const [addAssetMaster, setAddAssetMaster] = useState({
        category:false,
        location:false,
        equipment:false,
        component:false
    });
    const dispatch = useAppDispatch();
    const [initialValues , setInitialValues] = useState({
        id: assetsData?.id || "",
        name: assetsData?.name || "",
        description: assetsData?.description || "",
        asset_id: assetsData?.asset_id || generateAssetId(),
        make: assetsData?.make || "",
        model: assetsData?.model || "",
        dateOfPurchase: assetsData?.dateOfPurchase? assetsData?.dateOfPurchase.split("T")[0] : "",
        warranty: assetsData?.warranty || "",
        supplier: assetsData?.supplier || "",
        category: assetsData?.category || "",
        location: assetsData?.location || "",
        equipment: assetsData?.equipment || "",
        component: assetsData?.component || "",
        checkList: assetsData?.checkList || "",
        image: "",
        invoice: "",
        categoryDetails: {
            name: assetsData.HDCategory ? assetsData.HDCategory.name : "",
            id: assetsData.HDCategory ? assetsData.HDCategory.id :"",
        },
        locationDetails: {
            name: assetsData.Location ? assetsData.Location.name :"",
            id:  assetsData.Location ? assetsData.Location.id :"",
        },
        equipmentDetails: {
            name:  assetsData.Equipment ? assetsData.Equipment.name :"",
            id:  assetsData.Equipment ? assetsData.Equipment.name :"",
        },
        componentDetails: {
            name: assetsData.Component ? assetsData.Component.name : "",
            id: assetsData.Component ? assetsData.Component.id :"",
        },
        previewInvoice: assetsData?.invoice ?   assetsData?.invoice : "",
        previewAssetImage: assetsData?.image ?   assetsData?.image : ""
    })
    const [searchLoaders, setSearchLoaders] = useState({category:false,location: false,equipment: false, component:false})
    const [categoryList, setCategoryList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [equipmentList, setEquipmentList] = useState([]);
    const [componentList, setComponentList] = useState([]);


    const searchCategory = useCallback((value) => {
        setSearchLoaders((prev) => ({...prev,category: true}));
        PostApiMethod("petrol-bunk/category/getHpCategory", {
            searchString: value,
            page: "1",
            limit: "50",
          })
            .then((res) => {
                setSearchLoaders((prev) => ({...prev,category: false}));
                setCategoryList(res.data.results.data);
            })
            .catch((e) => {
              setSearchLoaders((prev) => ({...prev,category: false}));
              setCategoryList([]);
            });
    },[]);

    const searchLocation = useCallback((value,categoryId) => {
        setSearchLoaders((prev) => ({...prev,location: true}));
        PostApiMethod("petrol-bunk/location/getLocation", {
            searchString: value,
            page: "1",
            limit: "50",
            category: categoryId
          })
            .then((res) => {
                setSearchLoaders((prev) => ({...prev,location: false}));
                setLocationList(res.data.results.data);
            })
            .catch((e) => {
              setSearchLoaders((prev) => ({...prev,location: false}));
              setLocationList([]);
            });
    },[]);

    const searchEquipment = useCallback((value,categoryId) => {
        setSearchLoaders((prev) => ({...prev,equipment: true}));
        PostApiMethod("petrol-bunk/equipment/getEquipment", {
            searchString: value,
            page: "1",
            limit: "50",
            category: categoryId
          })
            .then((res) => {
                setSearchLoaders((prev) => ({...prev,equipment: false}));
                setEquipmentList(res.data.results.data);
            })
            .catch((e) => {
              setSearchLoaders((prev) => ({...prev,equipment: false}));
              setEquipmentList([]);
            });
    },[]);

    const searchComponent = useCallback((value,equipmentId) => {
        setSearchLoaders((prev) => ({...prev,component: true}));
        PostApiMethod("petrol-bunk/component/getComponent", {
            searchString: value,
            page: "1",
            limit: "50",
            equipment: equipmentId
          })
            .then((res) => {
                setSearchLoaders((prev) => ({...prev,component: false}));
                setComponentList(res.data.results.data);
            })
            .catch((e) => {
              setSearchLoaders((prev) => ({...prev,component: false}));
              setComponentList([]);
            });
    },[]);

   const submit = useCallback((values) => {
    dispatch(showLoader({showLoader: true}))
    const form = new FormData();
    form.append("name",values.name)
    form.append("description",values.description)
    form.append("asset_id",values.asset_id)
    form.append("make",values.make)
    form.append("model",values.model)
    form.append("dateOfPurchase",values.dateOfPurchase)
    form.append("warranty",values.warranty)
    form.append("supplier",values.supplier)
    form.append("category",values.category)
    form.append("location",values.location)
    form.append("equipment",values.equipment)
    form.append("component",values.component)
    form.append("image", values.image)
    if (values.checkList)
    form.append("checkList",values.checkList)

    if (values.invoice)
      form.append("invoice", values.invoice)
if(assetsData?.edit) {

    PutApiMethod(`petrol-bunk/pb-asset/${values.id}`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        dispatch(showLoader({showLoader: false}));
        dispatch(
            showToaster({
              dialogBgColor: "success",
              dialogMsg: "Asset has been updated successfully",
              showDialog: true,
              timer: "5000",
            })
          );
        //   setAssetsData({...assetsData,open: false});
          getAssets(dispatch,assetsData,setAssetsData);
    })
    .catch((e) => {
        dispatch(showLoader({showLoader: false}))
        const error = errorHandling(e);
        dispatch(
          showToaster({
            dialogBgColor: "bg-danger",
            dialogMsg: error,
            showDialog: true,
            timer: "5000",
          })
        );
      });
}else {
    PostApiMethod(`petrol-bunk/pb-asset/add`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        dispatch(showLoader({showLoader: false}))
        dispatch(
            showToaster({
              dialogBgColor: "success",
              dialogMsg: "Asset has been added successfully",
              showDialog: true,
              timer: "5000",
            })
          );
        //   setAssetsData({...assetsData,open: false})
          getAssets(dispatch,assetsData,setAssetsData);

    })
    .catch((e) => {
        dispatch(showLoader({showLoader: false}))
        const error = errorHandling(e);
        dispatch(
          showToaster({
            dialogBgColor: "bg-danger",
            dialogMsg: error,
            showDialog: true,
            timer: "5000",
          })
        );
      });
    }
  
   },[])

    return (
        <>
            <Box className="d-flex justify-content-between mt-4 align-items-center">
                <Box className="d-flex flex-column">
                    <Typography variant="div" className='fw-bold fs-5'>{assetsData?.edit ? "Edit" : "Add New"} Asset</Typography>
                    {/* <Typography variant="div" sx={{color:'gray'}}>Basic Information</Typography> */}
                </Box>
            </Box>
            <Formik
                initialValues={initialValues}
                validationSchema={assetSchema}
                onSubmit={async(values) => {
                    submit(values);
                }}
            >
                {({handleSubmit, handleChange, values, errors, touched, setFieldValue}) => (
                    <Form onSubmit={handleSubmit}>
                        <Grid container spacing={4} className='mt-1'>
                            <Grid item xs={6} sm={6} md={4} lg={4}>
                                <TextInput
                                    label="Asset Name"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                />
                                {touched.name && errors?.name && (<div className='text-danger'>{errors?.name}</div>)}
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4}>
                                <TextInput
                                    label={"Description"}
                                    name="description"
                                    value={values.description}
                                    onChange={handleChange}
                                />
                                {touched.description && errors?.description && (<div className='text-danger'>{errors?.description}</div>)}
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4}>
                                <TextInput 
                                    label="Asset ID"
                                    name="asset_id"
                                    value={values.asset_id}
                                    onChange={handleChange}
                                />
                                {touched.asset_id && errors?.asset_id && (<div className='text-danger'>{errors?.asset_id}</div>)}
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4}>
                                <ApiSearch
                                value={values.categoryDetails}
                                onChange={(newValue) => {
                                   setFieldValue("categoryDetails", {id:newValue.id,name: newValue.name});
                                   setFieldValue("category",newValue.id);
                                   setFieldValue("locationDetails", {id:"",name: ""});
                                   setFieldValue("location","");
                                   setLocationList([]);
                                   setFieldValue("equipmentDetails", {id:"",name: ""});
                                   setFieldValue("equipment","");
                                   setEquipmentList([]);
                                   setFieldValue("componentDetails", {id:"",name: ""});
                                   setFieldValue("component","");
                                   setComponentList([]);

                                }}
                                loading={searchLoaders.category}
                                options={categoryList}
                                searchApi={searchCategory}
                                isOptionEqualToValue={(option, value) => option?.name === value.name}
                                getOptionLabel={(option) => 
                                  option?.name
                                }
                                showTitle={true}
                                label={"Category"}
                                showAddIcon={true}
                                addBtnOnClick={() => {
                                    setAddAssetMaster({ ...addAssetMaster, ...{ category: true } })
                                }}
                                />
                                {touched.category && errors?.category && (<div className='text-danger'>{errors?.category}</div>)}
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4}>
                            <ApiSearch
                                value={values.locationDetails}
                                onChange={(newValue) => {
                                   setFieldValue("locationDetails", {id:newValue.id,name: newValue.name})
                                   setFieldValue("location",newValue.id)
                                }}
                                loading={searchLoaders.location}
                                options={locationList}
                                searchApi={(search)=>{
                                    if (values.category)
                                    searchLocation(search,values.category)}}
                                isOptionEqualToValue={(option, value) => option?.name === value.name}
                                getOptionLabel={(option) => 
                                  option?.name
                                }
                                showTitle={true}
                                label={"Location"}
                                showAddIcon={true}
                                addBtnOnClick={() => {
                                    setAddAssetMaster({ ...addAssetMaster, ...{ location: true } })
                                }}
                                disable={values.category ? false : true}
                                />
                                {touched.location && errors?.location && (<div className='text-danger'>{errors?.location}</div>)}
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4}>
                            <ApiSearch
                                value={values.equipmentDetails}
                                onChange={(newValue) => {
                                   setFieldValue("equipmentDetails", {id:newValue.id,name: newValue.name})
                                   setFieldValue("equipment",newValue.id)
                                   setFieldValue("componentDetails", {id:"",name: ""});
                                   setFieldValue("component","");
                                   setComponentList([]);
                                }}
                                loading={searchLoaders.equipment}
                                options={equipmentList}
                                searchApi={(search)=>{
                                    if (values.category)
                                    searchEquipment(search,values.category)}}
                                isOptionEqualToValue={(option, value) => option?.name === value.name}
                                getOptionLabel={(option) => 
                                  option?.name
                                }
                                showTitle={true}
                                label={"Equipment"}
                                showAddIcon={true}
                                addBtnOnClick={() => {
                                    setAddAssetMaster({ ...addAssetMaster, ...{ equipment: true } })
                                }}
                                disable={values.category ? false : true}
                                />
                               
                                {touched.equipment && errors?.equipment && (<div className='text-danger'>{errors?.equipment}</div>)}
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4}>
                            <ApiSearch
                                value={values.componentDetails}
                                onChange={(newValue) => {
                                   setFieldValue("componentDetails", {id:newValue.id,name: newValue.name})
                                   setFieldValue("component",newValue.id)
                                }}
                                loading={searchLoaders.component}
                                options={componentList}
                                searchApi={(search)=>{
                                    if (values.equipment)
                                      searchComponent(search,values.equipment)}}
                                isOptionEqualToValue={(option, value) => option?.name === value.name}
                                getOptionLabel={(option) => 
                                  option?.name
                                }
                                showTitle={true}
                                label={"Component"}
                                showAddIcon={true}
                                addBtnOnClick={() => {
                                    setAddAssetMaster({ ...addAssetMaster, ...{ component: true } })
                                }}
                                disable={values.equipment ? false : true}
                                />
                                
                                {touched.component && errors?.component && (<div className='text-danger'>{errors?.component}</div>)}
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4}>
                                <TextInput
                                    label={"Make"}
                                    name="make"
                                    value={values.make}
                                    onChange={handleChange}
                                />
                                {touched.make && errors?.make && (<div className='text-danger'>{errors?.make}</div>)}
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4}>
                                <TextInput
                                    label={"Model"}
                                    name="model"
                                    value={values.model}
                                    onChange={handleChange}
                                />
                                {touched.model && errors?.model && (<div className='text-danger'>{errors?.model}</div>)}
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4}>
                                <TextInput
                                    label={"Warranty"}
                                    name="warranty"
                                    value={values.warranty}
                                    onChange={handleChange}
                                />
                                {touched.warranty && errors?.warranty && (<div className='text-danger'>{errors?.warranty}</div>)}
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4}>
                                <TextInput
                                    label={"Supplier"}
                                    name="supplier"
                                    value={values.supplier}
                                    onChange={handleChange}
                                />
                                {touched.supplier && errors?.supplier && (<div className='text-danger'>{errors?.supplier}</div>)}
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4}>
                                <DateInput
                                    label={"Date of Purchase"}
                                    name="dateOfPurchase"
                                    value={values.dateOfPurchase}
                                    onChange={handleChange}
                                />
                                {touched.dateOfPurchase && errors?.dateOfPurchase && (<div className='text-danger'>{errors?.dateOfPurchase}</div>)}
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4}>
                                <ImageInput
                                    label={"Image Upload"}
                                    name="image"
                                    value={values.image}
                                    onChange={(e) => {

                                        setFieldValue("image", e.target.files[0]);

                                    }}                                />
                                {touched.image && errors?.image && (<div className='text-danger'>{errors?.image}</div>)}
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4}>
                                <ImageInput
                                    label={"Invoice Upload"}
                                    name="image"
                                    value={values.invoice}
                                    onChange={(e) => {
                                        setFieldValue("invoice", e.target.files[0]);
                                    }}
                                />
                                {touched.invoice && errors?.invoice && (<div className='text-danger'>{errors?.invoice}</div>)}
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4}>
                                <TextInput
                                    label={"Checklist"}
                                    name="checkList"
                                    value={values.checkList}
                                    onChange={handleChange}
                                />
                                {touched.checkList && errors?.checkList && (<div className='text-danger'>{errors?.checkList}</div>)}
                            </Grid>
                            {
                                values.previewAssetImage && (
                                    <>
                                    <Grid item xs={6} sm={6} md={4} lg={4}>
                                        <div className='d-flex flex-column'>
                                    <Typography variant="div" className='fw-bold'>Asset Image</Typography>
                                        <img src={"https://assetmanagementapi.firebridge.co.za/" + values.previewAssetImage} alt='Firebridge' width={150} height={150}/>
                                        </div>
                                    </Grid>
                                    </>
                                )
                            }
                               {
                                values.previewInvoice && (
                                    <>
                                    <Grid item xs={6} sm={6} md={4} lg={4}>
                                    <div className='d-flex flex-column'>
                                    <Typography variant="div" className='fw-bold'>Invoice</Typography>
                                        <img src={"https://assetmanagementapi.firebridge.co.za/" + values.previewInvoice} alt='Firebridge' width={150} height={150}/>
                                        </div>
                                    </Grid>

                                    </>
                                )
                            }
                        </Grid>
                        <Box className="mt-4">
                            <button type="button" className='btn btn-white me-3' onClick={() => setAssetsData({...assetsData,open:false})}>Back</button>
                            <button type="submit" className='btn btn-danger'>Submit</button>
                        </Box>
                        <Box className="d-flex justify-content-end align-items-center gap-3">
                            <Box className="d-flex flex-column mb-2">
                                <Typography sx={{textAlign:'center',fontWeight:'bold'}}>Barcode</Typography>
                                <Barcode value={initialValues.asset_id} />
                            </Box>
                            <Box className="d-flex flex-column mb-5">
                                <Typography sx={{textAlign:'center',fontWeight:'bold'}}>QR Code</Typography>
                                <QRCode value={initialValues.asset_id} style={{height:"100px", width:"100px", marginTop:"10px"}} />
                            </Box>
                        </Box>
                    </Form>
                )}
            </Formik>
            <Category 
                open={addAssetMaster?.category} 
                onClose={() => setAddAssetMaster({...addAssetMaster,...{category:false}})}
                header={"Create Category"} 
            />
            <Location 
                open={addAssetMaster?.location} 
                onClose={() => setAddAssetMaster({...addAssetMaster,...{location:false}})}
                header={"Create Location"} 
                category={masterData.category}
            />
            <Equipment 
                open={addAssetMaster?.equipment} 
                onClose={() => setAddAssetMaster({...addAssetMaster,...{equipment:false}})}
                header={"Create Equipment"} 
                category={masterData.category}
            />
            <Component 
                open={addAssetMaster?.component} 
                onClose={() => setAddAssetMaster({...addAssetMaster,...{component:false}})}
                header={"Create Component"} 
                equipment={masterData.equipment}
            />
        </>
    )
}

export default AddAssets
import { createSlice } from "@reduxjs/toolkit";

interface LoaderInterfaceData {
    showLoader: false,
    tableLoader: false,
    loaderMessage: '',
}
const defaultValue: LoaderInterfaceData = {
    showLoader: false,
    loaderMessage: "",
    tableLoader: false,
};

export const loaderSlice = createSlice({
    name: "loaderSlice",
    initialState: defaultValue,
    reducers: {
        showLoader: (state,action) => {
            let newValueState = { ...state };
            const payload = action.payload;
            newValueState.loaderMessage = payload.loaderMessage ?? "";
            newValueState.showLoader = payload.showLoader ?? false;
            return newValueState;
        },
        hideLoader: (state) => {
            let newValueState = { ...state };
            newValueState.loaderMessage = "";
            newValueState.showLoader = false;
            return newValueState;
        },
        showTableLoader: (state,action) => {
            let newValueState = { ...state };
            const payload = action.payload;
            newValueState.tableLoader = payload.tableLoader ?? false;
            return newValueState;
        },
        hideTableLoader: (state) => {
            let newValueState = { ...state };
            newValueState.tableLoader = false;
            return newValueState;
        },
    },
});
export const { showLoader,hideLoader,showTableLoader,hideTableLoader } = loaderSlice.actions;
export default loaderSlice.reducer;
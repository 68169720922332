import React, { useEffect, useState } from "react";
import { UnAuthorizedLayout } from "../Layouts/UnAuthorizedLayout";
import { useAppDispatch } from "../../Store/hooks";
import { setIsAuthenticated, setUserDetails } from "../../Store/authSlice";
import Grid from "@mui/material/Grid";
import { useNavigate, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import { PostApiMethod } from "../../Utils/ApiService";
import { showToaster } from "../../Store/toastSlice";
import { showLoader } from "../../Store/loaderSlice";
import Logo from "../../Assets/images/logo.png"
import { Box, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import * as Yup from "yup";


// form field validation schema
const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Password must be 8 character length")
      .max(20, "Password must be less than 20 character")
      .matches(
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/,
        "Password should contain atleast one lowercase, one uppercase, one numeric and one special character only"
      )
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords don't match")
      .required("Confirm password is required"),
  });

export default function ResetPassword() {
    const initialValues = {
    password: "",
    confirmPassword: "",
    };

    const dispatch = useAppDispatch();
    const [showPassword, setShowPassword] = useState(false);
    let { id } = useParams();
    console.log(useParams())
    const navigate = useNavigate()

    useEffect(() => {
        validateToken()
    },[])

    const validateToken = async() => {
        dispatch(showLoader({showLoader:true}))
        console.log(id)
        try{
            let res = await PostApiMethod("auth/verifyToken",{token:id})
            if(res["data"]["code"] !== 200){
                navigate("/sign-in")
            }
        }catch(error){
            dispatch(showToaster({
                dialogBgColor: "bg-danger",
                dialogMsg: error.response.data.message,
                showDialog: true,
                timer: "5000",
            }))
        }
        dispatch(showLoader({showLoader:false}))
    }

    const { values, handleChange, handleSubmit, errors, touched } = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
        dispatch(showLoader({ showLoader: true }));
        try{
            let res = await PostApiMethod("auth/update-password",{token:id,...values})
            if(res["data"]["code"] === 200){
                dispatch(showToaster({
                    dialogBgColor: "bg-success",
                    dialogMsg: res?.data?.message,
                    showDialog: true,
                    timer: "5000", 
                }))
                navigate("/sign-in")
            }
        }catch(error){
            dispatch(showToaster({
                dialogBgColor: "bg-danger",
                dialogMsg: error.response.data.message,
                showDialog: true,
                timer: "5000",
            }))
        }
        dispatch(showLoader({showLoader:false}))
        },
    });

    return (
        <UnAuthorizedLayout>
        <Box className="login">
            <img alt="logo" src={Logo} className='logo' />
            <Typography sx={{ color: '#6F7275' }}>Property Management System</Typography>

            <Box className="mt-4">
            <Typography variant='h3'>Reset Password</Typography>
            </Box>
            <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12} className="mt-3">
                <Typography className="poppins-family" >Password:</Typography>
                <TextField
                    variant="standard"
                    type="password"
                    onChange={handleChange}
                    name="password"
                    className="w-100 mt-1"
                    value={values.password}
                />
                {(errors.password && touched.password) && (
                    <Typography className="text-danger">{errors.password}</Typography>
                )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} className="mt-2">
                <Typography className="poppins-family">Confirm Password:</Typography>
                <TextField
                    variant="standard"
                    type={showPassword ? "text" : "password"}
                    name="confirmPassword"
                    onChange={handleChange}
                    className="w-100 mt-1"
                    value={values.confirmPassword}
                    InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                        <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                        </InputAdornment>
                    )
                    }}
                    fullWidth
                />
                {(errors.confirmPassword && touched.confirmPassword) && (
                    <Typography className="text-danger">{errors.confirmPassword}</Typography>
                )}
                </Grid>
            </Grid>
            <Box className="d-grid mt-5">
                <Button className="btn-danger" type="submit" sx={{ height: '40px' }} >
                    Submit
                </Button>
            </Box>
            </form>
        </Box>
        
        </UnAuthorizedLayout>
    );
}
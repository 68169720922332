import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Profile from '../../Assets/images/profile.png';
import { Autocomplete, IconButton, Menu, MenuItem, Pagination, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { GetApiMethod, PostApiMethod } from '../../Utils/ApiService';
import { isNotEmpty } from '../../Utils/HelperService';
import EditCalendarTwoToneIcon from '@mui/icons-material/EditCalendarTwoTone';
import { useLocation, useNavigate } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';
import FormControl from '@mui/material/FormControl';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { showToaster } from '../../Store/toastSlice';
import { showLoader } from '../../Store/loaderSlice';
import { useDispatch } from 'react-redux';
const ITEM_HEIGHT = 48;


const AssignVendorsList = (props: any) => {
    // const [property, setProperty] = useState("")
    let navigate = useNavigate();
    let dispatch = useDispatch();
    const [state, setState] = useState<any>("")
    const [loading, setLoading] = useState(false);
    const [addVendors, setAddVendors] = useState(false);
    const [vendorsList, setVendorsList] = useState(props.vendorsList);
    const [vendorsCount, setVendorsCount] = useState(0);
    const [label, setLabel] = useState("Add");
    const [btnLabel, setBtnLabel] = useState("Add");
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(0);
    const [page1, setPage1] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [propertyList, setPropertyList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    useEffect(() => {
        setState(props.helpDeskdata);
    }, [props.helpDeskdata])
    console.log("props.helpDeskdata", props.helpDeskdata);
    useEffect(() => {
        fetchVendors();
        fetchProperty();
    }, [])
    useEffect(() => {
        fetchVendors();
    }, [page1, limit])
    const fetchVendors = async () => {
        setLoading(true)
        let formdata = {
            role: 'PMA',
            page: page1,
            limit: limit,
            // searchString: search
        }
        let response = await PostApiMethod('user/getUser', formdata)
        if (response["data"]["error"] === false) {
            setVendorsList(response.data.results.data)
            setVendorsCount(response.data.results.totalCount)
            setLoading(false)
        }
        else {
            console.log("response", response)
            setLoading(false)
        }
    }
    const fetchProperty = async () => {
        let response = await PostApiMethod("property/properties/list", {})
        if (response["data"]["error"] === false) {
            setPropertyList(response.data.results.data);
            setLoading(false)
        }
        else {
            console.log("response", response)
            setLoading(false)
        }
    }

    const handleChange = (name: any, value: any) => {
        setState({ ...state, [name]: value })
    }
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        console.log("newPage", newPage)
        setPage(newPage);
        setPage1(newPage + 1);
        // props.parentData(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setLimit(parseInt(event.target.value, 10));
        setPage(0);
        // props.parentData(event.target.value);
        // props.limit(parseInt(event.target.value, 10));
    };
    const handleFilterSubmit = (e: any) => {

    }
    const handleStatusSubmit = async (data: any) => {
        setLoading(true)
        let formdata = {
            help_desk_id: state.id,
            vendor_id: data.toString(),
            priority: state.priority,
        }
        let response = await PostApiMethod('property/help-desk/assign-help-desk', formdata)
        if (response && response["data"]["error"] === false) {
            dispatch(
                showToaster({
                    dialogBgColor: "bg-success",
                    dialogMsg: response["data"]["message"],
                    showDialog: true,
                    timer: "3000",
                })
            );
            dispatch(showLoader({ showLoader: false }));
            setLoading(false)
            props.pageStatus("listHelpDesk")

        }
        else {
            console.log("response", response)
            setLoading(false)
            dispatch(
                showToaster({
                    dialogBgColor: "bg-danger",
                    //   dialogMsg: e.response.data.message,
                    dialogMsg: "Error, Try again",
                    showDialog: true,
                    timer: "5000",
                })
            );
            dispatch(showLoader({ showLoader: false }));
        }
    }
    // assign-help-desk
    //
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [cOpen, setcOpen] = useState<Number>(0)
    const handleClick = (id: Number, event: React.MouseEvent<HTMLElement>) => {
        setcOpen(cOpen === id ? 0 : id)
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e: any, data: any) => {
        setcOpen(0)
        setAnchorEl(null);
        if (e === "Assign") {
            handleStatusSubmit(data);
        }
        if (e === "Delete") {
            // console.log("data", data)
            // deleteHelpDesk(data.id);
        }
    };
    const options = [
        'Assign',
        'Delete',
    ];
    // 
    return (
        <>
            <Box sx={{ mt: 3 }}>
                <Typography className="poppins-family fw-bold">Assign Vendors</Typography>
                <Paper>
                    <Typography className="poppins-family fw-bold">All Vendors</Typography>

                    <Grid container spacing={2} sx={{ m: 1 }}>
                        <Grid item lg={3} md={4} sm={12} xs={12}>
                            <Typography className='poppins-family'>Select Property</Typography>
                            <Autocomplete
                                id="property_id"
                                size="small"
                                options={propertyList}
                                getOptionLabel={(option: any) => option.property_name}
                                onChange={(event: any, newValue: any) => { handleChange("property_id", newValue.id) }}
                                renderInput={(params) => (
                                    <TextField
                                        name="property_id"
                                        {...params}
                                        variant="standard"
                                        // label="Property"
                                        placeholder="Favorites"
                                    />
                                )}
                            />

                        </Grid>
                        <Grid item lg={3} md={4} sm={12} xs={12}>
                            <Typography className='poppins-family'>Role</Typography>
                            <Autocomplete
                                id="role_id"
                                size="small"
                                options={roleList}
                                getOptionLabel={(option: any) => option.role_name}
                                onChange={(event: any, newValue: any) => { handleChange("role_id", newValue.id) }}
                                renderInput={(params) => (
                                    <TextField
                                        name="role_id"
                                        {...params}
                                        variant="standard"
                                        // label="Role"
                                        placeholder="Favorites"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item lg={3} md={4} sm={12} xs={12}>
                            <Button variant="outlined" color="error" className='mt-4' onClick={handleFilterSubmit}>Apply</Button>
                        </Grid>
                    </Grid>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>SI.No</TableCell>
                                    <TableCell>Profile Image</TableCell>
                                    <TableCell>Full Name</TableCell>
                                    <TableCell>Assign Property</TableCell>
                                    <TableCell>Role</TableCell>
                                    <TableCell>Contact</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {vendorsList && vendorsList.map((vendor: any, index: any) =>
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{isNotEmpty(vendor.profilePicture) ? <img src={vendor.profilePicture} alt="profile" width={40} height={40} /> : <img src={Profile} alt="profile" width={40} height={40} />}</TableCell>
                                        <TableCell>{isNotEmpty(vendor.firstName) ? vendor.firstName : "" + " " + isNotEmpty(vendor.lastName) ? vendor.lastName : ""}</TableCell>
                                        <TableCell>{isNotEmpty(vendor.propertyId) ? vendor.propertyId : ""}</TableCell>
                                        <TableCell>{isNotEmpty(vendor.role) ? vendor.role : ""}</TableCell>
                                        <TableCell>{isNotEmpty(vendor.phoneNumber) ? vendor.phoneNumber : ""}</TableCell>
                                        <TableCell>{vendor.isActive === true ? "Active" : "InActive"}</TableCell>
                                        <TableCell>
                                            <IconButton
                                                aria-label="more"
                                                id="long-button"
                                                aria-controls={open ? 'long-menu' : undefined}
                                                aria-expanded={open ? 'true' : undefined}
                                                aria-haspopup="true"
                                                onClick={(e) => { handleClick(vendor.id, e) }}
                                            >
                                                <Typography className='poppins-family' color="primary"></Typography>
                                                <MoreVertOutlinedIcon color="primary" />
                                            </IconButton>
                                            <Menu
                                                id="long-menu"
                                                MenuListProps={{
                                                    'aria-labelledby': 'long-button',
                                                }}
                                                anchorEl={anchorEl}
                                                open={cOpen === vendor.id}
                                                onClose={handleClose}
                                                PaperProps={{
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5,
                                                        width: '20ch',
                                                    },
                                                }}
                                            >
                                                {options.map((option: any) => (
                                                    <MenuItem key={option} onClick={() => { handleClose(option, vendor.id) }}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {vendorsCount ?
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={vendorsCount}
                            rowsPerPage={limit}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            style={{ margin: 0 }}
                        />
                        : ""}
                </Paper>
            </Box>
        </>
    )
}

export default AssignVendorsList
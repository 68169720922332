import React, { useEffect, useCallback, useState } from 'react';
import { AuthorizedLayout } from '../Layouts/AuthorizedLayout';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import { CardContent, Table, TableRow, TableBody, TableCell, TableContainer, TableHead, TableFooter } from '@mui/material';
import EngineeringIcon from '@mui/icons-material/Engineering';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { useAppDispatch } from "../../Store/hooks";
import { showLoader } from "../../Store/loaderSlice";
import { DeleteApiMethod, PostApiMethod } from "../../Utils/ApiService";
import { showToaster } from "../../Store/toastSlice";
import Pagination from "../../Components/Pagination/Pagination";
import AddEditType from './AddEditType';
import Actions from '../../Components/Actions/Actions';
import { errorHandling } from '../../Utils/HelperService';

const InvoiceType = () => {
    const [typeList, setTypeList] = useState([]);
    const [page, setPage] = useState({ page: 1, limit: 10, count: 0 })
    const [loading, setLoading] = useState(true);
    const dispatch = useAppDispatch();
    const [openForm, setOpenForm] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [value, setValue] = useState({ name: "", tax: "", taxType: "", owner_user_id: "", id: "" })
    useEffect(() => {
        getInvoiceType(0, 10)
    }, [])

    const rowsPerPage = useCallback((val: any) => {
        setPage({ ...page, page: val.page, limit: val.limit })
        getInvoiceType(val.page, val.limit);
    }, [page])

    const getInvoiceType = useCallback(async (page: number, limit: number) => {
        let response: any;
        setLoading(true);
        dispatch(showLoader({ showLoader: true }));
        try {
            response = await PostApiMethod("property/invoice-type/list", { page: page, limit: limit });
            setTypeList(response.data.results.data);
            dispatch(showLoader({ showLoader: false }));
            setPage((prevPage) => ({
                ...prevPage,
                count: response["data"]["results"]["totalCount"]
            }))

        } catch (err: any) {
            dispatch(showLoader({ showLoader: false }));
            dispatch(
                showToaster({
                    dialogBgColor: "bg-danger",
                    dialogMsg: err.response.data.message,
                    showDialog: true,
                    timer: "5000",
                })
            );
        }
        setLoading(false);
    }, []);


    const delteType = useCallback((id:string)=>{
        dispatch(showLoader({showLoader:true}));
        DeleteApiMethod("property/invoice-type/"+id).then((res) => {
            getInvoiceType(page.page,page.limit);
            dispatch(showLoader({ showLoader: false }));
            dispatch(
                showToaster({
                    dialogBgColor: "success",
                    dialogMsg: "Deleted successfully",
                    showDialog: true,
                    timer: "5000",
                }));

        }).catch((e)=>{
            const error = errorHandling(e);
            dispatch(
                showToaster({
                    dialogBgColor: "bg-danger",
                    dialogMsg: error,
                    showDialog: true,
                    timer: "5000",
                })
            );
            dispatch(showLoader({ showLoader: false }));

        })
    },[]);

    return (<>
        {/* // <AuthorizedLayout>
        //     <Card variant="outlined" sx={{ borderRadius: '8px' }} className="border-0 default-page-height">
        //         <CardContent> */}
                    <Box className="d-flex justify-content-between align-items-center">
                        <Box className="d-flex align-items-center">
                            {/* <EngineeringIcon className='text-danger fs-20' />
                            <Typography className="poppins-family fw-bold ms-1">Invoice Type</Typography> */}
                        </Box>
                        <Box>
                            <button className="btn-danger" onClick={() => {
                                setValue({ name: "", tax: "", taxType: "Percentage", owner_user_id: "", id: "" })
                                setOpenForm(true);
                                setIsUpdate(false);
                            }}><AddIcon className='fs-23 ' />Add New Type</button>
                        </Box>
                    </Box>
                    <Box className="mt-1">
                        {!loading && <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Tax</TableCell>
                                        <TableCell>Tax Type</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {typeList.map((val: any, key) => {
                                        return (
                                            <TableRow key={key}>
                                                <TableCell>{val["name"]}</TableCell>
                                                <TableCell>{val["tax"]}</TableCell>
                                                <TableCell>{val["tax_type"]}</TableCell>
                                                <TableCell>
                                                    <Actions
                                                        name={"More"}
                                                        data={[
                                                            {
                                                                name: "Edit", onClick: () => {
                                                                    setOpenForm(true);
                                                                    setIsUpdate(true);
                                                                    setValue({ name: val.name, tax: val.tax, taxType: val.tax_type, id: val.id, owner_user_id: val.owner_user_id });

                                                                }, color: "text-primary"
                                                            },
                                                            {
                                                                name: "Delete", onClick: () => {
                                                                    delteType(val.id);
                                                                }, color: "text-danger"
                                                            }
                                                        ]}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                                {typeList.length !== 0 && (<TableFooter>
                                    <TableRow>
                                        <Pagination pagination={page} colSpan={6} rowsPerPageChange={rowsPerPage} />
                                    </TableRow>
                                </TableFooter>)}
                            </Table>
                            {typeList.length === 0 && (
                                <Box className="d-flex justify-content-center w-100">
                                    <Typography>No Data</Typography>
                                </Box>
                            )}
                        </TableContainer>}
                        <AddEditType isUpdate={isUpdate} isOpen={openForm} setIsOpen={setOpenForm} value={value} refreshPage={() => {
                            getInvoiceType(page.page, page.limit);
                        }} />
                    </Box>
        {/* //         </CardContent>
        //     </Card>
        // </AuthorizedLayout> */}
        </>
    )
}

export default InvoiceType
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "../../../Store/hooks";
import { DeleteApiMethod, PostApiMethod } from "../../../Utils/ApiService";
import { showToaster } from "../../../Store/toastSlice";
import { showLoader } from "../../../Store/loaderSlice";
import Actions from "../../../Components/Actions/Actions";
import Pagination from "../../../Components/Pagination/Pagination";
import ColorText from "../../../Components/Text/ColorText";
import HelpDeskTypeFormModal from "./HelpDeskTypeFormModal";
import { errorHandling, isNotEmpty } from "../../../Utils/HelperService";
import AddIcon from '@mui/icons-material/Add';

const HelpDeskTypeList = () => {
  const [typeList, setTypeList] = useState([]);
  const dispatch = useAppDispatch();
  const [page, setPage] = useState({ page: 1, limit: 10, count: 0 });
  const [formValue, setFormValue] = useState({
    value: { name: "", status: true, image: null, id: "" },
    isUpdate: false,
  });
  const [openForm, setOpenForm] = useState(false);

  const rowsPerPage = useCallback(
    (val: any) => {
      setPage({ ...page, page: val.page, limit: val.limit });
      fetchType(val.page, val.limit);
    },
    [page]
  );

  const fetchType = useCallback(async (page: number, limit: number) => {
    let response: any;
    dispatch(showLoader({ showLoader: true }));
    try {
      response = await PostApiMethod("helpDeskType/list", {
        page: page,
        limit: limit,
      });
      setTypeList(response.data.results.data);
      dispatch(showLoader({ showLoader: false }));
      setPage((prevPage) => ({
        ...prevPage,
        count: response["data"]["results"]["totalCount"],
      }));
    } catch (err: any) {
      dispatch(showLoader({ showLoader: false }));
      dispatch(
        showToaster({
          dialogBgColor: "bg-danger",
          dialogMsg: err.response.data.message,
          showDialog: true,
          timer: "5000",
        })
      );
    }
  }, []);

  useEffect(() => {
    fetchType(0, 10);
  }, []);

  const deleteType = useCallback(
    (typeId: string) => {
      dispatch(showLoader({ showLoader: true }));
      DeleteApiMethod("helpDeskType/" + typeId)
        .then((res) => {
          fetchType(page.page, page.limit);
          dispatch(showLoader({ showLoader: false }));
          dispatch(
            showToaster({
              dialogBgColor: "success",
              dialogMsg: "Deleted successfully",
              showDialog: true,
              timer: "5000",
            })
          );
        })
        .catch((e) => {
          const error = errorHandling(e);
          dispatch(
            showToaster({
              dialogBgColor: "bg-danger",
              dialogMsg: error,
              showDialog: true,
              timer: "5000",
            })
          );
          dispatch(showLoader({ showLoader: false }));
        });
    },
    [page]
  );

  return (
    <>
      <Box className="d-flex justify-content-between align-items-center">
        <Box className="d-flex align-items-center">
          {/* <EngineeringIcon className='text-danger fs-20' />
                            <Typography className="poppins-family fw-bold ms-1">Invoice Type</Typography> */}
        </Box>
        <Box>
          <button
            className="btn-danger"
            onClick={() => {
            setOpenForm(true);
                setFormValue({
                  value: { name: "", status: true, image: null, id: "" },
                  isUpdate: false,
                });
            }}
          >
            <AddIcon className="fs-23 " />
            Add New Type
          </button>
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <TableContainer className="table-striped">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Thumbnail</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {typeList.map((val: any, key) => {
                return (
                  <TableRow key={key}>
                    <TableCell className="table-text-color">
                      {val.name}
                    </TableCell>
                    <TableCell>
                      {isNotEmpty(val.thumbnail) ? (
                        <img
                          src={
                            process.env.REACT_APP_API_URL + "/" + val.thumbnail
                          }
                          alt="profile"
                          width={40}
                          height={40}
                          className="thumbnail-profile"
                        />
                      ) : (
                        "-"
                      )}
                    </TableCell>

                    <TableCell className="table-text-color active-text">
                      {val.status ? (
                        <ColorText color={"#24BC94"}>Active</ColorText>
                      ) : (
                        <ColorText color={"red"}>Deactive</ColorText>
                      )}
                    </TableCell>

                    <TableCell>
                      <Actions
                        name={"More"}
                        data={[
                          {
                            name: "Edit",
                            onClick: () => {
                              setOpenForm(true);
                              setFormValue({
                                value: {
                                  name: val.name,
                                  status: val.status,
                                  image: val.thumbnail,
                                  id: val.id,
                                },
                                isUpdate: true,
                              });
                            },
                            color: "text-primary",
                          },
                          {
                            name: "Delete",
                            onClick: () => {
                              deleteType(val.id);
                            },
                            color: "text-danger",
                          },
                        ]}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            {typeList.length !== 0 && (
              <TableFooter>
                <TableRow>
                  <Pagination
                    pagination={page}
                    colSpan={6}
                    rowsPerPageChange={rowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
          {typeList.length === 0 && (
            <Box className="d-flex justify-content-center w-100">
              <Typography>No Data</Typography>
            </Box>
          )}
        </TableContainer>
      </Box>
      <HelpDeskTypeFormModal
        isOpen={openForm}
        setIsOpen={setOpenForm}
        value={formValue.value}
        isUpdate={formValue.isUpdate}
        refreshPage={() => {
          fetchType(page.page, page.limit);
        }}
      />
    </>
  );
};

export default HelpDeskTypeList;

import React, { useCallback, useEffect, useState } from "react";
import { AuthorizedLayout } from "../Layouts/AuthorizedLayout";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import propertyImg from "../../Assets/images/icons/property.png";
import styles from "./propertyDetails.module.css";
import editImg from "../../Assets/images/icon_edit_white.svg";
import type { TableProps } from "antd";
import { showLoader } from "../../Store/loaderSlice";
import { PostApiMethod1 } from "./api_service";
import { useAppDispatch } from "../../Store/hooks";
import { showToaster } from "../../Store/toastSlice";
import samplImg from "../../Assets/images/sample images/sample.jpg";
import SubHeading1 from "./components/subHeading1";
import { ButtonPrimary } from "../../Components/Button/ButtonPrimary";
import { GetApiMethod } from "../../Utils/ApiService";
import { isNotEmpty } from "../../Utils/HelperService";
import Pagination from "../../Components/Pagination/Pagination";

export default function PropertyDetails() {
  const [UnitList, setUnitList] = useState<any[]>([]);
  const location = useLocation();
  const passedState = location.state;
  const dispatch = useAppDispatch();
  const isApiVAlues = true;
  const array = [1, 2, 3, 4, 5];
  const navigate = useNavigate();

  function editinfo() {
    navigate("/Property/" + passedState.id, { state: passedState });

    console.log("passedState: ", passedState);
  }
  interface DataType {
    key: string;
    sIno: string;
    propertyType: String;
    property: String;
    noOfUnits: number;
    description: String;
    address: string;
  }
  const columns: TableProps<DataType>["columns"] = [
    {
      title: "SI.no",
      key: "sIno",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Unit Name",
      dataIndex: "property_type",
      key: "property_type",
    },

    {
      title: "Bedroom",
      dataIndex: "property_name",
      key: "property_name",
    },
    {
      title: "Bathroom",
      dataIndex: "number_of_unit",
      key: "number_of_unit",
    },
    {
      title: "Kitchen",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Square Feet",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Amentities",
      key: "View Details",
      dataIndex: "description",
    },
    {
      title: "Parking",
      key: "action",
      dataIndex: "description",
    },
    {
      title: "Kitchen",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Condition",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Image",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Availablity",
      dataIndex: "description",
      key: "description",
    },
  ];
  const [page, setPage] = useState({ page: 1, limit: 10, count: 0 });
  const [propertyDetails, setPropertyDetails] = useState();
console.log(propertyDetails)
  const fetchPropertyList = async () => {
    dispatch(showLoader({ showLoader: true }));
    try {
      const body = {
        property_id: passedState.id,
        user_id: passedState.owner_user_id,
      };
      const res = await PostApiMethod1(
        "property/unit/getUnitByPropertyId",
        body
      );
      if (res["data"]["code"] === 200) {
        const responseData = res["data"]["results"]["data"];
        setUnitList(responseData["rows"]);
        setPage((prevPage) => ({
          ...prevPage,
          count: res["data"]["results"]["data"]["count"],
        }));
        dispatch(showLoader({ showLoader: false }));
      }
    } catch (e: any) {
      dispatch(
        showToaster({
          dialogBgColor: "bg-danger",
          dialogMsg: "Error, Try again", // dialogMsg: e.response.data.message,

          showDialog: true,
          timer: "5000",
        })
      );
      dispatch(showLoader({ showLoader: false }));
    }
  };

  const getPropertyDetails = useCallback(() => {
    dispatch(showLoader({ showLoader: true }));

    GetApiMethod("/property/properties/" + passedState.id)
      .then((res) => {
        setPropertyDetails(res.data.results.data);
        dispatch(showLoader({ showLoader: false }));
      })
      .catch((e) => {
        dispatch(showLoader({ showLoader: false }));
      });
  }, []);

  useEffect(() => {
    fetchPropertyList();
    getPropertyDetails();
    return () => {};
  }, [setUnitList]);

  const rowsPerPage = useCallback(
    (val: any) => {
      setPage({ ...page, page: val.page, limit: val.limit });
      // fetchType(val.page, val.limit);
    },
    [page]
  );

  console.log(passedState.FileManagers);

  return (
    <>
      <AuthorizedLayout>
        <Box className="h-48 d-flex justify-content-between align-items-center">
          <Box className="d-flex align-items-center">
            <img src={propertyImg} alt="Property" width={20} />
            <Typography className="psemiboldfont page-header-text">
              Property
            </Typography>
          </Box>
          <Box>
            <ButtonPrimary
              className="h-30"
              text="Edit Info"
              onClick={editinfo}
              imgSrc={editImg}
            />
          </Box>
        </Box>
        {/* <Box className={styles.header}>
            <Box className="d-flex justify-content-between align-items-center">
              <Box className="d-flex align-items-center">
                <img
                  src={propertyImg}
                  alt="Property"
                  width={20}
                  className={styles.smallImg}
                />
                <Typography className="poppins-family fw-bold">
                  Property
                </Typography>
              </Box>
            </Box>

            <ButtonPrimary
              className="h-30"
              text="Edit Info"
              onClick={editinfo}
              imgSrc={editImg}
            />
          </Box> */}
        <Box className={styles.box}>
          <Box className="my-30" sx={{ margin: "30px 0" }}>
            {/* <SubHeading1
                headingProp="Property Details"
                className="m-0"
                sx={{ margin: "30px 0" }}
              /> */}
            <Box
              className={`${styles.propertySection} pt-26 pb-36`}
              sx={{ paddingTop: "26px", paddingBottom: "36px" }}
            >
              {passedState.FileManagers.length > 0 ? (
                <img
                  className={`${styles.mainImg} radius-8`}
                  src={
                    process.env.REACT_APP_API_URL +
                    "/" +
                    passedState.FileManagers[0].folderPath
                  }
                  alt="property"
                  width="150px"
                  height="150px"
                />
              ) : (
                <img
                  className={`${styles.mainImg} radius-8`}
                  src={samplImg}
                  alt="property"
                  width="150px"
                  height="150px"
                />
              )}

              <Box className="InnerHeadingSec">
                {/* <Box className={styles.nameBox}>
                    <h4 className={styles.head2}>
                      {" "}
                      {PlaceIcon}
                      {isApiVAlues
                        ? passedState.property_name
                        : "Property Name"}
                    </h4>
                    <p className={styles.description}>
                      {isApiVAlues ? passedState.address : "Address"}
                    </p>
                  </Box> */}
                <Box className={styles.nameBox}>
                  <h4 className={styles.head2}>Description</h4>
                  <p className={styles.descriptiom}>
                    {isApiVAlues ? passedState.description : "Description"}
                  </p>
                </Box>
              </Box>
            </Box>
            <Box className={styles.imgSection}>
              <h5 className={styles.head3}>Image Gallery</h5>
              <Box>
                {(isApiVAlues ? passedState.FileManagers.length : 0) === 0 ? (
                  <p>No Images</p>
                ) : (
                  passedState.FileManagers.map((val: any, index: number) => (
                    <img
                      src={process.env.REACT_APP_API_URL + "/" + val.folderPath}
                      alt="property"
                      className={`${styles.imgList} radius-8`}
                      width={100}
                      height={100}
                    />
                  ))
                )}
                {/* <Box className={styles.galleryIMg}> */}
                {/* {array.map((val) => (
                      <img
                        src={samplImg}
                        alt="property images"
                        className={`${styles.imgList} radius-8`}
                        width={100}
                        height={100}
                      />
                    ))} */}
                {/* </Box> */}
                <Box
                  className={`${styles.detailsSection} table-striped table-striped-header`}
                  sx={{ margin: "30px 0" }}
                >
                  <h5 className={styles.head3}>Property Details</h5>
                  <table width="100%">
                    <tr>
                      <th>Property Name</th>
                      <td>
                        {propertyDetails
                          ? propertyDetails["property_name"]
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "50%" }}>Total Unit</th>
                      <td>
                        {isApiVAlues
                          ? passedState.number_of_unit
                          : "No of units"}
                      </td>
                    </tr>
                    <tr>
                      <th>Available For Lease</th>
                      <td>
                        {isApiVAlues
                          ? passedState.property_type === "lease"
                            ? "Available"
                            : "Not for Lease"
                          : "Leaase oe own"}
                      </td>
                    </tr>
                    {/* <tr>
                        <th>Current Tenants</th>
                        <td>0</td>
                      </tr> */}
                    <tr>
                      <th>Average Rent</th>
                      <td>
                        {propertyDetails
                          ? propertyDetails["lease_amount"]
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Address</th>
                      <td>
                      {propertyDetails ? (
                        <>
                          {propertyDetails["address"]}
                          <br />
                          {propertyDetails["city_id"]} {" , "}
                          {propertyDetails["state_id"]} <br />
                          {propertyDetails["country_id"]}
                          {" , "}
                          {propertyDetails["zip_code"]}
                          <br />
                          {propertyDetails["map_link"]}
                        </>
                      ) : (
                        "-"
                      )}
                      </td>
                      {/* <td>{propertyDetails ? `${propertyDetails['address']}  ,  ${propertyDetails['city_id']} , ${propertyDetails['state_id']} , ${propertyDetails['country_id']},  ${propertyDetails['zip_code']} ,  ${propertyDetails['map_link']}  `: "-"}</td> */}
                    </tr>
                    {/* <tr>
                        <th>Security Deposit</th>
                        <td>100000</td>
                      </tr>
                      <tr>
                        <th>Late Fee</th>
                        <td>100</td>
                      </tr> */}
                  </table>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <TableContainer className="table-striped">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>SI.No</TableCell>
                          <TableCell>Unit Name</TableCell>
                          <TableCell>Bedroom</TableCell>
                          <TableCell>Bathroom</TableCell>
                          <TableCell>Kitchen</TableCell>
                          <TableCell>Square Feet</TableCell>
                          <TableCell>Amentities</TableCell>
                          <TableCell>Parking</TableCell>
                          <TableCell>Condition</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Image</TableCell>
                          {/* <TableCell>Availablity</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {UnitList.map((val: any, key) => {
                          return (
                            <TableRow key={key}>
                              <TableCell>{key + 1}</TableCell>
                              <TableCell className="table-text-color">
                                {val.unit_name}
                              </TableCell>
                              <TableCell className="table-text-color">
                                {val.bedroom}
                              </TableCell>
                              <TableCell className="table-text-color">
                                {val.bath}
                              </TableCell>
                              <TableCell className="table-text-color">
                                {val.kitchen}
                              </TableCell>
                              <TableCell className="table-text-color">
                                {val.square_feet}
                              </TableCell>
                              <TableCell className="table-text-color">
                                {val.amenities}
                              </TableCell>
                              <TableCell className="table-text-color">
                                {val.parking}
                              </TableCell>
                              <TableCell className="table-text-color">
                                {val.condition}
                              </TableCell>
                              <TableCell className="table-text-color">
                                {val.description}
                              </TableCell>
                              <TableCell>
                                {isNotEmpty(val.image) ? (
                                  <img
                                    src={
                                      process.env.REACT_APP_API_URL +
                                      "/" +
                                      val.image
                                    }
                                    alt="profile"
                                    width={40}
                                    height={40}
                                    className="thumbnail-profile"
                                  />
                                ) : (
                                  "-"
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                      {UnitList.length !== 0 && (
                        <TableFooter>
                          <TableRow>
                            <Pagination
                              pagination={page}
                              colSpan={6}
                              rowsPerPageChange={rowsPerPage}
                            />
                          </TableRow>
                        </TableFooter>
                      )}
                    </Table>
                  </TableContainer>
                  {UnitList.length === 0 && (
                    <Box className="d-flex justify-content-center w-100">
                      <Typography>No Data</Typography>
                    </Box>
                  )}
                </Box>
                {/* <section className={styles.allUnitSection}>
                    <Box className={styles.unitTableBox}>
                      <Table
                        columns={columns}
                        dataSource={UnitList}
                        locale={{
                          emptyText: "No List Details",
                        }}
                      />
                    </Box>
                  </section> */}
              </Box>
            </Box>
          </Box>
        </Box>
      </AuthorizedLayout>
    </>
  );
}

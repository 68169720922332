import { TableCell, TableRow } from '@mui/material';
import React from 'react';

const NoData = (props) => {
    const { colSpan } = props;
    return(
        <>
            <TableRow>
                <TableCell colSpan={colSpan} align="center">No Data</TableCell>
            </TableRow>
        </>
    )
}

export default NoData;
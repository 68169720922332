import React, { useState, useContext } from 'react';
import { TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import { Grid, Box, FormLabel, TextField, Menu, MenuItem, IconButton, Typography } from '@mui/material';
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";
import { TrainingContext } from './TrainingManagement';

const TrainingCertificates = () => {
    const training = useContext(TrainingContext);
    const [anchorEl, setAnchorEl] = useState(null)
    const [cOpen, setcOpen] = useState(0);

    const handleClose = () => {
        setcOpen(0);
        setAnchorEl(null);
    }
    
    const handleClick = (id,event) => {
        setcOpen(cOpen === id ? 0 : id);
        setAnchorEl(event.currentTarget);
    }

    return (
        <>
            <Box>
                <Grid container spacing={4} className='my-1'>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <FormLabel className='fw-bold'>Search:</FormLabel>
                        <TextField 
                            variant='standard'
                            placeholder='Type here...'
                            className='form-control'
                        />
                    </Grid>
                </Grid>
                <TableContainer className='mt-3'>
                    <Table className='table table-striped'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Training/Certificate Name</TableCell>
                                <TableCell>Employee</TableCell>
                                <TableCell>Cost</TableCell>
                                <TableCell>Conducted</TableCell>
                                <TableCell>Expiring</TableCell>
                                <TableCell>Expires</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {training.trainingData.length !== 0 &&
                                training.trainingData.map((val,key) => (
                                    <TableRow key={key}>
                                        <TableCell>{val.training_certificate}</TableCell>
                                        <TableCell>{val.employee}</TableCell>
                                        <TableCell>R{val.cost}</TableCell>
                                        <TableCell>{val.conducted}</TableCell>
                                        <TableCell>{val.expiring}</TableCell>
                                        <TableCell>
                                            {val.expires === 'active' && <div className='text-success'>Active</div>}
                                            {val.expires === 'expiring' && <div className='text-warning'>Expiring</div>}
                                            {val.expires === 'expired' && <div className='text-danger'>Expired</div>}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                aria-label="more"
                                                id={"long-button_" + val.id}
                                                aria-controls={
                                                cOpen === val.id
                                                    ? "long-menu_" + val.id
                                                    : undefined
                                                }
                                                aria-expanded={
                                                cOpen === val.id ? "true" : undefined
                                                }
                                                aria-haspopup="true"
                                                onClick={(e) => {
                                                    handleClick(val.id, e);
                                                }}
                                            >
                                                <Typography
                                                className="poppins-family"
                                                color="primary"
                                                >
                                                Action
                                                </Typography>
                                                <KeyboardArrowDownTwoToneIcon color="primary" />
                                            </IconButton>
                                            <Menu
                                                id={"long-menu_" + val.id}
                                                MenuListProps={{
                                                "aria-labelledby": "long-button_" + val.id,
                                                }}
                                                anchorEl={anchorEl}
                                                open={cOpen === val.id}
                                                onClose={handleClose}
                                            >
                                                <MenuItem className='text-primary'>View</MenuItem>
                                                <MenuItem className='text-success'>Edit</MenuItem>
                                                <MenuItem className='text-danger'>Delete</MenuItem>
                                            </Menu>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                            {training.trainingData.length === 0 && <TableRow>
                                <TableCell colSpan={7} align="center">No Data</TableCell>
                            </TableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    )
}

export default React.memo(TrainingCertificates)
import React from "react";
import { DtoRoutesDefinition } from "../Dto/auth";
import { useAppSelector } from "../Store/hooks";


export const RoutePermissionCheck = (routeInformation: DtoRoutesDefinition) => {
    let authState = useAppSelector((state) => state.auth);
    if (!routeInformation.permission) {
        return true;
    } 
    // else if (authState.adminData && authState.adminData.roles && routeInformation.permission && authState.adminData.roles.includes(routeInformation.permission)) {
    //     return true;
    // }
    return true;
};
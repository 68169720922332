import React, { useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PropertySearchComponent from "../../Components/AutoCompleteTextView/PropertySearchComponent";
import UnitSearchComponent from "../../Components/AutoCompleteTextView/UnitSearchComponent";
import { useFormik } from "formik";
import { InvoiceReminder } from "../../Components/Validation";
import { ErrorTextField } from "../../Components/Error/ErrorTextField";
import { useAppDispatch } from "../../Store/hooks";
import { showLoader } from "../../Store/loaderSlice";
import { PostApiMethod } from "../../Utils/ApiService";
import { showToaster } from "../../Store/toastSlice";
import { errorHandling } from "../../Utils/HelperService";

const GroupReminder = (props: any) => {
  const initialValue = {
    property_id: "",
    property: null,
    unit_id: "",
    unit: null,
    title: "",
    details: "",
    property_all: false,
    unit_all: false,
  };
  const dispatch = useAppDispatch();

  const sendReminder = useCallback((values: any) => {
    dispatch(showLoader({ showLoader: true }));
    PostApiMethod("/property/notice-boards/add", values)
      .then((res: any) => {
        dispatch(showLoader({ showLoader: true }));
        dispatch(
          showToaster({
            dialogBgColor: "success",
            dialogMsg: "Reminder send successfully",
            showDialog: true,
            timer: "5000",
          })
        );
        props.handleOthers({ groupreminder: false });
      })
      .catch((e: any) => {
        dispatch(showLoader({ showLoader: false }));
        const error = errorHandling(e);
        dispatch(
          showToaster({
            dialogBgColor: "bg-danger",
            dialogMsg: error,
            showDialog: true,
            timer: "5000",
          })
        );
      });
  }, []);

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: InvoiceReminder,
    enableReinitialize: true,
    onSubmit: async (values) => {
      sendReminder(values);
    },
  });
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box className="pt-3">
          <Typography className="poppins-family fs-5 fw-bold">
            Send Group Reminder
          </Typography>
          <Grid container spacing={4} className="mt-1">
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography className="poppins-family">Property</Typography>
              <PropertySearchComponent
                onChange={(newValue: any) => {
                  setFieldValue("property_id", newValue.id);
                  setFieldValue("property", newValue);
                  setFieldValue("unit_id", "");
                }}
                disableClearable={true}
              />
              <ErrorTextField
                error={errors.property_id}
                touched={touched.property_id!}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography className="poppins-family">Unit Name</Typography>
              <UnitSearchComponent
                onChange={(newValue: any) => {
                  setFieldValue("unit_id", newValue.id);
                }}
                disableClearable={true}
                propertyId={values.property_id}
                userId={values!.property! && values!.property!["owner_user_id"]}
              />
              <ErrorTextField
                error={errors.unit_id}
                touched={touched.unit_id!}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography className="poppins-family">Title</Typography>
              <TextField
                className="w-100"
                variant="standard"
                placeholder="Type here.."
                onChange={handleChange}
                name="title"
              />
              <ErrorTextField error={errors.title} touched={touched.title!} />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="All Property"
                  onChange={(e: any) => {
                    setFieldValue("property_all", e.target.checked);
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="All Unit"
                  onChange={(e: any) => {
                    setFieldValue("unit_all", e.target.checked);
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography className="poppins-family">Description</Typography>
              <TextField
                className="w-100"
                variant="standard"
                placeholder="Type here.."
                onChange={handleChange}
                name="details"
              />
              <ErrorTextField
                error={errors.details}
                touched={touched.details!}
              />
            </Grid>
          </Grid>
          <Box className="mt-4">
            <button
              className="btn-white border-0"
              onClick={() => props.handleOthers({ groupreminder: false })}
            >
              <ChevronLeftIcon sx={{ fontSize: "25px" }} />
              Back
            </button>
            <button className="btn-danger ms-3">Submit</button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default GroupReminder;

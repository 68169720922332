import React, { useCallback, useState } from "react";
import { AuthorizedLayout } from "../Layouts/AuthorizedLayout";
import { Typography, Box, MenuItem, Grid } from "@mui/material";
import {
  ImageInput,
  SelectDropdown,
  TextInput,
} from "../../Components/Text/TextInput";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ApiSearch from "../../Components/AutoCompleteTextView/ApiSearch";
import { PostApiMethod } from "../../Utils/ApiService";
import { showLoader } from "../../Store/loaderSlice";
import { showToaster } from "../../Store/toastSlice";
import { useAppDispatch } from "../../Store/hooks";
import { errorHandling } from "../../Utils/HelperService";
import { useNavigate } from "react-router-dom";

const PDHelpDeskSchema = Yup.object().shape({
  type: Yup.string().required("Type is required"),
  assetId: Yup.string().required("Asset is required"),
  category: Yup.string().required("Category is required"),
  component: Yup.string().required("Component is required"),
  equipment: Yup.string().required("Equipment is required"),
  location: Yup.string().required("Location is required"),
  notes: Yup.string().required("Notes is required"),
  priority: Yup.string().required("Priority is required")
  // media:Yup.string().required("Category is required")
});

const PDAddHelpDesk = () => {
  const navigate = useNavigate();
  const [searchLoaders, setSearchLoaders] = useState({
    category: false,
    location: false,
    equipment: false,
    component: false,
    asset: false,
  });
  const dispatch = useAppDispatch();
  const [categoryList, setCategoryList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);
  const [componentList, setComponentList] = useState([]);
  const [assetList, setAssetList] = useState([]);
  const [initialValue, setInitialValue] = useState({
    type: "",
    assetId: "",
    category: "",
    component: "",
    equipment: "",
    location: "",
    notes: "",
    media: "",
    priority:"",
    categoryDetails: {
      name: "",
      id: "",
    },
    locationDetails: {
      name: "",
      id: "",
    },
    equipmentDetails: {
      name: "",
      id: "",
    },
    componentDetails: {
      name: "",
      id: "",
    },
    assetDetails: {
      name: "",
      id: "",
    },
  });
  const searchCategory = useCallback((value) => {
    setSearchLoaders((prev) => ({ ...prev, category: true }));
    PostApiMethod("petrol-bunk/category/getHpCategory", {
      searchString: value,
      page: "1",
      limit: "50",
    })
      .then((res) => {
        setSearchLoaders((prev) => ({ ...prev, category: false }));
        setCategoryList(res.data.results.data);
      })
      .catch((e) => {
        setSearchLoaders((prev) => ({ ...prev, category: false }));
        setCategoryList([]);
      });
  }, []);

  const searchLocation = useCallback((value, categoryId) => {
    setSearchLoaders((prev) => ({ ...prev, location: true }));
    PostApiMethod("petrol-bunk/location/getLocation", {
      searchString: value,
      page: "1",
      limit: "50",
      category: categoryId,
    })
      .then((res) => {
        setSearchLoaders((prev) => ({ ...prev, location: false }));
        setLocationList(res.data.results.data);
      })
      .catch((e) => {
        setSearchLoaders((prev) => ({ ...prev, location: false }));
        setLocationList([]);
      });
  }, []);

  const searchEquipment = useCallback((value, categoryId) => {
    setSearchLoaders((prev) => ({ ...prev, equipment: true }));
    PostApiMethod("petrol-bunk/equipment/getEquipment", {
      searchString: value,
      page: "1",
      limit: "50",
      category: categoryId,
    })
      .then((res) => {
        setSearchLoaders((prev) => ({ ...prev, equipment: false }));
        setEquipmentList(res.data.results.data);
      })
      .catch((e) => {
        setSearchLoaders((prev) => ({ ...prev, equipment: false }));
        setEquipmentList([]);
      });
  }, []);

  const searchComponent = useCallback((value, equipmentId) => {
    setSearchLoaders((prev) => ({ ...prev, component: true }));
    PostApiMethod("petrol-bunk/component/getComponent", {
      searchString: value,
      page: "1",
      limit: "50",
      equipment: equipmentId,
    })
      .then((res) => {
        setSearchLoaders((prev) => ({ ...prev, component: false }));
        setComponentList(res.data.results.data);
      })
      .catch((e) => {
        setSearchLoaders((prev) => ({ ...prev, component: false }));
        setComponentList([]);
      });
  }, []);

  const searchAsset = useCallback((value) => {
    setSearchLoaders((prev) => ({ ...prev, asset: true }));
    PostApiMethod("petrol-bunk/pb-asset/getAssetList", {
      searchString: value,
      page: "1",
      limit: "20",
    })
      .then((res) => {
        setSearchLoaders((prev) => ({ ...prev, asset: false }));
        setAssetList(res.data.results.data);
      })
      .catch((e) => {
        setSearchLoaders((prev) => ({ ...prev, asset: false }));
        setAssetList([]);
      });
  }, []);

  const submit = useCallback((values) => {
    dispatch(showLoader({ showLoader: true }));
    const form = new FormData();
    form.append("type", values.type);
    form.append("assetId", values.assetId);
    form.append("category", values.category);
    form.append("component", values.component);
    form.append("equipment", values.equipment);
    form.append("location", values.location);
    form.append("notes", values.notes);

    if (values.media) form.append("media", values.media);

    PostApiMethod(`petrol-bunk/pb-help-desk/add`, form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        dispatch(showLoader({ showLoader: false }));
        dispatch(
            showToaster({
              dialogBgColor: "success",
              dialogMsg: "Ticket has been created successfully",
              showDialog: true,
              timer: "5000",
            })
          );
          navigate("/helpDesk")
      })
      .catch((e) => {
        dispatch(showLoader({ showLoader: false }));
        const error = errorHandling(e);
        dispatch(
          showToaster({
            dialogBgColor: "bg-danger",
            dialogMsg: error,
            showDialog: true,
            timer: "5000",
          })
        );
      });
  }, []);

  return (
    <AuthorizedLayout>
      <Box className="mb-2">
        <Typography sx={{ fontWeight: "bold", fontSize: "23px" }}>
          Add Helpdesk
        </Typography>
      </Box>
      <Formik
        initialValues={initialValue}
        validationSchema={PDHelpDeskSchema}
        onSubmit={async (values) => {
            submit(values)
        }}
      >
        {({
          handleChange,
          handleSubmit,
          values,
          errors,
          touched,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <SelectDropdown
                  label="Type"
                  name="type"
                  onChange={handleChange}
                  value={values?.type}
                >
                  <MenuItem value="">Choose</MenuItem>
                  <MenuItem value="External">External</MenuItem>
                  <MenuItem value="Internal">Internal</MenuItem>
                </SelectDropdown>
                {touched.type && errors.type && (
                  <div className="text-danger">{errors.type}</div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <ApiSearch
                  value={values.assetDetails}
                  onChange={(newValue) => {
                    setFieldValue("assetDetails", {
                      id: newValue.id,
                      name: newValue.name,
                    });
                    setFieldValue("assetId", newValue.id);
                    setFieldValue("categoryDetails",{
                      id: newValue?.HDCategory.id,
                      name: newValue?.HDCategory.name
                    });
                    setFieldValue("category", newValue?.HDCategory.id);
                    console.log(newValue)
                    setFieldValue("locationDetails", {
                      id: newValue?.Location.id,
                      name: newValue?.Location.name,
                    });
                    setFieldValue("location", newValue?.Location.id);
                    setFieldValue("equipmentDetails", {
                      id: newValue?.Equipment.id,
                      name: newValue?.Equipment.name,
                    });
                    setFieldValue("equipment", newValue?.Equipment.id);
                    setFieldValue("componentDetails", { 
                      id: newValue?.Component.id, 
                      name: newValue?.Component.name
                    });
                    setFieldValue("component", newValue?.Component.id);
                  }}
                  loading={searchLoaders.asset}
                  options={assetList}
                  searchApi={searchAsset}
                  isOptionEqualToValue={(option, value) =>
                    option?.name === value.name
                  }
                  getOptionLabel={(option) => option?.name}
                  showTitle={true}
                  label={"Asset"}
                  placeHolder="Search Asset"
                />
                {touched.assetId && errors.assetId && (
                  <div className="text-danger">{errors.assetId}</div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <ApiSearch
                  value={values.categoryDetails}
                  onChange={(newValue) => {
                    setFieldValue("categoryDetails", {
                      id: newValue.id,
                      name: newValue.name,
                    });
                    setFieldValue("category", newValue.id);
                    setFieldValue("locationDetails", { id: "", name: "" });
                    setFieldValue("location", "");
                    setLocationList([]);
                    setFieldValue("equipmentDetails", { id: "", name: "" });
                    setFieldValue("equipment", "");
                    setEquipmentList([]);
                    setFieldValue("componentDetails", { id: "", name: "" });
                    setFieldValue("component", "");
                    setComponentList([]);
                  }}
                  loading={searchLoaders.category}
                  options={categoryList}
                  searchApi={searchCategory}
                  isOptionEqualToValue={(option, value) =>
                    option?.name === value.name
                  }
                  getOptionLabel={(option) => option?.name}
                  showTitle={true}
                  label={"Category"}
                  placeHolder="Search Category"

                />
                {touched.category && errors.category && (
                  <div className="text-danger">{errors.category}</div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <ApiSearch
                  value={values.locationDetails}
                  onChange={(newValue) => {
                    setFieldValue("locationDetails", {
                      id: newValue.id,
                      name: newValue.name,
                    });
                    setFieldValue("location", newValue.id);
                  }}
                  loading={searchLoaders.location}
                  options={locationList}
                  searchApi={(search) => {
                    if (values.category)
                      searchLocation(search, values.category);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option?.name === value.name
                  }
                  getOptionLabel={(option) => option?.name}
                  showTitle={true}
                  label={"Location"}
                  disable={values.category ? false : true}
                  placeHolder="Search Location"

                />
                {touched.location && errors.location && (
                  <div className="text-danger">{errors.location}</div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <ApiSearch
                  value={values.equipmentDetails}
                  onChange={(newValue) => {
                    setFieldValue("equipmentDetails", {
                      id: newValue.id,
                      name: newValue.name,
                    });
                    setFieldValue("equipment", newValue.id);
                    setFieldValue("componentDetails", { id: "", name: "" });
                    setFieldValue("component", "");
                    setComponentList([]);
                  }}
                  loading={searchLoaders.equipment}
                  options={equipmentList}
                  searchApi={(search) => {
                    if (values.category)
                      searchEquipment(search, values.category);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option?.name === value.name
                  }
                  getOptionLabel={(option) => option?.name}
                  showTitle={true}
                  label={"Equipment"}
                  disable={values.category ? false : true}
                  placeHolder="Search Equipment"

                />
                {touched.equipment && errors.equipment && (
                  <div className="text-danger">{errors.equipment}</div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <ApiSearch
                  value={values.componentDetails}
                  onChange={(newValue) => {
                    setFieldValue("componentDetails", {
                      id: newValue.id,
                      name: newValue.name,
                    });
                    setFieldValue("component", newValue.id);
                  }}
                  loading={searchLoaders.component}
                  options={componentList}
                  searchApi={(search) => {
                    if (values.equipment)
                      searchComponent(search, values.equipment);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option?.name === value.name
                  }
                  getOptionLabel={(option) => option?.name}
                  showTitle={true}
                  label={"Component"}
                  disable={values.equipment ? false : true}
                  placeHolder="Search Component"

                />
                {touched.component && errors.component && (
                  <div className="text-danger">{errors.component}</div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextInput
                  label={"Notes"}
                  name="notes"
                  onChange={handleChange}
                  value={values?.notes}
                />
                {touched.notes && errors.notes && (
                  <div className="text-danger">{errors.notes}</div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <ImageInput
                  label={"Media"}
                  name="media"
                  onChange={(e) => {
                    setFieldValue("media", e.target.files[0]);
                  }}
                  value={values?.media}
                />
                {touched.media && errors.media && (
                  <div className="text-danger">{errors.media}</div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <SelectDropdown
                  label="Priority"
                  name="priority"
                  onChange={handleChange}
                  value={values?.priority}
                >
                  <MenuItem value="">Choose</MenuItem>
                  <MenuItem value="Low">Low</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="Immediate">Immediate</MenuItem>
                </SelectDropdown>
                {touched.priority && errors.priority && (
                  <div className="text-danger">{errors.priority}</div>
                )}
              </Grid>
            </Grid>
            <Box className="mt-3 d-flex gap-2">
              <button type="button" className="btn btn-white" onClick={() => navigate("/helpDesk")}>Back</button>
              <button type="submit" className="btn btn-danger">
                Submit
              </button>
            </Box>
          </Form>
        )}
      </Formik>
    </AuthorizedLayout>
  );
};

export default PDAddHelpDesk;

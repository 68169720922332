import React, { useState, useContext, useRef } from 'react';
import { DayPilot, DayPilotCalendar, DayPilotNavigator } from "@daypilot/daypilot-lite-react";
import { Box, Modal, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { MaintenanceContext } from './Maintenance';
import { convertToAmPm, currentDate } from '../../Components/CommonFormik';
import CloseIcon from '@mui/icons-material/Close';
import { ModalStyle } from '../../Components/ModalStyle';

const MaintenanceCalendar = () => {
    const maintenance = useContext(MaintenanceContext);
    const [visitDetails,setVisitDetails] = useState(null)
    
    const [config, setConfig] = useState({
        viewType: "Week",
        durationBarVisible: true,
        timeRangeSelectedHandling: "Enabled",
        headerDateFormat: "dd/MM/yyyy",
        onEventClick:args => {
            setOpen(true)
            setVisitDetails(args.e.data)
        },
        onBeforeHeaderRender: args => {
            const today = new DayPilot.Date().getDatePart();
            if(args.column.start.equals(today)){
                args.header.backColor = "#6ea8fe";
                args.header.fontColor = "#ffffff";
            }
        },
        onBeforeCellRender: (args) => {
            const today = new DayPilot.Date().getDatePart();
        
            if (args.cell.start.equals(today)) {
                args.cell.cssClass = "current-date";
            }
        }
    })
    const [selectDay, setSelectDay] = useState(new Date());
    const [open, setOpen] = useState(false);
    const calendarRef = useRef(null);

    const nextWeek = async() => {
        let date = new Date(selectDay)
        date.setDate(date.getDate() + 7);
        setSelectDay(date);
        let month = (date.getMonth()+1) < 10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
        let day = date.getDate() < 10 ? "0"+(date.getDate()) : date.getDate();
        date = date.getFullYear()+"-"+month+"-"+day;
        maintenance.getWeekList(date);
    }
    
    const previousWeek = async() => {
        let date = new Date(selectDay)
        date.setDate(date.getDate() - 7);
        setSelectDay(date);
        let month = (date.getMonth()+1) < 10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
        let day = date.getDate() < 10 ? "0"+(date.getDate()) : date.getDate();
        date = date.getFullYear()+"-"+month+"-"+day
        maintenance.getWeekList(date);
    }
    
    const today = () => {
        let date = new Date();
        setSelectDay(date);
        maintenance.getWeekList(currentDate());
    }

    const dateChanged = (args) => {
        const date = args.day.value;
        
        if (new Date(date).getTime() !== new Date(selectDay).getTime()) {
          setSelectDay(date);
          
          if (calendarRef.current) {
            calendarRef.current.control.update({
              startDate: args.day
            });
          }
        }
    };
    
    return (
        <>
            <Box>
                <button className='btn btn-danger me-1' onClick={previousWeek}><ChevronLeftIcon /></button>
                <button className='btn btn-danger' onClick={today}>Today</button>
                <button className='btn btn-danger ms-1' onClick={nextWeek}><ChevronRightIcon /></button>
            </Box>
            <Box className="mt-3 d-flex">
                <DayPilotCalendar {...config} ref={calendarRef} events={maintenance.weekList} />
                <DayPilotNavigator 
                    selectMode={"Week"}
                    showMonths={1}
                    startDate={selectDay}
                    selectionDay={selectDay}
                    onTimeRangeSelected={dateChanged}
                />
            </Box>

            <Modal
                open={open}
                onClose={() => setOpen(false)}
            >
                <Box sx={ModalStyle({width:400,height:350})} className="p-3">
                <Box className="border-bottom d-flex justify-content-between">
                    <h5>Maintenance Schedule</h5>
                    <CloseIcon sx={{cursor:'pointer'}} onClick={() => setOpen(false)}/>
                </Box>
                <Box className="d-flex justify-content-between mt-1">
                    <Box>
                    <Typography component="div" className="fw-bold">Date</Typography>
                    <Typography component="div">{visitDetails && visitDetails.start.value.split("T")[0]}</Typography>
                    </Box>
                    <Box>
                    <Typography component="div" className="fw-bold">Timing</Typography>
                    <Typography component="div">{visitDetails && convertToAmPm(visitDetails.start.value.split("T")[1]) +"-"+ convertToAmPm(visitDetails.end.value.split("T")[1])}</Typography>
                    </Box>
                </Box>
                    <table className='table table-striped mt-2'>
                        <tbody>
                            {(visitDetails && visitDetails.areas.search(",") !== -1) 
                                ? visitDetails.areas.split(",").map((val,key) => {
                                    return (
                                    <tr key={key}>
                                        <td>{val}</td>
                                    </tr>
                                    )
                                }) : <tr><td>{visitDetails && visitDetails.areas}</td></tr>}
                        </tbody>
                    </table>
                </Box>
            </Modal>
        </>
    )
}

export default MaintenanceCalendar
import React from "react";
import { useAppSelector } from "../../Store/hooks";
import "./Loader.css";
import { showLoader } from "../../Store/loaderSlice";
export default function Loader() {
    let loaderState = useAppSelector((state) => state.loader);

    return loaderState.showLoader ? (
        <div className="loader-component">
            <div
                className="spinner-border text-danger spinner-loader"
                role="status"
            >
                <span className="visually-hidden">
                    {loaderState.loaderMessage}
                </span>
            </div>
        </div>
    ) : (
        <></>
    );
}
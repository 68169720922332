import { ReactNode } from "react";
import "./UnAuthorizedLayout.css";
import Toaster from "../../Components/Toaster/Toaster";
import Loader from "../../Components/Loader/Loader";

interface Props {
  children: ReactNode;
}

export const UnAuthorizedLayout = (props: Props) => {
  const { children } = props;
  return (
    <>
      {/* <div className="unauthorized-background-image"></div> */}
      <div className="unauth-section">
        <div className='unauth-box'>
          {children}
        </div>
      </div>
      <Toaster />
      <Loader />
    </>
  );
};

import React, { memo, useContext } from 'react';
import { Typography, Box, Grid, MenuItem, TextField } from '@mui/material';
import { Formik, Form } from 'formik';
import { parkingSchema } from './ParkingSchema';
import Select from '@mui/material/Select';
import { ParkingContext } from './Parking';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { PostApiMethod, PutApiMethod } from '../../Utils/ApiService';
import { useAppDispatch } from '../../Store/hooks';
import { showLoader } from '../../Store/loaderSlice';
import { showToaster } from "../../Store/toastSlice";

const AddParking = () => {
    const parking = useContext(ParkingContext);
    const dispatch = useAppDispatch();
    console.log(parking)
    const response = (color,msg) => {
        dispatch(showToaster({dialogBgColor: color,
        dialogMsg: msg,
        showDialog: true,
        timer: "3000",}))
    }

    const createParking = async(formData,resetForm) => {
        dispatch(showLoader({showLoader:true}))
        try{
            let res = await PostApiMethod("property/parking/add",formData);
            if(res["data"]["error"] === false){
                resetForm({values:''})
                response("bg-success",res["data"]["message"])
                parking.getParking(parking.page)
                parking.onBack();
            }else{
                response("bg-danger",res["data"]["message"])
            }
        }catch(error){
            response("bg-danger","Failed Parking creation")
        }
        dispatch(showLoader({showLoader:false}))
    }

    const editParking = async(formData) => {
        dispatch(showLoader({showLoader:true}))
        try{
            let res = await PutApiMethod("property/parking/update/"+parking.editData.id,formData);
            if(res["data"]["error"] === false){
                response("bg-success",res["data"]["message"])
                parking.getParking(parking.page)
                parking.onBack();
            }
        }catch(error){
            response("bg-danger","Failed update parking details")
        }
        dispatch(showLoader({showLoader:false}))
    }
    return (
        <>
            <Box className="mt-4">
                <Typography className='fw-bold fs-3'>{Object.entries(parking.editData).length === 0 ? "Add New" : "Edit"} Parking</Typography>
            </Box>
            <Box>
                <Formik
                    initialValues={{
                        parking_no: parking.editData.parking_no || '',
                        parking_type:parking.editData.parking_type || '',
                        // visitor_name:parking.editData.visitor_name || '',
                        vehicle_4_wheeler:parking.editData.vehicle_4_wheeler || '',
                        vehicle_2_wheeler:parking.editData.vehicle_2_wheeler || '',
                        unit_id:parking.editData.unit_id || '',
                        billing_cycle:parking.editData.billing_cycle || '',
                        fees:parking.editData.fees || '',
                        tenant_id:parking.editData.tenant_id || "",
                        department_id:parking.editData.department_id || "",
                        staff_id: parking.editData.staff_id || '',
                        start_date: parking.editData.start_date ? parking.editData.start_date.split("T")[0] : '',
                        description: parking.editData.description || '',
                    }}
                    validationSchema={parkingSchema}
                    onSubmit={async(values, {resetForm}) => {
                        const formData = new FormData();
                        Object.keys(values).map((val,key) => {
                            if(values[val] !== ""){
                                formData.append(val,values[val])
                            } 
                            return true
                        })
                        if(Object.entries(parking.editData).length === 0){
                            createParking(formData,resetForm)
                        }else{
                            editParking(formData)
                        }
                    }}
                >
                    {({ handleSubmit, handleChange, values, errors, touched }) => (
                        <Form onSubmit={handleSubmit}>
                            <Grid container spacing={3} className="mt-3"> 
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Parking No</Typography>
                                    <TextField 
                                        name="parking_no"
                                        className='w-100'
                                        variant='standard'
                                        placeholder='Type here'
                                        onChange={handleChange}
                                        value={values.parking_no}
                                    />
                                    {touched.parking_no && errors.parking_no && (<div className='text-danger'>{errors.parking_no}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Parking Type</Typography>
                                    <Select displayEmpty className="w-100 mt-2" name="parking_type" variant='standard'value={values.parking_type} onChange={handleChange}>
                                        <MenuItem disabled value="">
                                            <em>Choose</em>
                                        </MenuItem>
                                        <MenuItem value="Visitors">Visitors</MenuItem>
                                        <MenuItem value="Staffs">Staffs</MenuItem>
                                        <MenuItem value="Residents">Residents</MenuItem>
                                    </Select>
                                    {touched.parking_type && errors.parking_type && (<div className='text-danger'>{errors.parking_type}</div>)}
                                </Grid>
                                {/* <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Visitor Name</Typography>
                                    <TextField 
                                        variant="standard"
                                        className="w-100"
                                        placeholder="Type here"
                                        name="visitor_name"
                                        value={values.visitor_name}
                                        onChange={handleChange}
                                    />
                                    {touched.visitor_name && errors.visitor_name && (<div className='text-danger'>{errors.visitor_name}</div>)}
                                </Grid> */}
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Unit</Typography>
                                    <Select displayEmpty className="w-100 mt-2" name="unit_id" variant='standard' value={values.unit_id} onChange={handleChange}>
                                        <MenuItem disabled value="">
                                            <em>Choose</em>
                                        </MenuItem>
                                        {parking.unit.length !== 0 && parking.unit.map((val,key) => {
                                            return (
                                                <MenuItem value={val.id} key={key}>{val.unit_name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                    {touched.unit_id && errors.unit_id && (<div className='text-danger'>{errors.unit_id}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Vehicle(4 wheeler)</Typography>
                                    <TextField 
                                        variant="standard"
                                        className="w-100"
                                        placeholder="Type here"
                                        name="vehicle_4_wheeler"
                                        value={values.vehicle_4_wheeler}
                                        onChange={handleChange}
                                    />
                                    {touched.vehicle_4_wheeler && errors.vehicle_4_wheeler && (<div className='text-danger'>{errors.vehicle_4_wheeler}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Vehicle(2 wheeler)</Typography>
                                    <TextField 
                                        variant="standard"
                                        className="w-100"
                                        placeholder="Type here"
                                        name="vehicle_2_wheeler"
                                        value={values.vehicle_2_wheeler}
                                        onChange={handleChange}
                                    />
                                    {touched.vehicle_2_wheeler && errors.vehicle_2_wheeler && (<div className='text-danger'>{errors.vehicle_2_wheeler}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Billing Cycle</Typography>
                                    <Select displayEmpty className="w-100 mt-2" name="billing_cycle" variant='standard' value={values.billing_cycle} onChange={handleChange}>
                                        <MenuItem disabled value="">
                                            <em>Choose</em>
                                        </MenuItem>
                                        <MenuItem value="Monthly">Monthly</MenuItem>
                                        <MenuItem value="Yearly">Yearly</MenuItem>
                                        <MenuItem value="Hourly">Hourly</MenuItem>
                                    </Select>
                                    {touched.billing_cycle && errors.billing_cycle && (<div className='text-danger'>{errors.billing_cycle}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Fees</Typography>
                                    <TextField
                                        type="number"
                                        className='w-100'
                                        variant="standard"
                                        value={values.fees}
                                        name="fees"
                                        placeholder='Type here'
                                        onChange={handleChange}
                                    />
                                    {touched.fees && errors.fees && (<div className='text-danger'>{errors.fees}</div>)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Tenant Name</Typography>
                                    <Select displayEmpty className="w-100 mt-2" name="tenant_id" variant='standard'value={values.tenant_id} onChange={handleChange}>
                                        <MenuItem disabled value="">
                                            <em>Choose</em>
                                        </MenuItem>
                                        {parking.tenant.length !== 0 && parking.tenant.map((val,key) => {
                                            return (
                                                <MenuItem value={val.id} key={key}>{val.firstName +' '+ val.lastName}</MenuItem>
                                            )
                                        })}
                                        
                                    </Select>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Department</Typography>
                                    <Select displayEmpty className="w-100 mt-2" name="department_id" variant='standard'value={values.department_id} onChange={handleChange}>
                                        <MenuItem disabled value="">
                                            <em>Choose</em>
                                        </MenuItem>
                                        {parking.department.length !== 0 && parking.department.map((val,key) => {
                                            return (
                                                <MenuItem value={val.id} key={key}>{val.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Staff</Typography>
                                    <Select displayEmpty className="w-100 mt-2" name="staff_id" variant='standard'value={values.staff_id} onChange={handleChange}>
                                        <MenuItem disabled value="">
                                            <em>Choose</em>
                                        </MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Start Date</Typography>
                                    <TextField
                                        type="date"
                                        className='w-100'
                                        variant="standard"
                                        value={values.start_date}
                                        name="start_date"
                                        placeholder='Type here'
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Typography className="fw-bold">Description</Typography>
                                    <TextField
                                        className='w-100'
                                        variant="standard"
                                        value={values.description}
                                        name="description"
                                        placeholder='Type here'
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                            <Box className="mt-5">
                                <button type="button" className='btn btn-white' onClick={parking.onBack}><ArrowBackIosIcon style={{fontSize:'18px'}}/>Back</button>
                                <button type="submit" className='btn btn-danger ms-2'>Submit</button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    )
}

export default memo(AddParking);
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { Tab } from '@mui/material';
import CustomTabPanel from '../../Components/Tab/CustomTabPanel';
import Jobs from './Jobs';
import Area from './Area';
import { useAppDispatch } from '../../Store/hooks';
import { showLoader } from '../../Store/loaderSlice';
import { PostApiMethod } from '../../Utils/ApiService';
import { ChildTabs } from '../../Components/Tab/AntTab';
import { showToaster } from '../../Store/toastSlice';

export const MaintenanceMasterContext = createContext();

const MaintenanceMasters = () => {
    const [tabValue, setTabValue] = useState(0);
    const [list, setList] = useState({jobList:[],areaList:[]});
    const [jobPage, setJobPage] = useState({page:1,limit:5,count:0});
    const [areaPage, setAreaPage] = useState({page:1,limit:5,count:0});
    const dispatch = useAppDispatch();

    useEffect(() => {
        getJobs(jobPage);
        getAreas(areaPage);
    },[])

    const handleChange = (e,val) => {
        setTabValue(val)
    }

    const getJobs = useCallback(async(params) => {
        dispatch(showLoader({showLoader:true}));
        let parameter = params.limit === -1 ? {} : {page:params.page,limit:params.limit};
        try{
            let res = await PostApiMethod("maintenance/job-type/list",parameter);
            if(res['data']['error'] === false){
                setJobPage((pre) => ({...pre,count:res["data"]["results"]["totalCount"]}))
                setList((pre) => ({...pre,jobList:res["data"]["results"]["data"]}))
            }
        }catch(error){
            dispatch(showToaster({
                dialogBgColor: "bg-danger",
                dialogMsg: error.response ? error.response.data.message : "Failed to get jobs",
                showDialog: true,
                timer: "3000"
            }))
        }
        dispatch(showLoader({showLoader:false}))
    },[list]);

    const getAreas = useCallback(async(params) => {
        dispatch(showLoader({showLoader:true}));
        let parameter = params.limit === -1 ? {} : {page:params.page,limit:params.limit};
        try{
            let res = await PostApiMethod("maintenance/area-type/list",parameter);
            if(res["data"]["error"] === false){
                setAreaPage({page:params.page,limit:params.limit,count:res["data"]["results"]["totalCount"]})
                setList((pre) => ({...pre,areaList:res["data"]["results"]["data"]}))
            }
        }catch(error){
            dispatch(showToaster({
                dialogBgColor: "bg-danger",
                dialogMsg: error.response ? error.response.data.message : "Failed to get area",
                showDialog: true,
                timer: "3000"
            }))
        }
        dispatch(showLoader({showLoader:false}));
    },[list]);

    const jobNextPage = useCallback((val) => {
        setJobPage({...jobPage,page:val.page,limit:val.limit});
        getJobs(val);
    },[jobPage]);

    const areaNextPage = useCallback((val) => {
        setAreaPage({...areaPage,page:val.page,limit:val.limit});
        getAreas(val);
    },[areaPage])

    return (
        <>
            <MaintenanceMasterContext.Provider value={{
                jobList:list.jobList,
                areaList:list.areaList,
                getJobs:getJobs,
                jobPage:jobPage,
                jobNextPage:jobNextPage,
                areaPage:areaPage,
                areaNextPage:areaNextPage,
                getAreas:getAreas
            }}>
                <ChildTabs value={tabValue} onChange={handleChange}>
                    <Tab label="Jobs" className='tab-menu' />
                    <Tab label="Areas" className='tab-menu' />
                </ChildTabs>
                <CustomTabPanel value={tabValue} index={0}>
                    <Jobs />
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}>
                    <Area />
                </CustomTabPanel>
            </MaintenanceMasterContext.Provider>
        </>
    )
}

export default MaintenanceMasters
import React, { useState } from 'react';
import { Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const BoxComponent = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Box sx={{border:'1px solid #ECECEC',width:'100%',height:'100px',borderRadius:'10px',borderBottom:'5px solid red',padding:'11px'}} className="my-3">
                <Box className="fw-bold d-flex justify-content-between">
                    <Box>{props.issue}</Box>
                    <MoreVertIcon sx={{cursor:'pointer'}} onClick={handleClick}/>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem className='text-primary' onClick={props.onEdit}>Edit</MenuItem>
                        <MenuItem className='text-danger' onClick={props.onDelete}>Delete</MenuItem>
                    </Menu>
                </Box>
                <Box sx={{color:'gray'}}>{props.area.area_name}</Box>
                <Box className="d-flex">
                    <Box sx={{color:'gray',fontSize:'14px'}}><em>{props.date.split("T")[0]}</em></Box>
                </Box>
            </Box>
        </>
    )
}

export default BoxComponent
import React from "react";
import { DtoRoutesDefinition } from "../Dto/auth";

import LoginPage from "../Pages/AuthPages/LoginPage";
import CustomerPage from "../Pages/HomePages/Customer";
import CustomerTable from "../Pages/HomePages/CustomerTable";
import UserManagement from "../Pages/HomePages/UserManagement";
import Maintainers from "../Pages/Maintainers/Maintainers";
import Property from "../Pages/PropertyManagement/Main";
// import Property from "../Pages/property/property";
import PropertyDetails from "../Pages/property/propertyDetails";
import DashBoard from "../Pages/Dashboard/dashboard";
import Vendors from "../Pages/Vendors/Vendors";
import HelpDesk from "../Pages/HelpDesk/HelpDesk";
import ShowHelpDesk from "../Pages/HelpDesk/ShowHelpDesk";
import AssignVendorsList from "../Pages/Vendors/AssignVendorsList";
import Tenant from "../Pages/Tenant/Tenant";
import UpdateForms from "../Pages/property/updateForms";
import BillingCenter from "../Pages/BillingCenter/BillingCenter";
import Expenses from "../Pages/Expenses/Expenses";
import HelpDeskTypeList from "../Pages/HelpDesk/HelpDeskType/HelpDeskTypeList";
import InvoiceType from "../Pages/InvoiceType/InvoiceType";
import Settings from "../Pages/Settings/Settings";
import Document from "../Pages/Document/document";
// import Assets from "../Pages/Assets/Assets";
import AssetsDetail from "../Pages/Assets/AssetDetails";
import NoticeBoard from "../Pages/notice-board/NoticeBoard";
import Maintenance from "../Pages/Maintenance/Maintenance"
import Visitor from "../Pages/Visitor/Visitor";
import Amenities from "../Pages/Amenities/Amenities";
import Parking from "../Pages/Parking/Parking";
import TrainingManagement from "../Pages/TrainingManagement/TrainingManagement";
import AssetManagement from "../Pages/Assets/AssetManagement";
import PermissionForm from "../Pages/HomePages/PermissionForm";
import PDAddHelpDesk from "../Pages/HelpDesk/PDAddHelpDesk";
import ResetPassword from "../Pages/AuthPages/ResetPassword";

export const ProtectedRoutes: DtoRoutesDefinition[] = [
  {
    path: "/",
    element: <></>,
    redirectTo: "/dashboard",
    modules: "Dashboard",
  },
  {
    path: "/dashboard",
    element: <DashBoard />,
    modules: "Dashboard",
  },
  {
    path: "/customer-admin",
    element: <CustomerTable />,
    permission: "super-admin",
  },
  {
    path: "/user",
    element: <UserManagement />,
    permission: "super-admin",
    modules: "User"
  },
  {
    path:"/assign-permission/:id",
    element:<PermissionForm />,
    permission:"super-admin",
    modules:"User"
  },
  // {
  //   path: "/user/table",
  //   element: <UserManagement />,
  //   permission: "admin",
  // },
  {
    path: "/maintainers",
    element: <Maintainers />,
    permission: "admin",
  },
  {
    path: "/expenses",
    element: <Expenses />,
    permission: "admin",
  },
  {
    path: "/property",
    element: <Property />,
  },
  {
    path: "/Property/details",
    element: <PropertyDetails />,
  },
  {
    path: "/Property/:id",
    element: <UpdateForms />,
  },
  {
    path: "/billing-center",
    element: <BillingCenter />,
    permission: "admin",
  },
  {
    path: "/invoice-type",
    element: <InvoiceType />,
    permission: "admin",
  },
  {
    path: "/vendors",
    element: <Vendors />,
  },

  {
    path: "/helpdesk",
    element: <HelpDesk />,
    modules: "Tickets"
  },
  {
    path: "/maintenance",
    element: <Maintenance />,
  },
  {
    path: "/assign-vendors",
    element: <AssignVendorsList />,
  },
  {
    path: "/tenant",
    element: <Tenant />,
  },
  {
    path: "/document",
    element: <Document />,
  },
  {
    path: "/assets",
    element: <AssetManagement />,
    modules: "Assets"
  },
  {
    path: "/assets/assets-details",
    element: <AssetsDetail />,
    modules: "Assets"
  },
  {
    path: "/notice-board",
    element: <NoticeBoard />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
  {
    path: "/report",
    element: <DashBoard />,
    modules: "Dashboard"
  },
  {
    path: "/helpdesk-type",
    element: <HelpDeskTypeList />,
    modules: "Tickets"
  },
  {
    path: "/visitor-management",
    element: <Visitor/>
  },
  {
    path: "/amenities",
    element: <Amenities />
  },
  { 
    path: "/parking",
    element: <Parking />
  },
  {
    path: "/training-management",
    element: <TrainingManagement />
  },
  {
    path:"/helpDesk/add",
    element:<PDAddHelpDesk />,
    modules:"Tickets"
  },
  {
    path: "/assets/:id",
    element: <AssetManagement />,
  }
];

export const AuthRoutes: DtoRoutesDefinition[] = [
    {
        path: "/sign-in",
        element: <LoginPage />
    },
    {
        path: "/sign-up",
        element: <></>
    },
    {
        path: "/reset-password/:id",
        element: <ResetPassword />
    }
];

import React, { useState } from "react";
import { TextField, Typography } from "@mui/material";
import Autocomplete, {
  AutocompleteInputChangeReason,
} from "@mui/material/Autocomplete";
import { CircularProgress } from "@mui/material";
import { Add } from "@mui/icons-material";

interface ApiSearchProps {
  options: any;
  getOptionLabel: any;
  searchApi: (value: string) => void;
  loading: boolean;
  onChange: (newValue: any) => void;
  value: any;
  disableClearable?: boolean;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  showTitle?: boolean;
  label?: string;
  showAddIcon?: boolean;
  addBtnOnClick?: () => void;
  disable?: boolean;
  placeHolder?: string;
}

const ApiSearch = (props: ApiSearchProps) => {
  const {
    options,
    getOptionLabel,
    searchApi,
    loading,
    value,
    disableClearable = true,
    isOptionEqualToValue,
    showTitle = false,
    label,
    showAddIcon = false,
    addBtnOnClick,
    disable = false,
    placeHolder = "Choose"
  } = props;
  return (
    <>
      {showTitle && <Typography className="fw-bold">{label}</Typography>}
      <Autocomplete
        options={options}
        id="size-small-standard"
        size="small"
        disableClearable={disableClearable}
        value={value}
        getOptionLabel={getOptionLabel} // Assuming 'name' is the label property in fetched data
        onInputChange={(event, newValue, reason) => {
          if (reason === "input") {
            searchApi(newValue);
          }
        }}
        isOptionEqualToValue={isOptionEqualToValue}
        onChange={(event, newValue, reason) => {
          props.onChange(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            placeholder={placeHolder}
            disabled={disable} 
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : showAddIcon ? (
                    <Add sx={{ cursor: "pointer" }} onClick={addBtnOnClick} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default ApiSearch;

import React, { useCallback, useContext, useEffect } from 'react';
import { useAppDispatch } from '../../Store/hooks';
import { DeleteApiMethod, PostApiMethod, PutApiMethod } from '../../Utils/ApiService';
import { showLoader } from '../../Store/loaderSlice';
import { showToaster } from '../../Store/toastSlice';
import { Modal, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, FormLabel, Grid, TextField, TableFooter, Box, Typography } from '@mui/material';
import { UserContext } from './UserManagement';
import Pagination from '../../Components/Pagination/Pagination';
import MenuGroup from '../../Components/DropDown/MenuGroup';
import { CustomModal, ModalStyle } from '../../Components/ModalStyle';
import { moduleSchema } from './AddUserSchema';
import { Form, Formik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import { PermissionCheck } from '../../App/PermissionCheck';

const ModuleTable = () => {
    const user = useContext(UserContext);
    const { module, setModule } = user;
    const dispatch = useAppDispatch();

    useEffect(() => {
        getModules(user?.module?.page);
    },[]);

    const getModules = useCallback(async(params) => {
        dispatch(showLoader({showLoader:true}));
        var parameters = params?.limit === -1 ? {} : {page:params?.page,limit:params?.limit};
        try {
            let res = await PostApiMethod('module/getModule',{...parameters,searchString:params?.searchString});
            if(res['data']['error'] === false){
                setModule({
                    ...module,
                    modal:false,
                    data:res['data']['results']['data'],
                    page:{page:params.page, limit:params.limit, count:res['data']['results']['totalCount'], },
                    searchString:params?.searchString
                })
            }
        } catch (error) {
            setModule({
                ...module,
                data:[],
                modal:false
            })
            dispatch(showToaster({dialogBgColor: "bg-danger",
                dialogMsg: error.response ? error.response.data.message : "Failed to get roles",
                showDialog: true,
                timer: "3000"
            }))
        }
        dispatch(showLoader({showLoader:false}))
    },[]);

    const handleNextPage = useCallback((val) => {
        getModules(val)
    },[]);

    const createModule = useCallback(async(values,resetForm) => {
        dispatch(showLoader({showLoader:true}))
        try {
            let res = await PostApiMethod('module/add',values);
            if(!res?.data?.error){
                dispatch(showToaster({
                    dialogBgColor: "bg-success",
                    dialogMsg: res?.data?.message,
                    showDialog: true,
                    timer: "3000"
                }));
                getModules(module?.page)
            }
        } catch (error) {
            dispatch(showToaster({
                dialogBgColor: "bg-danger",
                dialogMsg: error.response ? error.response.data.message : "Failed to create module",
                showDialog: true,
                timer: "3000"
            }))
        }
        dispatch(showLoader({showLoader:false}))
    },[]);

    const updateModule = useCallback(async(values,modulesId) => {
        dispatch(showLoader({showLoader:true}))
        try {
            let res = await PutApiMethod('module/'+modulesId?.id,values);
            if(!res?.data?.error){
                dispatch(showToaster({
                    dialogBgColor: "bg-success",
                    dialogMsg: res?.data?.message,
                    showDialog: true,
                    timer: "3000"
                }));
                getModules(module?.page)
            }
        } catch (error) {
            dispatch(showToaster({
                dialogBgColor: "bg-danger",
                dialogMsg: error.response ? error.response.data.message : "Failed to update module",
                showDialog: true,
                timer: "3000"
            }))
        }
        dispatch(showLoader({showLoader:false}))
    },[]);

    const deleteModule = useCallback(async(val) => {
        dispatch(showLoader({showLoader:true}));
        try {
            await DeleteApiMethod(`module/${val.id}`);
            dispatch(showToaster({
                dialogBgColor:'bg-success',
                dialogMsg:val.name+'has been deleted',
                showDialog:true,
                timer:"3000"
            }));
            getModules(module?.page);
            console.log("deleted")
        } catch (error) {
            console.log("deleted error")
            dispatch(showToaster({
                dialogBgColor: "bg-danger",
                dialogMsg: error.response ? error.response.data.message : "Failed to update module",
                showDialog: true,
                timer: "3000"
            }))
        }
        dispatch(showLoader({showLoader:false}))
    },[]);

    const editPermission = PermissionCheck("pb_module_edit");
    const deletePermission = PermissionCheck("pb_module_delete");
    const viewPermission = PermissionCheck("pb_module_view");

    return (
        <>
             <Grid container spacing={4} className='mt-1'>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormLabel className='fw-bold fs-6'>Search</FormLabel>
                    <TextField 
                        variant='standard'
                        placeholder='Type here...'
                        className='form-control'
                        onChange={(e) => getModules({...module?.page,searchString:e.target.value})}
                    />
                </Grid>
            </Grid>
            {viewPermission && <TableContainer className="mt-2">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {module?.data?.map((val,key) => (
                            <TableRow key={key}>
                                <TableCell>{val.name}</TableCell>
                                <TableCell align='right'>
                                    <MenuGroup 
                                        id={val.module_id}
                                        view={false}
                                        edit={editPermission}
                                        delete={deletePermission}
                                        clickEdit={() => setModule({...module,edit:true,modal:true,name:val.name,id:val.id})}
                                        clickDelete={() => deleteModule(val)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                        {module?.data.length === 0 && <TableRow>
                            <TableCell colSpan={2} align='center'>No Data</TableCell>
                        </TableRow>}
                    </TableBody>
                    {module?.data?.length !== 0 &&<TableFooter>
                        <Pagination colSpan={2} pagination={module?.page} rowsPerPage={10} rowsPerPageChange={handleNextPage} />
                    </TableFooter>}
                </Table>
            </TableContainer>}
            
            {/**Module Modal */}
            <CustomModal
                open={module?.modal}
                onClose={() => setModule({...module,...{modal:false}})}
                header={`${module?.edit ? "Edit " : "Create "}Module`}
                style={ModalStyle({width:600,height:'auto'})}
            >
                <Formik
                    initialValues={{
                        name: module?.name
                    }}
                    validationSchema={moduleSchema}
                    onSubmit={async (values, { resetForm }) => {
                        if (module?.edit) {
                            updateModule(values, module);
                        } else {
                            createModule(values, resetForm)
                        }
                    }}
                >
                    {({ handleSubmit, handleChange, values, errors, touched }) => (
                        <>
                            <Box className="modal-body">
                                <Form onSubmit={handleSubmit}>
                                    <Grid container spacing={1} className='p-3'>
                                        <Grid item md={12}>
                                            <FormLabel className='fw-bold'>Name:<sup className='text-danger'>*</sup></FormLabel>
                                            <TextField
                                                className='w-100'
                                                placeholder='Type here...'
                                                variant='standard'
                                                value={values.name}
                                                name='name'
                                                onChange={handleChange}
                                            />
                                            {touched.name && errors.name && <div className='text-danger'>{errors.name}</div>}
                                        </Grid>
                                    </Grid>
                                    <Box className="d-flex justify-content-end mt-3 p-3">
                                        <button className='btn btn-danger' type="submit">Submit</button>
                                    </Box>
                                </Form>
                            </Box>
                        </>
                    )}
                </Formik>
            </CustomModal>
        </>
    )
}

export default ModuleTable
import React, { useEffect, useState, useCallback, useContext } from 'react'
import { AuthorizedLayout } from '../Layouts/AuthorizedLayout';
import { Box, Grid, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Table, TableBody, TableHead, TableRow, TableCell, TableContainer, Typography, IconButton, Menu, MenuItem, Modal } from '@mui/material';
import { useAppDispatch } from '../../Store/hooks';
import { showLoader } from '../../Store/loaderSlice';
import { DeleteApiMethod, PostApiMethod, PutApiMethod } from '../../Utils/ApiService';
import { showToaster } from '../../Store/toastSlice';
import Pagination from '../../Components/Pagination/Pagination';
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";
import CloseIcon from '@mui/icons-material/Close';
import { ModalStyle } from '../../Components/ModalStyle';
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import { VisitorContext } from '../Visitor/Visitor';

const deptSchema = Yup.object().shape({
    name: Yup.string().required("Name is required")
})


const AdditionalRequirement = () => {
    const [deptList, setDeptList] = useState([]);
    const [page, setPage] = useState({page:1,limit:5,count:0});
    const [anchorEl, setAnchorEl] = useState(null)
    const [cOpen, setcOpen] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [editData, setEditData] = useState({});
    const visitor = useContext(VisitorContext);
    const dispatch = useAppDispatch();

    const apiResponse = (color,msg) => {
        dispatch(showToaster({dialogBgColor: color,
        dialogMsg: msg,
        showDialog: true,
        timer: "3000",}))
    }

    const handleClose = () => {
        setcOpen(0)
        setAnchorEl(null) 
    }
    
    const handleClick = (id,event) => {
        setcOpen(cOpen === id ? 0 : id);
        setAnchorEl(event.currentTarget);
    }

    useEffect(() => {
        getRequirement(page)
    },[])

    const createRequirement = async(val,resetForm) => {
        setModalOpen(false)
        dispatch(showLoader({showLoader:true}))
        try{
            let res = await PostApiMethod("property/additional-requirement/add",val);
            if(res["data"]["error"] === false){
                apiResponse("bg-success","Requirement created successfully");
                resetForm({values:''})
                getRequirement(page)
                visitor.getAdditionalRequirement();
            }
        }catch(error){
            apiResponse("bg-danger","Failed to create requirement");
        }
        dispatch(showLoader({showLoader:false}))
    }

    const getRequirement = useCallback(async(params,str) => {
        dispatch(showLoader({showLoader:true}))
        var parameters = params.limit === -1 ? {} : {page:params.page,limit:params.limit};
        try{
            let res = await PostApiMethod("property/additional-requirement/list",{...parameters,searchString:str});
            if(res["data"]["error"] === false){
                setDeptList(res["data"]["results"]['data'])
                setPage({page:params.page,limit:params.limit,count:res["data"]["results"]["totalCount"]});
            }
        }catch(error){
            apiResponse("bg-danger","Failed to fetch requirement details")
            setDeptList([]);
        }
        dispatch(showLoader({showLoader:false}))
    },[deptList]);

    const handleNextPage = useCallback((val) => {
        dispatch(showLoader({ showLoader: true }));
        setPage({...page,page:val.page,limit:val.limit})
        getRequirement(val)
    },[page])

    const editModalOpen = (val) => {
        setEditData({id:val.id,name:val.name})
        setEditOpen(true);
        setModalOpen(true)
    }

    const updateRequirement = async(val) => {
        setEditOpen(false);
        setModalOpen(false);
        dispatch(showLoader({showLoader:true}))
        try{
            let res = await PutApiMethod("property/additional-requirement/update/"+editData.id,val);
            if(res["data"]["error"] === false){
                apiResponse("bg-success",val.name+" details updated successfully");
                getRequirement(page);
                visitor.getAdditionalRequirement();
            }
        }catch(error){
            apiResponse("bg-danger","Failed to update requirement data")
        }
        dispatch(showLoader({showLoader:false}))
    }

    const deleteRequirement = async(val) => {
        dispatch(showLoader({showLoader:true}))
        try{
            let res = await DeleteApiMethod("property/additional-requirement/"+val.id)
            apiResponse("bg-success",val.name+" has been deleted")
            getRequirement({page:1,limit:5,count:0});
            visitor.getAdditionalRequirement();
        }catch(error){
            apiResponse("bg-danger","Failed to delete requirement details")
        }
        dispatch(showLoader({showLoader:false}))
    }

    return (
        <>
            {/* <AuthorizedLayout > */}
                <Box className='d-flex justify-content-between align-items-center flex-wrap'>
                    {/* <h4>Requirement</h4> */}
                    <Box className="w-25">
                        <Typography className='fw-bold mt-3'>Search</Typography>
                        <TextField 
                            placeholder='Type here'
                            variant='standard'
                            className='w-100'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                    <Search />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) => getRequirement(page,e.target.value)}
                        />
                    </Box>
                    <button className='btn btn-danger' onClick={() => setModalOpen(true)}><AddIcon/>Add New Requirement</button>
                </Box>
                <Box className="mt-4">
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell>SI.NO</TableCell> */}
                                    <TableCell>Name</TableCell>
                                    <TableCell align="right">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {deptList.length !== 0 && deptList.map((val,key) => {
                                    return (
                                        <TableRow key={key}>
                                            {/* <TableCell>{key +1}</TableCell> */}
                                            <TableCell >{val.name}</TableCell>
                                            <TableCell align="right">
                                            <IconButton
                                                aria-label="more"
                                                id={"long-button_" + val.id}
                                                aria-controls={
                                                    cOpen === val.id
                                                    ? "long-menu_" + val.id
                                                    : undefined
                                                }
                                                aria-expanded={
                                                    cOpen === val.id ? "true" : undefined
                                                }
                                                aria-haspopup="true"
                                                onClick={(e) => {
                                                    handleClick(val.id, e);
                                                }}
                                            >
                                                <Typography className="poppins-family" color="primary">
                                                    Action
                                                </Typography>
                                                <KeyboardArrowDownTwoToneIcon color="primary" />
                                            </IconButton>
                                            <Menu
                                                id={"long-menu_" + val.id}
                                                MenuListProps={{
                                                    "aria-labelledby": "long-button_" + val.id,
                                                }}
                                                anchorEl={anchorEl}
                                                open={cOpen === val.id}
                                                onClose={handleClose}
                                            >
                                                <MenuItem className='text-primary' onClick={() => editModalOpen(val)}>Edit</MenuItem>
                                                <MenuItem className='text-danger' onClick={() => deleteRequirement(val)}>Delete</MenuItem>
                                            </Menu>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                                {deptList.length === 0 && <TableRow>
                                    <TableCell colSpan={3} align="center">No Data</TableCell>
                                </TableRow>}
                            </TableBody>
                            {deptList.length !== 0 && <Pagination pagination={page} rowsPerPage={5} rowsPerPageChange={handleNextPage}/>}
                        </Table>
                    </TableContainer>
                </Box>

                <Modal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                >
                    <Box sx={ModalStyle({width:600,height:280})}>
                        <Box className="border-bottom p-3 d-flex justify-content-between align-items-center">
                            <Typography className='fw-bold fs-5'>{!editOpen ? "Create" : "Edit"} Requirement</Typography>
                            <CloseIcon sx={{cursor:'pointer'}} onClick={() => setModalOpen(false)}/>
                        </Box>
                        <Box>
                            <Formik
                                initialValues={{
                                    name: editOpen ? editData.name : ''
                                }}
                                validationSchema={deptSchema}
                                onSubmit={async(values, {resetForm}) => {
                                    if(editOpen){
                                        updateRequirement(values)
                                    }else{
                                        createRequirement(values,resetForm)
                                    }
                                }}
                            >
                                {({ handleSubmit, handleChange, values, errors, touched }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <Grid container spacing={2} className='p-4 '>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Typography className='fw-bold'>Name</Typography>
                                                <TextField
                                                    type="text"
                                                    className='w-100'
                                                    placeholder='Type here...'
                                                    value={values.name}
                                                    name='name'
                                                    variant="standard"
                                                    onChange={handleChange}
                                                />
                                                {touched.name && errors.name && <div className="text-danger">{errors.name}</div>}
                                            </Grid>
                                        </Grid>
                                        <Box className="border-top p-4 d-flex justify-content-end mt-3">
                                            <button className='btn btn-primary'>Submit</button>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                        
                    </Box>
                </Modal>
            {/* </AuthorizedLayout> */}
        </>
    )
}

export default AdditionalRequirement
import * as Yup from "yup";

export const bookingSchema = Yup.object().shape({
    propertyId: Yup.string().required("Property field is required"),
    unitId: Yup.string().required("Unit field is required"),
    // name: Yup.string().required("First name field is required"),
    amenitiesId: Yup.string().required("Amenities field is required"),
    bookingdate: Yup.string().required("Date field is required"),
    startTime: Yup.string().required("Start Time field is required"),
    endTime: Yup.string().required("End Time field is required"),
    no_of_people: Yup.string().required("No of people field is require")
}) 

export const amenitiesSchema = Yup.object().shape({
    name: Yup.string().required("Amenity field is required"),
    propertyId: Yup.string().required("Property field is required"),
    openingTime: Yup.string().required("Open Timing field is required"),
    closingTime: Yup.string().required("Close Timing field is required")
})

import React, { createContext, useCallback, useEffect, useState } from 'react';
import { AuthorizedLayout } from '../Layouts/AuthorizedLayout';
import BoxHeader from '../../Components/Box/BoxHeader';
import { Modal, Typography, Box, FormLabel, TextField, Grid } from '@mui/material';
import SelectedUserMenu from "../../Assets/images/menu-icons/manage_accounts_selected.png";
import UserTable from './UserTable';
import AddUser from './AddUser';
import PermissionForm from './PermissionForm';
import { AntTab, AntTabs } from "../../Components/Tab/AntTab";
import CustomTabPanel from "../../Components/Tab/CustomTabPanel";
import RoleTable from './RoleTable';
import { ModalStyle } from '../../Components/ModalStyle';
import CloseIcon from '@mui/icons-material/Close';
import { Form, Formik } from 'formik';
import { roleSchema } from './AddUserSchema';
import { PostApiMethod, PutApiMethod } from '../../Utils/ApiService';
import { useAppDispatch } from '../../Store/hooks';
import { showToaster } from '../../Store/toastSlice';
import { showLoader } from '../../Store/loaderSlice';
import ModuleTable from './ModuleTable';
import PageTable from './PageTable';
import ModulePage from './ModulePage';
import ModuleRole from './ModuleRole';
import PagePermission from './PagePermission';
import { ListMenu } from '../../Components/DropDown/ListMenu';
import { PermissionCheck } from '../../App/PermissionCheck';

export const UserContext = createContext();

const UserManagement = () => {
    const [data, setData] = useState({
        userData:[],
        page:{page:1,limit:5},
        open:false,
        edit:false
    });
    const [searchUser, setSearchUser] = useState('');
    const [masterData, setMasterData] = useState({
        roles:[],
        modules:[],
        pages:[]
    })
    const rolePermission = PermissionCheck('pb_role_view');
    const modulePermission = PermissionCheck('pb_module_view');
    const subModulePermission = PermissionCheck('pb_submodule_view');
    const roleAddPermission = PermissionCheck("pb_role_add");
    const moduleAddPermission = PermissionCheck("pb_module_add");
    const subModuleAddPermission = PermissionCheck("pb_submodule_add");
    const userAddPermission = PermissionCheck("pb_user_add");
    const [selectedIndex, setSelectedIndex] = useState(rolePermission ? 0 : modulePermission ? 1 : subModulePermission ? 2 : 0);
    const [module, setModule] = useState({
        data:[],
        modal:false,
        page:{page:1,limit:5,count:0},
        edit:false,
        name:'',
        searchString:'',
    });
    const [role, setRole] = useState({
        modal:false,
        name:'',
        page:{page:1,limit:5,count:0},
        data:[],
        edit:false,
        searchString:'',
    });
    const [page, setPage] = useState({
        modal:false,
        formData:{},
        page:{page:1,limit:5,count:0},
        data:[],
        edit:false,
        searchString:'',
    });
    const [modulePage, setModulePage] = useState({
        modal:false,
        formData:{},
        page:{page:1,limit:5,count:0},
        data:[],
        edit:false,
        searchString:'',
    });
    const [moduleRole, setModuleRole] = useState({
        modal:false,
        formData:{},
        page:{page:1,limit:5,count:0},
        data:[],
        edit:false,
        searchString:'',
    });
    const [pagePermission, setPagePermission] = useState({
        modal:false,
        permission:[{
            module_id:'',
            role_id:'',
            page_id:'',
            view: false,
            add: false,
            edit: false,
            remove: false
        }],
        page:{page:1,limit:5,count:0},
        data:[],
        edit:false,
        searchString:'',
    });

    const [tabValue, setTabValue] = useState(0);
    const dispatch = useAppDispatch();

    useEffect(() => {
        getRoles();
        getModules();
        getUsers({page:1,limit:5});
    },[])

    const handleTabChange = useCallback((e,val) => {
        setTabValue(val)
    },[tabValue]);

    const getRoles = async() => {
        try {
            let res = await PostApiMethod('role/getRole');
            if(!res?.data?.error){
                setMasterData((pre) =>({...pre,roles:res?.data?.results?.data}))
            }
        } catch (error) {
            dispatch(showToaster({
                dialogBgColor:'bg-danger',
                dialogMsg: error.response ? error.response.data.message : `Failed to get roles data`,
                showDialog: true,
                timer: "3000"
            }))
        }
    };

    const getModules = async() => {
        try {
            let res = await PostApiMethod('module/getModule');
            if(!res?.data?.error){
                setMasterData((pre) => ({...pre,modules:res?.data?.results?.data}))
            }
        } catch (error) {
            dispatch(showToaster({
                dialogBgColor:'bg-danger',
                dialogMsg: error.response ? error.response.data.message : `Failed to get modules data`,
                showDialog: true,
                timer: "3000"
            }))
        }
    }

    const getUsers = async(data) => {
        dispatch(showLoader({showLoader:true}))
        try{
            let res = await PostApiMethod("user/parentUsers",{
                page:data?.page,
                limit:data?.limit === -1 ? 0 : data?.limit,
                searchString:data?.searchString,
                parentId: "1"
            });
            if(!res?.data?.error){
                setData({
                    userData:res?.data?.results?.data,
                    open: false,
                    edit: false,
                    page:{
                        page:data?.page,
                        limit:data?.limit,
                        count:res?.data?.results?.totalCount
                    }
                })
            }
        }catch(error){
            dispatch(showToaster({
                dialogBgColor:'bg-danger',
                dialogMsg: error.response ? error.response.data.message : `Failed to get modules data`,
                showDialog: true,
                timer: "3000"
            }))
        }
        dispatch(showLoader({showLoader:false}))
    }

    let tabAddFun = [
        {name:"Add Role",func:() => setRole({...role,modal:true,edit:false,name:''}),component:<RoleTable />,show:rolePermission,add:roleAddPermission},
        {name:"Add Module",func:() => setModule({...module,modal:true,edit:false,name:''}),component:<ModuleTable />,show: modulePermission,add:moduleAddPermission},
        /**Modules & Pages changed to Sub Module */
        {name:"Sub Module",func:() => setModulePage({...modulePage,modal:true,edit:false,}),component:<ModulePage />,show: subModulePermission,add:subModuleAddPermission },
    ];

    return (
        <>
            <AuthorizedLayout>
                <UserContext.Provider value={{
                    userData:data.userData,
                    role,
                    setRole,
                    module,
                    setModule,
                    page,
                    setPage,
                    modulePage,
                    setModulePage,
                    moduleRole,
                    setModuleRole,
                    pagePermission,
                    setPagePermission,
                    masterData,
                    setMasterData,
                    data,
                    setData,
                    getUsers,
                    searchUser,
                    setSearchUser
                }}>
                    <BoxHeader 
                        dashboard_name="User Management"
                        button_name={ tabValue === 0 ? "Add User" : tabAddFun[selectedIndex]["name"]} 
                        image={SelectedUserMenu}
                        onAdd={() => tabValue === 0 ?  setData({
                            userData:[...data.userData],
                            page:{...data.page}, 
                            open:true, 
                            edit:false
                        }) 
                            : tabAddFun[selectedIndex]['func']() }
                        button_show={tabValue === 0 ? userAddPermission && !data?.open : tabAddFun[selectedIndex]?.add}
                    >
                        <AntTabs value={tabValue} onChange={handleTabChange}>
                            <AntTab label="All Users" className='tab-menu'/>
                            <AntTab label={
                                <ListMenu selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} data={tabAddFun} />
                            } />
                        </AntTabs>
                    </BoxHeader>
                    <CustomTabPanel value={tabValue} index={0}>
                        { !data?.open ? <UserTable /> : <AddUser /> }
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={1}>
                        {tabAddFun[selectedIndex]['component']}
                    </CustomTabPanel>
                </UserContext.Provider>
            </AuthorizedLayout>
        </>
    )
}

export default UserManagement

import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Profile from '../../Assets/images/profile.png';
import Button from '@mui/material/Button';
import { GetApiMethod, PostApiMethod, PutApiMethod } from '../../Utils/ApiService';
import { isNotEmpty } from '../../Utils/HelperService';
import { useNavigate } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAppDispatch } from "../../Store/hooks";
import { showLoader } from "../../Store/loaderSlice";

const ITEM_HEIGHT = 48;
const ListTenantHistory = (props: any) => {
    // const [property, setProperty] = useState("")
    let navigate = useNavigate();
    const getInitialState = () => ({ id: "", type: "", description: "", attachement: "", status: true, userId: 1, property_id: "", unit_id: "" })
    const [state, setState] = useState(getInitialState)
    const [loading, setLoading] = useState(false);
    const [addTenant, setAddTenant] = useState(false);
    const [tenantList, setTenantList] = useState([]);
    const [tenantCount, setTenantCount] = useState(0);
    const [label, setLabel] = useState("Add");
    const [btnLabel, setBtnLabel] = useState("Add");
    const [upData, setUpData] = useState<any>([]);
    const [propertyList, setPropertyList] = useState([]);
    const [unitList, setUnitList] = useState([]);
    const [showTenant, setShowTenant] = useState(false);
    const [assignVendors, setAssignVendors] = useState(false);
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState('')
    const dispatch = useAppDispatch();

    useEffect(() => {
        setState({ ...state, "attachement": upData[0] })
    }, [upData])
    const fetchProperty = async () => {
        dispatch(showLoader({showLoader:true}))
        let response = await PostApiMethod("property/properties/list", {})
        if (response["data"]["error"] === false) {
            setPropertyList(response.data.results.data);
            setLoading(false)
        }
        else {
            console.log("response", response)
            setLoading(false)
        }
    }
    const fetchUnit = async () => {
        let response = await PostApiMethod("property/unit/list", {})
        if (response["data"]["error"] === false) {
            setUnitList(response.data.results.data);
            setLoading(false)
        }
        else {
            console.log("response", response)
            setLoading(false)
        }
    }
    const fetchTenantSearchList = async (search: any = "") => {
        dispatch(showLoader({showLoader:true}))
        setLoading(true)
        let formdata = {
            page: page,
            limit: limit,
            searchString: search,
            role: "PMU"
        }
        let response = await PostApiMethod('user/getUser', formdata)
        if (response["data"]["error"] === false) {
            setTenantList(response.data.results.data)
            setTenantCount(response.data.results.totalCount)
            setLoading(false)
        }
        else {
            console.log("response", response)
            setLoading(false)
        }
        dispatch(showLoader({showLoader:false}))
    }
    useEffect(() => {
        fetchProperty();
        fetchUnit();
        fetchTenantSearchList();
    }, [])

    const handleChange = (e: any) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true)
        // form.append()
        const form = new FormData();
        let tState: any = { ...state }
        Object.keys(state).map((val: any, key) => {
            form.append(val, tState[val])
        })
        let res;
        if (label === "Add") {
            res = await PostApiMethod('property/help-desk/add', form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        } else {
            console.log("form", state.type)
            res = await PutApiMethod('property/help-desk/update/' + state.id, form)
        }
        if (res["data"]["code"] === 201) {
            console.log("success");
            setState(getInitialState);
            navigate('/tenant')
        }
        else {
            console.log("res", res)
            setLoading(false)
        }
        setLoading(false)
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
        props.parentData(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        props.parentData(event.target.value);
        props.limit(parseInt(event.target.value, 10));
    };
    const fetchTenantById = async (id: any) => {
        let response = await GetApiMethod('property/help-desk/' + id)
        if (response["data"]["error"] === false) {
            setState(response.data.results.data);
            setLoading(false)
        }
        else {
            console.log("response", response)
            setLoading(false)
        }
    }
    // const handleTenantEdit = async (data: any) => {
    //     setLabel("Edit");
    //     setBtnLabel("Save");
    //     setAddTenant(true);
    //     let option = await fetchTenantById(data.id)
    // }
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [cOpen, setcOpen] = useState<Number>(0)
    const handleClick = (id: Number, event: React.MouseEvent<HTMLElement>) => {
        setcOpen(cOpen === id ? 0 : id)
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e: any, data: any) => {
        setcOpen(0)
        setAnchorEl(null);
        if (e === "View") {
            props.pageStatus('showTenant');
            // fetchTenantById(data)
            let tmpdata = {
                page: e,
                data: data
            }
            props.pageData(tmpdata)
        }
        if (e === "Edit") {
            props.pageStatus('addTenant');
            let tmpdata = {
                page: e,
                data: data
            }
            props.pageData(tmpdata)
        }
        if (e === "Delete") {
            deleteTenant(data.id);
        }
    };

    const deleteTenant = async (id: any) => {
        // let response = await DeleteApiMethod('property/help-desk/' + id)
        // if (response["data"]["error"] === false) {
        //     fetchTenantSearchList();
        // }
        // else {
        //     console.log("response", response)
        // }
        setLoading(false)
    }
    const options = [
        'Edit',
        'Delete',
    ];
    return (
        <>
            <Box sx={{ mt: 3 }}>
                <h5><ArrowBackIcon sx={{cursor:'pointer'}} onClick={() => { props.historyTab();}}/> Tenant History</h5>
                <Paper>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>SI.No</TableCell>
                                    <TableCell>Tenant</TableCell>
                                    <TableCell>Property</TableCell>
                                    <TableCell>Unit</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>General Rent</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>View</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tenantList && tenantList.map((tenant: any, index: any) =>
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                            {isNotEmpty(tenant.profilePicture) ? <img src={tenant.profilePicture} alt="profile" width={40} height={40} /> : <img src={Profile} alt="profile" width={40} height={40} />}
                                            {isNotEmpty(tenant.firstName) ? tenant.firstName : "" + " " + isNotEmpty(tenant.lastName) ? tenant.lastName : ""}
                                        </TableCell>
                                        <TableCell>{tenant.property && tenant.property.property_name}</TableCell>
                                        <TableCell>{tenant.unit && tenant.unit.unit_name}</TableCell>
                                        <TableCell>{isNotEmpty(tenant.email) ? tenant.email : ""}</TableCell>
                                        <TableCell>{isNotEmpty(tenant.phoneNumber) ? tenant.phoneNumber : ""}</TableCell>
                                        <TableCell>{ tenant.isActive && "Active" }</TableCell>
                                        <TableCell> 
                                            <Button variant="contained" onClick={() => handleClose("View", tenant)} style={{ borderRadius: 25 }}>
                                                View
                                            </Button>
                                        </TableCell>
                                        
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {tenantCount ?
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 100]}
                            component="div"
                            count={tenantCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            style={{ margin: 0 }}
                        />
                        : ""}
                </Paper>
            </Box>
        </>
    )
}

export default ListTenantHistory
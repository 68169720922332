import React, { useEffect, useState } from "react";
import { Box, Grid, TextField, Button, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import { UpdateExpenseSchema } from "../../Components/Validation";
import { useFormik } from "formik";
import { PutApiMethod , PostApiMethod ,DeleteApiMethod} from "../../Utils/ApiService";
import { showLoader } from "../../Store/loaderSlice";
import { errorHandling } from "../../Utils/HelperService";
import { showToaster } from "../../Store/toastSlice"; 
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormLabel from "@mui/material/FormLabel";
import AddIcon from "@mui/icons-material/Add";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Modal from "@mui/material/Modal";
import Swal from "sweetalert2";

const EditExpense = (props: any) => {  
  const loader = useAppSelector((state) => state.loader);
  const [propertyList, setPropertyList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [expenseTypeList, setExpenseTypeList] = useState([]);
  const [TagList, setTagList] = useState([]);
  const [openExpenseType, setOpenExpenseType] = useState(false);
  const [openUnitType, setOpenUnitType] = useState(false);
  const [expenseTypeName, setExpenseTypeName] = useState('');
  const [TagTypeName, setTagTypeName] = useState('');
  const [error, setError] = useState('');
  const [error2, setError2] = useState('');
  const [imgArray , setImgArray] = useState([]);
  const [images, setImages] = useState<File[]>([]);

  const [initialValue, setInitialValue] = useState({
    name: "",
    property_id: "",
    unit_id : "",
    expense_type_id: "",
    description: "",
    responsibility: "",
    amount: "",
    tag_id: "",
    FileManagers:"",
    id:""
  });
  const dispatch = useAppDispatch();

  const handleImageChange = (e:any) => {
    e.preventDefault();
    setImages(e.target.files);
  };
  const submit = async (value: any) => {
      dispatch(showLoader({ showLoader: true }));

      const param = {
        name: value.name,
        propertyId: value.property_id,
        unitId: value.unit_id   ,
        expenseTypeId: value.expense_type_id,
        description: value.description,
        responsibility: value.responsibility,
        amount: value.amount,
        tagId: value.tag_id,
      };
      if (images.length > 0) {
        const form = new FormData();
        form.append("name",value.name)
        form.append("propertyId",value.property_id)
        form.append("unitId",value.unit_id)
        form.append("expenseTypeId",value.expense_type_id)
        form.append("description",value.description)
        form.append("responsibility",value.responsibility)
        form.append("amount",value.amount)
        form.append("tagId",value.tag_id)
        for (let image of images) {
          form.append("attachment", image);
        }
        await PutApiMethod(`property/expense/update/${initialValue.id}`, form, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }
      else{
        await PutApiMethod("property/expense/update/" + initialValue.id, param)
      }
          dispatch(showLoader({ showLoader: false }));
          dispatch(
            showToaster({
              dialogBgColor: "success",
              dialogMsg: "Updated successfully",
              showDialog: true,
              timer: "5000",
            })
          );
          props.pageStatus("listExpense");
          dispatch(showLoader({ showLoader: false }));
    }

    const handleImageDelete = async (Imgid: any) => {
      dispatch(showLoader({ showLoader: true }));
      try {
        const res = await DeleteApiMethod(`property/file-manager/${Imgid}`);
        dispatch(showLoader({ showLoader: false }));
        dispatch(
          showToaster({
            dialogBgColor: "success",
            dialogMsg: "Image Deleted successfully",
            showDialog: true,
            timer: "5000",
          })
        );
        setImgArray((prevImgArray: any) => prevImgArray.filter((file: any) => file.id !== Imgid));
      } catch (error) {
        dispatch(showLoader({ showLoader: false }));
        dispatch(
          showToaster({
            dialogBgColor: "error",
            dialogMsg: "Failed to delete image",
            showDialog: true,
            timer: "5000",
          })
        );
      }
    };


  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: UpdateExpenseSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      submit(values);
    },
  });
  const handleExpenseTypeOpen = () => {
    setOpenExpenseType(!openExpenseType);
  };

  const handleUnitTypeOpen = () => {
    setOpenUnitType(!openUnitType);
  };
  const fetchProperty = async () => {
    let response = await PostApiMethod("property/properties/list", {});
    if (response["data"]["error"] === false) {
      setPropertyList(response.data.results.data);
    } else {
      console.log("response", response);
    }
  };
  const fetchUnit = async () => {
    let response = await PostApiMethod("property/unit/list", {});
    if (response["data"]["error"] === false) {
      setUnitList(response.data.results.data);
    } else {
      console.log("response", response);
    }
  };
  const fetchExpenseType = async () => {
    let response = await PostApiMethod("property/expenseType/list", {});
    if (response["data"]["error"] === false) {
      setExpenseTypeList(response.data.results.data);
    } else {
      console.log("response", response);
    }
  };
  const fetchTag = async () => {
    let response = await PostApiMethod("property/tag/list", {});
    if (response["data"]["error"] === false) {
      setTagList(response.data.results.data);
    } else {
      console.log("response", response);
    }
  };

  const handleAddExpenseType = async (e:any) => {
    e.preventDefault();
    try{
      if (expenseTypeName.trim() === '') {
        setError('Expense Type Name is required');
        return;
      }
      setError('');
      let res:any = await PostApiMethod("property/expenseType/add", {name:expenseTypeName});
      setOpenExpenseType(!openExpenseType);
      setExpenseTypeName('');
      fetchExpenseType();
      dispatch(
        showToaster({
          dialogBgColor: "success",
          dialogMsg:"Expense Type  added successfully",
          showDialog: true,
          timer: "5000",
        })
      );
    }
    catch(err:any){
      const error = errorHandling(err);
      dispatch(
        showToaster({
          dialogBgColor: "bg-danger",
          dialogMsg: error,
          showDialog: true,
          timer: "5000",
        })
      );
      dispatch(showLoader({ showLoader: false }));
    }
   
  };

  const handleAddTagType = async (e:any) => {
    e.preventDefault();
    try{
      if (TagTypeName.trim() === '') {
        setError2('Tag Name is required');
        return;
      }
      setError2('');
      let res:any = await PostApiMethod("property/tag/add", {name:TagTypeName});
      setOpenUnitType(!openUnitType);
      setTagTypeName('');
      fetchTag();
      dispatch(
        showToaster({
          dialogBgColor: "success",
          dialogMsg:"Tag  added successfully",
          showDialog: true,
          timer: "5000",
        })
      );
    }
    catch(err:any){
      const error = errorHandling(err);
      dispatch(
        showToaster({
          dialogBgColor: "bg-danger",
          dialogMsg: error,
          showDialog: true,
          timer: "5000",
        })
      );
      dispatch(showLoader({ showLoader: false }));
    }
   
  };
  useEffect(() => {
    fetchProperty();
    fetchUnit();
    fetchExpenseType();
    fetchTag();
  }, []);

  useEffect(() => {
    if (props.expenseData) {
      setInitialValue(props.expenseData);
      setImgArray(props.expenseData.FileManagers)
    }
  }, [props.expenseData]);
  return (
    <>
      <Box sx={{ mt: 3 }}>
        <form onSubmit={handleSubmit}>
        <FormControl className="mt-3">
          <Box className="card-title mb-3">
            <Typography variant="h5">
              Update Expense
            </Typography>
          </Box>
          <FormLabel
            className="poppins-family text-black"
            id="responsibilty-type"
          >
            Responsibility Type
          </FormLabel>
          <RadioGroup row aria-labelledby="responsibility" name="responsibility" value={values.responsibility} onChange={handleChange}>
            <FormControlLabel
              className="poppins-family"
              value="Tenant"
              control={<Radio />}
              label="Tenant"
            />
            <FormControlLabel
              className="poppins-family"
              value="Property Owner"
              control={<Radio />}
              label="Property Owner"
            />
          </RadioGroup>
        </FormControl>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
            <FormLabel className="poppins-family text-black">Name</FormLabel>
              <TextField
                type="text"
                variant="standard"
                placeholder="Type here..."
                className="w-100"
                name="name"
                onChange={handleChange}
                value={values.name}
              />
                  {errors.name && touched.name && (
                  <Typography className="text-danger">{errors.name}</Typography>
                )}
                </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
            <FormLabel className="poppins-family text-black">
                Property
              </FormLabel>
              <FormControl variant="standard" className="w-100">
                 <Select
                    name="property_id"
                    value={values.property_id}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="">
                    None
                  </MenuItem>
                  {propertyList.map((data:any) => (
                    <MenuItem key={data.id} value={data.id}>
                      {data.property_name}
                    </MenuItem>
                  ))}
                </Select>
             {errors.property_id && touched.property_id && (
                  <Typography className="text-danger">{errors.property_id}</Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
            <FormLabel className="poppins-family text-black">
                Unit Name
              </FormLabel>
              <FormControl variant="standard" className="w-100">
                <Select
                    name="unit_id"
                    value={values.unit_id}
                    onChange={handleChange}
                    displayEmpty
                  >
                <MenuItem value="">
                    None
                  </MenuItem>
                {unitList.map((data:any) => (
                    <MenuItem key={data.id} value={data.id}>
                      {data.unit_name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.unit_id && touched.unit_id && (
                  <Typography className="text-danger">{errors.unit_id}</Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
            <Box className="d-flex justify-content-between">
                <FormLabel className="poppins-family text-black">
                  Expense Type
                </FormLabel>
                <Typography
                  onClick={handleExpenseTypeOpen}
                  className="poppins-family text-primary d-flex align-items-center"
                  sx={{ cursor: "pointer", fontSize: "15px" }}
                >
                  <AddIcon sx={{ fontSize: "15px" }} />
                  Add New Type
                </Typography>
              </Box>
              <FormControl variant="standard" className="w-100">
                <Select
                    name="expense_type_id"
                    value={values.expense_type_id}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="">
                    None
                  </MenuItem>
                {expenseTypeList.map((data:any) => (
                    <MenuItem key={data.id} value={data.id}>
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.expense_type_id && touched.expense_type_id && (
                  <Typography className="text-danger">{errors.expense_type_id}</Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
            <FormLabel className="poppins-family text-black">
                Description
              </FormLabel>
              <TextField
                type="text"
                variant="standard"
                className="w-100"
                placeholder="Type here..."
                name="description"
                onChange={handleChange}
                value={values.description}
              />
                 {errors.description && touched.description && (
                  <Typography className="text-danger">
                    {errors.description}
                  </Typography>
                )}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
            <FormLabel className="poppins-family text-black">
                Amount
              </FormLabel>
              <TextField
                variant="standard"
                className="w-100"
                placeholder="Type here..."
                name="amount"
                onChange={handleChange}
                value={values.amount}
              />
                 {errors.amount && touched.amount && (
                  <Typography className="text-danger">
                    {errors.amount}
                  </Typography>
                )}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
            <Box className="d-flex justify-content-between">
                <FormLabel className="poppins-family text-black">
                Tag
                </FormLabel>
                <Typography
                  onClick={handleUnitTypeOpen}
                  className="poppins-family text-primary d-flex align-items-center"
                  sx={{ cursor: "pointer", fontSize: "15px" }}
                >
                  <AddIcon sx={{ fontSize: "15px" }} />
                  Add New Type
                </Typography>
              </Box>
              <FormControl variant="standard" className="w-100">
                <Select
                    name="tag_id"
                    value={values.tag_id}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="">
                    None
                  </MenuItem>
                {TagList.map((data:any) => (
                    <MenuItem key={data.id} value={data.id}>
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.tag_id && touched.tag_id && (
                  <Typography className="text-danger">{errors.tag_id}</Typography>
                )}
              </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
            <input
              name="file_name"
              type="file" 
              onChange={(e:any) =>handleImageChange(e)}
              placeholder="Upload image"
              accept="image/*"
              multiple
              // required
            />
            </Grid>
            <Grid item lg={12} md={4} sm={12} xs={12}>
              {imgArray.length > 0 && (
                imgArray.map((file: any) => (
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                  <img 
                  height={"80%"}
                width={"50%"}
                    src={`${process.env.REACT_APP_API_URL}/${file.folderPath}`} 
                    alt="" 
                    key={file.id} 
                  />
                  <Button   onClick={() => {
                Swal.fire({
                  title: "Are you sure?",
                  text: "You are about to Delete this Image!",
                  icon: "error",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                }).then((result:any) => {
                  if (result.isConfirmed) {
                    handleImageDelete(file.id);
                  }
                });
              }}
             className="btn-danger">delete</Button>
                  </Grid>
                ))
              )}
            </Grid>
          </Grid>
          <Box sx={{ mt: 4 }} className="d-flex">
            <Button
              className="btn-white"
              onClick={() => {
                props.pageStatus("listExpense");
              }}
            >
              <ChevronLeftIcon className="fs-20" />
              Back
            </Button>
            <Button
              className="btn-danger ms-2"
              type="submit"
              disabled={loader.showLoader}
            >
              Save
            </Button>
          </Box>
        </form>
      </Box>
       {/**Add New Expense Type start*/}
       <Modal open={openExpenseType}>
        <Box className="d-flex justify-content-center align-items-center vh-100 border-0">
          <Box className="bg-white p-4 rounded" sx={{ width: "30%" }}>
            <form onSubmit={handleAddExpenseType}>
            <Typography className="poppins-family fw-bold fs-6">
              Add New Expense Type
            </Typography>
            <Box className="mt-3">
              <Typography className="poppins-family">
                Expense Type Name
              </Typography>
              <TextField
                variant="standard"
                placeholder="Type here..."
                className="w-100"
                value={expenseTypeName}
                onChange={(e) => setExpenseTypeName(e.target.value)}        
              />
              {error && (
                  <Typography className="text-danger">
                    {error}
                  </Typography>
                )}
            </Box>
            <Box className="mt-3">
              <button
                className="btn btn-white border-0"
                onClick={handleExpenseTypeOpen}
              >
                <ChevronLeftIcon
                  sx={{ fontSize: "23px", marginRight: "-6px" }}
                />
                Back
              </button>
              <button type="submit" className="btn btn-danger ms-2">Save</button>
            </Box>
            </form>
          </Box>
        </Box>
      </Modal>
       {/**Add New Expense Type start*/}
         {/**Add New Tag Type Start*/}
      <Modal open={openUnitType}>
        <Box className="d-flex justify-content-center align-items-center vh-100 border-0">
          <Box className="bg-white p-4 rounded" sx={{ width: "30%" }}>
            <form onSubmit={handleAddTagType}>
            <Typography className="poppins-family fw-bold fs-6">
              Add New Tag Type
            </Typography>
            <Box className="mt-3">
              <Typography className="poppins-family">
                Tag Type Name
              </Typography>
              <TextField
                variant="standard"
                placeholder="Type here..."
                className="w-100"
                value={TagTypeName}
                onChange={(e) => setTagTypeName(e.target.value)}        
              />
              {error2 && (
                  <Typography className="text-danger">
                    {error2}
                  </Typography>
                )}
            </Box>
            <Box className="mt-3">
              <button
                className="btn btn-white border-0"
                onClick={handleUnitTypeOpen}
              >
                <ChevronLeftIcon
                  sx={{ fontSize: "23px", marginRight: "-6px" }}
                />
                Back
              </button>
              <button type="submit" className="btn btn-danger ms-2">Save</button>
            </Box>
            </form>
          </Box>
        </Box>
      </Modal>
      {/**Add New Tag Type End*/}
    </>
  );
};

export default EditExpense;
